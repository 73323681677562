import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Banner from "../Common/Banner/Banner";
import Breadcrumb from "../Common/Breadcrumb/Breadcrumb";
import SideNavbar from "../Templates/SideNavbar/AboutUsSideNavbar";
import LoadingOverlay from "react-loading-overlay";
import { getData } from "../../Utils/utils";

import { postData, sitekey } from "../../Utils/utils";
import Swal from "sweetalert2";
import Reaptcha from "reaptcha";
const html = `<!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
    <title></title>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
    <!--[if !mso]><!-->
    <!-- <link href="https://fonts.googleapis.com/css?family=Cabin" rel="stylesheet" type="text/css" />
    <link href="https://fonts.googleapis.com/css?family=Montserrat" rel="stylesheet" type="text/css" /> -->
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet">
    <!--<![endif]-->
    <style>
        * {
            box-sizing: border-box;
        }

        body {
            margin: 0;
            padding: 0;
        }

        a[x-apple-data-detectors] {
            color: inherit !important;
            text-decoration: inherit !important;
        }

        #MessageViewBody a {
            color: inherit;
            text-decoration: none;
        }

        p {
            line-height: inherit
        }

        .desktop_hide,
        .desktop_hide table {
            mso-hide: all;
            display: none;
            max-height: 0px;
            overflow: hidden;
        }

        @media (max-width:670px) {
            .desktop_hide table.icons-inner {
                display: inline-block !important;
            }

            .icons-inner {
                text-align: center;
            }

            .icons-inner td {
                margin: 0 auto;
            }

            .image_block img.big,
            .row-content {
                width: 100% !important;
            }

            .mobile_hide {
                display: none;
            }

            .stack .column {
                width: 100%;
                display: block;
            }

            .mobile_hide {
                min-height: 0;
                max-height: 0;
                max-width: 0;
                overflow: hidden;
                font-size: 0px;
            }

            .desktop_hide,
            .desktop_hide table {
                display: table !important;
                max-height: none !important;
            }
        }
    </style>
</head>

<body style="background-color: #FFF; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
    <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
        style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #FFF;" width="100%">
        <tbody>
            <tr>
                <td>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
                        role="presentation"
                        style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #FFF;" width="100%">
                        <tbody>
                            <tr>
                                <td>
                                    <table align="center" border="0" cellpadding="0" cellspacing="0"
                                        class="row-content stack" role="presentation"
                                        style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 650px;"
                                        width="650">
                                        <tbody>
                                            <tr>
                                                <td class="column column-1"
                                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                                                    width="100%">
                                                    <table border="0" cellpadding="0" cellspacing="0"
                                                        class="image_block block-1" role="presentation"
                                                        style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                        width="100%">
                                                        <tr>
                                                            <td class="pad"
                                                                style="padding-bottom:15px;padding-top:15px;width:100%;padding-right:0px;padding-left:0px;">
                                                                <div align="center" class="alignment"
                                                                    style="line-height:10px">
                                                                    <img alt="RMRMs" src="https://secureservercdn.net/192.169.220.85/dgx.08d.myftpupload.com/wp-content/uploads/2020/11/Layer-130.png"
                                                                        style="display: block; height: auto; border: 0; width: 100px; max-width: 100%;"
                                                                        title="RMRMs" width="195" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-2"
                        role="presentation"
                        style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #FFF;" width="100%">
                        <tbody>
                            <tr>
                                <td>
                                    <table align="center" border="0" cellpadding="0" cellspacing="0"
                                        class="row-content stack" role="presentation"
                                        style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; background: rgba(0, 0, 0, 0.1);color: #000000; width: 650px;border-radius: 20px;"
                                        width="650">
                                        <tbody>
                                            <tr>
                                                <td class="column column-1"
                                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-bottom: 0px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                                                    width="100%">
                                                    
                                                    <table border="0" cellpadding="0" cellspacing="0"
                                                        class="heading_block block-3" role="presentation"
                                                        style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                        width="100%">
                                                        <tr>
                                                            <td class="pad"
                                                                style="padding-top:35px;text-align:center;width:100%;">
                                                                <h1
                                                                    style="margin: 0; color: #000; direction: ltr; font-family: 'Jost', sans-serif; font-size: 28px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0;">
                                                                    <strong> {name} </strong>
                                                                </h1>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table border="0" cellpadding="0" cellspacing="0"
                                                        class="text_block block-4" role="presentation"
                                                        style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                                                        width="100%">
                                                        <tr>
                                                            <td class="pad"
                                                                style="padding-left:45px;padding-right:45px;padding-top:10px;">
                                                                <div style="font-family: Arial, sans-serif">
                                                                    <div class="txtTinyMce-wrapper"
                                                                        style="font-size: 12px; font-family: 'Jost', sans-serif; mso-line-height-alt: 18px; color: #393d47; line-height: 1.5;">
                                                                        <p
                                                                            style="margin: 0; text-align: center; mso-line-height-alt: 27px;">
                                                                            <span
                                                                                style="font-size:18px;color:#000;"> {message} </span>
                                                                        </p>
                                                                        <p
                                                                            style="margin: 40px 0 0 0; text-align: center; mso-line-height-alt: 27px;">
                                                                            <span style="font-size:14px;color:#000;">If
                                                                                you didn't make this request, simply
                                                                                ignore this email.</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table border="0" cellpadding="30" cellspacing="0"
                                                        class="divider_block block-5" role="presentation"
                                                        style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                        width="100%">
                                                        <tr>
                                                            <td class="pad">
                                                                <div align="center" class="alignment">
                                                                    <table border="0" cellpadding="0" cellspacing="0"
                                                                        role="presentation"
                                                                        style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                                        width="80%">
                                                                        <tr>
                                                                            <td class="divider_inner"
                                                                                style="font-size: 1px; line-height: 1px; border-top: 1px solid #000;">
                                                                                <span> </span>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table border="0" cellpadding="0" cellspacing="0"
                                                        class="text_block block-2" role="presentation"
                                                        style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                                                        width="100%">
                                                        <tr>
                                                            <td class="pad">
                                                                <div style="font-family: sans-serif">
                                                                    <div class="txtTinyMce-wrapper"
                                                                        style="font-size: 18px; mso-line-height-alt: 14.399999999999999px; color: #000; line-height: 1.2; font-family: 'Jost', sans-serif; font-weight: 600;">
                                                                        <p style="margin: 0; text-align: center;">
                                                                            <span style="font-size:18px;">
                                                                                <span
                                                                                    style="color:#000;"> {email}
                                                                                    / {phone}
                                                                                </span>
                                                                        </p>
                                                                        <p
                                                                            style="margin: 0; mso-line-height-alt: 14.399999999999999px;">
                                                                             </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table><!-- End -->
</body>
</html>`;
const Contact = () => {
  const location = useLocation();
  const { state: contactInfo = {} } = location.state || {};
  console.log("sdkkdskds", contactInfo);
  const [form, setForm] = useState({
      name: "",
      email: "",
      phone: "",
      subject: "Products",
      message: "",
    }),
    [isLoading, setLoading] = useState(false),
    [isCatchpaCheck, setCatchpaCheck] = useState(false),
    captchaRef = useRef(null),
    { name, email, phone, subject, message } = form;
  const [content, setContent] = useState("");
  const fetchTeamsData = async () => {
    const { content = "" } = await getData("/ourteam/get");
    setContent(content);
  };

  useEffect(() => {
    fetchTeamsData();
    // Check if data is available from location state
    if (
      contactInfo &&
      Object.keys(contactInfo).length !== 0 &&
      contactInfo.constructor === Object
    ) {
      // Update the form state with new email and subject
      setForm((prevForm) => ({
        ...prevForm,
        //  email: contactInfo.email || "",
        subject: contactInfo.subject || "Products",
      }));
      if (contactInfo.alert) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Please enter your information here.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }, [contactInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var emailList = [{ email: "info@RMRMs.com" }, { email: "" }, { email: "" }];

  const onChange = (e) => {
      e.preventDefault();
      const { target: { name, value } = {} } = e;
      setForm({ ...form, [name]: value });
    },
    onSubmit = async (event) => {
      event.preventDefault();

      let text = html.replace(/{name}/g, name);
      text = text.replace(/{message}/g, message);
      text = text.replace(/{email}/g, email);
      text = text.replace(/{phone}/g, phone);
      if (isCatchpaCheck) {
        setLoading(true);
        await postData(
          { template: text, subject, email: contactInfo?.email },
          "/send/mail"
        );
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your information has been noted.",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 1500);
        setLoading(false);
      }
    },
    verify = () => {
      captchaRef.current.getResponse().then((res) => {
        if (res) setCatchpaCheck(true);
      });
    };

  LoadingOverlay.propTypes = undefined;

  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <Header />

        <div className="mainSection">
          <Banner
            bgPath={"url(/images/HeaderDark.jpg)"}
            breadcrumb={
              <Breadcrumb
                title="Contact Us"
                listItem="Home"
                listItemPath="/"
                listLink="Contact Us"
                listLinkPath="/contact"
              />
            }
          />

          <div className="container">
            <div className="row">
              <div className="col_sm_12 col_md_5 col_lg_3">
                <SideNavbar />
              </div>

              <div className="col_sm_12 col_md_7 col_lg_9">
                <section className="page__section pb_0">
                  <div className="container">
                    <h3 className="page__section--heading">
                      We’d Love to Hear From You
                    </h3>
                    <p className="page__section--para text__align--center">
                      We look forward to working with you to meet your Reference
                      Material needs.
                    </p>
                  </div>
                </section>

                <section className="page__section form__section">
                  <div className="container">
                    <form onSubmit={onSubmit} className="form">
                      <div className="row">
                        <div className="col_sm_12 col_md_12 col_lg_6">
                          <div>
                            <div className="form__group">
                              <label className="form__label">Name</label>
                              <input
                                required
                                type="text"
                                className="form__input"
                                onChange={onChange}
                                placeholder="Name"
                                name="name"
                                value={name}
                              />
                            </div>
                            <div className="form__group">
                              <label className="form__label title--h4">
                                Subject
                              </label>
                              <select
                                required
                                className="form__select"
                                onChange={onChange}
                                name="subject"
                                value={subject}
                              >
                                {/* <option>Select Subject</option> */}
                                <option>Products</option>
                                <option>Services</option>
                                <option>Certificates</option>
                                <option>Industry Catalogs</option>
                                <option>Contact Us</option>
                                <option>General Inquiry</option>
                                <option>Products Inquiry</option>
                                <option>Services Inquiry</option>
                                <option>RMCP Inquiry</option>
                                <option>
                                  Certificate/Product Information Sheet Inquiry
                                </option>
                                <option>Industry Catalog Inquiry</option>
                                <option>About Us Inquiry</option>
                                <option>Photo Gallery Inquiry</option>
                                <option>Quality Inquiry</option>
                                <option>Latest News Inquiry</option>
                                {contactInfo &&
                                  contactInfo.fromservice &&
                                  contactInfo.subject && (
                                    <option>{contactInfo?.subject}</option>
                                  )}
                                {contactInfo &&
                                  contactInfo.fromproduct &&
                                  contactInfo.subject && (
                                    <option>{contactInfo?.subject}</option>
                                  )}
                              </select>
                            </div>
                            <div className="form__group">
                              <label className="form__label">Message</label>
                              <textarea
                                required
                                className="form__textarea"
                                onChange={onChange}
                                placeholder="Message"
                                name="message"
                                value={message}
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div className="col_sm_12 col_md_12 col_lg_6">
                          <div>
                            <div className="form__group">
                              <label className="form__label">Email</label>
                              <input
                                required
                                type="email"
                                onChange={onChange}
                                className="form__input"
                                placeholder="Email"
                                name="email"
                                value={email}
                              />
                            </div>
                            <div className="form__group">
                              <label className="form__label">Phone</label>
                              <input
                                required
                                type="tel"
                                onChange={onChange}
                                className="form__input"
                                placeholder="+1 0123456789"
                                name="phone"
                                value={phone}
                                pattern="^(\+\d{1,3}[- ]?)?\d{10}$"
                              />
                            </div>
                            <div className="form__group">
                              <Reaptcha
                                sitekey={sitekey}
                                ref={captchaRef}
                                onVerify={verify}
                              />
                            </div>
                            <div className="form__group mb_0">
                              <button type="submit" className="form__submit">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
              </div>
            </div>

            <section>
              <div
                dangerouslySetInnerHTML={{ __html: content }}
                // className="reset-props"
              ></div>
            </section>
          </div>
        </div>

        <Footer footerEmails={true} emailList={emailList} />
      </LoadingOverlay>
    </>
  );
};

export default Contact;
