import React, { useEffect } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Banner from "../Common/Banner/Banner";
import Breadcrumb from "../Common/Breadcrumb/Breadcrumb";
import Sidebar from "../Templates/CerificateSidebar/CertificateSidebar";
import CertificateCards from "../Templates/Certificate/CertificateCards";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getData } from "../../Utils/utils";

const Certificates = () => {
  const [inputValue, setInputValue] = React.useState(""),
    [selectValue, setSelectValue] = React.useState(""),
    [certificates, setCertificates] = React.useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  var emailList = [
    { email: "Certs@RMRMs.com" },
    { email: "" },
    { email: "" },
  ];
  const onChangeInput = (e) => {
      setInputValue(e.target.value);
    },
    onChangeSelect = (e) => {
      if ("Select Category" === e.target.value) {
        setSelectValue("");
      } else {
        setSelectValue(e.target.value);
      }
    },
    fetchData = async () => {
      const res = await getData("/certificates/get");
      const sortedCertificates = res.certificates.sort((a, b) => {
        return new Date(b.revision_date) - new Date(a.revision_date);
      });
      setCertificates(sortedCertificates);
    };

  function createData(content, crm, rm) {
    return { content, crm, rm };
  }


  const rows = [
    createData("Title of Document", "Mandatory", "Mandatory"),
    createData("Unique CRM/RM Identifier", "Mandatory", "Mandatory"),
    createData("Name of CRM/RM", "Mandatory", "Mandatory"),
    createData("Description of Material", "Mandatory", "Recommended"),
    createData("Intended Uses", "Mandatory", "Mandatory"),
    createData(
      "Property, Property Value and Associated Uncertainty",
      "Mandatory",
      "Optional"
    ),
    createData("Metrological Traceability", "Mandatory", "Optional"),
    createData(
      "Methods for Method-Independent Measurands",
      "Recommended",
      "Recommended"
    ),
    createData(
      "Methods for Method Dependent Measurands",
      "Mandatory when applicable",
      "Recommended"
    ),
    createData(
      "Minimum Recommended Sample Quantity",
      "Mandatory when applicable",
      "Mandatory when applicable"
    ),
    createData("Homogeneity", "Mandatory", "Mandatory"),
    createData("Period of Validity", "Mandatory", "Mandatory"),
    createData(
      "Name and Contact Details of Producer",
      "Mandatory",
      "Mandatory"
    ),
    createData(
      "Name and Function of Approving Officer",
      "Mandatory",
      "Optional"
    ),
    createData("Non-Certified Values", "Optional", "Optional"),
    createData("Storage Instructions", "Mandatory", "Mandatory"),
    createData("Handling and Use Instructions", "Mandatory", "Mandatory"),
    createData("Collaborators", "Optional", "Optional"),
    createData("Sub-Contractors", "Optional", "Optional"),
    createData("Reference to Certification Report", "Optional", "Optional"),
    createData("Referenced Documents", "Recommended", "Optional"),
    createData("Page number and Total Pages", "Mandatory", "Mandatory"),
    createData("Document Version", "Mandatory", "Mandatory"),
    createData("Commutability", "Not Applicable", "Not Applicable"),
    createData("Health and Safety Information", "Optional", "Optional"),
    createData("Legal Notice", "Optional", "Optional"),
  ];

  return (
    <>
      <Header />
      <div className="mainSection">
        <Banner
          bgPath={"url(/images/HeaderDark.jpg)"}
          breadcrumb={
            <Breadcrumb
              title="Certificates"
              listItem="Home"
              listItemPath="/"
              listLink="Certificates"
              listLinkPath="/certificates"
            />
          }
        />

        <div className="container pt_1">
          <div className="row">
            <div className="col_sm_12 col_md_3 col_lg_3">
              <Sidebar
                onChangeInput={onChangeInput}
                onChangeSelect={onChangeSelect}
                certificates={certificates}
              />
            </div>
            <div className="col_sm_12 col_md_9 col_lg_9">
              {inputValue || selectValue ? (
                <div className="certificates">
                  <CertificateCards
                    inputValue={inputValue}
                    selectValue={selectValue}
                    certificates={certificates}
                  />
                </div>
              ) : (
                <div className="certificate-verbiage">
                  <div className="table--title">
                    Contents of a Certificate of Analysis (CoA) or a RM Product
                    Information Sheet (PIS)
                  </div>
                  <div className="table--subtitle">
                    (Taken from ISO Guide 31:2015 & ASTM E3330-22)
                  </div>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      aria-label="simple table"
                      className="table"
                    >
                      <TableHead className="table__head">
                        <TableRow>
                          <TableCell>Content</TableCell>
                          <TableCell align="right">CRM CoA</TableCell>
                          <TableCell align="right">RM PIS</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="table__body">
                        {rows.map((row) => (
                          <TableRow
                            key={row.content}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.content}
                            </TableCell>
                            <TableCell align="right">{row.crm}</TableCell>
                            <TableCell align="right">{row.rm}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer footerEmails={true} emailList={emailList} />
    </>
  );
};

export default Certificates;
