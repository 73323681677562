import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import EyeIcon from "../../../../Components/Icon/EyeIcon";
import axios from "../../../../Services/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "4px",
  p: 2,
};
const SampleSentViewModal = ({
  viewSampleModal,
  handleViewSampleClose,
  calenderData,
  userId,
  customerId,
  setLoader,
  value,
}) => {
  const previewRmpt187 = (value) => {
    setLoader(true);

    axios
      .get(
        `/rmpt/get/previewCustomer?type=5&id=${value.user_id}&reference_id=${value.reference_id}`
      )

      .then((response) => {
        let res = JSON.parse(response.data);

        if (res.status === 200) {
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(res.data) +
              "'></iframe>"
          );
        } else {
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthorized!") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session Expired",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .then(() => {
        setLoader(false);
      });
  };
  const previewRmpt188 = (value) => {
    setLoader(true);

    axios
      .get(
        `/rmpt/get/preview?type=5&id=${userId}&reference_id=${customerId}&analyst_id=${value.id}`
      )

      .then((response) => {
        let res = JSON.parse(response.data);

        if (res.status === 200) {
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(res.data) +
              "'></iframe>"
          );
        } else {
        }
      })
      .catch((error) => {
        console.log("dsds", error);
        if (error.response.data.message === "Unauthorized!") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session Expired",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .then(() => {
        setLoader(false);
      });
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={viewSampleModal}
      onClose={handleViewSampleClose}
      closeAfterTransition
      className="uploadModal"
    >
      <Fade in={viewSampleModal}>
        <Box
          sx={style}
          component="form"
          className="uploadModalForm uploadModalOverflow"
        >
          <Typography
            id=""
            variant="h6"
            component="h2"
            style={{
              fontWeight: "700",
            }}
          >
            Sample Sent
            <IconButton
              aria-label="close"
              onClick={handleViewSampleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                className="sample__modal"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0 16px",
                }}
              >
                <h2
                  style={{
                    backgroundColor: "transparent",
                    padding: "0",
                  }}
                >
                  Scheme Design - {`(RMPT 187)`}
                </h2>
                <div className="button--icon modal--icon">
                  <Tooltip title="View/Download">
                    <button
                      onClick={(e) => {
                        e.preventDefault();

                        previewRmpt187(value);
                      }}
                    >
                      <EyeIcon />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div
                className="sample__modal"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0 16px",
                }}
              >
                <Typography
                  id=""
                  variant="h6"
                  component="h2"
                  className=""
                  style={{
                    backgroundColor: "transparent",
                    padding: "0",
                  }}
                >
                  Subscriber Analysis Instructions - {`(RMPT 188)`}
                </Typography>
                <br />
              </div>
              <ul className="sample__list">
                {calenderData && calenderData.length > 0
                  ? calenderData.map((value, index) => {
                      return (
                        <li className="sample__list--item">
                          <div className="flex alignitems--center justifyspace--between">
                            <p>
                              <span>{index + 1}. </span>
                              <span className="analyst">{value.analyst}</span>
                              <span>{value.method}</span>
                              <span>{value.material}</span>
                              <span>{value.sample_type}</span>
                            </p>
                            <div className="button--icon modal--icon">
                              <Tooltip title="View/Download">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    previewRmpt188(value);
                                  }}
                                >
                                  <EyeIcon />
                                </button>
                              </Tooltip>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SampleSentViewModal;
