import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import Swal from "sweetalert2";
import EyeIcon from "../../../../Components/Icon/EyeIcon";
import { env } from "../../../../Utils/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "4px",
  p: 2,
};
const AppealLetterModal = ({
  viewAppealLetterModal,
  handleViewAppealLetterClose,

  calenderData,
  userId,
  customerId,
  setLoader,
}) => {
  const previewRmpt191 = (value) => {
    axios
      .get(
        env +
          `/common/get/preview?type=8&id=${userId}&reference_id=${customerId}&analyst_id=${value.id}`,
        {
          headers: {
            Authorization: localStorage.getItem("access_token"),
          },
        },
        setLoader(true)
      )

      .then((response) => {
        let res = response.data;
        if (res.status === 200) {
          setLoader(false);
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(res.data) +
              "'></iframe>"
          );
        } else {
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthorized!") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session Expired",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .then(() => {});
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={viewAppealLetterModal}
      onClose={handleViewAppealLetterClose}
      closeAfterTransition
      className="uploadModal"
    >
      <Fade in={viewAppealLetterModal}>
        <Box
          sx={style}
          component="form"
          className="uploadModalForm uploadModalOverflow"
        >
          <Typography
            id=""
            variant="h6"
            component="h2"
            style={{
              fontWeight: "700",
            }}
          >
            Appeal Letter
            <IconButton
              aria-label="close"
              onClick={handleViewAppealLetterClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="sample__modal">
                <Typography id="" variant="h6" component="h2" className="">
                  RMPT Appeal Data Report Form - {`(RMPT - 191)`}
                </Typography>

                <ul className="sample__list">
                  {calenderData && calenderData.length > 0
                    ? calenderData.map((value, index) => {
                        return (
                          <li className="sample__list--item">
                            <div className="flex alignitems--center justifyspace--between">
                              <p>
                                <span>{index + 1}. </span>
                                <span className="analyst">{value.analyst}</span>
                                <span>{value.method}</span>
                                <span>{value.material}</span>
                                <span>{value.sample_type}</span>
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <div className="button--icon modal--icon">
                                  {value.isAppeal ? (
                                    <Tooltip title="View/Download">
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();

                                          previewRmpt191(value);
                                        }}
                                      >
                                        <EyeIcon />
                                      </button>
                                    </Tooltip>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AppealLetterModal;
