import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Header from "./components/Header";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useState } from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DrawerCom from "./components/Drawer";

const mdTheme = createTheme();

function DashboardContent() {
  const [open, setOpen] = React.useState(true);
  const [pageStore, setPageStore] = useState({
    pageHeading: "",
    bannerTitle: "",
    detail: "",
    ourTeamHeading: "",
    ourTeamDetail: "",
  });
  const [ourTeam, setOurTeam] = useState([
    { photo: "", name: "", post: "", email: "", linkedinUrl: "" },
  ]);

  const onAddTeam = () => {
    setOurTeam([
      ...ourTeam,
      { photo: "", name: "", post: "", email: "", linkedinUrl: "" },
    ]);
  };
  const onRemoveTeam = (index) => {
    const list = [...ourTeam];
    list.splice(index, 1);
    setOurTeam(list);
  };
  const onChangeTeam = (e, name, index) => {
    if (name === "photo") {
      ourTeam[index].photo = e.target.files[0];
      setOurTeam([...ourTeam]);
    } else if (name === "name") {
      ourTeam[index].name = e.target.value;
      setOurTeam([...ourTeam]);
    } else if (name === "post") {
      ourTeam[index].post = e.target.value;
      setOurTeam([...ourTeam]);
    } else if (name === "email") {
      ourTeam[index].email = e.target.value;
      setOurTeam([...ourTeam]);
    } else {
      ourTeam[index].linkedinUrl = e.target.value;
      setOurTeam([...ourTeam]);
    }
  };

  const onPageChange = (e, name) => {
    if (name === "pageHeading") {
      setPageStore({ ...pageStore, pageHeading: e.target.value });
    } else if (name === "bannerTitle") {
      setPageStore({ ...pageStore, bannerTitle: e.target.value });
    } else if (name === "detail") {
      setPageStore({ ...pageStore, detail: e.target.value });
    } else if (name === "ourTeamHeading") {
      setPageStore({ ...pageStore, ourTeamHeading: e.target.value });
    } else {
      setPageStore({ ...pageStore, ourTeamDetail: e.target.value });
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header open={open} toggleDrawer={toggleDrawer} />
        <DrawerCom open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8} lg={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ pb: 2 }}
                  >
                    Contact Us Page
                  </Typography>
                  <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <TextField
                          value={pageStore.pageHeading}
                          onChange={(e) => onPageChange(e, "pageHeading")}
                          label="Page Heading"
                          variant="outlined"
                          fullWidth
                          required
                          size="small"
                          style={{ backgroundColor: "white" }}
                          id="outlined-basic"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          value={pageStore.bannerTitle}
                          onChange={(e) => onPageChange(e, "bannerTitle")}
                          label="Banner Title"
                          variant="outlined"
                          fullWidth
                          required
                          size="small"
                          style={{ backgroundColor: "white" }}
                          id="outlined-basic"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          required
                          multiline
                          rows={4}
                          size="small"
                          value={pageStore.detail}
                          onChange={(e) => onPageChange(e, "detail")}
                          label="Detail"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          sx={{ pt: 3 }}
                        >
                          Our Team
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          value={pageStore.ourTeamHeading}
                          onChange={(e) => onPageChange(e, "ourTeamHeading")}
                          label="Our Team Heading"
                          variant="outlined"
                          fullWidth
                          required
                          size="small"
                          style={{ backgroundColor: "white" }}
                          id="outlined-basic"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          required
                          multiline
                          rows={4}
                          size="small"
                          value={pageStore.ourTeamDetail}
                          onChange={(e) => onPageChange(e, "ourTeamDetail")}
                          label="Our Team Details"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          sx={{ pt: 3 }}
                        >
                          Add Team Members
                        </Typography>
                      </Grid>
                      {ourTeam?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <Grid item xs={2} className={"pdfInputUpload"}>
                              <TextField
                                style={{ backgroundColor: "white" }}
                                onChange={(e) =>
                                  onChangeTeam(e, "photo", index)
                                }
                                name="upload-photo"
                                fullWidth
                                type="file"
                              />
                            </Grid>
                            <Grid item xs={2} className={"pdfInputText"}>
                              <TextField
                                label="Name"
                                value={item.name}
                                onChange={(e) => onChangeTeam(e, "name", index)}
                                id="outlined-basic"
                                fullWidth
                                variant="outlined"
                                style={{
                                  marginTop: "6px",
                                  backgroundColor: "white",
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} className={"pdfInputText"}>
                              <TextField
                                label="Post"
                                value={item.post}
                                onChange={(e) => onChangeTeam(e, "post", index)}
                                id="outlined-basic"
                                fullWidth
                                variant="outlined"
                                style={{
                                  marginTop: "6px",
                                  backgroundColor: "white",
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} className={"pdfInputText"}>
                              <TextField
                                label="Email"
                                value={item.email}
                                onChange={(e) =>
                                  onChangeTeam(e, "email", index)
                                }
                                id="outlined-basic"
                                fullWidth
                                variant="outlined"
                                style={{
                                  marginTop: "6px",
                                  backgroundColor: "white",
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} className={"pdfInputText"}>
                              <TextField
                                label="Linedin URL"
                                value={item.linkedinUrl}
                                onChange={(e) =>
                                  onChangeTeam(e, "linkedinUrl", index)
                                }
                                id="outlined-basic"
                                fullWidth
                                variant="outlined"
                                style={{
                                  marginTop: "6px",
                                  backgroundColor: "white",
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} className={"pdfInputBtn"}>
                              <Fab
                                color="primary"
                                aria-label="add"
                                size="small"
                                sx={{ mr: 1 }}
                                onClick={() => onAddTeam()}
                              >
                                <AddIcon />
                              </Fab>
                              {ourTeam.length === 1 ? (
                                ""
                              ) : (
                                <Fab
                                  color="error"
                                  aria-label="delete"
                                  size="small"
                                  onClick={() => onRemoveTeam(index)}
                                >
                                  <DeleteIcon />
                                </Fab>
                              )}
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                    </Grid>
                    <Button
                      type="submit"
                      className={"btnClass"}
                      variant="contained"
                      sx={{ mt: 3, mb: 2, ml: 2 }}
                    >
                      Save
                    </Button>
                    <Button
                      component={Link}
                      to="/admin/listing"
                      variant="outlined"
                      sx={{ mt: 3, mb: 2, ml: 2 }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function ContactAdmin() {
  return <DashboardContent />;
}
