import React from 'react';
import { NavLink, useLocation} from 'react-router-dom';
import ArrowDownDark from '../../Icon/ArrowDownDark';
import './sideNavbar.scss';

const SideNavbar = () => {

    const location = useLocation(),
    { productMenu = [] } = location.state || {};

    const accordionCollapse = () => {
        var accordionBtn = document.querySelectorAll('.accordion__item--btn');
        var accordionBody = document.querySelectorAll('.accordion__item--body');
        accordionBtn.forEach((item) => {
            return item.classList.remove('active');
        });
        accordionBody.forEach((item) => {
            return item.style.maxHeight = null;
        }); 
    }

    const accordionHandler = (event) => {   
        var currentAccordionBtn = event.target;
        var currentAccordionBody = currentAccordionBtn.nextElementSibling;

        if(currentAccordionBtn.classList.contains('active')){
            accordionCollapse();
            currentAccordionBtn.classList.remove('active');
            currentAccordionBody.style.maxHeight = null;
        }
        else{
            accordionCollapse();
            currentAccordionBtn.classList.add('active');
            if (currentAccordionBody.style.maxHeight) {
                currentAccordionBody.style.maxHeight = null;
            } else {
                currentAccordionBody.style.maxHeight = currentAccordionBody.scrollHeight + 'px';
            }
        }        
    }

    return (
        <div className='accordion accordion-sm'>
                {
                    productMenu.map((item, i) => {
                        return (<>
                            <div key={i} className='accordion__item'>
                                <button className="accordion__item--btn" onClick={accordionHandler}>{item.category_name}<ArrowDownDark/></button>
                                <div className="accordion__item--body">
                                    <ul>
                                        {
                                            item.contents.map((subItem, index) => {
                                                return (
                                                    <li key={index}>
                                                        <NavLink to={'/products/contents/'} className='color--gray' state={{ content: subItem, productMenu }}>{subItem.page_title}</NavLink>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </>
                        )
                    })
                }

                <div className='accordion__item'>
                    <NavLink to='/products/ausmon-monitors' state={{ productMenu }} className="accordion__item--btn">Ausmon Monitors</NavLink>
                </div>
        </div>
    );
}

export default SideNavbar;
