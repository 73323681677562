import React, { useEffect } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Banner from "../Common/Banner/Banner";
import Breadcrumb from "../Common/Breadcrumb/Breadcrumb";
import News from "../Templates/News/News";
import SideNavbar from "../Templates/SideNavbar/AboutUsSideNavbar";

const LatestNews = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var emailList = [{ email: "Info@RMRMs.com" }, { email: "" }, { email: "" }];

  return (
    <>
      <Header />

      <div className="mainSection">
        <Banner
          bgPath={"url(/images/HeaderDark.jpg)"}
          breadcrumb={
            <Breadcrumb
              title="Latest News"
              listItem="Home"
              listItemPath="/"
              listLink="Latest News"
              listLinkPath="/latest-news"
              // darkTitle="dark-title"
            />
          }
          bgPosition={"banner__section--backgroundPosition"}
        />

        <div className="container">
          <div className="row">
            <div className="col_sm_12 col_md_5 col_lg_3">
              <SideNavbar />
            </div>

            <div className="col_sm_12 col_md_7 col_lg_9">
              <section className="page__section">
                <div className="container-fluid">
                  <News />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <Footer footerEmails={true} emailList={emailList} />
    </>
  );
};

export default LatestNews;
