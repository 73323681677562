import React from 'react';
// import { useLocation } from "react-router-dom";
import Card from './Card/ProductCard';
import "./card.scss";

const Cards = ({ productMenu }) => {
    // const location = useLocation(),
    // { productMenu = [] } = location.state || {};

    return (
        <div className="cards__container">
            {productMenu.map((lists, index) => (
                <Card key={index} index={index} lists={lists} productMenu={productMenu}/>
            ))}
        </div>
    );
};

export default Cards;


// const cardLists = [
    //     {
    //         title: "RMRMs Produced RMs",
    //         text: "The Rocky Mountain Reference Materials (RMRMs) Rocky Mountain Cooperative Program (RMCP) originated as and remains today the only totally 'voluntary organization' in the private industry dedicated to the production and development of quality Reference Materials",
    //         url: "/products/rmrms",
    //     },
    //     {
    //         title: "Aluminum CRMs",
    //         text: "The Arvida Research and Development Centre (ARDC) produces, certifies and distributes standard samples of aluminum and its alloys for use as certified reference materials (CRM)",
    //         url: "/products/aluminium-crms/rio-tinto-alcan-crm",
    //     },
    //     {
    //         title: "Cast Iron CRMs",
    //         text: "Intended for calibration, validation and matrix-match verification of cast iron spectrometric analysis: Spark, Glow-Discharge or Laser Excitation Atomic Emission Spectrometry, and X-ray Fluorescence Spectrometry",
    //         url: "/products/cast-iron-crms/cmi-cast-irons",
    //     },
    //     {
    //         title: "Setting Up Materials",
    //         text: "Setting-Up Materials (SUSs) are used to drift correct the calibration curves. The process of drift correcting the curves has many names: normalization, standardization, and re-calibration. Regardless of the name given the process, the curves are adjusted back to their state at the time of the original calibration",
    //         url: "/products/setting-up-materials/aluminium-sus",
    //     },
    //     {
    //         title: "RTA Non-Metallics",
    //         text: "ALCAN produces non-metallic Certified Reference Materials (CRM's) used to calibrate or drift correct techniques such as XRF, ICP, AAS, XRF, and DSC. ALCAN standards are powdered and sold in quantities from 500 mg to 500 g according to their end use.",
    //         url: "/products/rta-non-metallics/bayer-proces",
    //     },
    //     {
    //         title: "Ausmon Drift Monitors for XRF",
    //         text: "Establishing an accurate calibration for a series of elements in a product is a tedious and costly exercise. The use of a stable monitor means that the calibration procedure need only be carried out at long intervals.",
    //         url: "/products/ausmon-monitors",
    //     },        
    // ];