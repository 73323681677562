import React, { useEffect, useState } from "react";
import Header from "../../Common/Header/Header";
import axios from "../../../Services/axios";
import Swal from "sweetalert2";
const Letter = () => {
  const [analyte, setanalyte] = useState([]);
  const [state, setState] = useState(1);
  const [newAnalyte, setNewAnalyte] = useState([]);
  const [rmptId, setRmptId] = useState("");
  const displayAnalyte = (data) => {
    let map = "";
    for (let i = 0; i < data.length; i++) {
      map += `${i !== data.length - 1 ? `${data[i]}, ` : `${data[i]} `}`;
    }
    return map;
  };
  const displayNewAnalyte = (newData) => {
    let map = "";
    for (let i = 0; i < newData.length; i++) {
      map += `${
        i !== newData.length - 1 ? `${newData[i]}, ` : `${newData[i]}`
      }`;
    }

    return map;
  };
  const submitAppeal = () => {
    const userId = localStorage.getItem("UserId");
    const referenceId = localStorage.getItem("referenceId");
    const analystId = localStorage.getItem("analystId");

    let obj = {
      id: userId,
      reference_id: referenceId,
      analyst_id: analystId,
      state: state,
    };
    axios
      .post("/rmpt/update/rmpt191", obj)

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            position: "center",

            title: "Submitted Successfully",
            showConfirmButton: true,
          }).then(() => {
            if (state === 1) {
              window.open("/analyst-form-status", "_self");
            } else {
              localStorage.removeItem("type");
              localStorage.setItem("type", "reappeal");
              window.location.href = "/data-submit-report";
            }
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      })
      .catch((error) => {})
      .then(() => {});
  };
  useEffect(() => {
    const userId = localStorage.getItem("UserId");
    const referenceId = localStorage.getItem("referenceId");
    const analystId = localStorage.getItem("analystId");

    axios
      .get(
        `/rmpt/getAppealData?id=${userId}&reference_id=${referenceId}&analyst_id=${analystId}`
      )

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          setRmptId(res.data.rmptId);
          setanalyte(res.data.analyte);
          setNewAnalyte(res.data.newanalyte);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      })
      .catch((error) => {})
      .then(() => {});
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header themeWhite={true} contactBtn={true} bgLight={true} />
      <div className="mainSection mainPortal">
        <section className="page__section">
          <div className="container">
            <div className="form__container letter">
              <div className="letter__head">
                <div className="letter__logo">
                  <img src="/images/rmrms-logo.png" alt="RMRMs" width="90px" />
                </div>
                <div className="letter__title">
                  <h2>Rocky Mountain Proficiency Testing</h2>
                  <p>A Division of Rocky Mountain Reference Materials</p>
                </div>
                <div className="letter__logo">
                  <img
                    src="/images/anab-testing2x.png"
                    alt="Anab"
                    width="90px"
                  />
                </div>
              </div>

              <div className="letter__body">
                <div className="body--head">
                  <p>Joe Hotshot</p>
                  <p>October 20, 2018</p>
                </div>
                <div className="body--head">
                  <p>TQA Metals USA</p>
                </div>
                <div className="body--head">
                  <p>123 Somewhere Dr.</p>
                </div>
                <div className="body--head">
                  <p>Pretty Place, US 00000</p>
                </div>
                <div className="body--head">
                  <p>(P:) (123) 456-7890 (F:) (123) 456-7891</p>
                </div>
                <div className="body--head">
                  <p>Lab@TQA-Metals.com</p>
                </div>
                <hr />
                <p>Dear Joe,</p>
                <p>
                  Your RMPT sample, {rmptId}, has been evaluated and the
                  following element(s) were found having a{" "}
                  <span className="color--warning">
                    Z-Score ≥2 but {"<3"}:{" "}
                    {displayAnalyte(analyte).length > 0
                      ? displayAnalyte(analyte)
                      : "None"}
                    ;
                  </span>{" "}
                  and/or having a{" "}
                  <span className="color--primary">
                    Z-Score ≥3:{" "}
                    {displayNewAnalyte(newAnalyte).length > 0
                      ? displayNewAnalyte(newAnalyte)
                      : "None"}
                    ;
                  </span>{" "}
                  were found to be outside of the acceptable limits.
                </p>
                <p>
                  You may accept your originally submitted data by checking the
                  first option and the final report will be sent to you. If you
                  wish to appeal the findings please check the second option
                  below and return within 96 hours and we will email an 'Amended
                  Data Submittal Report Form' for re-analysis of the
                  Warning/Outlier element(s).
                </p>

                <div className="">
                  <div className="form__group">
                    <label className="checkbox-container">
                      <input
                        checked={state === 1}
                        onChange={(e) => {
                          setState(1);
                        }}
                        defaultChecked
                        type="checkbox"
                        className="form-checkbox"
                      />
                      <span className="checkmark"></span>I accept the data as
                      submitted, please process the final report
                    </label>
                  </div>
                </div>

                <p style={{ marginBottom: "0", textAlign: "center" }}>or</p>

                <div className="mb_10">
                  <div className="form__group">
                    <label className="checkbox-container">
                      <input
                        checked={state === 0}
                        onChange={(e) => {
                          setState(0);
                        }}
                        type="checkbox"
                        className="form-checkbox"
                      />
                      <span className="checkmark"></span>I wish to appeal the
                      findings and will resubmit the data for any or all of the
                      elements of concern (one time only).
                    </label>
                  </div>
                </div>

                <p>
                  Within 1-72 hours of receiving your approval or completion of
                  an appeal, your completed PT results will be sent to you. Your
                  laminated hard copies will be sent to you via mail.
                </p>
                <p>
                  Please remember your PT sample is yours to keep and is a CRM.
                  The Certificate of Analysis will be included in your final,
                  completed report package.
                </p>

                <ul>
                  <li>Thanks and Regards,</li>
                  <li>RMPT Program Manager</li>
                  <li>RMPT@RMRMs.com</li>
                </ul>
              </div>

              <div className="form__group flex justifyspace--center">
                <button
                  onClick={() => {
                    submitAppeal();
                  }}
                  type="submit"
                  className="btn btn--primary"
                  style={{ width: "fit-content", padding: "10px 20px" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Letter;
