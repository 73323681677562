import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

import Header from "../../components/Header";
import TableListing from "../../components/Table";
import DrawerCom from "../../components/Drawer";
import { getData, postData } from "../../../Utils/utils";
import Swal from "sweetalert2";

const mdTheme = createTheme();

function ListingContent() {
  const [open, setOpen] = React.useState(true),
    [rows, setRows] = React.useState([]),
    toggleDrawer = () => {
      setOpen(!open);
    },
    fetchData = async () => {
      const result = await getData("/product/get/contents");
      setRows(result);
    },
    handleDelete = async (data) => {
      const { id, category_id, page_title, default_content, hidden_content } =
        data;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await postData(
            {
              id,
              category_id,
              page_title,
              default_content,
              hidden_content,
              active: 0,
            },
            "/product/add/contents"
          );
          fetchData();
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header open={open} toggleDrawer={toggleDrawer} />
        <DrawerCom open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
            <TableListing
              rows={rows}
              fetchData={fetchData}
              name={"Product"}
              handleDelete={handleDelete}
              link={"subProduct"}
            />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Listing() {
  return <ListingContent />;
}
