import React, { useEffect, useState } from "react";
import Header from "../../Common/Header/Header";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import axios from "../../../Services/axios";
import Swal from "sweetalert2";

import FormControl from "@mui/material/FormControl";

import { Formik, Form, FieldArray } from "formik";

import Creatable from "react-select/creatable";
import { withAsyncPaginate } from "react-select-async-paginate";
import { Tooltip } from "@mui/material";

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
const loadMethods = async (search, prevOptions) => {
  await sleep(200);
  let newarray = [
    {
      label: "1 - AES",
      value: "1 - AES",
    },
    {
      label: "2 - XRF",
      value: "2 - XRF",
    },
    {
      label: "3 - ICP",
      value: "3 - ICP",
    },
    {
      label: "4 - Com",
      value: "4 - Com",
    },
    {
      label: "5 - IGF",
      value: "5 - IGF",
    },
    {
      label: "6 - GDS",
      value: "6 - GDS",
    },

    {
      label: "7 - FAA",
      value: "7 - FAA",
    },
    {
      label: "8 - GFAA",
      value: "8 - GFAA",
    },

    {
      label: "9 - Other",
      value: "9 - Other",
    },
  ];
  let filteredOptions;
  if (!search) {
    filteredOptions = newarray;
  } else {
    const searchLower = search.toLowerCase();

    filteredOptions = newarray.filter(({ label }) =>
      label.toLowerCase().includes(searchLower)
    );
  }

  const hasMore = filteredOptions.length > prevOptions.length + 10;
  const slicedOptions = filteredOptions.slice(
    prevOptions.length,
    prevOptions.length + 10
  );

  return {
    options: slicedOptions,
    hasMore,
  };
};

const DataSubmitReport = () => {
  const [analyte, setanalyte] = useState([]);

  useEffect(() => {
    // let matrix =
    //   localStorage.getItem("rmptdata") &&
    //   JSON.parse(localStorage.getItem("rmptdata")).material;

    let pinData =
      localStorage.getItem("pin") && JSON.parse(localStorage.getItem("pin"));

    let pin = pinData;

    axios
      .get(`/analytes/get?pin=${pin}&skip=0&limit=1000&is_inventory=0`)

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          let arr = [];
          for (let i = 0; i < res.data.analyte[0].analyte.length; i++) {
            if (
              res.data.analyte[0].analyte[i].valueAssignment !== "" &&
              res.data.analyte[0].analyte[i].valueAssignment
            ) {
              if (localStorage.getItem("method") === "5 - IGF") {
                if (
                  res.data.analyte[0].analyte[i].analyte === "N" ||
                  res.data.analyte[0].analyte[i].analyte === "O" ||
                  res.data.analyte[0].analyte[i].analyte === "H"
                ) {
                  arr.push({
                    analyte: res.data.analyte[0].analyte[i].analyte,
                    analysedValue: "",
                    method: localStorage.getItem("method"),
                  });
                }
              } else if (localStorage.getItem("method") === "4 - Com") {
                if (
                  res.data.analyte[0].analyte[i].analyte === "C" ||
                  res.data.analyte[0].analyte[i].analyte === "S"
                ) {
                  arr.push({
                    analyte: res.data.analyte[0].analyte[i].analyte,
                    analysedValue: "",
                    method: localStorage.getItem("method"),
                  });
                }
              } else {
                arr.push({
                  analyte: res.data.analyte[0].analyte[i].analyte,
                  analysedValue: "",
                  method: localStorage.getItem("method"),
                });
              }
            }
          }
          setanalyte(arr);
          //   setCalender(res.data);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      })
      .catch((error) => {})
      .then(() => {});
    window.scrollTo(0, 0);
  }, []);

  const [checkedAnalyte, setCheckedAnalyte] = useState([]);
  const [checkedNewAnalyte, setCheckedNewAnalyte] = useState([]);
  useEffect(() => {
    const userId = localStorage.getItem("UserId");
    const referenceId = localStorage.getItem("referenceId");
    const analystId = localStorage.getItem("analystId");

    axios
      .get(
        `/rmpt/getAppealData?id=${userId}&reference_id=${referenceId}&analyst_id=${analystId}`
      )

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          setCheckedAnalyte(res.data.analyte);
          setCheckedNewAnalyte(res.data.newanalyte);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      })
      .catch((error) => {})
      .then(() => {});
    window.scrollTo(0, 0);
  }, []);
  const getValue = (value) => {
    let arr = [
      {
        label: "1 - 8 mm Disk Sample",
        value: "1",
      },
      {
        label: "2 - Chip Pack (10 g)",
        value: "2",
      },
      {
        label: "3 - Pin Pack (1 Qtr.)",
        value: "3",
      },
      {
        label: "4 - Chip Sample(20 g)",
        value: "4",
      },
    ];

    let index = arr.findIndex((x) => x.label === value);
    if (index === -1) return "";
    else return arr[index].value;
  };
  return (
    <>
      <Header themeWhite={true} contactBtn={true} bgLight={true} />
      <div className="mainSection mainPortal">
        <section className="page__section">
          <div className="container">
            <div className="form__container">
              <div className="form form__user form__report">
                <div className="bg--light" style={{ padding: "8px" }}>
                  <h1 className="form__title mb_0">
                    {localStorage.getItem("type") === "reappeal"
                      ? "Appealed "
                      : ""}
                    Results for RMPT{" "}
                    <span
                      style={{ backgroundColor: "#FFFF00", marginLeft: "10px" }}
                    >
                      #
                      {localStorage.getItem("rmptid") &&
                        localStorage.getItem("rmptid")}
                      {localStorage.getItem("type") === "reappeal" ? "-1" : ""}
                    </span>
                  </h1>
                </div>

                <Formik
                  initialValues={{ analyte: analyte }}
                  enableReinitialize
                  onSubmit={(values, { setSubmitting }) => {
                    const obj = {
                      analyte: values.analyte,
                      reference_id: JSON.parse(localStorage.getItem("rmptdata"))
                        .reference_id,
                      id: JSON.parse(localStorage.getItem("rmptdata")).id,
                    };
                    let url = "";
                    if (
                      localStorage.getItem("type") &&
                      localStorage.getItem("type") === "reappeal"
                    ) {
                      url = "/rmpt/update/rmpt189reappeal";
                    } else {
                      url = "/rmpt/update/rmpt189";
                    }
                    axios
                      .post(url, obj)

                      .then((response) => {
                        let res = JSON.parse(response.data);
                        if (res.status === 200) {
                          Swal.fire({
                            icon: "success",
                            position: "center",

                            title: "Submitted Successfully",
                            showConfirmButton: true,
                          }).then(() => {
                            if (localStorage.getItem("type") === "reappeal") {
                              window.open("/analyst-form-status", "_self");
                            } else window.open("/letter", "_self");
                          });
                          //   window.open("/analyst-form-status", "_self");
                        } else {
                          Swal.fire({
                            position: "center",
                            icon: "error",
                            title: res.message,
                            showConfirmButton: true,
                            // timer: 1500,
                          });
                        }
                      })
                      .catch((error) => {})
                      .then(() => {});
                  }}
                  render={({
                    values,
                    errors,
                    touched,
                    handleReset,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    resetForm,
                  }) => {
                    return (
                      <>
                        <Form>
                          <div className="form__calendar">
                            <div>
                              <TableContainer className="form__table">
                                <Table className="table__calendar table__calendar--variableWidth table__report">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="left" padding="none">
                                        Analyte
                                      </TableCell>
                                      <TableCell align="left" padding="none">
                                        Analyzed Value
                                      </TableCell>
                                      <TableCell align="left" padding="none">
                                        Method's Used
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>

                                  {/* <TableBody> */}
                                  <FieldArray
                                    name="analyte"
                                    render={(arrayHelpers) => {
                                      return (
                                        <>
                                          {values.analyte.length > 0 &&
                                            values.analyte.map(
                                              (friend, index) => (
                                                <TableBody>
                                                  <TableRow>
                                                    <TableCell
                                                      style={{
                                                        backgroundColor:
                                                          checkedAnalyte.findIndex(
                                                            (x) =>
                                                              x ===
                                                              values.analyte[
                                                                index
                                                              ].analyte.toString()
                                                          ) !== -1
                                                            ? "rgb(255, 255, 0)"
                                                            : checkedNewAnalyte.findIndex(
                                                                (x) =>
                                                                  x ===
                                                                  values.analyte[
                                                                    index
                                                                  ].analyte.toString()
                                                              ) !== -1
                                                            ? "rgba(153, 0, 0,0.6)"
                                                            : "",
                                                      }}
                                                      align="left"
                                                      padding="none"
                                                    >
                                                      <Box autoComplete="off">
                                                        <Tooltip title="">
                                                          <TextField
                                                            label=""
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            value={
                                                              values.analyte &&
                                                              values.analyte[
                                                                index
                                                              ] &&
                                                              values.analyte[
                                                                index
                                                              ].analyte
                                                            }
                                                            onChange={(e) => {
                                                              setFieldValue(
                                                                `analyte[${index}].analyte`,
                                                                e.target.value
                                                              );
                                                            }}
                                                          />
                                                        </Tooltip>
                                                      </Box>
                                                    </TableCell>

                                                    <TableCell
                                                      align="left"
                                                      padding="none"
                                                    >
                                                      <Box autoComplete="off">
                                                        <Tooltip title="">
                                                          <TextField
                                                            label=""
                                                            autoComplete="off"
                                                            // type="number"
                                                            variant="outlined"
                                                            size="small"
                                                            placeholder="Type..."
                                                            value={
                                                              values.analyte &&
                                                              values.analyte[
                                                                index
                                                              ] &&
                                                              values.analyte[
                                                                index
                                                              ].analysedValue
                                                            }
                                                            onChange={(e) => {
                                                              setFieldValue(
                                                                `analyte[${index}].analysedValue`,
                                                                e.target.value.toString()
                                                              );
                                                            }}
                                                            fullWidth
                                                          />
                                                        </Tooltip>
                                                      </Box>
                                                    </TableCell>

                                                    <TableCell
                                                      align="left"
                                                      padding="none"
                                                    >
                                                      <FormControl
                                                        fullWidth
                                                        size="small"
                                                      >
                                                        <CreatableAsyncPaginate
                                                          // onBlur={handleBlur}
                                                          placeholder={
                                                            "Select ..."
                                                          }
                                                          value={
                                                            values.analyte &&
                                                            values.analyte[
                                                              index
                                                            ] &&
                                                            values.analyte[
                                                              index
                                                            ].method
                                                              ? {
                                                                  label:
                                                                    values.analyte &&
                                                                    values
                                                                      .analyte[
                                                                      index
                                                                    ] &&
                                                                    values
                                                                      .analyte[
                                                                      index
                                                                    ].method,
                                                                  value:
                                                                    getValue(
                                                                      values.analyte &&
                                                                        values
                                                                          .analyte[
                                                                          index
                                                                        ] &&
                                                                        values
                                                                          .analyte[
                                                                          index
                                                                        ].method
                                                                    ),
                                                                }
                                                              : ""
                                                          }
                                                          onChange={(e) => {
                                                            setFieldValue(
                                                              `analyte[${index}].method`,
                                                              e.value
                                                            );
                                                          }}
                                                          loadOptions={
                                                            loadMethods
                                                          }
                                                          className="tableDropdown"
                                                        />
                                                      </FormControl>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              )
                                            )}
                                        </>
                                      );
                                    }}
                                  />
                                  {/* </TableBody> */}
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                          <div className="form__group form__group--btn flex justifyspace--between">
                            <button type="submit" className="btn btn--primary">
                              Submit
                            </button>
                            <button
                              onClick={() => {
                                resetForm();
                              }}
                              type="button"
                              className="btn btn--transparent btn--reset"
                            >
                              Reset
                            </button>
                          </div>
                        </Form>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default DataSubmitReport;
