import React from "react";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { Formik } from "formik";
import Swal from "sweetalert2";
import axios from "../../Services/axios";
import * as Yup from "yup";
import Header from "../Common/Header/Header";

const ChangePassword = () => {
  const [value, setValue] = React.useState({
    confirmPassword: false,
    showPassword: false,
    oldPassword: false,
  });

  const handleClickShowPassword = () => {
    setValue({
      ...value,
      showPassword: !value.showPassword,
    });
  };
  const handleClickOldPassword = () => {
    setValue({
      ...value,
      oldPassword: !value.showPassword,
    });
  };

  const handleClickConfirmPassword = () => {
    setValue({
      ...value,
      confirmPassword: !value.confirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const loginSchema = Yup.object({
    oldPassword: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Please Enter Valid Password use at least one uppercase letter, lowercase letter, number, special character with minimum 8 characters"
      ),
    newPassword: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Please Enter Valid Password use at least one uppercase letter, lowercase letter, number, special character with minimum 8 characters"
      ),
    confirmPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords don't match!"),
  });
  return (
    <>
      <Header themeWhite={true} contactBtn={true} bgLight={true} />

      <div className="mainSection mainPortal">
        <section className="page__section">
          <div className="container">
            <div className="form__container form__password">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  oldPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                }}
                validationSchema={loginSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  let loginObj = {
                    password: values.oldPassword,
                    new_password: values.newPassword,
                  };
                  axios
                    .post("/rmpt/user/change/password", loginObj)

                    .then((response) => {
                      let res = JSON.parse(response.data);
                      if (res.status === 200) {
                        setSubmitting(false);

                        Swal.fire({
                          position: "center",
                          icon: "success",
                          title: res.message,
                          showConfirmButton: true,
                          timer: 1500,
                        });
                        resetForm();
                        setSubmitting(false);
                      } else {
                        Swal.fire({
                          position: "top-end",
                          icon: "error",
                          title: res.message,
                          showConfirmButton: false,
                          timer: 1500,
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch((error) => {
                      setSubmitting(false);
                    })
                    .then(() => {
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit} className="form form__user">
                    {/* Logo */}
                    {/* <div className="form__brand mb_50">
                        <NavLink to="/">
                        <img
                            className="form__logo"
                            src={Logo}
                            alt="Logo | Rocky Mountain Reference Materials, LLC"
                            width="120px"
                        />
                        </NavLink>
                    </div> */}

                    <div className="form__title">Change password</div>

                    <div className="form__subtitle">
                      Create password use at least one uppercase letter,
                      lowercase letter, number, special character with minimum 8
                      characters.
                    </div>

                    <div className="form__section">
                      <div className="form__group">
                        <div className="form__input--group">
                          <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <InputLabel htmlFor="outlined-new-password">
                              Enter Old Password
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-new-password"
                              type={value.oldPassword ? "text" : "password"}
                              name="oldPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.oldPassword}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickOldPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {value.oldPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Enter Old Password"
                            />
                          </FormControl>
                          <div className="form__input--error">
                            {touched.oldPassword && errors.oldPassword}
                            {touched.oldPassword || errors.oldPassword}
                          </div>
                        </div>
                      </div>
                      <div className="form__group">
                        <div className="form__input--group">
                          <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <InputLabel htmlFor="outlined-new-password">
                              Enter New Password
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-new-password"
                              type={value.showPassword ? "text" : "password"}
                              name="newPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.newPassword}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {value.showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Enter New Password"
                            />
                          </FormControl>
                          <div className="form__input--error">
                            {touched.newPassword && errors.newPassword}
                            {touched.newPassword || errors.newPassword}
                          </div>
                        </div>
                      </div>

                      <div className="form__group">
                        <div className="form__input--group">
                          <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <InputLabel htmlFor="outlined-confirm-password">
                              Confirm New Password
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-confirm-password"
                              type={value.confirmPassword ? "text" : "password"}
                              name="confirmPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {value.confirmPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Confirm New Password"
                            />
                          </FormControl>
                          <div className="form__input--error">
                            {touched.confirmPassword && errors.confirmPassword}
                            {touched.confirmPassword || errors.confirmPassword}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form__group form__actions">
                      <button type="submit" className="btn btn--primary">
                        Change Password
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ChangePassword;
