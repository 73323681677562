import * as React from "react";
import { useEffect, useState } from "react";
import Header from "../../Common/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import EyeIcon from "../../Icon/EyeIcon";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import Collapse from "@mui/material/Collapse";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import IconButton from "@mui/material/IconButton";
import UploadIcon from "../../../Components/Icon/UploadIcon";
import CircularProgress from "@mui/material/CircularProgress";

import axios from "../../../Services/axios";
import ChartModal from "../../CustomerPortal/RMPT/modal/chartModal";
import PoUploadModal from "../../CustomerPortal/RMPT/modal/poUploadModal";
import SampleSentViewModal from "../../CustomerPortal/RMPT/modal/sampleSentViewModal";
import AnalysisCompleteModal from "../../CustomerPortal/RMPT/modal/analysisCompleteModal";
import AppealCompleteModal from "../../CustomerPortal/RMPT/modal/appealCompleteModal";
import AppealLetterModal from "../../CustomerPortal/RMPT/modal/appealLetterModal";
import AnalysisFormModal from "./modal/analysisFormModal";
import AppealFormModal from "./modal/appealFormModal";

const mdTheme = createTheme();

const NewFormStatus = () => {
  const navigate = useNavigate();
  const [status, setstatus] = React.useState("");
  const [customerId, setCustomerId] = React.useState("");
  const [calenderData, setCalenderData] = useState([]);
  const [viewAnalysisFormModal, setViewAnalysisFormModal] =
    React.useState(false);
  const [viewAppealFormModal, setViewAppealFormModal] = React.useState(false);
  const handleViewAppealFormClose = () => setViewAppealFormModal(false);
  const handleViewAnalysisFormClose = () => setViewAnalysisFormModal(false);

  const getValue = (value) => {
    let arr = [
      {
        label: "1 - 8 mm Disk Sample",
        value: "1",
      },
      {
        label: "2 - Chip Pack (10 g)",
        value: "2",
      },
      {
        label: "3 - Pin Pack (1 Qtr.)",
        value: "3",
      },
      {
        label: "4 - Chip Sample(20 g)",
        value: "4",
      },
    ];

    let index = arr.findIndex((x) => x.label === value);
    if (index === -1) return "";
    else return arr[index].value;
  };
  const getMethod = (value) => {
    let arr = [
      {
        label: "1 - AES",
        value: "1",
      },
      {
        label: "2 - XRF",
        value: "2",
      },
      {
        label: "3 - ICP",
        value: "3",
      },
      {
        label: "4 - Com",
        value: "4",
      },
      {
        label: "5 - IGF",
        value: "5",
      },
      {
        label: "6 - GDS",
        value: "6",
      },

      {
        label: "7 - FAA",
        value: "7",
      },
      {
        label: "8 - GFAA",
        value: "8",
      },

      {
        label: "9 - Other",
        value: "9",
      },
    ];
    let index = arr.findIndex((x) => x.label === value);
    if (index === -1) return "";
    else return arr[index].value;
  };
  const [activeIndex, setActiveIndex] = useState(null);

  const masterCheckedRmpt188 = (value) => {
    let checkedAll = true;
    if (value.calenderData.length === 0) {
      checkedAll = false;
    }
    value.calenderData.forEach((e) => {
      if (!e.rmpt188) {
        checkedAll = false;
      }
      e.child.forEach((x) => {
        if (!x.rmpt188) {
          checkedAll = false;
        }
      });
    });
    return checkedAll;
  };

  const masterCheckedChildRmpt188 = (value) => {
    let checkedAll = true;
    if (value.child.length === 0) {
      checkedAll = false;
    }
    value.child.forEach((e) => {
      if (!e.rmpt188) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };
  const getMasterChildLastUpdatedDateRmpt188 = (value) => {
    const calenderDateArray = [];
    value.child.forEach((e) => {
      calenderDateArray.push(e.rmpt188updated);
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };

  const generateMasterRmptNumber = (val, value) => {
    const date1 = new Date("12/30/1899");
    const date2 = new Date(val.sample_date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (value !== null) {
      const rmpt = parseInt(diffDays);
      return rmpt.toString() + val.material + value;
    } else {
      return "";
    }
  };

  const generateChildRmptNumber = (val, value) => {
    const date1 = new Date("12/30/1899");
    const date2 = new Date(val.sample_date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (value !== null) {
      const rmpt = parseInt(diffDays);
      return rmpt.toString() + val.material + value;
    } else {
      return "";
    }
  };

  const [viewAnalysisFormCompleteModal, setViewAnalysisFormCompleteModal] =
    React.useState(false);
  const handleViewAnalysisFormCompleteOpen = () =>
    setViewAnalysisFormCompleteModal(true);
  const handleViewAnalysisFormCompleteClose = () =>
    setViewAnalysisFormCompleteModal(false);

  const [chartModal, setChartModal] = React.useState(false);
  const handleChartModalOpen = () => setChartModal(true);
  const handleChartModalClose = () => setChartModal(false);

  const [viewAppealLetterModal, setViewAppealLetterModal] =
    React.useState(false);
  const handleViewAppealLetterOpen = () => setViewAppealLetterModal(true);
  const handleViewAppealLetterClose = () => setViewAppealLetterModal(false);

  const [viewAppealCompleteModal, setViewAppealCompleteModal] =
    React.useState(false);
  const handleViewAppealCompleteOpen = () => setViewAppealCompleteModal(true);
  const handleViewAppealCompleteClose = () => setViewAppealCompleteModal(false);

  const openAccordianChild = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const previewRmpt191 = (val, value) => {
    setLoader(true);

    axios
      .get(
        `/rmpt/get/previewCustomer?type=8&id=${value.user_id}&reference_id=${value.reference_id}&analyst_id=${val.id}`
      )

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(res.data) +
              "'></iframe>"
          );
        } else {
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthorized!") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session Expired",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .then(() => {
        setLoader(false);
      });
  };
  const previewRmpt189 = (val, value) => {
    setLoader(true);
    axios
      .get(
        `/rmpt/get/preview?type=6&id=${value.user_id}&reference_id=${value.reference_id}&analyst_id=${val.id}`
      )

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(res.data) +
              "'></iframe>"
          );
        } else {
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthorized!") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session Expired",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .then(() => {
        setLoader(false);
      });
  };
  const previewQuoteRequested = (value) => {
    axios
      .get(
        `/rmpt/get/previewCustomer?type=3&id=${value.user_id}&reference_id=${value.reference_id}`,
        setLoader(true)
      )

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          setLoader(false);
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(res.data) +
              "'></iframe>"
          );
        } else {
        }
      })
      .catch((error) => {})
      .then(() => {});
  };
  const previewRmpt188 = (value, val) => {
    setLoader(true);

    axios
      .get(
        `/rmpt/get/preview?type=5&id=${value.user_id}&reference_id=${value.reference_id}&analyst_id=${val.id}`
      )

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(res.data) +
              "'></iframe>"
          );
        } else {
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthorized!") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session Expired",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .then(() => {
        setLoader(false);
      });
  };
  const [rows, setRows] = React.useState([]);

  const fetchData = async () => {
    axios
      .get("/rmpt/user/get/calenders")

      .then((response) => {
        let result = JSON.parse(response.data);
        if (result.status === 200) {
          //  setCalender(res.data);
          // console.log("smdsm", result.data);
          const newresult = makeUniqueCompanyArray(result.data);
          for (let index = 0; index < newresult.length; index++) {
            for (let j = 0; j < newresult[index].firstChild.length; j++) {
              newresult[index].firstChild[j].calenderData = modifyData(
                newresult[index].firstChild[j].data
              );
            }
          }

          console.log("sdjjds", newresult);

          setRows(newresult);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: result.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      })
      .catch((error) => {
        console.log("err", error);
      })
      .then(() => {});
  };
  const makeUniqueCompanyArray = (data) => {
    let company = data.map((x) => x.company_id);
    company = [...new Set(company)];
    let data12 = [];
    for (let i = 0; i < company.length; i++) {
      const newData = data;
      let item = {};
      let arr = [];
      for (let index = 0; index < newData.length; index++) {
        if (index === 0) {
          item = newData[index];
        }
        arr.push(newData[index]);
      }

      item.firstChild = arr;

      data12.push(item);
    }

    return data12;
  };
  const checkedRmpt189 = (value) => {
    let checkedAll = true;
    if (value.data.length === 0) {
      checkedAll = false;
    }
    value.data.forEach((e) => {
      if (!e.rmpt189) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };
  const modifyData = (data) => {
    const calculateJulianDate = (date) => {
      const date1 = new Date("12/30/1899");
      const date2 = new Date(date);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    };

    let materials = data.map(
      (x) => calculateJulianDate(x.sample_date) + x.material + data.pin
    );
    materials = [...new Set(materials)];
    let data12 = [];
    for (let i = 0; i < materials.length; i++) {
      const newData = data.filter(
        (x) =>
          calculateJulianDate(x.sample_date) + x.material + data.pin ===
          materials[i]
      );
      let item = {};
      let arr = [];
      for (let index = 0; index < newData.length; index++) {
        if (index === 0) {
          item = newData[index];
        }
        arr.push(newData[index]);
      }

      item.child = arr;
      let counting = 0;
      let analyst = "";
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index];
        if (!index) {
          analyst = element.analyst;
          counting = 1;
          arr[index].counting = counting;
        } else if (analyst === element.analyst) {
          arr[index].counting = counting;
        } else {
          analyst = element.analyst;
          counting = counting + 1;
          arr[index].counting = counting;
        }
      }
      data12.push(item);
    }
    return data12;
  };

  const getMasterLastUpdatedDateRmpt188 = (value) => {
    const calenderDateArray = [];
    value.data.forEach((e) => {
      calenderDateArray.push(e.rmpt188updated);
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };

  const [userId, setUserId] = useState("");
  useEffect(() => {
    // eslint-disable-next-line
    fetchData();
    // eslint-disable-next-line
  }, []);

  const previewRmptReappeal = (val, value) => {
    setLoader(true);

    axios
      .get(
        `/rmpt/get/preview?type=9&id=${value.user_id}&reference_id=${value.reference_id}&analyst_id=${val.id}`
      )

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(res.data) +
              "'></iframe>"
          );
        } else {
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthorized!") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session Expired",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .then(() => {
        setLoader(false);
      });
  };
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const formattedDate = months[month] + " " + day + ", " + year;
    return formattedDate;
  }
  const [modalPo, setModalPo] = React.useState(false);
  const handlePoOpen = () => setModalPo(true);
  const handlePoClose = () => setModalPo(false);
  const generateNewReferenceId = (customer_number, order_date) => {
    const date1 = new Date("12/30/1899");
    const date2 = new Date(order_date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    const rmpt = customer_number + "-" + parseInt(diffDays);
    return rmpt.toString();
  };

  function getLatestDate(data) {
    // convert to timestamp and sort
    var sorted_ms = data
      .map(function (item) {
        return new Date(item).getTime();
      })
      .sort();
    // take latest
    var latest_ms = sorted_ms[sorted_ms.length - 1];
    // convert to js date object
    return new Date(latest_ms);
  }

  const [loader, setLoader] = useState(false);
  const masterCheckedChildRmpt189 = (value) => {
    let checkedAll = true;
    if (value?.child?.length === 0) {
      checkedAll = false;
    }
    value?.child?.forEach((e) => {
      if (!e.rmpt189) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };
  const getMasterChildLastUpdatedDateRmpt189 = (value) => {
    const calenderDateArray = [];
    value?.child?.forEach((e) => {
      calenderDateArray.push(e.rmpt189updated);
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };
  const masterCheckedRmpt189 = (value) => {
    let checkedAll = true;
    if (value.data.length === 0) {
      checkedAll = false;
    }
    value.data.forEach((e) => {
      if (!e.rmpt189) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };

  const getMasterLastUpdatedDateRmpt189 = (value) => {
    const calenderDateArray = [];
    value.data.forEach((e) => {
      calenderDateArray.push(e.rmpt189updated);
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };

  const masterCheckedChildRmptReappeal = (value) => {
    let checkedAll = true;
    if (value?.child?.length === 0) {
      checkedAll = false;
    }
    value?.child?.forEach((e) => {
      if (!e.isReappeal) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };
  const getMasterChildLastUpdatedDateRmptReappeal = (value) => {
    const calenderDateArray = [];
    value?.child?.forEach((e) => {
      calenderDateArray.push(e.reappealupdated);
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };
  const masterCheckedRmptReappeal = (value) => {
    let checkedAll = true;
    if (value.data.length === 0) {
      checkedAll = false;
    }
    value.data.forEach((e) => {
      if (!e.isReappeal) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };

  const getMasterLastUpdatedDateRmptReappeal = (value) => {
    const calenderDateArray = [];
    value.data.forEach((e) => {
      calenderDateArray.push(e.reappealupdated);
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };

  const masterCheckedChildRmptAppeal = (value) => {
    let checkedAll = true;
    if (value?.child?.length === 0) {
      checkedAll = false;
    }
    value?.child?.forEach((e) => {
      if (!e.isAppeal) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };
  const getMasterChildLastUpdatedDateAppeal = (value) => {
    const calenderDateArray = [];
    value?.child?.forEach((e) => {
      calenderDateArray.push(e.appealupdated);
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };
  const masterCheckedRmptAppeal = (value) => {
    let checkedAll = true;
    if (value.data.length === 0) {
      checkedAll = false;
    }
    value.data.forEach((e) => {
      if (!e.isAppeal) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };

  const getMasterLastUpdatedDateAppeal = (value) => {
    const calenderDateArray = [];
    value.data.forEach((e) => {
      calenderDateArray.push(e.appealupdated);
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };

  const [viewSampleModal, setViewSampleModal] = React.useState(false);
  const handleViewSampleOpen = () => setViewSampleModal(true);
  const handleViewSampleClose = () => setViewSampleModal(false);

  const getSampleCloseChartCheckedLastChild = (e) => {
    let checkedAll = true;

    if (!e.isAppeal && !e.isRejected && !e.isReappeal && !e.isAccepted) {
      checkedAll = false;
    }
    if (e.isAppeal && e.isRejected && !e.isReappeal) {
      checkedAll = false;
    } else if (e.isAppeal && !e.isRejected && !e.isAccepted) {
      checkedAll = false;
    }

    return checkedAll;
  };

  const getSampleClosedLastUpdatedDateLastChild = (e) => {
    const calenderDateArray = [];

    if (e.isReappeal) {
      if (e.reappealupdated && e.reappealupdated !== null)
        calenderDateArray.push(e.reappealupdated);
    } else if (e.isAccepted) {
      if (e.appealupdated && e.appealupdated !== null)
        calenderDateArray.push(e.appealupdated);
    }

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };

  const getSampleCloseChartChecked = (value) => {
    let checkedAll = true;
    if (value.data.length === 0) {
      checkedAll = false;
    }
    value.data.forEach((e) => {
      if (!e.isAppeal && !e.isRejected && !e.isReappeal && !e.isAccepted) {
        checkedAll = false;
      }
      if (e.isAppeal && e.isRejected && !e.isReappeal) {
        checkedAll = false;
      } else if (e.isAppeal && !e.isRejected && !e.isAccepted) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };
  const getSampleClosedLastUpdatedDate = (value) => {
    const calenderDateArray = [];
    value.data.forEach((e) => {
      if (e.isReappeal) {
        if (e.reappealupdated && e.reappealupdated !== null)
          calenderDateArray.push(e.reappealupdated);
      } else if (e.isAccepted) {
        if (e.appealupdated && e.appealupdated !== null)
          calenderDateArray.push(e.appealupdated);
      }
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };

  const getSampleCloseChartCheckedChild = (value) => {
    let checkedAll = true;
    if (value.child.length === 0) {
      checkedAll = false;
    }
    value.child.forEach((e) => {
      if (!e.isAppeal && !e.isRejected && !e.isReappeal && !e.isAccepted) {
        checkedAll = false;
      }
      if (e.isAppeal && e.isRejected && !e.isReappeal) {
        checkedAll = false;
      } else if (e.isAppeal && !e.isRejected && !e.isAccepted) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };
  const getSampleClosedLastUpdatedDateChild = (value) => {
    const calenderDateArray = [];
    value.child.forEach((e) => {
      if (e.isReappeal) {
        if (e.reappealupdated && e.reappealupdated !== null)
          calenderDateArray.push(e.reappealupdated);
      } else if (e.isAccepted) {
        if (e.appealupdated && e.appealupdated !== null)
          calenderDateArray.push(e.appealupdated);
      }
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };
  return (
    <ThemeProvider theme={mdTheme}>
      {loader ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : null}
      <Header themeWhite={true} contactBtn={true} bgLight={true} />
      <div className="mainSection mainPortal">
        <section className="page__section">
          <div className="container">
            <ChartModal
              chartModal={chartModal}
              handleChartModalClose={handleChartModalClose}
              calenderData={calenderData}
              userId={userId}
              customerId={customerId}
            />
            <AnalysisFormModal
              viewAnalysisFormModal={viewAnalysisFormModal}
              handleViewAnalysisFormClose={handleViewAnalysisFormClose}
              calenderData={calenderData}
              userId={userId}
              customerId={customerId}
            />
            <AppealFormModal
              viewAppealFormModal={viewAppealFormModal}
              handleViewAppealFormClose={handleViewAppealFormClose}
              calenderData={calenderData}
            />

            {/* Po Upload Modal */}
            <PoUploadModal
              modalPo={modalPo}
              handlePoClose={handlePoClose}
              setModalPo={setModalPo}
              fetchData={fetchData}
              status={status}
              customerId={customerId}
              setLoader={setLoader}
            />

            {/* Analysis Complete Modal */}
            <AnalysisCompleteModal
              viewAnalysisFormCompleteModal={viewAnalysisFormCompleteModal}
              handleViewAnalysisFormCompleteClose={
                handleViewAnalysisFormCompleteClose
              }
              calenderData={calenderData}
              userId={userId}
              customerId={customerId}
              setLoader={setLoader}
            />

            {/* Appeal Letter Modal */}
            <AppealLetterModal
              viewAppealLetterModal={viewAppealLetterModal}
              handleViewAppealLetterClose={handleViewAppealLetterClose}
              calenderData={calenderData}
              userId={userId}
              customerId={customerId}
              setLoader={setLoader}
            />

            {/* Appeal Complete Modal */}
            <AppealCompleteModal
              viewAppealCompleteModal={viewAppealCompleteModal}
              handleViewAppealCompleteClose={handleViewAppealCompleteClose}
              calenderData={calenderData}
              userId={userId}
              customerId={customerId}
              setLoader={setLoader}
            />

            <div className="admin__form--status">
              {/* <h2 className="admin__form--title">RMPT Form Status</h2> */}
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                }}
                className="form form__user"
              >
                <h1 className="form__title form__title--row">
                  Analyst Form Status
                  <div className="btn--wrap">
                    <button className="btn btn--print">
                      <svg
                        id="printer-line"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="27"
                        viewBox="0 0 30 27"
                      >
                        <path
                          id="Path_54"
                          data-name="Path 54"
                          d="M29,9H27V5H9V9H7a4,4,0,0,0-4,4V24H6.92V22.09H5V13a2,2,0,0,1,2-2H29a2,2,0,0,1,2,2v9H29.08v2H33V13A4,4,0,0,0,29,9ZM25,9H11V7H25Z"
                          transform="translate(-3 -5)"
                          fill="rgba(109,124,144,0.62)"
                        />
                        <path
                          id="Path_55"
                          data-name="Path 55"
                          d="M28,18H8a1,1,0,0,0,0,2H9V32H27V20h1a1,1,0,0,0,0-2ZM25,30H11V20H25Z"
                          transform="translate(-3 -5)"
                          fill="rgba(109,124,144,0.62)"
                        />
                        <path
                          id="Path_56"
                          data-name="Path 56"
                          d="M27,13.04h2v2H27Z"
                          transform="translate(-3 -5)"
                          fill="rgba(109,124,144,0.62)"
                        />
                      </svg>
                    </button>
                    <button
                      onClick={() => {
                        navigate("/sample-calender");
                      }}
                      className="btn btn--primary"
                    >
                      Renewal
                    </button>
                  </div>
                </h1>
              </form>

              {rows && rows.length > 0
                ? rows.map((value, index) => {
                    return (
                      <div>
                        <TableContainer className="form__table">
                          <Table
                            style={{ zIndex: 1 }}
                            className="form__rmpt--status form__head--fixed"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="left"
                                  padding="none"
                                ></TableCell>
                                <TableCell
                                  align="left"
                                  style={{ padding: "10px 0" }}
                                >
                                  <div>Reference ID</div>
                                </TableCell>

                                <TableCell align="center" padding="none">
                                  Calendar Complete
                                </TableCell>

                                <TableCell align="center" padding="none">
                                  Quote Issued
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  PO Issued
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  Sample Sent
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  Analysis Forms
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  Analysis Complete
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  Appeal Letter
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  Appeal Forms
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  Appeal Complete
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  Sample Complete
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  View All Details
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {value.firstChild && value.firstChild.length > 0
                                ? value.firstChild.map((value, index) => {
                                    return (
                                      <>
                                        <TableRow hover className="table__row">
                                          {/* Plus Icon */}
                                          <TableCell
                                            align="left"
                                            padding="none"
                                          >
                                            <IconButton
                                              aria-label="expand row"
                                              size="small"
                                              disabled={
                                                (value.calenderData &&
                                                  value.calenderData.length ===
                                                    0) ||
                                                value.quote_issued === false
                                              }
                                              onClick={() => {
                                                openAccordianChild(index);
                                              }}
                                            >
                                              {activeIndex === index ? (
                                                <RemoveIcon />
                                              ) : (
                                                <AddIcon />
                                              )}
                                            </IconButton>
                                          </TableCell>

                                          {/* Reference ID */}
                                          <TableCell
                                            align="left"
                                            padding="none"
                                          >
                                            {value.customer_number === null
                                              ? value.reference_id
                                              : generateNewReferenceId(
                                                  value.customer_number,
                                                  value.order_date
                                                )}{" "}
                                          </TableCell>

                                          {/* Calendar Complete */}
                                          <TableCell>
                                            <div
                                              className="flex alignitems--center"
                                              style={{
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Tooltip
                                                title={
                                                  value.quote_requested
                                                    ? `Status Updated On: ${formatDate(
                                                        value?.update_status
                                                          ?.quote_requested
                                                      )}`
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  checked={
                                                    value.quote_requested
                                                  }
                                                />
                                              </Tooltip>
                                              <div className="btn--wrap">
                                                <Tooltip
                                                  title={
                                                    value.quote_requested
                                                      ? "View/Download"
                                                      : ""
                                                  }
                                                >
                                                  <button
                                                    disabled={
                                                      value.quote_requested ===
                                                      false
                                                    }
                                                    onClick={() => {
                                                      previewQuoteRequested(
                                                        value
                                                      );
                                                    }}
                                                    className="view-btn"
                                                  >
                                                    <EyeIcon />
                                                  </button>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </TableCell>

                                          {/* Quote Issued */}
                                          <TableCell
                                            align="center"
                                            padding="none"
                                          >
                                            <div
                                              className="flex alignitems--center"
                                              style={{
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Tooltip
                                                title={
                                                  value.quote_issued
                                                    ? `Status Updated On: ${formatDate(
                                                        value?.update_status
                                                          ?.quote_issued
                                                      )}`
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  checked={value.quote_issued}
                                                />
                                              </Tooltip>
                                              <div className="btn--wrap">
                                                <Tooltip
                                                  title={
                                                    value.quote_issued
                                                      ? `View/Download`
                                                      : ""
                                                  }
                                                >
                                                  <button
                                                    disabled={
                                                      value.quote_issued ===
                                                      false
                                                    }
                                                    onClick={() => {
                                                      window.open(
                                                        value.quote_file,
                                                        "_blank",
                                                        "noreferrer"
                                                      );
                                                    }}
                                                    className="view-btn"
                                                  >
                                                    <EyeIcon />
                                                  </button>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </TableCell>

                                          {/* PO Issued */}
                                          <TableCell
                                            align="center"
                                            padding="none"
                                          >
                                            <div
                                              className="flex alignitems--center"
                                              style={{
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Tooltip
                                                title={
                                                  value.po_posted
                                                    ? `Status Updated On: ${formatDate(
                                                        value?.update_status
                                                          ?.po_issued
                                                      )}`
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  checked={value.po_posted}
                                                />
                                              </Tooltip>
                                              <div className="btn--wrap">
                                                <Tooltip
                                                  title={
                                                    value.po_posted
                                                      ? "View/Download"
                                                      : ""
                                                  }
                                                >
                                                  <button
                                                    disabled={
                                                      value.po_posted === false
                                                    }
                                                    onClick={() => {
                                                      window.open(
                                                        value.po_file,
                                                        "_blank",
                                                        "noreferrer"
                                                      );
                                                    }}
                                                    className="view-btn"
                                                  >
                                                    <EyeIcon />
                                                  </button>
                                                </Tooltip>
                                              </div>
                                              <div
                                                style={{
                                                  display:
                                                    value.po_posted === true
                                                      ? "none"
                                                      : undefined,
                                                }}
                                                className="button--icon"
                                              >
                                                <button
                                                  disabled={
                                                    value.quote_issued === false
                                                  }
                                                  onClick={() => {
                                                    setCustomerId(
                                                      value.reference_id
                                                    );
                                                    setstatus(
                                                      value.update_status
                                                    );
                                                    handlePoOpen();
                                                  }}
                                                >
                                                  <UploadIcon />
                                                </button>
                                              </div>
                                            </div>
                                          </TableCell>

                                          {/* Sample Sent */}
                                          <TableCell>
                                            <div
                                              className="flex alignitems--center"
                                              style={{
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Tooltip
                                                title={
                                                  masterCheckedRmpt188(value)
                                                    ? getMasterLastUpdatedDateRmpt188(
                                                        value
                                                      )
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  checked={masterCheckedRmpt188(
                                                    value
                                                  )}
                                                />
                                              </Tooltip>
                                              <div className="btn--wrap">
                                                <Tooltip
                                                  title={
                                                    masterCheckedRmpt188(value)
                                                      ? "View/Download"
                                                      : ""
                                                  }
                                                >
                                                  <button
                                                    disabled={
                                                      masterCheckedRmpt188(
                                                        value
                                                      ) === false
                                                    }
                                                    className="view-btn"
                                                    onClick={() => {
                                                      setCustomerId(
                                                        value.reference_id
                                                      );
                                                      setUserId(value.user_id);

                                                      const arr = [];
                                                      value.data.forEach(
                                                        (e) => {
                                                          arr.push(e);
                                                        }
                                                      );
                                                      setCalenderData(arr);

                                                      handleViewSampleOpen();
                                                    }}
                                                  >
                                                    <EyeIcon />
                                                  </button>
                                                </Tooltip>

                                                {/* Sample Sent View Modal */}
                                                <SampleSentViewModal
                                                  value={value}
                                                  setLoader={setLoader}
                                                  viewSampleModal={
                                                    viewSampleModal
                                                  }
                                                  handleViewSampleClose={
                                                    handleViewSampleClose
                                                  }
                                                  calenderData={calenderData}
                                                  userId={userId}
                                                  customerId={customerId}
                                                />
                                              </div>
                                            </div>
                                          </TableCell>
                                          {/* Analysis Forms */}
                                          <TableCell
                                            align="center"
                                            padding="none"
                                          >
                                            <div
                                              className="flex alignitems--center"
                                              style={{
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Tooltip
                                                title={
                                                  checkedRmpt189(value)
                                                    ? getMasterLastUpdatedDateRmpt189(
                                                        value
                                                      )
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  checked={checkedRmpt189(
                                                    value
                                                  )}
                                                />
                                              </Tooltip>
                                              <div className="btn--wrap"></div>
                                            </div>
                                          </TableCell>
                                          {/* Analysis Complete */}
                                          <TableCell>
                                            <div
                                              className="flex alignitems--center"
                                              style={{
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Tooltip
                                                title={
                                                  masterCheckedRmpt189(value)
                                                    ? getMasterLastUpdatedDateRmpt189(
                                                        value
                                                      )
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  checked={masterCheckedRmpt189(
                                                    value
                                                  )}
                                                />
                                              </Tooltip>
                                              <div className="btn--wrap">
                                                <Tooltip title="">
                                                  <button
                                                    className="view-btn"
                                                    disabled={
                                                      masterCheckedRmpt189(
                                                        value
                                                      ) === false
                                                    }
                                                    onClick={() => {
                                                      setCustomerId(
                                                        value.reference_id
                                                      );
                                                      setUserId(value.user_id);

                                                      const arr = [];
                                                      value.data.forEach(
                                                        (e) => {
                                                          arr.push(e);
                                                        }
                                                      );
                                                      setCalenderData(arr);
                                                      handleViewAnalysisFormCompleteOpen();
                                                    }}
                                                  >
                                                    <EyeIcon />
                                                  </button>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </TableCell>

                                          {/* Appeal Letter */}
                                          <TableCell>
                                            <div
                                              className="flex alignitems--center"
                                              style={{
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Tooltip
                                                title={
                                                  masterCheckedRmptAppeal(value)
                                                    ? getMasterLastUpdatedDateAppeal(
                                                        value
                                                      )
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  checked={masterCheckedRmptAppeal(
                                                    value
                                                  )}
                                                />
                                              </Tooltip>
                                              <div className="btn--wrap">
                                                <Tooltip
                                                  title={
                                                    masterCheckedRmptAppeal(
                                                      value
                                                    )
                                                      ? "View/Download"
                                                      : ""
                                                  }
                                                >
                                                  <button
                                                    disabled={
                                                      masterCheckedRmptAppeal(
                                                        value
                                                      ) === false
                                                    }
                                                    onClick={() => {
                                                      setCustomerId(
                                                        value.reference_id
                                                      );
                                                      setUserId(value.user_id);

                                                      const arr = [];
                                                      value.data.forEach(
                                                        (e) => {
                                                          arr.push(e);
                                                        }
                                                      );
                                                      setCalenderData(arr);

                                                      handleViewAppealLetterOpen();
                                                    }}
                                                    className="view-btn"
                                                  >
                                                    <EyeIcon />
                                                  </button>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </TableCell>
                                          {/* Appeal Forms */}
                                          <TableCell
                                            align="center"
                                            padding="none"
                                          >
                                            <div
                                              className="flex alignitems--center"
                                              style={{
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Tooltip
                                                title={
                                                  masterCheckedRmptReappeal(
                                                    value
                                                  )
                                                    ? getMasterLastUpdatedDateRmptReappeal(
                                                        value
                                                      )
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  checked={masterCheckedRmptReappeal(
                                                    value
                                                  )}
                                                />
                                              </Tooltip>
                                              <div className="btn--wrap"></div>
                                            </div>
                                          </TableCell>

                                          {/* Appeal Complete */}
                                          <TableCell>
                                            <div
                                              className="flex alignitems--center"
                                              style={{
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Tooltip
                                                title={
                                                  masterCheckedRmptReappeal(
                                                    value
                                                  )
                                                    ? getMasterLastUpdatedDateRmptReappeal(
                                                        value
                                                      )
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  checked={masterCheckedRmptReappeal(
                                                    value
                                                  )}
                                                />
                                              </Tooltip>
                                              <div className="btn--wrap">
                                                <Tooltip
                                                  title={
                                                    masterCheckedRmptReappeal(
                                                      value
                                                    )
                                                      ? "View/Download"
                                                      : ""
                                                  }
                                                >
                                                  <button
                                                    disabled={
                                                      masterCheckedRmptReappeal(
                                                        value
                                                      ) === false
                                                    }
                                                    onClick={() => {
                                                      setCustomerId(
                                                        value.reference_id
                                                      );
                                                      setUserId(value.user_id);
                                                      const arr = [];
                                                      value.data.forEach(
                                                        (e) => {
                                                          arr.push(e);
                                                        }
                                                      );
                                                      setCalenderData(arr);

                                                      handleViewAppealCompleteOpen();
                                                    }}
                                                    className="view-btn"
                                                  >
                                                    <EyeIcon />
                                                  </button>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </TableCell>

                                          {/* Sample Complete */}
                                          <TableCell>
                                            <div
                                              className="flex alignitems--center"
                                              style={{
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Tooltip
                                                title={
                                                  getSampleCloseChartChecked(
                                                    value
                                                  )
                                                    ? getSampleClosedLastUpdatedDate(
                                                        value
                                                      )
                                                    : ""
                                                }
                                              >
                                                <Checkbox
                                                  checked={getSampleCloseChartChecked(
                                                    value
                                                  )}
                                                />
                                              </Tooltip>
                                              <div className="btn--wrap">
                                                <Tooltip
                                                  title={
                                                    getSampleCloseChartChecked(
                                                      value
                                                    )
                                                      ? "View/Download"
                                                      : ""
                                                  }
                                                >
                                                  <button
                                                    disabled={
                                                      !getSampleCloseChartChecked(
                                                        value
                                                      )
                                                    }
                                                    onClick={() => {
                                                      setCustomerId(
                                                        value.reference_id
                                                      );
                                                      setUserId(value.user_id);

                                                      const arr = [];
                                                      value.data.forEach(
                                                        (e) => {
                                                          arr.push(e);
                                                        }
                                                      );
                                                      setCalenderData(arr);

                                                      handleChartModalOpen();
                                                    }}
                                                    className="view-btn"
                                                  >
                                                    <EyeIcon />
                                                  </button>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </TableCell>

                                          {/* View All Details */}
                                          <TableCell
                                            align="center"
                                            padding="none"
                                          >
                                            <div
                                              className="flex alignitems--center"
                                              style={{
                                                justifyContent: "center",
                                              }}
                                            >
                                              <div className="btn--wrap">
                                                <Tooltip title="">
                                                  <button className="view-btn">
                                                    <EyeIcon />
                                                  </button>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                        <TableRow className="table__row">
                                          <TableCell colSpan={13}>
                                            <Collapse
                                              in={activeIndex === index}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              {value.calenderData &&
                                              value.calenderData.length > 0
                                                ? value.calenderData.map(
                                                    (val, index) => {
                                                      return (
                                                        <Table>
                                                          <TableBody>
                                                            <TableRow
                                                              hover
                                                              className="table__subRow"
                                                            >
                                                              <TableCell
                                                                align="left"
                                                                padding="none"
                                                              ></TableCell>

                                                              <TableCell
                                                                align="left"
                                                                padding="none"
                                                              >
                                                                {val.sample_date ===
                                                                null
                                                                  ? value.reference_id
                                                                  : generateMasterRmptNumber(
                                                                      val,
                                                                      val.pin
                                                                    )}
                                                              </TableCell>

                                                              <TableCell
                                                                align="center"
                                                                padding="none"
                                                              >
                                                                {/* <div
                                                                  className="flex alignitems--center"
                                                                  style={{
                                                                    justifyContent:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <Tooltip
                                                                    title={
                                                                      value.quote_requested
                                                                        ? `Status Updated On: ${formatDate(
                                                                            value
                                                                              ?.update_status
                                                                              ?.quote_requested
                                                                          )}`
                                                                        : ""
                                                                    }
                                                                  >
                                                                    <Checkbox
                                                                      checked={
                                                                        value.quote_requested
                                                                      }
                                                                    />
                                                                  </Tooltip>
                                                                  <div className="btn--wrap">
                                                                    <Tooltip
                                                                      title={
                                                                        value.quote_requested
                                                                          ? `View/Download`
                                                                          : ""
                                                                      }
                                                                    >
                                                                      <button
                                                                        disabled={
                                                                          value.quote_requested ===
                                                                          false
                                                                        }
                                                                        onClick={() => {
                                                                          previewQuoteRequested(
                                                                            value
                                                                          );
                                                                        }}
                                                                        className="view-btn"
                                                                      >
                                                                        <EyeIcon />
                                                                      </button>
                                                                    </Tooltip>
                                                                  </div>
                                                                </div> */}
                                                              </TableCell>

                                                              <TableCell
                                                                align="center"
                                                                padding="none"
                                                              >
                                                                {/* <div
                                                                  className="flex alignitems--center"
                                                                  style={{
                                                                    justifyContent:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <Tooltip
                                                                    title={
                                                                      value.quote_issued
                                                                        ? `Status Updated On: ${formatDate(
                                                                            value
                                                                              ?.update_status
                                                                              ?.quote_issued
                                                                          )}`
                                                                        : ""
                                                                    }
                                                                  >
                                                                    <Checkbox
                                                                      checked={
                                                                        value.quote_issued
                                                                      }
                                                                    />
                                                                  </Tooltip>
                                                                  <div className="btn--wrap">
                                                                    <Tooltip
                                                                      title={
                                                                        value.quote_issued
                                                                          ? `View/Download`
                                                                          : ""
                                                                      }
                                                                    >
                                                                      <button
                                                                        disabled={
                                                                          value.quote_issued ===
                                                                          false
                                                                        }
                                                                        onClick={() => {
                                                                          window.open(
                                                                            value.quote_file,
                                                                            "_blank",
                                                                            "noreferrer"
                                                                          );
                                                                        }}
                                                                        className="view-btn"
                                                                      >
                                                                        <EyeIcon />
                                                                      </button>
                                                                    </Tooltip>
                                                                  </div>
                                                                </div> */}
                                                              </TableCell>

                                                              <TableCell
                                                                align="center"
                                                                padding="none"
                                                              >
                                                                {/* <div
                                                                  className="flex alignitems--center"
                                                                  style={{
                                                                    justifyContent:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <Tooltip
                                                                    title={
                                                                      value.po_posted
                                                                        ? `Status Updated On: ${formatDate(
                                                                            value
                                                                              ?.update_status
                                                                              ?.po_issued
                                                                          )}`
                                                                        : ""
                                                                    }
                                                                  >
                                                                    <Checkbox
                                                                      checked={
                                                                        value.po_posted
                                                                      }
                                                                    />
                                                                  </Tooltip>
                                                                  <div className="btn--wrap">
                                                                    <Tooltip
                                                                      title={
                                                                        value.po_posted
                                                                          ? "View/Download"
                                                                          : ""
                                                                      }
                                                                    >
                                                                      <button
                                                                        disabled={
                                                                          value.po_posted ===
                                                                          false
                                                                        }
                                                                        onClick={() => {
                                                                          window.open(
                                                                            value.po_file,
                                                                            "_blank",
                                                                            "noreferrer"
                                                                          );
                                                                        }}
                                                                        className="view-btn"
                                                                      >
                                                                        <EyeIcon />
                                                                      </button>
                                                                    </Tooltip>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        value.po_posted ===
                                                                        true
                                                                          ? "none"
                                                                          : undefined,
                                                                    }}
                                                                    className="button--icon"
                                                                  >
                                                                    <button
                                                                      disabled={
                                                                        value.quote_issued ===
                                                                        false
                                                                      }
                                                                      onClick={() => {
                                                                        setCustomerId(
                                                                          value.reference_id
                                                                        );
                                                                        setstatus(
                                                                          value.update_status
                                                                        );
                                                                        handlePoOpen();
                                                                      }}
                                                                    >
                                                                      <UploadIcon />
                                                                    </button>
                                                                  </div>
                                                                </div> */}
                                                              </TableCell>

                                                              <TableCell
                                                                align="center"
                                                                padding="none"
                                                              >
                                                                <div
                                                                  className="flex alignitems--center"
                                                                  style={{
                                                                    justifyContent:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <Tooltip
                                                                    title={
                                                                      masterCheckedChildRmpt188(
                                                                        val
                                                                      )
                                                                        ? getMasterChildLastUpdatedDateRmpt188(
                                                                            val
                                                                          )
                                                                        : ""
                                                                    }
                                                                  >
                                                                    <Checkbox
                                                                      checked={masterCheckedChildRmpt188(
                                                                        val
                                                                      )}
                                                                    />
                                                                  </Tooltip>
                                                                  <div className="btn--wrap">
                                                                    <Tooltip
                                                                      title={
                                                                        masterCheckedChildRmpt188(
                                                                          val
                                                                        )
                                                                          ? "View/Download"
                                                                          : ""
                                                                      }
                                                                    >
                                                                      <button
                                                                        disabled={
                                                                          masterCheckedChildRmpt188(
                                                                            val
                                                                          ) ===
                                                                          false
                                                                        }
                                                                        className="view-btn"
                                                                        onClick={() => {
                                                                          setCustomerId(
                                                                            value.reference_id
                                                                          );
                                                                          setUserId(
                                                                            value.user_id
                                                                          );

                                                                          const arr =
                                                                            [];
                                                                          val.child.forEach(
                                                                            (
                                                                              e
                                                                            ) => {
                                                                              arr.push(
                                                                                e
                                                                              );
                                                                            }
                                                                          );
                                                                          setCalenderData(
                                                                            arr
                                                                          );
                                                                          handleViewSampleOpen();
                                                                        }}
                                                                      >
                                                                        <EyeIcon />
                                                                      </button>
                                                                    </Tooltip>
                                                                  </div>
                                                                </div>
                                                              </TableCell>
                                                              {/* Analysis Forms */}
                                                              <TableCell
                                                                align="center"
                                                                padding="none"
                                                              >
                                                                <div
                                                                  className="flex alignitems--center"
                                                                  style={{
                                                                    justifyContent:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <Tooltip
                                                                    title={
                                                                      masterCheckedChildRmpt189(
                                                                        val
                                                                      )
                                                                        ? getMasterChildLastUpdatedDateRmpt189(
                                                                            val
                                                                          )
                                                                        : ""
                                                                    }
                                                                  >
                                                                    <Checkbox
                                                                      checked={masterCheckedChildRmpt189(
                                                                        val
                                                                      )}
                                                                    />
                                                                  </Tooltip>
                                                                  <div className="btn--wrap">
                                                                    {/* <Tooltip
                                                                      title={
                                                                        masterCheckedChildRmpt189(
                                                                          val
                                                                        )
                                                                          ? "View/Download"
                                                                          : ""
                                                                      }
                                                                    > */}
                                                                    {/* <button
                                                                        disabled={
                                                                          masterCheckedChildRmpt188(
                                                                            val
                                                                          ) ===
                                                                            false ||
                                                                          masterCheckedChildRmpt189(
                                                                            val
                                                                          ) ===
                                                                            true
                                                                        }
                                                                        className="view-btn"
                                                                        onClick={() => {
                                                                          setCustomerId(
                                                                            value.reference_id
                                                                          );
                                                                          setUserId(
                                                                            value.user_id
                                                                          );

                                                                          const arr =
                                                                            [];
                                                                          val.child.forEach(
                                                                            (
                                                                              e
                                                                            ) => {
                                                                              arr.push(
                                                                                e
                                                                              );
                                                                            }
                                                                          );
                                                                          setCalenderData(
                                                                            arr
                                                                          );

                                                                          handleViewAnalysisFormOpen();
                                                                        }}
                                                                      >
                                                                        <AddIcon />
                                                                      </button> */}
                                                                    {/* </Tooltip> */}
                                                                  </div>
                                                                </div>
                                                              </TableCell>
                                                              {/* Analysis Complete */}
                                                              <TableCell>
                                                                <div
                                                                  className="flex alignitems--center"
                                                                  style={{
                                                                    justifyContent:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <Tooltip
                                                                    title={
                                                                      masterCheckedChildRmpt189(
                                                                        val
                                                                      )
                                                                        ? getMasterChildLastUpdatedDateRmpt189(
                                                                            val
                                                                          )
                                                                        : ""
                                                                    }
                                                                  >
                                                                    <Checkbox
                                                                      checked={masterCheckedChildRmpt189(
                                                                        val
                                                                      )}
                                                                    />
                                                                  </Tooltip>
                                                                  <div className="btn--wrap">
                                                                    <Tooltip title="">
                                                                      <button
                                                                        disabled={
                                                                          masterCheckedChildRmpt189(
                                                                            val
                                                                          ) ===
                                                                          false
                                                                        }
                                                                        className="view-btn"
                                                                        onClick={() => {
                                                                          setCustomerId(
                                                                            value.reference_id
                                                                          );
                                                                          setUserId(
                                                                            value.user_id
                                                                          );

                                                                          const arr =
                                                                            [];
                                                                          val.child.forEach(
                                                                            (
                                                                              e
                                                                            ) => {
                                                                              arr.push(
                                                                                e
                                                                              );
                                                                            }
                                                                          );
                                                                          setCalenderData(
                                                                            arr
                                                                          );

                                                                          handleViewAnalysisFormCompleteOpen();
                                                                        }}
                                                                      >
                                                                        <EyeIcon />
                                                                      </button>
                                                                    </Tooltip>
                                                                  </div>
                                                                </div>
                                                              </TableCell>

                                                              {/* Appeal Letter */}
                                                              <TableCell>
                                                                <div
                                                                  className="flex alignitems--center"
                                                                  style={{
                                                                    justifyContent:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <Tooltip
                                                                    title={
                                                                      masterCheckedChildRmptAppeal(
                                                                        val
                                                                      )
                                                                        ? getMasterChildLastUpdatedDateAppeal(
                                                                            val
                                                                          )
                                                                        : ""
                                                                    }
                                                                  >
                                                                    <Checkbox
                                                                      checked={masterCheckedChildRmptAppeal(
                                                                        val
                                                                      )}
                                                                    />
                                                                  </Tooltip>
                                                                  <div className="btn--wrap">
                                                                    <Tooltip
                                                                      title={
                                                                        masterCheckedChildRmptAppeal(
                                                                          val
                                                                        )
                                                                          ? "View/Download"
                                                                          : ""
                                                                      }
                                                                    >
                                                                      <button
                                                                        disabled={
                                                                          masterCheckedChildRmptAppeal(
                                                                            val
                                                                          ) ===
                                                                          false
                                                                        }
                                                                        onClick={() => {
                                                                          setCustomerId(
                                                                            value.reference_id
                                                                          );
                                                                          setUserId(
                                                                            value.user_id
                                                                          );

                                                                          const arr =
                                                                            [];
                                                                          val.child.forEach(
                                                                            (
                                                                              e
                                                                            ) => {
                                                                              arr.push(
                                                                                e
                                                                              );
                                                                            }
                                                                          );
                                                                          setCalenderData(
                                                                            arr
                                                                          );

                                                                          handleViewAppealLetterOpen();
                                                                        }}
                                                                        className="view-btn"
                                                                      >
                                                                        <EyeIcon />
                                                                      </button>
                                                                    </Tooltip>
                                                                  </div>
                                                                </div>
                                                              </TableCell>
                                                              <TableCell
                                                                align="center"
                                                                padding="none"
                                                              >
                                                                <div
                                                                  className="flex alignitems--center"
                                                                  style={{
                                                                    justifyContent:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <Tooltip
                                                                    title={
                                                                      masterCheckedChildRmptReappeal(
                                                                        val
                                                                      )
                                                                        ? getMasterChildLastUpdatedDateRmptReappeal(
                                                                            val
                                                                          )
                                                                        : ""
                                                                    }
                                                                  >
                                                                    <Checkbox
                                                                      checked={masterCheckedChildRmptReappeal(
                                                                        val
                                                                      )}
                                                                    />
                                                                  </Tooltip>
                                                                  <div className="btn--wrap">
                                                                    {/* <Tooltip
                                                                      title={
                                                                        masterCheckedChildRmptReappeal(
                                                                          val
                                                                        )
                                                                          ? "View/Download"
                                                                          : ""
                                                                      }
                                                                    >
                                                                      <button
                                                                        disabled={
                                                                          masterCheckedChildRmptAppeal(
                                                                            val
                                                                          ) ===
                                                                            false ||
                                                                          masterCheckedChildRmptReappeal(
                                                                            val
                                                                          ) ===
                                                                            true
                                                                        }
                                                                        onClick={() => {
                                                                          setCustomerId(
                                                                            value.reference_id
                                                                          );
                                                                          setUserId(
                                                                            value.user_id
                                                                          );

                                                                          const arr =
                                                                            [];
                                                                          val.child.forEach(
                                                                            (
                                                                              e
                                                                            ) => {
                                                                              arr.push(
                                                                                e
                                                                              );
                                                                            }
                                                                          );
                                                                          setCalenderData(
                                                                            arr
                                                                          );

                                                                          handleViewAppealFormOpen();
                                                                        }}
                                                                        className="view-btn"
                                                                      >
                                                                        <AddIcon />
                                                                      </button>
                                                                    </Tooltip> */}
                                                                  </div>
                                                                </div>
                                                              </TableCell>

                                                              {/* Appeal Complete */}
                                                              <TableCell>
                                                                <div
                                                                  className="flex alignitems--center"
                                                                  style={{
                                                                    justifyContent:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <Tooltip
                                                                    title={
                                                                      masterCheckedChildRmptReappeal(
                                                                        val
                                                                      )
                                                                        ? getMasterChildLastUpdatedDateRmptReappeal(
                                                                            val
                                                                          )
                                                                        : ""
                                                                    }
                                                                  >
                                                                    <Checkbox
                                                                      checked={masterCheckedChildRmptReappeal(
                                                                        val
                                                                      )}
                                                                    />
                                                                  </Tooltip>
                                                                  <div className="btn--wrap">
                                                                    <Tooltip
                                                                      title={
                                                                        masterCheckedChildRmptReappeal(
                                                                          val
                                                                        )
                                                                          ? "View/Download"
                                                                          : ""
                                                                      }
                                                                    >
                                                                      <button
                                                                        disabled={
                                                                          masterCheckedChildRmptReappeal(
                                                                            val
                                                                          ) ===
                                                                          false
                                                                        }
                                                                        onClick={() => {
                                                                          setCustomerId(
                                                                            value.reference_id
                                                                          );
                                                                          setUserId(
                                                                            value.user_id
                                                                          );
                                                                          const arr =
                                                                            [];
                                                                          val.child.forEach(
                                                                            (
                                                                              e
                                                                            ) => {
                                                                              arr.push(
                                                                                e
                                                                              );
                                                                            }
                                                                          );
                                                                          setCalenderData(
                                                                            arr
                                                                          );

                                                                          handleViewAppealCompleteOpen();
                                                                        }}
                                                                        className="view-btn"
                                                                      >
                                                                        <EyeIcon />
                                                                      </button>
                                                                    </Tooltip>
                                                                  </div>
                                                                </div>
                                                              </TableCell>

                                                              {/* Sample Complete */}
                                                              <TableCell>
                                                                <div
                                                                  className="flex alignitems--center"
                                                                  style={{
                                                                    justifyContent:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <Tooltip
                                                                    title={
                                                                      getSampleCloseChartCheckedChild(
                                                                        val
                                                                      )
                                                                        ? getSampleClosedLastUpdatedDateChild(
                                                                            val
                                                                          )
                                                                        : ""
                                                                    }
                                                                  >
                                                                    <Checkbox
                                                                      checked={getSampleCloseChartCheckedChild(
                                                                        val
                                                                      )}
                                                                    />
                                                                  </Tooltip>
                                                                  <div className="btn--wrap">
                                                                    <Tooltip
                                                                      title={
                                                                        getSampleCloseChartCheckedChild(
                                                                          val
                                                                        )
                                                                          ? "View/Download"
                                                                          : ""
                                                                      }
                                                                    >
                                                                      <button
                                                                        disabled={
                                                                          !getSampleCloseChartCheckedChild(
                                                                            val
                                                                          )
                                                                        }
                                                                        onClick={() => {
                                                                          setCustomerId(
                                                                            value.reference_id
                                                                          );
                                                                          setUserId(
                                                                            value.user_id
                                                                          );

                                                                          const arr =
                                                                            [];
                                                                          val.child.forEach(
                                                                            (
                                                                              e
                                                                            ) => {
                                                                              arr.push(
                                                                                e
                                                                              );
                                                                            }
                                                                          );
                                                                          setCalenderData(
                                                                            arr
                                                                          );

                                                                          handleChartModalOpen();
                                                                        }}
                                                                        className="view-btn"
                                                                      >
                                                                        <EyeIcon />
                                                                      </button>
                                                                    </Tooltip>
                                                                  </div>
                                                                </div>
                                                              </TableCell>
                                                              <TableCell
                                                                align="center"
                                                                padding="none"
                                                              >
                                                                <div className="btn--wrap">
                                                                  <Tooltip title="">
                                                                    <button className="view-btn">
                                                                      <EyeIcon />
                                                                    </button>
                                                                  </Tooltip>
                                                                </div>
                                                              </TableCell>
                                                            </TableRow>
                                                            <TableRow
                                                              hover
                                                              className="table__subRow"
                                                            >
                                                              <TableCell
                                                                colSpan={15}
                                                              >
                                                                <Table>
                                                                  <TableBody>
                                                                    {val.child &&
                                                                    val.child
                                                                      .length >
                                                                      0
                                                                      ? val.child.map(
                                                                          (
                                                                            val,
                                                                            idx
                                                                          ) => {
                                                                            return (
                                                                              <TableRow
                                                                                hover
                                                                                className="table__childRow"
                                                                              >
                                                                                <TableCell
                                                                                  align="left"
                                                                                  padding="none"
                                                                                ></TableCell>

                                                                                <TableCell
                                                                                  align="left"
                                                                                  padding="none"
                                                                                >
                                                                                  {val.order_date ===
                                                                                  null ? (
                                                                                    value.reference_id
                                                                                  ) : (
                                                                                    <Tooltip
                                                                                      title={
                                                                                        generateChildRmptNumber(
                                                                                          val,
                                                                                          val.pin
                                                                                        ) +
                                                                                        "-" +
                                                                                        val.counting +
                                                                                        val.method +
                                                                                        getValue(
                                                                                          val.sample_type
                                                                                        )
                                                                                      }
                                                                                    >
                                                                                      <div>
                                                                                        {" "}
                                                                                        {generateChildRmptNumber(
                                                                                          val,
                                                                                          val.pin
                                                                                        ) +
                                                                                          "-" +
                                                                                          val.counting +
                                                                                          getMethod(
                                                                                            val.method
                                                                                          ) +
                                                                                          getValue(
                                                                                            val.sample_type
                                                                                          )}
                                                                                      </div>
                                                                                    </Tooltip>
                                                                                  )}
                                                                                </TableCell>

                                                                                {/* Calendar Complete */}
                                                                                <TableCell>
                                                                                  {/* <div
                                                                                    className="flex alignitems--center"
                                                                                    style={{
                                                                                      justifyContent:
                                                                                        "center",
                                                                                    }}
                                                                                  >
                                                                                    <Tooltip
                                                                                      title={
                                                                                        value.quote_requested
                                                                                          ? `Status Updated On: ${formatDate(
                                                                                              value
                                                                                                ?.update_status
                                                                                                ?.quote_requested
                                                                                            )}`
                                                                                          : ""
                                                                                      }
                                                                                    >
                                                                                      <Checkbox
                                                                                        checked={
                                                                                          value.quote_requested
                                                                                        }
                                                                                      />
                                                                                    </Tooltip>
                                                                                    <div className="btn--wrap">
                                                                                      <Tooltip
                                                                                        title={
                                                                                          value.quote_requested
                                                                                            ? "View/Download"
                                                                                            : ""
                                                                                        }
                                                                                      >
                                                                                        <button
                                                                                          disabled={
                                                                                            value.quote_requested ===
                                                                                            false
                                                                                          }
                                                                                          onClick={() => {
                                                                                            previewQuoteRequested(
                                                                                              value
                                                                                            );
                                                                                          }}
                                                                                          className="view-btn"
                                                                                        >
                                                                                          <EyeIcon />
                                                                                        </button>
                                                                                      </Tooltip>
                                                                                    </div>
                                                                                  </div> */}
                                                                                </TableCell>

                                                                                <TableCell
                                                                                  align="center"
                                                                                  padding="none"
                                                                                >
                                                                                  {/* <div
                                                                                    className="flex alignitems--center"
                                                                                    style={{
                                                                                      justifyContent:
                                                                                        "center",
                                                                                    }}
                                                                                  >
                                                                                    <Tooltip
                                                                                      title={
                                                                                        value.quote_issued
                                                                                          ? `Status Updated On: ${formatDate(
                                                                                              value
                                                                                                ?.update_status
                                                                                                ?.quote_issued
                                                                                            )}`
                                                                                          : ""
                                                                                      }
                                                                                    >
                                                                                      <Checkbox
                                                                                        checked={
                                                                                          value.quote_issued
                                                                                        }
                                                                                      />
                                                                                    </Tooltip>
                                                                                    <div className="btn--wrap">
                                                                                      <Tooltip
                                                                                        title={
                                                                                          value.quote_issued
                                                                                            ? `View/Download`
                                                                                            : ""
                                                                                        }
                                                                                      >
                                                                                        <button
                                                                                          disabled={
                                                                                            value.quote_issued ===
                                                                                            false
                                                                                          }
                                                                                          onClick={() => {
                                                                                            window.open(
                                                                                              value.quote_file,
                                                                                              "_blank",
                                                                                              "noreferrer"
                                                                                            );
                                                                                          }}
                                                                                          className="view-btn"
                                                                                        >
                                                                                          <EyeIcon />
                                                                                        </button>
                                                                                      </Tooltip>
                                                                                    </div>
                                                                                  </div> */}
                                                                                </TableCell>

                                                                                <TableCell
                                                                                  align="center"
                                                                                  padding="none"
                                                                                >
                                                                                  {/* <div
                                                                                    className="flex alignitems--center"
                                                                                    style={{
                                                                                      justifyContent:
                                                                                        "center",
                                                                                    }}
                                                                                  >
                                                                                    <Tooltip
                                                                                      title={
                                                                                        value.po_posted
                                                                                          ? `Status Updated On: ${formatDate(
                                                                                              value
                                                                                                ?.update_status
                                                                                                ?.po_issued
                                                                                            )}`
                                                                                          : ""
                                                                                      }
                                                                                    >
                                                                                      <Checkbox
                                                                                        checked={
                                                                                          value.po_posted
                                                                                        }
                                                                                      />
                                                                                    </Tooltip>
                                                                                    <div className="btn--wrap">
                                                                                      <Tooltip
                                                                                        title={
                                                                                          value.po_posted
                                                                                            ? "View/Download"
                                                                                            : ""
                                                                                        }
                                                                                      >
                                                                                        <button
                                                                                          disabled={
                                                                                            value.po_posted ===
                                                                                            false
                                                                                          }
                                                                                          onClick={() => {
                                                                                            window.open(
                                                                                              value.po_file,
                                                                                              "_blank",
                                                                                              "noreferrer"
                                                                                            );
                                                                                          }}
                                                                                          className="view-btn"
                                                                                        >
                                                                                          <EyeIcon />
                                                                                        </button>
                                                                                      </Tooltip>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        display:
                                                                                          value.po_posted ===
                                                                                          true
                                                                                            ? "none"
                                                                                            : undefined,
                                                                                      }}
                                                                                      className="button--icon"
                                                                                    >
                                                                                      <button
                                                                                        disabled={
                                                                                          value.quote_issued ===
                                                                                          false
                                                                                        }
                                                                                        onClick={() => {
                                                                                          setCustomerId(
                                                                                            value.reference_id
                                                                                          );
                                                                                          setstatus(
                                                                                            value.update_status
                                                                                          );
                                                                                          handlePoOpen();
                                                                                        }}
                                                                                      >
                                                                                        <UploadIcon />
                                                                                      </button>
                                                                                    </div>
                                                                                  </div> */}
                                                                                </TableCell>

                                                                                <TableCell
                                                                                  align="center"
                                                                                  padding="none"
                                                                                >
                                                                                  {/* <div
                                                                                    className="flex alignitems--center"
                                                                                    style={{
                                                                                      justifyContent:
                                                                                        "center",
                                                                                    }}
                                                                                  >
                                                                                    <Tooltip
                                                                                      title={
                                                                                        val.rmpt188
                                                                                          ? `Status Updated On: ${formatDate(
                                                                                              val.rmpt188updated
                                                                                            )}`
                                                                                          : ""
                                                                                      }
                                                                                    >
                                                                                      <Checkbox
                                                                                        checked={
                                                                                          val.rmpt188
                                                                                        }
                                                                                      />
                                                                                    </Tooltip>
                                                                                    <div className="btn--wrap">
                                                                                      <Tooltip
                                                                                        title={
                                                                                          val.rmpt188
                                                                                            ? "View/Download"
                                                                                            : ""
                                                                                        }
                                                                                      >
                                                                                        <button
                                                                                          disabled={
                                                                                            val.rmpt188 ===
                                                                                              false ||
                                                                                            val.rmpt188 ===
                                                                                              null
                                                                                          }
                                                                                          className="view-btn"
                                                                                          onClick={() => {
                                                                                            setCustomerId(
                                                                                              value.reference_id
                                                                                            );
                                                                                            setUserId(
                                                                                              value.user_id
                                                                                            );
                                                                                            previewRmpt188(
                                                                                              value,
                                                                                              val
                                                                                            );
                                                                                          }}
                                                                                        >
                                                                                          <EyeIcon />
                                                                                        </button>
                                                                                      </Tooltip>
                                                                                    </div>
                                                                                  </div> */}
                                                                                </TableCell>
                                                                                <TableCell
                                                                                  align="center"
                                                                                  padding="none"
                                                                                >
                                                                                  <div
                                                                                    className="flex alignitems--center"
                                                                                    style={{
                                                                                      justifyContent:
                                                                                        "center",
                                                                                    }}
                                                                                  >
                                                                                    <Tooltip
                                                                                      title={
                                                                                        val.rmpt189
                                                                                          ? `Status Updated On: ${formatDate(
                                                                                              val.rmpt189updated
                                                                                            )}`
                                                                                          : ""
                                                                                      }
                                                                                    >
                                                                                      <Checkbox
                                                                                        checked={
                                                                                          val.rmpt189
                                                                                        }
                                                                                      />
                                                                                    </Tooltip>
                                                                                    <div className="btn--wrap">
                                                                                      <button
                                                                                        disabled={
                                                                                          val.rmpt188 !==
                                                                                            true ||
                                                                                          val.rmpt189 ===
                                                                                            true
                                                                                        }
                                                                                        // disabled={
                                                                                        //   masterCheckedChildRmpt188(
                                                                                        //     val
                                                                                        //   ) ===
                                                                                        //     false ||
                                                                                        //   masterCheckedChildRmpt189(
                                                                                        //     val
                                                                                        //   ) ===
                                                                                        //     true
                                                                                        // }
                                                                                        className="view-btn"
                                                                                        onClick={(
                                                                                          e
                                                                                        ) => {
                                                                                          // setCustomerId(
                                                                                          //   value.reference_id
                                                                                          // );
                                                                                          // setUserId(
                                                                                          //   value.user_id
                                                                                          // );

                                                                                          // const arr =
                                                                                          //   [];
                                                                                          // val.child.forEach(
                                                                                          //   (
                                                                                          //     e
                                                                                          //   ) => {
                                                                                          //     arr.push(
                                                                                          //       e
                                                                                          //     );
                                                                                          //   }
                                                                                          // );
                                                                                          // setCalenderData(
                                                                                          //   arr
                                                                                          // );

                                                                                          // handleViewAnalysisFormOpen();
                                                                                          e.preventDefault();
                                                                                          const replacerFunc =
                                                                                            () => {
                                                                                              const visited =
                                                                                                new WeakSet();
                                                                                              return (
                                                                                                key,
                                                                                                value
                                                                                              ) => {
                                                                                                if (
                                                                                                  typeof value ===
                                                                                                    "object" &&
                                                                                                  value !==
                                                                                                    null
                                                                                                ) {
                                                                                                  if (
                                                                                                    visited.has(
                                                                                                      value
                                                                                                    )
                                                                                                  ) {
                                                                                                    return;
                                                                                                  }
                                                                                                  visited.add(
                                                                                                    value
                                                                                                  );
                                                                                                }
                                                                                                return value;
                                                                                              };
                                                                                            };
                                                                                          localStorage.removeItem(
                                                                                            "type"
                                                                                          );
                                                                                          localStorage.setItem(
                                                                                            "method",
                                                                                            val.method
                                                                                          );
                                                                                          localStorage.setItem(
                                                                                            "rmptdata",
                                                                                            JSON.stringify(
                                                                                              val,
                                                                                              replacerFunc()
                                                                                            )
                                                                                          );

                                                                                          localStorage.setItem(
                                                                                            "pin",
                                                                                            JSON.stringify(
                                                                                              val.pin
                                                                                            )
                                                                                          );

                                                                                          localStorage.setItem(
                                                                                            "rmptid",
                                                                                            val.rmpt_id
                                                                                          );

                                                                                          localStorage.setItem(
                                                                                            "UserId",
                                                                                            value.user_id
                                                                                          );
                                                                                          localStorage.setItem(
                                                                                            "referenceId",
                                                                                            value.reference_id
                                                                                          );
                                                                                          localStorage.setItem(
                                                                                            "analystId",
                                                                                            val.id
                                                                                          );

                                                                                          window.location.href =
                                                                                            "/data-submit-report";
                                                                                        }}
                                                                                      >
                                                                                        <AddIcon />
                                                                                      </button>
                                                                                    </div>
                                                                                  </div>
                                                                                </TableCell>
                                                                                {/* Analysis Complete */}
                                                                                <TableCell>
                                                                                  <div
                                                                                    className="flex alignitems--center"
                                                                                    style={{
                                                                                      justifyContent:
                                                                                        "center",
                                                                                    }}
                                                                                  >
                                                                                    <Tooltip
                                                                                      title={
                                                                                        val.rmpt189
                                                                                          ? `Status Updated On: ${formatDate(
                                                                                              val.rmpt189updated
                                                                                            )}`
                                                                                          : ""
                                                                                      }
                                                                                    >
                                                                                      <Checkbox
                                                                                        checked={
                                                                                          val.rmpt189
                                                                                        }
                                                                                      />
                                                                                    </Tooltip>
                                                                                    <div className="btn--wrap">
                                                                                      <Tooltip title="">
                                                                                        <button
                                                                                          className="view-btn"
                                                                                          disabled={
                                                                                            !(
                                                                                              val.rmpt189 ===
                                                                                              true
                                                                                            )
                                                                                          }
                                                                                          onClick={() => {
                                                                                            previewRmpt189(
                                                                                              val,
                                                                                              value
                                                                                            );
                                                                                          }}
                                                                                        >
                                                                                          <EyeIcon />
                                                                                        </button>
                                                                                      </Tooltip>
                                                                                    </div>
                                                                                  </div>
                                                                                </TableCell>

                                                                                {/* Appeal Letter */}
                                                                                <TableCell>
                                                                                  <div
                                                                                    className="flex alignitems--center"
                                                                                    style={{
                                                                                      justifyContent:
                                                                                        "center",
                                                                                    }}
                                                                                  >
                                                                                    <Tooltip
                                                                                      title={
                                                                                        val.isAppeal
                                                                                          ? `Status Updated On ${formatDate(
                                                                                              val.appealupdated
                                                                                            )}`
                                                                                          : ""
                                                                                      }
                                                                                    >
                                                                                      <Checkbox
                                                                                        checked={
                                                                                          val.isAppeal
                                                                                        }
                                                                                      />
                                                                                    </Tooltip>
                                                                                    <div className="btn--wrap">
                                                                                      <Tooltip
                                                                                        title={
                                                                                          val.isAppeal
                                                                                            ? "View/Download"
                                                                                            : ""
                                                                                        }
                                                                                      >
                                                                                        <button
                                                                                          disabled={
                                                                                            !(
                                                                                              val.isAppeal ===
                                                                                              true
                                                                                            )
                                                                                          }
                                                                                          onClick={() => {
                                                                                            previewRmpt191(
                                                                                              val,
                                                                                              value
                                                                                            );
                                                                                          }}
                                                                                          className="view-btn"
                                                                                        >
                                                                                          <EyeIcon />
                                                                                        </button>
                                                                                      </Tooltip>
                                                                                    </div>
                                                                                  </div>
                                                                                </TableCell>
                                                                                {/* Appeal Forms */}
                                                                                <TableCell
                                                                                  align="center"
                                                                                  padding="none"
                                                                                >
                                                                                  <div
                                                                                    className="flex alignitems--center"
                                                                                    style={{
                                                                                      justifyContent:
                                                                                        "center",
                                                                                    }}
                                                                                  >
                                                                                    <Tooltip
                                                                                      title={
                                                                                        val.isReappeal
                                                                                          ? `Status Updated On ${formatDate(
                                                                                              val.reappealupdated
                                                                                            )}`
                                                                                          : ""
                                                                                      }
                                                                                    >
                                                                                      <Checkbox
                                                                                        checked={
                                                                                          val.isReappeal
                                                                                        }
                                                                                      />
                                                                                    </Tooltip>
                                                                                    <div className="btn--wrap">
                                                                                      <button
                                                                                        disabled={
                                                                                          val.isAccepted ===
                                                                                            true ||
                                                                                          !(
                                                                                            val.isAppeal ===
                                                                                            true
                                                                                          ) ||
                                                                                          val.isReappeal ===
                                                                                            true
                                                                                        }
                                                                                        onClick={(
                                                                                          e
                                                                                        ) => {
                                                                                          e.preventDefault();
                                                                                          const replacerFunc =
                                                                                            () => {
                                                                                              const visited =
                                                                                                new WeakSet();
                                                                                              return (
                                                                                                key,
                                                                                                value
                                                                                              ) => {
                                                                                                if (
                                                                                                  typeof value ===
                                                                                                    "object" &&
                                                                                                  value !==
                                                                                                    null
                                                                                                ) {
                                                                                                  if (
                                                                                                    visited.has(
                                                                                                      value
                                                                                                    )
                                                                                                  ) {
                                                                                                    return;
                                                                                                  }
                                                                                                  visited.add(
                                                                                                    value
                                                                                                  );
                                                                                                }
                                                                                                return value;
                                                                                              };
                                                                                            };
                                                                                          localStorage.setItem(
                                                                                            "rmptdata",
                                                                                            JSON.stringify(
                                                                                              val,
                                                                                              replacerFunc()
                                                                                            )
                                                                                          );

                                                                                          localStorage.setItem(
                                                                                            "pin",
                                                                                            JSON.stringify(
                                                                                              val.pin
                                                                                            )
                                                                                          );
                                                                                          localStorage.removeItem(
                                                                                            "type"
                                                                                          );
                                                                                          localStorage.setItem(
                                                                                            "type",
                                                                                            "reappeal"
                                                                                          );
                                                                                          localStorage.setItem(
                                                                                            "rmptid",
                                                                                            val.rmpt_id
                                                                                          );
                                                                                          window.location.href =
                                                                                            "/data-submit-report";
                                                                                        }}
                                                                                        className="view-btn"
                                                                                      >
                                                                                        <AddIcon />
                                                                                      </button>
                                                                                    </div>
                                                                                  </div>
                                                                                </TableCell>
                                                                                {/* Appeal Complete */}
                                                                                <TableCell>
                                                                                  <div
                                                                                    className="flex alignitems--center"
                                                                                    style={{
                                                                                      justifyContent:
                                                                                        "center",
                                                                                    }}
                                                                                  >
                                                                                    <Tooltip
                                                                                      title={
                                                                                        val.isReappeal
                                                                                          ? `Status Updated On ${formatDate(
                                                                                              val.reappealupdated
                                                                                            )}`
                                                                                          : ""
                                                                                      }
                                                                                    >
                                                                                      <Checkbox
                                                                                        checked={
                                                                                          val.isReappeal
                                                                                        }
                                                                                      />
                                                                                    </Tooltip>
                                                                                    <div className="btn--wrap">
                                                                                      <Tooltip
                                                                                        title={
                                                                                          val.isReappeal
                                                                                            ? "View/Download"
                                                                                            : ""
                                                                                        }
                                                                                      >
                                                                                        <button
                                                                                          disabled={
                                                                                            !(
                                                                                              val.isReappeal ===
                                                                                              true
                                                                                            )
                                                                                          }
                                                                                          onClick={() => {
                                                                                            previewRmptReappeal(
                                                                                              val,
                                                                                              value
                                                                                            );
                                                                                          }}
                                                                                          className="view-btn"
                                                                                        >
                                                                                          <EyeIcon />
                                                                                        </button>
                                                                                      </Tooltip>
                                                                                    </div>
                                                                                  </div>
                                                                                </TableCell>

                                                                                {/* Sample Complete */}
                                                                                <TableCell>
                                                                                  <div
                                                                                    className="flex alignitems--center"
                                                                                    style={{
                                                                                      justifyContent:
                                                                                        "center",
                                                                                    }}
                                                                                  >
                                                                                    <Tooltip
                                                                                      title={
                                                                                        getSampleCloseChartCheckedLastChild(
                                                                                          val
                                                                                        )
                                                                                          ? getSampleClosedLastUpdatedDateLastChild(
                                                                                              val
                                                                                            )
                                                                                          : ""
                                                                                      }
                                                                                    >
                                                                                      <Checkbox
                                                                                        checked={getSampleCloseChartCheckedLastChild(
                                                                                          val
                                                                                        )}
                                                                                      />
                                                                                    </Tooltip>
                                                                                    <div className="btn--wrap">
                                                                                      <Tooltip
                                                                                        title={
                                                                                          getSampleCloseChartCheckedLastChild(
                                                                                            val
                                                                                          )
                                                                                            ? "View/Download"
                                                                                            : ""
                                                                                        }
                                                                                      >
                                                                                        <button
                                                                                          disabled={
                                                                                            !getSampleCloseChartCheckedLastChild(
                                                                                              val
                                                                                            )
                                                                                          }
                                                                                          onClick={() => {
                                                                                            setCustomerId(
                                                                                              value.reference_id
                                                                                            );
                                                                                            setUserId(
                                                                                              value.user_id
                                                                                            );

                                                                                            setCalenderData(
                                                                                              [
                                                                                                val,
                                                                                              ]
                                                                                            );

                                                                                            handleChartModalOpen();
                                                                                          }}
                                                                                          className="view-btn"
                                                                                        >
                                                                                          <EyeIcon />
                                                                                        </button>
                                                                                      </Tooltip>
                                                                                    </div>
                                                                                  </div>
                                                                                </TableCell>

                                                                                <TableCell
                                                                                  align="center"
                                                                                  padding="none"
                                                                                >
                                                                                  <div className="btn--wrap">
                                                                                    <Tooltip title="">
                                                                                      <button className="view-btn">
                                                                                        <EyeIcon />
                                                                                      </button>
                                                                                    </Tooltip>
                                                                                  </div>
                                                                                </TableCell>
                                                                              </TableRow>
                                                                            );
                                                                          }
                                                                        )
                                                                      : null}
                                                                  </TableBody>
                                                                </Table>
                                                              </TableCell>
                                                            </TableRow>
                                                          </TableBody>
                                                        </Table>
                                                      );
                                                    }
                                                  )
                                                : null}
                                            </Collapse>
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    );
                                  })
                                : null}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </section>
      </div>
    </ThemeProvider>
  );
};

export default NewFormStatus;
