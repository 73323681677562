import React , { useEffect } from "react";
import Header from "../../../Common/Header/Header";
import Footer from "../../../Common/Footer/Footer";
import Banner from "../../../Common/Banner/Banner";
import Breadcrumb from "../../../Common/Breadcrumb/Breadcrumb";
import SideNavbar from '../../../Templates/SideNavbar/ProductSideNavbar';
import PDFIcon from "../../../Icon/Pdf";

const LowAlloySUS = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    },[]);

    var emailList = [
        {email: 'sales@rmrms.com'},
        {email: ''},
        {email: ''},
    ]

    return(
        <>
            <Header />
            <div className="mainSection">

                <Banner bgPath={"url(/images/HeaderDark.jpg)"} 
                    breadcrumb={<Breadcrumb title="Low Alloy SUS" listItem="Home" listItemPath="/" listLink="Products" listLinkPath="/products" />}
                />

                <div className='container'>
                    <div className='row'>
                        <div className='col_sm_12 col_md_5 col_lg_3'>
                            <SideNavbar/>
                        </div>

                        <div className='col_sm_12 col_md_7 col_lg_9'>
                            <section className="page__section">
                                <h3 className="page__section--heading text__align--left">Low Alloy SUS</h3>
                                <p className="page__section--para">Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
                            </section>

                            <section className="page__section bg--light p_3">
                                <h4 className="page__section--sub-heading text__align--left">SUS Setting-Up Materials</h4>
                                <ul className="catalog__list">
                                    <li className="catalog__list--item">
                                        <a href='/pdf/SUS-Al.pdf'><PDFIcon/>SUS Setting-Up Materials</a>
                                    </li>
                                </ul>
                            </section>

                            <section className="page__section">
                                <div className="row">
                                    <div className="col_sm_12 col_md_12 col_lg_8">
                                        <div className="product__info">
                                            <h4 className="page__section--sub-heading text__align--left">Material Forms and Dimensions
                                            </h4>
                                            <ul className="product__info--list">
                                                <li className="product__info--list-item">General - General purpose size (~40 mm diameter x ~38 mm thick) intended for drift correction of OE, XRF, and GD Spectroscopy.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col_sm_12 col_md_12 col_lg_4">
                                        <div className="product__pricing">
                                            <h4 className="page__section--sub-heading text__align--left">General Pricing</h4>
                                            <p className="page__section--para">Please Call for Pricing : <a href="tel:(720) 943-7676">(720) 943-7676</a></p>
                                            <ul className="product__pricing--list">
                                                <li className="product__pricing--list-title">
                                                    <p>Description</p>
                                                    <p>Suffix #</p>
                                                </li>
                                                <li className="product__pricing--list-item">
                                                    <p>General</p>
                                                    <p>-G</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                
            </div>
            <Footer footerEmails={true} emailList={emailList}/>
        </>
    )
}

export default LowAlloySUS;
