import React from 'react';

const Message = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20.126" height="20.126" viewBox="0 0 20.126 20.126">
                <g id="bx-message-square-detail" transform="translate(0.1 0.1)">
                    <path id="Path_1" data-name="Path 1" d="M16.948,3H8.978A5.984,5.984,0,0,0,3,8.978V21.93a1,1,0,0,0,1,1H16.948a5.984,5.984,0,0,0,5.978-5.978V8.978A5.984,5.984,0,0,0,16.948,3Zm3.985,13.948a3.989,3.989,0,0,1-3.985,3.985H4.993V8.978A3.989,3.989,0,0,1,8.978,4.993h7.971a3.989,3.989,0,0,1,3.985,3.985Z" transform="translate(-3 -3)" fill="#fff" stroke="#900" strokeWidth="0.2" />
                    <path id="Path_2" data-name="Path 2" d="M10.5,13.5h9.963v1.993H10.5Zm0,3.985h6.974v1.993H10.5Z" transform="translate(-5.518 -6.526)" fill="#fff" stroke="#900" strokeWidth="0.2" />
                </g>
            </svg>
        </>
    );
}

export default Message;