import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Pages/Home";
import Products from "./Components/Pages/Products";
import Services from "./Components/Pages/Services";
import Certificates from "./Components/Pages/Certificates";
import Industry from "./Components/Pages/IndustryCatalogs";
import SearchEngine from "./Components/Pages/SearchEngine";
import News from "./Components/Pages/LatestNews";
import About from "./Components/Pages/About";
import Contact from "./Components/Pages/Contact";
import OurQualityWeb from "./Components/Pages/OurQuality";

import GdprLayout from "./GdprLayout";
import RMRMs from "./Components/Pages/ProductPages/RMRMs";
import RioTintoAlcanCRM from "./Components/Pages/ProductPages/AluminiumCRMs/RioTintoAlcanCRMs";
import ArconicCRM from "./Components/Pages/ProductPages/AluminiumCRMs/ArconicCRMs";
import SuisseTpCRM from "./Components/Pages/ProductPages/AluminiumCRMs/SuisseTpCRMs";
import CMICastIrons from "./Components/Pages/ProductPages/CastIronCRMs/CmiCastIrons";
import BrammerCastIrons from "./Components/Pages/ProductPages/CastIronCRMs/BrammerCastIrons";
import AusmonMonitor from "./Components/Pages/ProductPages/AusmonMonitor";
import OurTeam from "./admin/team";
import OurQuality from "./admin/quality";

import { CatalogPage } from "./Components/Pages/BlogInternalPages/CatalogPage";
import { NewsPage } from "./Components/Pages/BlogInternalPages/NewsPage";
import Login from "./admin/Login";
import ProductHome from "./admin/products/pages/ProductHome";
import Listing from "./admin/products/pages/Listing";
import ServiceListing from "./admin/services/Listing";
import ServiceHome from "./admin/services/ServiceHome";
import SubProductContent from "./admin/products/pages/CreateSubProduct";
import ServicePage from "./admin/services/ServicePage";
import CertificateListing from "./admin/certificate/Listing";
import CatalogListing from "./admin/catalogs/Listing";
import UserListing from "./admin/users/Listing";

import HomeAdmin from "./admin/Home";
import AboutAdmin from "./admin/About";
import LatestNewsListing from "./admin/latestNews/Listing";
import SearchEngineAdmin from "./admin/SearchEngine";
import ContactAdmin from "./admin/Contact";
import AusmonMonitorsAdmin from "./admin/products/pages/AusmonMonitors";
import CreateCategory from "./admin/products/category/Listing";
import RMCP from "./Components/Pages/ServicePages/RMCP";
import RMPT from "./Components/Pages/ServicePages/RMPT";
import RMSP from "./Components/Pages/ServicePages/RMSP";
import FormStatusAdmin from "./admin/customerPortal/formStatus/Listing";
import AnalystStatusAdmin from "./admin/customerPortal/analystStatus/Listing";

import AluminiumSUS from "./Components/Pages/ProductPages/SettingUpMaterials/AluminiumSUSs";
import LowAlloySUS from "./Components/Pages/ProductPages/SettingUpMaterials/LowAlloySUSs";
import CastIronSUS from "./Components/Pages/ProductPages/SettingUpMaterials/CastIronSUSs";

import BayerProcess from "./Components/Pages/ProductPages/RtaNonMetallics/BayerProcess";
import Electrolysis from "./Components/Pages/ProductPages/RtaNonMetallics/Electrolysis";
import DynamicPage from "./Components/Pages/ProductPages/DynamicContents";
import DynamicService from "./Components/Pages/ServicePages/DynamicService";
import LatestNews from "./admin/latestNews/LatestNews";
import PhotoGalleryAdmin from "./admin/photoGallery/Listing";

import CustomerLogin from "./Components/CustomerPortal/Login";
import CustomerRegister from "./Components/CustomerPortal/Register";
import CreatePassword from "./Components/CustomerPortal/CreatePassword";
import ForgotPassword from "./Components/CustomerPortal/ForgotPassword";
import InfoRequest from "./Components/CustomerPortal/RMPT/InfoRequest";
import MyProfile from "./Components/CustomerPortal/RMPT/MyProfile";

import ProfileData from "./Components/CustomerPortal/RMPT/ProfileData";
import ProfileDataSheet from "./Components/CustomerPortal/RMPT/ProfileDataSheet";

import SampleCalender from "./Components/CustomerPortal/RMPT/Calendar";
import DataSubmittalReport from "./Components/CustomerPortal/RMPT/DataSubmitReport";
import FormStatus from "./Components/CustomerPortal/RMPT/FormStatus";
import Zscore from "./Components/CustomerPortal/RMPT/Zscore";
import Letter from "./Components/CustomerPortal/RMPT/Letter";
import ChangePassword from "./Components/CustomerPortal/ChangePassword";
// import AnalystFormStatus from "./Components/CustomerPortal/RMPT/AnalystFormStatus";
import SampleClosedChart from "./Components/CustomerPortal/RMPT/SampleClosedChart";
import Chart from "./Components/CustomerPortal/RMPT/Chart";
import PhotoGallery from "./Components/Pages/PhotoGallery";
import CreateGallery from "./admin/photoGallery/CreateGallery";
import MyProfileAnalyst from "./Components/CustomerPortal/RMPT/MyProfileAnalyst";
import AnalystSignup from "./Components/CustomerPortal/RMPT/AnalystSignup";
import CompanyListing from "./admin/company/Listing";
import NewFormStatus from "./Components/CustomerPortal/RMPT/NewFormStatus";

// const Header = React.lazy(() => import("./Components/Common/Header/Header"));

const loading = () => null;

function App() {
  // eslint-disable-next-line
  const [loginUser, setLoginUser] = React.useState(false);
  return (
    <Router>
      <React.Suspense fallback={loading()}>
        <Routes>
          <Route element={<GdprLayout />}>
            <Route path="/" element={<Home />}></Route>
            <Route path="/products" element={<Products />}></Route>
            <Route path="/services" element={<Services />}></Route>

            <Route path="/products/contents" element={<DynamicPage />}></Route>
            <Route
              path="/service/contents"
              element={<DynamicService />}
            ></Route>

            <Route path="/products/rmrms" element={<RMRMs />}></Route>
            <Route
              path="/products/aluminium-crms/rio-tinto-alan-crm"
              element={<RioTintoAlcanCRM />}
            ></Route>
            <Route
              path="/products/aluminium-crms/arconic-crm"
              element={<ArconicCRM />}
            ></Route>
            <Route
              path="/products/aluminium-crms/suisse-tp-crm"
              element={<SuisseTpCRM />}
            ></Route>
            <Route
              path="/products/cast-iron-crms/cmi-cast-irons"
              element={<CMICastIrons />}
            ></Route>
            <Route
              path="/products/cast-iron-crms/brammer-cast-irons"
              element={<BrammerCastIrons />}
            ></Route>
            <Route
              path="/products/setting-up-materials/aluminium-sus"
              element={<AluminiumSUS />}
            ></Route>
            <Route
              path="/products/setting-up-materials/low-alloy-sus"
              element={<LowAlloySUS />}
            ></Route>
            <Route
              path="/products/setting-up-materials/cast-iron-sus"
              element={<CastIronSUS />}
            ></Route>
            <Route
              path="/products/rta-non-metallics/bayer-proces"
              element={<BayerProcess />}
            ></Route>
            <Route
              path="/products/rta-non-metallics/electrolysis"
              element={<Electrolysis />}
            ></Route>
            <Route
              path="/products/ausmon-monitors"
              element={<AusmonMonitor />}
            ></Route>

            <Route path="/services/rmcp" element={<RMCP />}></Route>
            <Route path="/services/rmpt" element={<RMPT />}></Route>
            <Route path="/services/rmsp" element={<RMSP />}></Route>

            <Route path="/certificates" element={<Certificates />}></Route>
            <Route path="/industry-catalogs/" element={<Industry />}></Route>
            <Route
              path="/industry-catalogs/rms"
              element={<CatalogPage />}
            ></Route>
            <Route path="/photo-gallery" element={<PhotoGallery />}></Route>

            <Route path="/search-engine" element={<SearchEngine />}></Route>
            <Route path="/latest-news" element={<News />}></Route>
            <Route path="/latest-news/riotinto" element={<NewsPage />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/ourQuality" element={<OurQualityWeb />}></Route>

            <Route path="/customer-login" element={<CustomerLogin />}></Route>
            <Route
              path="/customer-register"
              element={<CustomerRegister />}
            ></Route>
            <Route path="/change-password" element={<ChangePassword />}></Route>

            <Route path="/reset-password" element={<CreatePassword />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route path="/info-request" element={<InfoRequest />}></Route>
            <Route path="/register-analyst" element={<AnalystSignup />}></Route>

            <Route path="/profile-data" element={<ProfileData />}></Route>
            <Route
              path="/profile-data-sheet"
              element={<ProfileDataSheet />}
            ></Route>
            <Route path="/my-profile" element={<MyProfile />}></Route>
            <Route
              path="/my-profile-analyst"
              element={<MyProfileAnalyst />}
            ></Route>

            <Route path="/sample-calender" element={<SampleCalender />}></Route>
            <Route
              path="/data-submit-report"
              element={<DataSubmittalReport />}
            ></Route>
            <Route path="/form-status" element={<FormStatus />}></Route>
            <Route path="/new-form-status" element={<NewFormStatus />}></Route>

            <Route path="/zscore" element={<Zscore />}></Route>
            <Route path="/letter" element={<Letter />}></Route>
            <Route path="/chart" element={<SampleClosedChart />}></Route>
            <Route path="/sampleClosedChart" element={<Chart />}></Route>
            <Route
              path="/analyst-form-status"
              element={<NewFormStatus />}
            ></Route>

            <Route path="/admin">
              <Route
                index={true}
                element={
                  localStorage?.access_token ? (
                    <CatalogListing />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="login"
                element={
                  localStorage?.access_token ? (
                    <CatalogListing />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="productHome"
                element={
                  localStorage?.access_token ? (
                    <ProductHome />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="photoGallery"
                element={
                  localStorage?.access_token ? (
                    <PhotoGalleryAdmin />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="createGallery"
                element={
                  localStorage?.access_token ? (
                    <CreateGallery />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="listing"
                element={
                  localStorage?.access_token ? (
                    <Listing />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="subProduct"
                element={
                  localStorage?.access_token ? (
                    <SubProductContent />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="createCategory"
                element={
                  localStorage?.access_token ? (
                    <CreateCategory />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="serviceHome"
                element={
                  localStorage?.access_token ? (
                    <ServiceHome />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="serviceListing"
                element={
                  localStorage?.access_token ? (
                    <ServiceListing />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="servicePage"
                element={
                  localStorage?.access_token ? (
                    <ServicePage />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="certificateListing"
                element={
                  localStorage?.access_token ? (
                    <CertificateListing />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="userListing"
                element={
                  localStorage?.access_token ? (
                    <UserListing />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="companyListing"
                element={
                  localStorage?.access_token ? (
                    <CompanyListing />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="catalogListing"
                element={
                  localStorage?.access_token ? (
                    <CatalogListing />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="home"
                element={
                  localStorage?.access_token ? (
                    <HomeAdmin />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="formStatusAdmin"
                element={
                  localStorage?.access_token ? (
                    <FormStatusAdmin />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="inventoryListing"
                element={
                  localStorage?.access_token ? (
                    <AnalystStatusAdmin />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="about"
                element={
                  localStorage?.access_token ? (
                    <AboutAdmin />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="latestNewsListing"
                element={
                  localStorage?.access_token ? (
                    <LatestNewsListing />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="latestNews"
                element={
                  localStorage?.access_token ? (
                    <LatestNews />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="ourTeam"
                element={
                  localStorage?.access_token ? (
                    <OurTeam />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="ourQuality"
                element={
                  localStorage?.access_token ? (
                    <OurQuality />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="searchEngine"
                element={
                  localStorage?.access_token ? (
                    <SearchEngineAdmin />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="contactAdmin"
                element={
                  localStorage?.access_token ? (
                    <ContactAdmin />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
              <Route
                path="ausmonMonitors"
                element={
                  localStorage?.access_token ? (
                    <AusmonMonitorsAdmin />
                  ) : (
                    <Login setLoginUser={setLoginUser} />
                  )
                }
              />
            </Route>
          </Route>
        </Routes>
      </React.Suspense>
    </Router>
  );
}

export default App;
