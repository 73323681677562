export const authenticate = (data, next) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("accesstoken", JSON.stringify(data));
    next();
  }
};

export const signout = (next) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("accesstoken");
    localStorage.removeItem("firstname");
    next();
  }
  // window.location.href = "/customer-login";
};

export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("accesstoken")) {
    return JSON.parse(localStorage.getItem("accesstoken")) !== undefined
      ? JSON.parse(localStorage.getItem("accesstoken"))
      : null;
  } else {
    return false;
  }
};
