import React , { useEffect } from "react";
import { Link } from 'react-router-dom';
import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';
import Banner from '../../Common/Banner/Banner';
import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';
import FacebookDark from '../../Icon/FacebookDark';
import TwitterDark from '../../Icon/TwitterDark';
import LinkedInDark from '../../Icon/LinkedinDark';
import WhatsappDark from '../../Icon/WhatsappDark';
import './internalPage.scss';

export const CatalogPage = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    },[]);

    var emailList = [
        {email: 'sales@rmrms.com'},
        {email: ''},
        {email: ''},
    ]
    
    return (
        <>
            <Header />
            <div className='mainSection'>
                <Banner bgPath={'url(/images/banner.jpg)'}
                    breadcrumb={<Breadcrumb title='Industry Catalogs' listItem='Home' listItemPath='/' listLink='Industry Catalogs' listLinkPath='/industry-catalogs' />}
                />
                <section className='page__section'>
                    <div className='container container--md'>
                        <h3 className='page__section--heading text__align--left'>These RMs are ideally suited for use with HHXRF &amp; HHLIBS, plus other</h3>
                        <div className='catalog__meta' style={{width:'fit-content'}}>
                            <p className='catalog__meta--category'>Category tag</p>
                            <p className='catalog__meta--date'>July 10, 2022</p>
                        </div>
                        <p className='page__section--para mb_20'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enimad minimveni am, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velitesse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enimad minimveni am, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velitesse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.mollit anim id est laborum.</p>
                        <p className='page__section--para mb_20'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enimad minimveni am, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

                        <h4 className='page__section--sub-heading text__align--left'>Heading 2</h4>
                        <p className='page__section--para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enimad minimveni am, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velitesse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        <p className='page__section--para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enimad minimveni am, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

                        <h4 className='page__section--sub-heading text__align--left'>Heading 3</h4>
                        <p className='page__section--para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enimad minimveni am, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velitesse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                        <h4 className='page__section--sub-heading text__align--left'>Heading 4</h4>
                        <p className='page__section--para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enimad minimveni am, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velitesse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                        <h4 className='page__section--sub-heading text__align--left'>Conclusion</h4>
                        <p className='page__section--para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enimad minimveni am, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velitesse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                        <hr/>

                        <div className='blog__footer'>
                            <div className='blog__footer--btn'>
                                <button className='btn btn--default'>Previous</button>
                                <button className='btn btn--red'>Next</button>
                            </div>

                            <div className='blog__footer--links'>
                                <p>Share</p>
                                <ul className='blog__footer--list'>
                                    <li className='blog__footer--list-item'>
                                        <Link to=''><FacebookDark/></Link>
                                    </li>
                                    <li className='blog__footer--list-item'>
                                        <Link to=''><WhatsappDark/></Link>
                                    </li>
                                    <li className='blog__footer--list-item'>
                                        <Link to=''><TwitterDark/></Link>
                                    </li>
                                    <li className='blog__footer--list-item'>
                                        <Link to=''><LinkedInDark/></Link>
                                    </li>
                                </ul>
                            </div>
                        </div>


                        <div className='related__items'>
                            <h4>Related Catalog</h4>
                            <ul className='related__items--list'>
                                <li className='related__items--list-item'>
                                    <img src='/images/post-img.jpg' alt=''/>
                                    <div className=''>
                                        <h4>These RMs are ideally suited for use with HHXRF &amp; HHLIBS...</h4>
                                        <Link to='' className='color--gray'>Read more</Link>
                                    </div>
                                </li>
                                <li className='related__items--list-item'>
                                    <img src='/images/post-img.jpg' alt=''/>
                                    <div className=''>
                                        <h4>These RMs are ideally suited for use with HHXRF &amp; HHLIBS...</h4>
                                        <Link to='' className='color--gray'>Read more</Link>
                                    </div>
                                </li>
                                <li className='related__items--list-item'>
                                    <img src='/images/post-img.jpg' alt=''/>
                                    <div className=''>
                                        <h4>These RMs are ideally suited for use with HHXRF &amp; HHLIBS...</h4>
                                        <Link to='' className='color--gray'>Read more</Link>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                </section>
            </div>
            <Footer footerEmails={true} emailList={emailList}/>
        </>
    );
}
