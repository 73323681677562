import React from 'react';
import { Link } from 'react-router-dom';
import './breadcrumb.scss';

const Breadcrumb = ({title, listItem, listItemPath, listLink, listLinkPath, darkTitle}) => {
    return(
        <div className="breadcrumb">
            <div className={`breadcrumb__title ${darkTitle}`}>{title}</div>
            <div className="breadcrumb__list">
                <Link to={listItemPath} className="breadcrumb__list--item">{listItem}</Link>
                <Link to={listLinkPath} className="breadcrumb__list--link">{listLink}</Link>
            </div>
        </div>
    );
}

export default Breadcrumb;