import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Header from "../../components/Header";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ProductSection from "../../components/ProdcutSection";

import "../../style.scss";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DrawerCom from "./../../components/Drawer";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Container,
  Toolbar,
  Box,
  Typography,
  CssBaseline,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { getData, postData, uploadFile } from "../../../Utils/utils";

import LoadingOverlay from "react-loading-overlay";

const mdTheme = createTheme();

function SubProductContent() {
  const [pageStore, setPageStore] = useState({
      id: 0,
      category_id: "",
      page_title: "",
      files: [{ file_name: "", file: "" }],
      default_content: "",
      hidden_content: "",
      files_title: "",
      left_footer_title: "",
      left_footer: "",
      right_footer_title: "",
      right_footer: "",
    }),
    [categories, setCategories] = React.useState([]),
    [open, setOpen] = useState(true),
    [isError, setError] = useState(false),
    [isLoading, setLoading] = React.useState(false),
    {
      id,
      category_id,
      page_title,
      files,
      default_content,
      hidden_content,
      files_title,
      left_footer_title,
      left_footer,
      right_footer,
      right_footer_title,
      email,
      subject
    } = pageStore,
    navigate = useNavigate(),
    location = useLocation(),
    { content } = location.state || {},
    fetchData = async () => {
      const result = await getData("/product/get/categories");
      setCategories(result);
    };

  useEffect(() => {
    fetchData();
    content && setPageStore(content);
  }, [content]);

  const onPageChange = async (e, name, index) => {
      const { target: { value = "" } = {} } = e;
      if (name === "category_id") {
        setPageStore({ ...pageStore, category_id: value });
      } else if (
        name === "page_title" ||
        name === "left_footer_title" ||
        name === "right_footer_title"
      ) {
        setPageStore({ ...pageStore, [name]: value });
      } else if (
        name === "default_content" ||
        name === "hidden_content" ||
        name === "left_footer" ||
        name === "right_footer"
      ) {
        setPageStore({ ...pageStore, [name]: e });
      } else if (name === "push") {
        const files = pageStore.files || [];
        files.push({ file_name: "", file: "" });
        setPageStore({ ...pageStore, files });
      } else if (name === "pop") {
        const files = pageStore.files || [];
        files.splice(index, 1);
        setPageStore({ ...pageStore, files });
      } else if (name === "files_title") {
        setPageStore({ ...pageStore, files_title: value });
      } else if (name === "file_name") {
        const files = pageStore.files || [];
        files[index].file_name = value;
        setPageStore({ ...pageStore, files });
      } else if (name === "file") {
        const image = e.target.files[0];
        if (!image.name.match(/\.(pdf)$/)) {
          return false;
        } else {
          setLoading(true);
          const { data: { url = "" } = {} } =
            (await uploadFile(e.target.files[0], "/common/upload/file")) || {};
          const files = pageStore.files || [];
          files[index].file = url;
          setPageStore({ ...pageStore, files });
          setLoading(false);
        }
      }
      else if (name === 'email') {
        setPageStore({ ...pageStore, [name]: value });
      } else if (name === 'subject') {
        setPageStore({ ...pageStore, [name]: value });
      }
    },
    toggleDrawer = () => {
      setOpen(!open);
    },
    handleSubmit = async (event) => {
      event.preventDefault();
      if (!category_id || !page_title) {
        setError(true);
        return;
      }

      const obj = {
        category_id,
        page_title,
        files,
        default_content,
        hidden_content,
        files_title,
        left_footer,
        right_footer,
        left_footer_title,
        right_footer_title,
        email,
        subject
      };
      if (!!id) {
        obj.id = id;
      }

      setLoading(true);
      await postData(obj, "/product/add/contents");
      setLoading(false);
      setTimeout(() => {
        navigate("/admin/listing");
      }, 1000);
    };
  LoadingOverlay.propTypes = undefined;
  return (
    <LoadingOverlay active={isLoading} spinner text="Loading ...">
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Header open={open} toggleDrawer={toggleDrawer} />
          <DrawerCom open={open} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      minHeight: 240,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ pb: 2 }}
                    >
                      Create Product Page
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <FormControl fullWidth size="small">
                            <InputLabel id="select-label">
                              Select Category
                            </InputLabel>
                            <Select
                              labelId="select-label"
                              id="select"
                              value={category_id}
                              label="Select Category"
                              onChange={(e) => onPageChange(e, "category_id")}
                              error={isError && !category_id}
                            >
                              {categories.map((category, index) => (
                                <MenuItem key={index} value={category.id}>
                                  {category.name}
                                </MenuItem>
                              ))}
                            </Select>
                            {isError && !category_id ? (
                              <FormHelperText>
                                category is required
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            size="small"
                            value={page_title}
                            onChange={(e) => onPageChange(e, "page_title")}
                            label="Title"
                            variant="outlined"
                            error={isError && !page_title}
                            helperText={
                              isError && !page_title && "title is required"
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                  {/* Email field */}
                  <TextField
                    id="outlined-basic-email"
                    fullWidth
                    size="small"
                    value={email}
                    onChange={(e) => onPageChange(e, 'email')}
                    label="Email"
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  {/* Subject field */}
                  <TextField
                    id="outlined-basic-subject"
                    fullWidth
                    size="small"
                    value={subject}
                    onChange={(e) => onPageChange(e, 'subject')}
                    label="Subject"
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />
                </Grid>
                        <ProductSection
                          onPageChange={onPageChange}
                          pageStore={pageStore}
                        />
                      </Grid>

                      <Button
                        type="submit"
                        className={"btnClass"}
                        variant="contained"
                        sx={{ mt: 3, mb: 2, ml: 2 }}
                      >
                        Save
                      </Button>
                      <Button
                        component={Link}
                        to="/admin/listing"
                        variant="outlined"
                        sx={{ mt: 3, mb: 2, ml: 2 }}
                      >
                        Cancel
                      </Button>
                      {/* <Button variant="outlined" sx={{ mt: 3, mb: 2, ml:2 }} onClick={handleSectionGen}>Add New Section</Button>*/}
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </LoadingOverlay>
  );
}

export default function SubProduct() {
  return <SubProductContent />;
}
