import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';
import Banner from '../../Common/Banner/Banner';
import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';
import ArrowDown from '../../Icon/ArrowDown';
import ArrowDownDark from '../../Icon/ArrowDownDark';

const AusmonMonitors = () => {

    const location = useLocation(),
        { productMenu = [] } = location.state || {};

    var arrDown = 
    `<svg xmlns="http://www.w3.org/2000/svg" width="11.987" height="5.986" viewBox="0 0 11.987 5.986">
        <path id="arrow-ios-downward-outline" d="M13.482,19.482a.855.855,0,0,1-.547-.2L7.807,15.012A.856.856,0,0,1,8.9,13.7l4.581,3.829,4.581-3.692a.876.876,0,1,1,1.086,1.376l-5.128,4.128A.855.855,0,0,1,13.482,19.482Z" transform="translate(-7.498 -13.498)" fill="#900" />
    </svg>`
    ;

    const accordionSectionHandler = (event) => {
        event.target.classList.toggle('active');
        var accordionBody = event.target.previousElementSibling;
        if (accordionBody.style.maxHeight) {
            accordionBody.style.maxHeight = null;
            event.target.innerHTML = "Show more" + arrDown;
        } else {
            accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
            event.target.innerHTML = "Show less" + arrDown;
        }
    }

    const accordionCollapse = () => {
        var accordionBtn = document.querySelectorAll('.accordion__item--btn');
        var accordionBody = document.querySelectorAll('.accordion__item--body');
        accordionBtn.forEach((item) => {
            return item.classList.remove('active');
        });
        accordionBody.forEach((item) => {
            return item.style.maxHeight = null;
        }); 
    }

    const accordionHandler = (event) => {   
        var currentAccordionBtn = event.target;
        var currentAccordionBody = currentAccordionBtn.nextElementSibling;

        if(currentAccordionBtn.classList.contains('active')){
            accordionCollapse();
            currentAccordionBtn.classList.remove('active');
            currentAccordionBody.style.maxHeight = null;
        }
        else{
            accordionCollapse();
            currentAccordionBtn.classList.add('active');
            if (currentAccordionBody.style.maxHeight) {
                currentAccordionBody.style.maxHeight = null;
            } else {
                currentAccordionBody.style.maxHeight = currentAccordionBody.scrollHeight + 'px';
            }
        }        
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    var emailList = [
        { email: 'sales@rmrms.com' },
        { email: '' },
        { email: '' },
    ]

    return (
        <>
            <Header />
            <div className='mainSection'>

                <Banner bgPath={'url(/images/HeaderDark.jpg)'}
                    breadcrumb={<Breadcrumb title='Ausmon Monitors' listItem='Home' listItemPath='/' listLink='Products'
                        listLinkPath='/products' />}
                />

                <div className='container'>
                    <div className='row'>
                        <div className='col_sm_12 col_md_5 col_lg_3'>
                            <div className='accordion accordion-sm'>
                                {
                                    productMenu.map((item, i) => {
                                        return (<>
                                            <div key={i} className='accordion__item'>
                                                <button className="accordion__item--btn" onClick={accordionHandler}>{item.category_name}<ArrowDownDark /></button>
                                                <div className="accordion__item--body">
                                                    <ul>
                                                        {
                                                            item.contents.map((subItem, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <NavLink to='/products/contents' className='color--gray' state={{ content: subItem, productMenu }}>{subItem.page_title}</NavLink>
                                                                    </li>
                                                                )
                                                            })
                                                        }

                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                        )
                                    })
                                }

                                <div className='accordion__item'>
                                    <a href='/products/ausmon-monitors' className="accordion__item--btn">Ausmon Monitors</a>
                                </div>


                            </div>
                        </div>

                        <div className='col_sm_12 col_md_7 col_lg_9'>
                            <section className='page__section'>
                                <h3 className='page__section--heading text__align--left'>Ausmon Drift Monitors for XRF</h3>
                                <p className='page__section--para'>Establishing an accurate calibration for a series of elements in a product is a tedious and costly exercise. The use of a stable monitor means that the calibration procedure need only be carried out at long intervals.</p>
                                <p className='page__section--para'>The monitors listed below have been formulated so that they have appropriate count rates when analyzing the different ores and products. The monitors contain little flux and should have good long term stability. Most monitor types have been in use for many years and have given excellent stability.</p>

                                <p className='page__section--para'>Most monitor types have been in use for many years and have given excellent stability.</p>
                            </section>

                            <section className='page__section p_0'>
                                <div className='accordion accordion--show-more m_0'>
                                    <div className='accordion__item'>
                                        <div className='accordion__item--body'>
                                            <p className='page__section--para'>Establishing an accurate calibration for a series of elements in a product is a tedious and costly exercise. The use of a stable monitor means that the calibration procedure need only be carried out at long intervals.</p>
                                            <p className='page__section--para'>The monitors listed below have been formulated so that they have appropriate count rates when analyzing the different ores and products. The monitors contain little flux and should have good long term stability. Most monitor types have been in use for many years and have given excellent stability.</p>
                                            <p className='page__section--para'>Most monitor types have been in use for many years and have given excellent stability.</p>
                                        </div>
                                        <button className='accordion__item--btn' onClick={accordionSectionHandler}>Show more <ArrowDown />
                                        </button>
                                    </div>
                                </div>
                            </section>

                            <section className='page__section pt_0'>
                                <ul className='ausmon__card__list'>
                                    <li className='ausmon__card__list--item'>
                                        <h4 className='page__section--sub-heading text__align--left'>Ausmon for Silicates and General Use</h4>
                                        <p className='page__section--para'>These monitors were designed for the analysis of rocks, soils, and related materials. They can also be used as general-purpose monitors for analyzing a wide range of materials, e.g., Vegetables, etc. They contain the following elements as majors: Fe, Mn, Ti, Ca, K, Cl, S, P, Si, Al, Mg, Na and F. In addition about 2000ppm of each of the following are present: Sc (1000ppm), V, Cr, Co, Cu, Ni, Zn, Ga, Ge, Se, As, Rb, Sr, Br, Y, Zr, Nb, Mo, Ag, Cd, Sn, Sb, Te, Cs, Ba, La, Ce, Nd, Pr, Gd, Sm, Yb, Hf, Ta, W, Bi, Tl, Pb, Th, and U (53 elements).</p>
                                    </li>
                                    <li className='ausmon__card__list--item'>
                                        <h4 className='page__section--sub-heading text__align--left'>Iron Ores</h4>
                                        <p className='page__section--para'>These are formulated for use in the analysis of iron ores and related materials. The following elements are present: Fe, Si, Al, Ca, F, Na, Mg, P, S, Cl, K, Ti, V, Cr, Mn, Co, Ni, Cu, Zn, As, Br, Sn, Cd, Sb, Bi, Mo, Ba, and Pb (28 elements).</p>
                                    </li>
                                    <li className='ausmon__card__list--item'>
                                        <h4 className='page__section--sub-heading text__align--left'>Bauxites</h4>
                                        <p className='page__section--para'>This is used when analyzing bauxites and high Al products. The following elements are present: Fe, Si, Al, Ca, F, Na, Mg, P, S, Cl, K, Ti, V, Cr, Mn, Co, Ni, Cu, Zn, As, Br, Sn, and Ga (24 elements).</p>
                                    </li><li className='ausmon__card__list--item'>
                                        <h4 className='page__section--sub-heading text__align--left'>Mineral Sands</h4>
                                        <p className='page__section--para'>These monitors have been prepared for use when analyzing mineral sand products, e.g. ilmenite, rutile, zircon, monazite, and xenotime. The following elements are present: Ti, Fe, Zr, Si, Y, La, Ce, Nd, Pr, Yb, P, F, Na, Mg, Al, S, Cl, K, Ca, Sc, V, Mn, Cr, Co, Ni, Cu, Zn, Br, As, Sr, Nb, Mo, Cd, Sn, Ba, Hf, Pb, Th, and U (39 elements).</p>
                                    </li><li className='ausmon__card__list--item'>
                                        <h4 className='page__section--sub-heading text__align--left'>Cement (A)</h4>
                                        <p className='page__section--para'>This monitor is suitable when making detailed analyses of cement or another high Ca products. Monitors prepared for analyzing cement and related materials contain: Ca, Si, Al, Mg, Fe, Na, Cl, S, F, P, K, Ti, Cr, Mn, Zn, Sr, Br, Ba, and Pb (19 elements).</p>
                                    </li><li className='ausmon__card__list--item'>
                                        <h4 className='page__section--sub-heading text__align--left'>Cement (B)</h4>
                                        <p className='page__section--para'>This monitor has fewer trace elements, is designed for online analysis using wavelength or energy dispersive spectrometers, and contains Na, Mg, Al, Si, P, Cl, K, Ca, and Fe (9 elements).</p>
                                    </li><li className='ausmon__card__list--item'>
                                        <h4 className='page__section--sub-heading text__align--left'>Ni Products</h4>
                                        <p className='page__section--para'>This monitor has fewer trace elements, is designed for online analysis using wavelength or energy dispersive spectrometers, and contains Na, Mg, Al, Si, P, Cl, K, Ca, and Fe (9 elements).</p>
                                    </li><li className='ausmon__card__list--item'>
                                        <h4 className='page__section--sub-heading text__align--left'>Manganese Ores</h4>
                                        <p className='page__section--para'>These monitors are for use when analyzing manganese ores. They contain: Mn, Fe, Si, Na, Mg, Al, P, K, Ca, Ti, V, Sr, Br, Ba, and Pb (15 elements).</p>
                                    </li><li className='ausmon__card__list--item'>
                                        <h4 className='page__section--sub-heading text__align--left'>Lead, Zinc, Iron, and Copper Sulfides</h4>
                                        <p className='page__section--para'>These monitors are for use with lead, zinc, iron and copper sulfides, as ores, concentrates and related products. They contain Pb, Zn, Fe, Cu, S, F, Na, Mg, Al, Si, P, K, Ca, Cl, Ti, Co, Ni, Cr, Mn, As, Sr, Se, Ag, Cd, Sn, Sb, Ba, Te, Tl, Mo, U, and Bi (32 elements).</p>
                                    </li><li className='ausmon__card__list--item'>
                                        <h4 className='page__section--sub-heading text__align--left'>Rare Earths</h4>
                                        <p className='page__section--para'>These monitors are to be used when analyzing monazite, xenotime, and other rare earth minerals for the rare earth oxides. They contain La, Ce, Pr, Nd, Sm, Eu, Gd, Tb, Dy, Ho, Er, Tm, Yb, Lu, Y, P, F, Na, Mg, Al, Si, S, Cl, K, Ca, Sc, Ti, Mn, Fe, Ni, Br, Sr, Zr, Nb, Ba, Hf, Pb, Th, and U (39 elements).</p>
                                        <p className='page__section--para'>All the above monitors are aimed at laboratories using wavelength dispersive spectrometers. The following are made for use with energy-dispersive instruments. Normally these are supplied as 32mm diameter discs.</p>
                                    </li><li className='ausmon__card__list--item'>
                                        <h4 className='page__section--sub-heading text__align--left'>MCACAL</h4>
                                        <p className='page__section--para'>This monitor is for the calibration of the energy-dispersive system, and for this purpose it contains the following elements: F, Na, Mg, Si, Cl, Ca, V, Zn, As, Fe, Y, Mo, Cd, and Ba (13 elements).</p>
                                    </li><li className='ausmon__card__list--item'>
                                        <h4 className='page__section--sub-heading text__align--left'>Mon A</h4>
                                        <p className='page__section--para'>This is intended as a drift monitor with the following elements: Mg, Si, P, W, Pb, and Sn (6 elements).</p>
                                    </li><li className='ausmon__card__list--item'>
                                        <h4 className='page__section--sub-heading text__align--left'>Mon B</h4>
                                        <p className='page__section--para'>This is a drift monitor with the following elements: Na, Al, Si, Ca, Ti, Cr, and Ni (7 elements).</p>
                                    </li><li className='ausmon__card__list--item'>
                                        <h4 className='page__section--sub-heading text__align--left'>Specials</h4>
                                        <p className='page__section--para'>Monitor discs can be made to suit needs not covered by the above. Very often, this is for laboratories analyzing materials that do not have long-term stability and so they cannot use a similar product as a monitor, e.g., aqueous liquids or liquids from the petroleum industry. Cl in brine, Ca in milk, Cl, Br, and trace elements in synthetic rubbers are some of the cases for which we have made monitors.</p>
                                    </li><li className='ausmon__card__list--item'>
                                        <h4 className='page__section--sub-heading text__align--left'>Matched Monitors</h4>
                                        <p className='page__section--para'>Many industries require prompt XRF analyses of products, and any delay can seriously affect operations, in the extreme case causing plant shut down until XRF analyses return to normal. Breakage or loss of the XRF monitor may create such a situation, and for those industries, having a backup monitor is a wise investment. Where two monitors are required, one for backup purposes, attempts will be made to select two that are very similar. It is convenient to have two similar, but it is not necessary. The Ausmon and Iron Ores monitors can be supplied closely, matching one another. For these matched monitors, the intensity variation between discs of each element will have a standard deviation of less than 1%. Some industries that have multiple XRF instruments have taken advantage of using matched monitors so that each instrument has the same calibration constants.</p>
                                    </li>
                                </ul>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <Footer footerEmails={true} emailList={emailList} />
        </>
    )
}

export default AusmonMonitors;

