import React from 'react';

const ArrowRight = () => {
    return(
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="18.003" height="15.753" viewBox="0 0 18.003 15.753">
                <g id="arrow-right" transform="translate(-9 -10.124)">
                    <path id="Path_3" data-name="Path 3" d="M18.329,10.454a1.125,1.125,0,0,1,1.593,0l6.75,6.75a1.125,1.125,0,0,1,0,1.593l-6.75,6.75a1.126,1.126,0,1,1-1.593-1.593L24.284,18l-5.955-5.954a1.125,1.125,0,0,1,0-1.593Z" fill="#900" fillRule="evenodd"/>
                    <path id="Path_4" data-name="Path 4" d="M9,18a1.125,1.125,0,0,1,1.125-1.125H24.75a1.125,1.125,0,0,1,0,2.25H10.125A1.125,1.125,0,0,1,9,18Z" fill="#900" fillRule="evenodd"/>
                </g>
            </svg>
        </>
    );
}

export default ArrowRight;