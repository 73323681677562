import React, { useEffect } from "react";
import Header from "../../../Common/Header/Header";
import Footer from "../../../Common/Footer/Footer";
import Banner from "../../../Common/Banner/Banner";
import Breadcrumb from "../../../Common/Breadcrumb/Breadcrumb";
import SideNavbar from "../../../Templates/SideNavbar/ProductSideNavbar";
import PDFIcon from "../../../Icon/Pdf";

const Electrolysis = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var emailList = [{ email: "sales@rmrms.com" }, { email: "" }, { email: "" }];

  return (
    <>
      <Header />
      <div className="mainSection">
        <Banner
          bgPath={"url(/images/HeaderDark.jpg)"}
          breadcrumb={
            <Breadcrumb
              title="Rio Tinto Alcan Non-Metallics | Electrolysis"
              listItem="Home"
              listItemPath="/"
              listLink="Products"
              listLinkPath="/products"
            />
          }
        />

        <div className="container">
          <div className="row">
            <div className="col_sm_12 col_md_5 col_lg_3">
              <SideNavbar />
            </div>

            <div className="col_sm_12 col_md_7 col_lg_9">
              <section className="page__section">
                <h3 className="page__section--heading text__align--left">
                  Rio Tinto Alcan Non-Metallic Standards
                </h3>
                <p className="page__section--para">
                  ALCAN produces non-metallic Certified Reference Materials
                  (CRM's) used to calibrate or drift correct techniques such as
                  XRF, ICP, AAS, XRF, and DSC. ALCAN standards are powdered and
                  sold in quantities from 500 mg to 500 g according to their end
                  use.
                </p>
                <p className="page__section--para">
                  These CRMs are certified by primary calibrations and are NIST
                  traceable. An inter-laboratory comparison is finally initiated
                  to achieve uniform consensus values and then all data is
                  statistically analyzed to ensure that the certified values and
                  confidence intervals are of the highest accuracy.
                </p>

                <h4 className="page__section--sub-heading text__align--left">
                  Introduction
                </h4>
                <p className="page__section--para">
                  The need for fast and accurate analysis of materials used or
                  produced by today¡¯s industry led to the development of
                  analytical techniques capable of accurate determination of
                  multiple characteristics with ever-tighter tolerance limits.
                </p>
                <p className="page__section--para">
                  Alcan has responded to these trends by continuously upgrading
                  the availability of quality of the certified reference
                  materials (CRM's) it produces and uses to control its
                  processes and to certify its products. It has also made these
                  CRM¡¯s available to its clients and the aluminum industry in
                  general. They consist of typical raw materials, process
                  intermediates and products whose composition and
                  physico-chemical properties have been characterized through
                  round robins under the supervision of the Arvida Research and
                  Development Centre to ISO guide 25 criteria. A limited supply
                  of environment-related standard samples is also available upon
                  special request. This catalogue contains an up-to-date list of
                  Alcan non-metallic certified reference materials used to
                  calibrate of standardize instrumental techniques such as XRF,
                  ICP, AAS, XRD, DSC, particle size determination, etc. and to
                  assure traceability of wet chemical analyses. The certified
                  value and its uncertainty, expressed as one standard deviation
                  are indicated where available, detailed information is given
                  on the certificates of analysis. The increasing drive to ISO
                  9000 accreditation has made Alcan CRM¡¯s ideal components of
                  quality assurance/quality control activities. The CRM¡¯s are
                  offered for sale subject to availability from stock. Alcan
                  reserves the right to discontinue any standard, limit the
                  quantity supplied or change the price at any time. Every
                  effort will be made to replace a standard that is not
                  available with an appropriate substitute. Alcan cannot accept
                  any liability for damage arising from the use of its CRM¡¯s.
                </p>

                <h4 className="page__section--sub-heading text__align--left">
                  Preparation And Certification
                </h4>
                <p className="page__section--para">
                  Alcan non-metallic standards are powdered samples and are sold
                  in quantities varying from 500 mg to 500 g according to their
                  end-use. The standard samples are prepared from a bulk supply
                  of a representative material, are well homogenized and tested
                  for uniformity of composition using XRF or Electric Sensing
                  Zone.
                </p>
                <p className="page__section--para">
                  Alcan standard samples for composition are certified by using
                  at least two independent, absolute methods of analysis, such
                  as XRF, ICP, AAS or colorimetric, titrimetric and gravimetric
                  techniques. If only one technique is available, two
                  technicians using two separate setups generate 10 independent
                  results each. Alcan standard sample are certified against
                  primary (absolute) calibrations and are NIST traceable. An
                  inter-laboratory comparison is finally initiated to achieve
                  uniform consensus values. All data are statistically analyzed
                  to ensure that the certified characteristic is of the highest
                  accuracy.
                </p>
                <p>
                  The actual certified composition/characteristic along with the
                  corresponding standard deviations are given on the certificate
                  of analysis issued with each standard sample.
                </p>

                <h4 className="page__section--heading text__align--left">
                  Non-Metallic Standards (Bayer Process &amp; Electrolysis)
                </h4>
                <p className="page__section--para">
                  These CRMs are certified by primary calibrations and are
                  traceable to the SI through NIST. An inter-laboratory
                  comparison is finally initiated to achieve uniform consensus
                  values, and then all data is statistically analyzed to ensure
                  that the certified values and confidence intervals are of the
                  highest accuracy.
                </p>
                <p className="page__section--para">
                  These typical raw materials and process intermediates have
                  been characterized under the supervision of the Arvida
                  Research and Development Center to ISO/IEC 17025.
                </p>
                <p className="page__section--para">
                  A Certificate of Analysis, with Confidence Intervals, is
                  issued with each CRM..
                </p>

                <h4 className="page__section--sub-heading text__align--left">
                  Types Of Available CRMs For Electrolysis:
                </h4>
                <p className="page__section--para">
                  Green Petroleum Coke, Calcined Petroleum Coke, Metallurgical
                  Coke, Anthracite, Pitch Elemental Composition, Raw Fluorspar,
                  Fluorspar Final Concentrate, Electrolytic Bath, Aluminum
                  Fluoride, Scrubber Alumina, Cryolite, and physical properties
                  for Pitch.
                </p>
                <p className="page__section--para">
                  These typical raw materials and process intermediates have
                  been characterized under the supervision of the Arvida
                  Research and Development Center to ISO guide 25. A Certificate
                  of Analysis, with Confidence Intervals, is issued with each
                  CRM.
                </p>
              </section>

              <section className="page__section bg--light p_3">
                <h4 className="page__section--sub-heading text__align--left">
                  Electrolysis
                </h4>
                <ul className="catalog__list">
                  <li className="catalog__list--item">
                    <a href="!#">
                      <PDFIcon />
                      Petroleum Coke
                    </a>
                  </li>
                  <li className="catalog__list--item">
                    <a href="!#">
                      <PDFIcon />
                      Pitch
                    </a>
                  </li>
                  <li className="catalog__list--item">
                    <a href="!#">
                      <PDFIcon />
                      Fluorspar
                    </a>
                  </li>
                  <li className="catalog__list--item">
                    <a href="!#">
                      <PDFIcon />
                      Electrolytic Bath
                    </a>
                  </li>
                  <li className="catalog__list--item">
                    <a href="!#">
                      <PDFIcon />
                      Cryolite
                    </a>
                  </li>
                </ul>
              </section>

              <section className="page__section pb_0">
                <div className="row">
                  <div className="col_sm_12 col_md_12 col_lg_8">
                    <div className="product__info">
                      <h4 className="page__section--sub-heading text__align--left">
                        Lorem ipsum is a placeholder
                      </h4>
                      <ul className="product__info--list">
                        <li className="product__info--list-item">
                          Lorem ipsum is a placeholder text commonly used to
                          demonstrate the visual form of a document or a
                          typeface without relying on meaningful content.
                        </li>
                        <li className="product__info--list-item">
                          Lorem ipsum is a placeholder text commonly used to
                          demonstrate the visual form of a document or a
                          typeface without relying on meaningful content.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col_sm_12 col_md_12 col_lg_4">
                    <div className="product__pricing">
                      <h4 className="page__section--sub-heading text__align--left">
                        General Pricing
                      </h4>
                      <p className="page__section--para">
                        Please Call for Pricing :{" "}
                        <a href="tel:(720) 943-7676">(720) 943-7676</a>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer footerEmails={true} emailList={emailList} />
    </>
  );
};

export default Electrolysis;
