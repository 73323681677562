import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "4px",
  p: 2,
};
const AppealFormModal = ({
  viewAppealFormModal,
  handleViewAppealFormClose,
  calenderData,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={viewAppealFormModal}
      onClose={handleViewAppealFormClose}
      closeAfterTransition
      className="uploadModal"
    >
      <Fade in={viewAppealFormModal}>
        <Box
          sx={style}
          component="form"
          className="uploadModalForm uploadModalOverflow"
        >
          <Typography
            id=""
            variant="h6"
            component="h2"
            style={{
              fontWeight: "700",
            }}
          >
            Appeal Forms
            <IconButton
              aria-label="close"
              onClick={handleViewAppealFormClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="sample__modal">
                <Typography id="" variant="h6" component="h2" className="">
                  RMPT Appeal Data Report Form - {`(RMPT - 192)`}
                </Typography>

                <ul className="sample__list">
                  {calenderData && calenderData.length > 0
                    ? calenderData.map((value, index) => {
                        return (
                          <li className="sample__list--item">
                            <div className="flex alignitems--center justifyspace--between">
                              <p>
                                <span>{index + 1}. </span>
                                <span className="analyst">{value.analyst}</span>
                                <span>{value.method}</span>
                                <span>{value.material}</span>
                                <span>{value.sample_type}</span>
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                {value.isRejected === true &&
                                value.isAppeal === true &&
                                value.isReappeal === false ? (
                                  <div className="button--icon modal--icon">
                                    <Tooltip title="Add">
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          const replacerFunc = () => {
                                            const visited = new WeakSet();
                                            return (key, value) => {
                                              if (
                                                typeof value === "object" &&
                                                value !== null
                                              ) {
                                                if (visited.has(value)) {
                                                  return;
                                                }
                                                visited.add(value);
                                              }
                                              return value;
                                            };
                                          };
                                          localStorage.setItem(
                                            "rmptdata",
                                            JSON.stringify(
                                              value,
                                              replacerFunc()
                                            )
                                          );

                                          localStorage.setItem(
                                            "pin",
                                            JSON.stringify(value.pin)
                                          );
                                          localStorage.removeItem("type");
                                          localStorage.setItem(
                                            "type",
                                            "reappeal"
                                          );
                                          localStorage.setItem(
                                            "rmptid",
                                            value.rmpt_id
                                          );
                                          window.location.href =
                                            "/data-submit-report";
                                        }}
                                      >
                                        <AddIcon />
                                      </button>
                                    </Tooltip>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AppealFormModal;
