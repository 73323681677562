import React from 'react';

const ArrowDownDark = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="11.987" height="5.986" viewBox="0 0 11.987 5.986">
                <path id="arrow-ios-downward-outline" d="M13.482,19.482a.855.855,0,0,1-.547-.2L7.807,15.012A.856.856,0,0,1,8.9,13.7l4.581,3.829,4.581-3.692a.876.876,0,1,1,1.086,1.376l-5.128,4.128A.855.855,0,0,1,13.482,19.482Z" transform="translate(-7.498 -13.498)" fill="#20292f" />
            </svg>
        </>
    );
}

export default ArrowDownDark;