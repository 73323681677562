import * as React from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { postData } from "../../Utils/utils";

const EnhancedTableToolbar = ({ name, link }) => {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {name} Page Listing
      </Typography>
      <Button
        variant="contained"
        component={Link}
        to={`/admin/${link}`}
        className={"btnPrimary"}
      >
        + New Page
      </Button>
    </Toolbar>
  );
};

export default function TableListing({
  rows,
  name,
  link,
  handleDelete,
  fetchData,
}) {
  const [list, setList] = useState([]);
  React.useEffect(() => {
    setList(rows);
  }, [rows]);

  const updateItems = async (data) => {
    if (link === "servicePage") {
      const { id, page_title, default_content, hidden_content, position,email,subject } =
        data;
      await postData(
        {
          id,
          page_title,
          default_content,
          hidden_content,
          email,
          subject,
          position: parseInt(position),
        },
        "/service/add/category"
      );
    } else {
      const {
        id,
        category_id,
        page_title,
        files,
        default_content,
        hidden_content,
        files_title,
        left_footer,
        right_footer,
        left_footer_title,
        right_footer_title,
        position,
      } = data;
      await postData(
        {
          id,
          category_id,
          page_title,
          files,
          default_content,
          hidden_content,
          files_title,
          left_footer,
          right_footer,
          left_footer_title,
          right_footer_title,
          position: parseInt(position),
        },
        "/product/add/contents"
      );
    }
  };
  const dragItem = React.useRef();
  const dragOverItem = React.useRef();
  const dragStart = (e, position) => {
    dragItem.current = position;
    console.log(e.target.innerHTML);
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
  };

  const drop = (e) => {
    const copyListItems = [...list];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    // console.log("kfdkfkkfkf", copyListItems);
    copyListItems.forEach((e, index) => {
      e.position = index + 1;
      updateItems(e);
    });
    setList(copyListItems);
    // fetchData();
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer component={Paper}>
          <EnhancedTableToolbar name={name} link={link} />
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className={"tableWrap"}
          >
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                {link !== "servicePage" ? (
                  <TableCell>Category Name</TableCell>
                ) : (
                  ""
                )}

                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row, index) => (
                <TableRow
                  onDragStart={(e) => dragStart(e, index)}
                  onDragEnter={(e) => dragEnter(e, index)}
                  onDragEnd={drop}
                  draggable
                  key={row.ProductName + index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.page_title}</TableCell>
                  {link !== "servicePage" ? (
                    <TableCell>{row.category_name}</TableCell>
                  ) : null}

                  <TableCell align="right">
                    <Fab
                      color="primary"
                      component={Link}
                      to={`/admin/${link}`}
                      state={{ content: row }}
                      aria-label="add"
                      size="small"
                      sx={{ mr: 1 }}
                    >
                      <EditIcon />
                    </Fab>
                    <Fab
                      color="error"
                      onClick={() => handleDelete(row)}
                      aria-label="delete"
                      size="small"
                    >
                      <DeleteIcon />
                    </Fab>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
