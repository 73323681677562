import React from 'react';
import Download from '../../../Icon/Download';

const Catalog = ({ post: { title, content, icon, name,  file }, index }) => {
    return (
        <div>
            <div className='catalog'>
                <div className='catalog__image'>
                    <img src={icon} alt="" />
                    <input type='hidden' value={name} />
                </div>
                {/* <div className='catalog__meta'>
                    <p className='catalog__meta--category'>{category}</p>
                    <p className='catalog__meta--date'>{date}</p>
                </div> */} 
                <h2 className='catalog__title'>{title}</h2>
                <p className='catalog__description'>{content}</p>
                <a href={file} className="catalog__btn btn--download" target="_blank" rel="noreferrer"><Download/>Catalog</a>
            </div>
        </div>
    );
};

export default Catalog;

