import React, { useEffect, useRef, useState } from "react";
import Header from "../../Common/Header/Header";
import axios from "../../../Services/axios";
import Swal from "sweetalert2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import ReactToPrint from "react-to-print";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
const image = new Image();
image.src = "https://rmsrms.s3.ap-south-1.amazonaws.com/files/Group 2570.png";

const plugin = {
  id: "customCanvasBackgroundImage",
  beforeDraw: (chart) => {
    if (image.complete) {
      const ctx = chart.ctx;

      ctx.drawImage(
        image,
        chart.chartArea.left,
        chart.chartArea.top,
        chart.chartArea.width,
        chart.chartArea.height
      );
    } else {
      image.onload = () => chart.draw();
    }
  },
};

export const options = {
  responsive: true,

  scales: {
    y: {
      ticks: {
        stepSize: 1,
      },
      title: {
        font: {
          size: 16,
        },

        display: true,
        text: "Standard Deviations from the MEAN",
      },

      min: -5,
      max: 5,
    },
    x: {
      title: {
        font: {
          size: 16,
        },

        display: true,
        text: "Reported Elements (excluding <values)",
      },
    },
  },

  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: true,
      text:
        localStorage.getItem("type") &&
        localStorage.getItem("type") === "reappeal"
          ? "Amended Z-Score Summary Chart"
          : "Z-Score Summary Chart",
      font: {
        size: 36,
      },
    },
    datalabels: {
      display: function (context) {
        if (
          context.dataset.data[context.dataIndex] > -5 &&
          context.dataset.data[context.dataIndex] < 5
        ) {
          return context.dataset.data[context.dataIndex];
        } else {
          return "";
        }
      },
      color: "black",
      align: "end",
      padding: {
        right: 4,
      },
      labels: {
        padding: { top: 20 },
        title: {
          font: {
            weight: "bold",
          },
        },
        value: {
          color: "black",
        },
      },
    },
  },
};

const labels = [
  "",
  "Al",
  "B",
  "C",
  "Co",
  "Cr",
  "Cu",
  "Fe",
  "Mn",
  "Mo",
  "N",
  "Nb",
  "Ni",
  "O",
  "P",
  "S",
  "Si",
  "Ti",
  "V",
  "W",
  "Zr",
  "",
];

export const data = {
  labels,

  datasets: [
    {
      borderWidth: 1,
      tension: 0.1,
      label: "Standard Deviation from the MEAN",
      data: [
        0, 1.58, -0.83, -0.65, -0.71, -0.92, -0.66, 0.61, 0.14, 0.13, 1.06,
        -1.14, -0.19, 0.25, 0.5, 1.43, -2.75, 0.34, 0.01, 2.94, 0.0, 0,
      ],
      borderColor: "#000",
      backgroundColor: "#000",
    },
  ],
};

const Example = React.forwardRef((props, ref) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const formattedDate = months[month] + " " + day + ", " + year;
    return formattedDate;
  }
  useEffect(() => {
    const userId = localStorage.getItem("UserId");
    const referenceId = localStorage.getItem("referenceId");
    const analystId = localStorage.getItem("analystId");

    axios
      .get(
        `/rmpt/getChartData?id=${userId}&reference_id=${referenceId}&analyst_id=${analystId}`
      )

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          props.setRmptId(res.data.rmptId);
          props.setDate(res.data.date);
          props.setSeries(res.data.series);
          props.setGrade(res.data.grade);
          props.setRmrm(res.data.rmrm);
          props.setUns(res.data.uns);
          props.setCompanyName(res.data.userData.basic_details.company_name);
          const chartLabel = [];
          chartLabel.push("");
          const chartData = [];
          chartData.push(0);
          res.data.chartData.forEach((e) => {
            if (e.zscore !== "" || e.cert !== 0) {
              chartLabel.push(e.analyte);
              chartData.push(e.zscore);
            }
          });
          chartLabel.push("");
          chartData.push(0);
          props.setlabels(chartLabel);
          props.setData(chartData);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      })
      .catch((error) => {})
      .then(() => {});
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div ref={ref} className="">
      <div className="chart">
        <div className="chart__head">
          <div className="chart__head--text">{props.companyName}</div>
          <div className="chart__head--text">{formatDate(props.date)}</div>
        </div>
        <div className="chart__desc">
          {props.grade} / <span>UNS {props.uns}</span> - RMPT#{" "}
          <span className="highlight">{props.rmptId}</span> - CRM#{" "}
          <span>
            CRM {props.rmrm}
            {props.series}
          </span>
        </div>
      </div>

      <Line
        plugins={[plugin]}
        options={options}
        data={{
          labels: props.labels,

          datasets: [
            {
              borderWidth: 1,
              tension: 0.1,
              label: "Standard Deviation from the MEAN",
              data: props.data,
              borderColor: "#000",
              backgroundColor: "#000",
            },
          ],
        }}
      />
      <div className="chart__footer">
        Z-Score, Calculated by Comparing User Submitted Data with the Value
        Assignment of the CRM used.
      </div>
    </div>
  );
});

const SampleClosedChart = () => {
  const componentRef = useRef();
  const [rmptId, setRmptId] = useState("");
  const [date, setDate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [labels, setlabels] = useState([]);
  const [grade, setGrade] = useState("");
  const [rmrm, setRmrm] = useState("");
  const [series, setSeries] = useState("");
  const [uns, setUns] = useState("");
  const [data, setData] = useState([]);

  return (
    <>
      <Header themeWhite={true} contactBtn={true} bgLight={true} />
      <div className="mainSection mainPortal">
        <section className="page__section">
          <div className="form__container chart__container">
            <ReactToPrint
              trigger={() => (
                <button className="btn--print">
                  <PrintOutlinedIcon />
                  Print this out!
                </button>
              )}
              content={() => componentRef.current}
            />
            <Example
              rmptId={rmptId}
              setRmptId={setRmptId}
              date={date}
              setDate={setDate}
              companyName={companyName}
              setCompanyName={setCompanyName}
              labels={labels}
              setlabels={setlabels}
              grade={grade}
              setGrade={setGrade}
              rmrm={rmrm}
              setRmrm={setRmrm}
              series={series}
              setSeries={setSeries}
              uns={uns}
              setUns={setUns}
              data={data}
              setData={setData}
              ref={componentRef}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default SampleClosedChart;
