import React, { useEffect } from "react";
import Header from "../../../Common/Header/Header";
import Footer from "../../../Common/Footer/Footer";
import Banner from "../../../Common/Banner/Banner";
import Breadcrumb from "../../../Common/Breadcrumb/Breadcrumb";
import SideNavbar from "../../../Templates/SideNavbar/ProductSideNavbar";
import PDFIcon from "../../../Icon/Pdf";
import ArrowDown from "../../../Icon/ArrowDown";

const RioTintoAlcanCRM = () => {
  var arrDown = `<svg xmlns="http://www.w3.org/2000/svg" width="11.987" height="5.986" viewBox="0 0 11.987 5.986">
            <path id="arrow-ios-downward-outline" d="M13.482,19.482a.855.855,0,0,1-.547-.2L7.807,15.012A.856.856,0,0,1,8.9,13.7l4.581,3.829,4.581-3.692a.876.876,0,1,1,1.086,1.376l-5.128,4.128A.855.855,0,0,1,13.482,19.482Z" transform="translate(-7.498 -13.498)" fill="#900" />
        </svg>`;
  const accordionHandler = (event) => {
    event.target.classList.toggle("active");
    var accordionBody = event.target.previousElementSibling;
    if (accordionBody.style.maxHeight) {
      accordionBody.style.maxHeight = null;
      event.target.innerHTML = "Show more" + arrDown;
    } else {
      accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
      event.target.innerHTML = "Show less" + arrDown;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var emailList = [{ email: "sales@rmrms.com" }, { email: "" }, { email: "" }];

  return (
    <>
      <Header />

      <div className="mainSection">
        <Banner
          bgPath={"url(/images/HeaderDark.jpg)"}
          breadcrumb={
            <Breadcrumb
              title="Rio Tinto Alcan CRMs"
              listItem="Home"
              listItemPath="/"
              listLink="Products"
              listLinkPath="/products"
            />
          }
        />

        <div className="container">
          <div className="row">
            <div className="col_sm_12 col_md_5 col_lg_3">
              <SideNavbar />
            </div>

            <div className="col_sm_12 col_md_7 col_lg_9">
              <section className="page__section">
                <h3 className="page__section--heading text__align--left">
                  About Rio Tinto Alcan CRMs
                </h3>
                <h4 className="page__section--sub-heading text__align--left">
                  Introduction
                </h4>
                <p className="page__section--para">
                  The Arvida Research and Development Centre (ARDC) produces,
                  certifies and distributes standard samples of aluminum and its
                  alloys for use as certified reference materials (CRM).
                </p>
                <p className="page__section--para">
                  These standards are designed for use with direct reading
                  optical emission spectrometers for spark emission analysis.
                  ARDC has produced these standards since 1949, primarily to
                  analyze aluminum alloys produced by the Rio Tinto Alcan group.
                </p>
                <h4 className="page__section--sub-heading text__align--left">
                  Intent of Use
                </h4>
                <p className="page__section--para">
                  Rio Tinto Alcan Certified Reference Material (CRM) for the
                  spectrochemical analysis of aluminum and its alloys is
                  produced by a direct chill, continuous cast method. The cast
                  billets measure generally 70 mm in diameter and up to 3 m in
                  length. They are subsequently scalped and sliced, producing
                  about 300 to 600 disks per cast.
                </p>
              </section>

              <section className="page__section p_0">
                <div className="accordion accordion--show-more m_0">
                  <div className="accordion__item">
                    <div className="accordion__item--body">
                      <p className="page__section--para">
                        Each finished disk measures 57 mm in diameter by 25 mm
                        thick. The disk is permanently stamped or labeled with:
                      </p>
                      <div className="product__info">
                        <ul className="product__info--list">
                          <li className="product__info--list-item">
                            Casting and machining
                          </li>
                          <li className="product__info--list-item">
                            Inductively Coupled Plasma Atomic Emission
                            Spectroscopy (ICP-AES)
                          </li>
                          <li className="product__info--list-item">
                            Inductively Coupled Plasma Mass Spectrometry
                            (ICP-MS)
                          </li>
                          <li className="product__info--list-item">
                            Spark Atomic Emission Spectroscopy (Spark-AES)
                          </li>
                          <li className="product__info--list-item">
                            Certification
                          </li>
                        </ul>
                      </div>
                      <p className="page__section--para">
                        Some older standards may also have individually
                        certified concentration values stamped on their back.
                      </p>
                      <div className="image__list">
                        <div
                          className="image__list--item"
                          style={{ width: 200 }}
                        >
                          <img
                            src="/images/Pic-1.png"
                            alt=""
                            className="mb_20"
                          />
                          <p>8079 AF 'CRM' Slice 55 of Billet A</p>
                        </div>
                        <div
                          className="image__list--item"
                          style={{ width: 200 }}
                        >
                          <img
                            src="/images/Pic-2.png"
                            alt=""
                            className="mb_20"
                          />
                          <p>3104 AR 'CRM' Slice 96 of Billet A</p>
                        </div>
                      </div>
                      <p className="page__section--para">
                        The Rio Tinto Alcan CRMs are specifically prepared and
                        certified for the spectrochemical analysis of metallic
                        aluminum and its alloys. To minimize any residual
                        effects of macro and microstructure on the spectral
                        response, the CRMs should be analyzed by Optical
                        Emission Spectrometric method, on the annular band at a
                        distance of 10 mm from the edge of the sample, as
                        specified in the Rio Tinto Alcan Method of analysis 1304
                        or ASTM E1251.
                      </p>
                      <div className="image__list">
                        <div className="image__list--item">
                          <img src="/images/Fig-2.png" alt="" />
                        </div>
                      </div>
                      <h4 className="page__section--sub-heading text__align--left">
                        Nomenclature
                      </h4>
                      <p className="page__section--para">
                        Rio Tinto Alcan reference material names take the form
                        AAAA SSS, where 'AAAA' is generally the identification
                        of the alloy using the Aluminum Association nomenclature
                        and 'SSS' identifies the particular cast (series) with
                        the exception of special CRMs (examples: the boron
                        standards). The series identification is two-letters, in
                        the sequence from 'AA' to 'ZZ', with an optional single
                        letter prefix indicating the category of standard.
                      </p>
                      <p className="page__section--para">
                        ARDC produces five basic types of drift RM and OES CRMs:
                      </p>
                      <div className="product__info">
                        <ul className="product__info--list">
                          <li className="product__info--list-item">
                            High purity standards use HP as the alloy
                            identification followed by the two-letter series
                            identification, for example HP FK. HP standards are
                            samples of aluminum with very high purity,
                            &gt;99.999%. They are used during the
                            standardization of the instrument to fix the bottom
                            of the analytical curve for alloying elements.
                          </li>
                          <li className="product__info--list-item">
                            Blank standards use the alloy identification with a
                            'C' prefix to the two-letter series identification,
                            for example 3104 CAP. Blank standards are aluminum
                            alloy standards fabricated from high purity
                            aluminum, so therefore have trace element
                            concentrations {"<"}1 ppm. They are used during the
                            standardization to fix the bottom of the analytical
                            curve generally for minor and trace elements to
                            compensate for matrix effects of the alloying
                            elements.
                          </li>
                          <li className="product__info--list-item">
                            Working standards use the alloy identification
                            followed by the two-letter series identification,
                            for example 3104 AR. They are also known as TOP
                            standards and are generally certified for 24 or more
                            alloying and trace elements.
                          </li>
                          <li className="product__info--list-item">
                            Boron standards are aluminum samples containing only
                            three elements used for boron analysis. They are
                            certified for boron, silicon, and iron. Example: 1S
                            CAM.
                          </li>
                          <li className="product__info--list-item">
                            Drift samples use the alloy identification with a
                            'D' prefix to the two-letter series identification,
                            for example 3104 DAS. They are aluminum alloy
                            samples with a low concentration of trace elements.
                          </li>
                        </ul>
                      </div>
                      <p className="page__section--para">
                        Drift samples are very homogeneous but are not
                        certified. They are used to determine whether the
                        measuring instrument has drifted enough to require
                        standardization.
                      </p>
                      <h4 className="page__section--sub-heading text__align--left">
                        Method of Analysis
                      </h4>
                      <p className="page__section--para">
                        Methods of analysis used for certification vary from
                        classical wet chemistry to modern instrumental
                        techniques. They include:
                      </p>
                      <div className="product__info">
                        <ul className="product__info--list">
                          <li className="product__info--list-item">
                            Photometric method
                          </li>
                          <li className="product__info--list-item">
                            Atomic Absorption Spectrometry (AAS)
                          </li>
                          <li className="product__info--list-item">
                            Inductively Coupled Plasma- Atomic Emission
                            Spectrometry and Mass Spectrometry (ICP/AES and ICP/
                            MS )
                          </li>
                          <li className="product__info--list-item">
                            Optical Emission Spectrometry (OES)
                          </li>
                          <li className="product__info--list-item">
                            Glow Discharge Mass Spectrometry (GDMS).
                          </li>
                        </ul>
                      </div>
                      <p className="page__section--para">
                        Values below 0.5 ppm are indicated by 'L' within the
                        catalogue, uncertified values used for alloy
                        identification are indicated by 'N' within the
                        catalogue.
                      </p>
                      <h4 className="page__section--sub-heading text__align--left">
                        Traceability
                      </h4>
                      <p className="page__section--para">
                        The traceability of the certified values is ensured by
                        the comparative use of other internationally recognized
                        certified reference materials produced by NIST, and
                        other suppliers of OES CRMs.
                      </p>
                      <h4 className="page__section--sub-heading text__align--left">
                        Assignment of Uncertainty
                      </h4>
                      <p className="page__section--para">
                        The certified values listed are the mean values from
                        several analyses, using at least two methods performed
                        by two different analysts when possible. Generally,
                        values from an external laboratory are also included in
                        the certification process. Each analytical measurement
                        has the uncertainty reported with a 95% level of
                        confidence. The uncertainty reported for the certified
                        value includes the standard deviation of the homogeneity
                        within the billet (for elements not certified
                        individually).
                      </p>
                      <h4 className="page__section--sub-heading text__align--left">
                        Individual Certification
                      </h4>
                      <p className="page__section--para">
                        All billets are tested for cast homogeneity using OES.
                        When indicated by the homogeneity test, each disk is
                        assigned an individual certified value. Homogeneity
                        testing reveals that some elements are not always
                        uniformly distributed, usually because of the loss (burn
                        off) of light elements (e.g. Li, Na, Ca, Mg) during
                        casting. In these cases, each disk is assigned an
                        individual value as determined from the homogeneity
                        test. The uncertainty reported for individually
                        certified elements is on the individual value and does
                        not include any variation due to billet homogeneity.
                        Individually certified elements are indicated by an 'i'
                        on the global certificate and in the catalogue.
                      </p>
                      <div className="image__list">
                        <div className="image__list--item">
                          <img src="/images/Fig-3.png" alt="" />
                        </div>
                      </div>
                      <h4 className="page__section--sub-heading text__align--left">
                        Certificate of Analysis
                      </h4>
                      <p className="page__section--para">
                        There are two formats of certificate of analysis.
                      </p>
                      <div className="product__info">
                        <ul className="product__info--list">
                          <li className="product__info--list-item">
                            A global certificate of analysis indicates the
                            certified values with the uncertainties and the
                            elements individually certified.
                          </li>
                          <li className="product__info--list-item">
                            Individual certificates are generated for standards
                            with individually certified elements. The
                            certificate for each disc reports its certified
                            composition, including the individual values.
                          </li>
                          <li className="product__info--list-item">
                            For blank, boron and HP standards, only the global
                            certificate is provided; these CRMs do not contain
                            individually certified elements.
                          </li>
                        </ul>
                      </div>
                      <h4 className="page__section--sub-heading text__align--left">
                        Period of Validity
                      </h4>
                      <p className="page__section--para">
                        The Rio Tinto Alcan certification is valid within the
                        stated uncertainty for the lifetime of the CRM when used
                        for the intended methods of analysis. A certification
                        revision may be required when an improved method of
                        analysis becomes available.
                      </p>
                      <h4 className="page__section--sub-heading text__align--left">
                        Signatures
                      </h4>
                      <p className="page__section--para">
                        The original unmodified certificate provided by RioTinto
                        Alcan is valid without signature (refer to ISO Guide 31)
                        as long as it includes, in the heading of the
                        certificate, the name of the organization, the full
                        address, the telephone and fax numbers, and the name of
                        the person responsible for the information appearing on
                        the certificate.
                      </p>
                      <h4 className="page__section--sub-heading text__align--left">
                        Accreditation Testing
                      </h4>
                      <p className="page__section--para">
                        The Arvida Research and Development Centre analytical
                        laboratory is accredited ISO 17025 and is audited
                        biannually by the Standard Council of Canada. The
                        laboratory prides itself in maintaining its high
                        standards in performing the methods of analysis referred
                        to above. The original certification date is August 8th,
                        1989.
                      </p>
                    </div>
                    <button
                      className="accordion__item--btn"
                      onClick={accordionHandler}
                    >
                      Show more <ArrowDown />
                    </button>
                  </div>
                </div>
              </section>

              <section className="page__section bg--light p_3 mt_50">
                <h4 className="page__section--sub-heading text__align--left">
                  Catalog Sections
                </h4>
                <ul className="catalog__list">
                  <li className="catalog__list--item">
                    <a href="/pdf/ALFOCat.pdf">
                      <PDFIcon />
                      Foundry Alloys
                    </a>
                  </li>
                  <li className="catalog__list--item">
                    <a href="/pdf/ALWRCat.pdf">
                      <PDFIcon />
                      Wrought Alloys
                    </a>
                  </li>
                  <li className="catalog__list--item">
                    <a href="/pdf/ALSPCat.pdf">
                      <PDFIcon />
                      Special Alloys
                    </a>
                  </li>
                </ul>
              </section>

              <section className="page__section pb_0">
                <div className="row">
                  <div className="col_sm_12 col_md_12 col_lg_8">
                    <div className="product__info">
                      <h4 className="page__section--sub-heading text__align--left">
                        Material Forms and Dimensions
                      </h4>
                      <ul className="product__info--list">
                        <li className="product__info--list-item">
                          General - General purpose size (~55mm diameter x ~25mm
                          thick) intended for use in consumptive techniques such
                          as Optical Emission and Glow Discharge Spectroscopy.
                        </li>
                        <li className="product__info--list-item">
                          X-Ray - About 48mm diameter x ~12mm thick discs
                          designed specifically for use with X-Ray Fluorescence
                          Instruments
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col_sm_12 col_md_12 col_lg_4">
                    <div className="product__pricing">
                      <h4 className="page__section--sub-heading text__align--left">
                        General Pricing
                      </h4>
                      <p className="page__section--para">
                        Please Call for Pricing :{" "}
                        <a href="tel:(720) 943-7676">(720) 943-7676</a>
                      </p>
                      <ul className="product__pricing--list">
                        <li className="product__pricing--list-title">
                          <p>Description</p>
                          <p>Suffix #</p>
                        </li>
                        <li className="product__pricing--list-item">
                          <p>General</p>
                          <p>-G</p>
                        </li>
                        <li className="product__pricing--list-item">
                          <p>X-Ray</p>
                          <p>-X</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <Footer footerEmails={true} emailList={emailList} />
    </>
  );
};

export default RioTintoAlcanCRM;
