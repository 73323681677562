import React from 'react';

const WhatsappDark = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.001" viewBox="0 0 24 24.001">
                <path id="social-whatsapp" d="M22.754,5.737a11.962,11.962,0,0,0-20.4,8.406,11.819,11.819,0,0,0,1.6,5.947l-1.7,6.161L8.585,24.6A11.981,11.981,0,0,0,14.3,26.043h0A11.939,11.939,0,0,0,26.25,14.15a11.8,11.8,0,0,0-3.5-8.412ZM14.3,24.036h0a9.952,9.952,0,0,1-5.055-1.378l-.363-.214-3.759.981,1-3.648L5.886,19.4A9.942,9.942,0,1,1,14.3,24.035Zm5.448-7.4c-.3-.149-1.768-.868-2.04-.967s-.473-.149-.672.149-.771.965-.946,1.165-.348.222-.647.074a8.157,8.157,0,0,1-2.4-1.475,8.976,8.976,0,0,1-1.661-2.058c-.174-.3-.019-.459.131-.606.134-.133.3-.347.448-.52a2,2,0,0,0,.3-.5.548.548,0,0,0-.024-.52c-.074-.149-.672-1.611-.92-2.207-.243-.58-.488-.5-.672-.51s-.375-.011-.573-.011a1.1,1.1,0,0,0-.8.372A3.331,3.331,0,0,0,8.228,11.5a5.787,5.787,0,0,0,1.219,3.074,13.264,13.264,0,0,0,5.1,4.487,17.311,17.311,0,0,0,1.7.626,4.114,4.114,0,0,0,1.88.118,3.076,3.076,0,0,0,2.016-1.413,2.484,2.484,0,0,0,.174-1.413c-.074-.123-.274-.2-.573-.347Z" transform="translate(-2.25 -2.25)" fill="#6d7c90" fill-rule="evenodd" />
            </svg>
        </>
    );
}

export default WhatsappDark;