import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Banner from "../Common/Banner/Banner";
import Breadcrumb from "../Common/Breadcrumb/Breadcrumb";
import SideNavbar from "../Templates/SideNavbar/AboutUsSideNavbar";
import ArrowDown from "../Icon/ArrowDown";
import AboutCard from "../Templates/AboutCards/AboutCard";
import { getData } from "../../Utils/utils";
const About = () => {
  var arrDown = `<svg xmlns="http://www.w3.org/2000/svg" width="11.987" height="5.986" viewBox="0 0 11.987 5.986">
            <path id="arrow-ios-downward-outline" d="M13.482,19.482a.855.855,0,0,1-.547-.2L7.807,15.012A.856.856,0,0,1,8.9,13.7l4.581,3.829,4.581-3.692a.876.876,0,1,1,1.086,1.376l-5.128,4.128A.855.855,0,0,1,13.482,19.482Z" transform="translate(-7.498 -13.498)" fill="#900" />
        </svg>`;

  const visionIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42.21"
      height="42.045"
      viewBox="0 0 42.21 42.045"
    >
      <g id="eye" transform="translate(0)">
        <path
          id="Path_24"
          data-name="Path 24"
          d="M41.966,130.539a29.631,29.631,0,0,0-9.7-7.876,24.86,24.86,0,0,0-11-2.663h-.317a24.86,24.86,0,0,0-11,2.663,29.64,29.64,0,0,0-9.7,7.875.834.834,0,0,0,0,1.183,29.641,29.641,0,0,0,9.7,7.875,24.86,24.86,0,0,0,11,2.663h.317a24.86,24.86,0,0,0,11-2.663,29.633,29.633,0,0,0,9.725-7.908.831.831,0,0,0,.219-.561.821.821,0,0,0-.246-.587Zm-31.285,7.58A27.8,27.8,0,0,1,1.9,131.13a27.805,27.805,0,0,1,8.779-6.99,24.514,24.514,0,0,1,2.928-1.229,11.116,11.116,0,0,0,0,16.439,24.513,24.513,0,0,1-2.928-1.229Zm10.426,2.491a9.481,9.481,0,1,1,9.481-9.481A9.492,9.492,0,0,1,21.106,140.61Zm10.426-2.491a24.511,24.511,0,0,1-2.928,1.229,11.116,11.116,0,0,0,0-16.439,24.513,24.513,0,0,1,2.928,1.229,27.8,27.8,0,0,1,8.779,6.99,27.805,27.805,0,0,1-8.779,6.99Zm0,0"
          transform="translate(-0.002 -110.107)"
          fill="#900"
        />
        <path
          id="Path_25"
          data-name="Path 25"
          d="M205.534,209.069a4.534,4.534,0,1,1,4.534-4.534A4.539,4.539,0,0,1,205.534,209.069Zm0-7.42a2.885,2.885,0,1,0,2.885,2.885A2.889,2.889,0,0,0,205.534,201.649Zm0,0"
          transform="translate(-184.43 -183.512)"
          fill="#900"
        />
        <path
          id="Path_26"
          data-name="Path 26"
          d="M246.824,414.9a.824.824,0,0,1-.824-.824v-3.3a.824.824,0,0,1,1.649,0v3.3A.824.824,0,0,1,246.824,414.9Zm0,0"
          transform="translate(-225.72 -376.156)"
          fill="#900"
        />
        <path
          id="Path_27"
          data-name="Path 27"
          d="M295.015,413.935a.822.822,0,0,1-.583-.242l-2.332-2.332a.825.825,0,0,1,1.166-1.166l2.332,2.332a.824.824,0,0,1-.583,1.407Zm0,0"
          transform="translate(-267.798 -376.157)"
          fill="#900"
        />
        <path
          id="Path_28"
          data-name="Path 28"
          d="M172.682,413.936a.824.824,0,0,1-.583-1.407l2.332-2.332a.825.825,0,0,1,1.166,1.166l-2.332,2.332A.822.822,0,0,1,172.682,413.936Zm0,0"
          transform="translate(-157.69 -376.159)"
          fill="#900"
        />
        <path
          id="Path_29"
          data-name="Path 29"
          d="M246.824,44.9a.824.824,0,0,1-.824-.824v-3.3a.824.824,0,1,1,1.649,0v3.3A.824.824,0,0,1,246.824,44.9Zm0,0"
          transform="translate(-225.72 -36.659)"
          fill="#900"
        />
        <path
          id="Path_30"
          data-name="Path 30"
          d="M175.015,55.652a.822.822,0,0,1-.583-.241L172.1,53.079a.824.824,0,1,1,1.166-1.166l2.332,2.332a.824.824,0,0,1-.583,1.407Zm0,0"
          transform="translate(-157.691 -47.412)"
          fill="#900"
        />
        <path
          id="Path_31"
          data-name="Path 31"
          d="M292.682,55.652a.824.824,0,0,1-.583-1.407l2.332-2.332a.824.824,0,0,1,1.166,1.166l-2.332,2.332A.823.823,0,0,1,292.682,55.652Zm0,0"
          transform="translate(-267.797 -47.412)"
          fill="#900"
        />
        <path
          id="Path_32"
          data-name="Path 32"
          d="M246.841,1.649a.824.824,0,1,1,0-1.649h0a.824.824,0,0,1,0,1.649Zm0,0"
          transform="translate(-225.735)"
          fill="#900"
        />
        <path
          id="Path_33"
          data-name="Path 33"
          d="M246.841,491.649a.824.824,0,1,1,0-1.649h0a.824.824,0,0,1,0,1.649Zm0,0"
          transform="translate(-225.735 -449.604)"
          fill="#900"
        />
      </g>
    </svg>
  );

  const missionIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42.001"
      height="42"
      viewBox="0 0 42.001 42"
    >
      <g id="mission" transform="translate(0 0)">
        <path
          id="Path_14"
          data-name="Path 14"
          d="M32.375,37.11H.875a.876.876,0,0,1-.768-1.295L15.857,6.941a.911.911,0,0,1,1.536,0l15.75,28.875a.876.876,0,0,1-.768,1.295ZM2.348,35.36H30.9L16.625,9.188Z"
          transform="translate(0 4.889)"
          fill="#900"
        />
        <path
          id="Path_15"
          data-name="Path 15"
          d="M29.746,32.25H21a.875.875,0,0,1,0-1.75h7.269L20.171,15.782l-3.335,6.9a.875.875,0,0,1-1.575-.763l4.074-8.428A.877.877,0,0,1,20.1,13a.921.921,0,0,1,.789.453l9.625,17.5a.876.876,0,0,1-.768,1.3Z"
          transform="translate(11.379 9.75)"
          fill="#900"
        />
        <path
          id="Path_16"
          data-name="Path 16"
          d="M9.875,13.125A.875.875,0,0,1,9,12.25V.875A.875.875,0,0,1,9.875,0h10.5a.876.876,0,0,1,.619,1.494l-2.882,2.88,2.88,2.88a.876.876,0,0,1-.618,1.494H10.75v3.5a.875.875,0,0,1-.875.875ZM10.75,7h7.513L16.257,4.994a.876.876,0,0,1,0-1.237L18.263,1.75H10.75Z"
          transform="translate(6.75 0)"
          fill="#900"
        />
        <path
          id="Path_17"
          data-name="Path 17"
          d="M14.884,17.242c-.021,0-.04,0-.063,0a.87.87,0,0,1-.637-.348l-1.925-2.565-1.925,2.565a.879.879,0,0,1-.637.348.846.846,0,0,1-.681-.254l-2.94-2.94a.875.875,0,0,1,1.237-1.237L9.54,15.035l2.019-2.693a.906.906,0,0,1,1.4,0l2.019,2.693L17.2,12.809a.875.875,0,0,1,1.237,1.237l-2.94,2.94a.873.873,0,0,1-.618.255Z"
          transform="translate(4.365 9.008)"
          fill="#900"
        />
      </g>
    </svg>
  );

  const accordionHandler = (event) => {
    event.target.classList.toggle("active");
    var accordionBody = event.target.previousElementSibling;
    if (accordionBody.style.maxHeight) {
      accordionBody.style.maxHeight = null;
      event.target.innerHTML = "Show more" + arrDown;
    } else {
      accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
      event.target.innerHTML = "Show less" + arrDown;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var emailList = [{ email: "Info@RMRMs.com" }, { email: "" }, { email: "" }];

  // const location = useLocation(),
  //   { content } = location.state || {};

  // eslint-disable-next-line
  const [isLoading, setLoading] = React.useState(false),
    [content, setProduct] = useState({}),
    fetchProductsData = async () => {
      setLoading(true);
      const result = await getData("/get/contents/by/category");
      setProduct(result?.aboutUs);
      // setServiceMenu(result?.service_categories)
      setLoading(false);
    };

  useEffect(() => {
    fetchProductsData();
  }, []);
  return (
    <>
      <Header />

      <div className="mainSection">
        <Banner
          bgPath={"url(/images/HeaderDark.jpg)"}
          breadcrumb={
            <Breadcrumb
              title={content.page_title}
              listItem="Home"
              listItemPath="/"
              listLink="About Us"
              listLinkPath="/about"
            />
          }
        />

        <div className="container">
          <div className="row">
            <div className="col_sm_12 col_md_5 col_lg_3">
              <SideNavbar />
            </div>

            <div className="col_sm_12 col_md_7 col_lg_9">
              {" "}
              <section className="page__section pt_1">
                {" "}
                <div className="container-fluid">
                  {" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content.default_content,
                    }}
                    className="reset-props"
                  ></div>{" "}
                </div>{" "}
              </section>
              <section className="page__section p_0">
                <div className="container-fluid">
                  <div className="accordion accordion--show-more m_0">
                    <div className="accordion__item">
                      <div className="accordion__item--body">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content.hidden_content,
                          }}
                          className="reset-props"
                        ></div>
                      </div>
                      <button
                        className="accordion__item--btn"
                        onClick={accordionHandler}
                      >
                        Show more <ArrowDown />
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <section className="page__section bg--light p_1">
            <div className="container-fluid">
              <div className="page__section--light-block">
                <h4 className="title--h4 mb_0">
                  {content.best_solution_heading}
                </h4>
                <p className="page__section--para">
                  {content.best_solution_content}
                </p>
              </div>
            </div>
          </section>
          {content.mission_content || content.quality_content ? (
            <section className="about__card__section">
              <div className="container-fluid">
                <div className="row">
                  {content.mission_content ? (
                    <div className="col_sm_12 col_md_6 col_lg_6">
                      <AboutCard
                        icon={missionIcon}
                        title={content.mission_heading}
                        description={content.mission_content}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {content.quality_content ? (
                    <div className="col_sm_12 col_md_6 col_lg_6">
                      <AboutCard
                        icon={visionIcon}
                        title={content.quality_heading}
                        description={content.quality_content}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
        </div>
      </div>

      <Footer footerEmails={true} emailList={emailList} />
    </>
  );
};

export default About;
