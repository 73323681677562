import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../Images/logo1.png";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { Formik } from "formik";
import Swal from "sweetalert2";
import axios from "../../Services/axios";
import * as Yup from "yup";

const CreatePassword = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState({
    confirmPassword: false,
    showPassword: false,
  });

  const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  };

  const handleClickShowPassword = () => {
    setValue({
      ...value,
      showPassword: !value.showPassword,
    });
  };

  const handleClickConfirmPassword = () => {
    setValue({
      ...value,
      confirmPassword: !value.confirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const loginSchema = Yup.object({
    newPassword: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Please Enter Valid Password use at least one uppercase letter, lowercase letter, number, special character with minimum 8 characters"
      ),
    confirmPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords don't match!"),
  });
  return (
    <>
      <div className="mainSection customerPortal">
        <section className="page__section">
          <div className="container">
            <Formik
              enableReinitialize={true}
              initialValues={{
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={loginSchema}
              onSubmit={(values, { setSubmitting }) => {
                let loginObj = {
                  password: values.newPassword,
                  link: getQueryVariable("link"),
                };
                axios
                  .post("/rmpt/user/reset/password", loginObj)

                  .then((response) => {
                    let res = JSON.parse(response.data);
                    if (res.status === 200) {
                      setSubmitting(false);
                      Swal.fire({
                        icon: "success",
                        position: "center",

                        title: res.message,
                        showConfirmButton: true,
                      }).then(() => {
                        navigate("/customer-login");
                      });
                    } else {
                      Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: res.message,
                        showConfirmButton: false,
                        timer: 1500,
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch((error) => {
                    setSubmitting(false);
                  })
                  .then(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit} className="form">
                  {/* Logo */}
                  <div className="form__brand mb_50">
                    <NavLink to="/">
                      <img
                        className="form__logo"
                        src={Logo}
                        alt="Logo | Rocky Mountain Reference Materials, LLC"
                        width="120px"
                      />
                    </NavLink>
                  </div>

                  <div className="form__title">Create your new password</div>

                  <div className="form__description">
                    Create password use at least one uppercase letter, lowercase
                    letter, number, special character with minimum 8 characters.
                  </div>

                  <div className="form__group">
                    {/* <label className='form__label'>Password</label> */}
                    <div className="form__input--group">
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="outlined-new-password">
                          Enter New Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-new-password"
                          type={value.showPassword ? "text" : "password"}
                          name="newPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.newPassword}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {value.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Enter New Password"
                        />
                      </FormControl>

                      {/* <input required type="password" className="form__input" placeholder='Enter Password' name='password' />
                                    <div className="form__input--icon">
                                        <button><img src="/images/gray-eye.svg" alt="Eye" width="18px" /></button>
                                    </div> */}
                    </div>
                    <div className="password__input--error">
                      {touched.newPassword && errors.newPassword}
                      {touched.newPassword || errors.newPassword}
                    </div>
                  </div>

                  <div className="form__group">
                    {/* <label className='form__label'>Confirm Password</label> */}
                    <div className="form__input--group">
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="outlined-confirm-password">
                          Confirm New Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-confirm-password"
                          type={value.confirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickConfirmPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {value.confirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Confirm New Password"
                        />
                      </FormControl>

                      {/* <input required type="password" className="form__input" placeholder='Confirm Password' name='password' />
                                    <div className="form__input--icon">
                                        <button><img src="/images/gray-eye.svg" alt="Eye" width="18px" /></button>
                                    </div> */}
                    </div>
                    <div className="password__input--error">
                      {touched.confirmPassword && errors.confirmPassword}
                      {touched.confirmPassword || errors.confirmPassword}
                    </div>
                  </div>

                  <div className="form__group">
                    <button type="submit" className="btn btn--red">
                      Reset Password
                    </button>
                  </div>

                  <div className="form__group text__align--center mt_0">
                    <NavLink to="/customer-login" className="form__link">
                      Back to Log In
                    </NavLink>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </section>
      </div>
    </>
  );
};

export default CreatePassword;
