import React from "react";
import { Link } from "react-router-dom";
import ArrowRightIcon from "../../../Icon/ArrowRight";

const ServiceCard = ({ lists, productMenu }) => {

    const { page_title,description } = lists,
     extractContent = (html) => {
      const text = new DOMParser().parseFromString(html, "text/html")
        .documentElement.textContent;
      return text;
    };


    return (
        <>
          <div className="card">
        <h4 className="card__title">{page_title}</h4>
        <Link
          to={"/service/contents/"}
          className="card__link"
 state={{ content: lists, productMenu }}        >
          <p className="card__text">{extractContent(description)}</p>
          Read More <ArrowRightIcon />
        </Link>
      </div>
        </>
    );
}

export default ServiceCard;