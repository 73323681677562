import axios from "axios";
// import { BASE_URL } from "./urls";

const axiosInstance = axios.create({
  baseURL: "https://api.rmrms.com",

  transformResponse: [
    function (data) {
      // Do whatever you want to transform the data
      return data;
    },
  ],
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    var accessToken;
    if (localStorage.getItem("accesstoken") !== null) {
      accessToken = JSON.parse(localStorage.getItem("accesstoken"));
    }
    config.headers = {
      "Content-Type": "application/json",
    };
    config.headers.Authorization = accessToken;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (JSON.parse(response.data).message === "Unauthorized!") {
      // localStorage.clear();
      window.location.href = "/customer-login";
      // window.location.reload()
    }
    return response;
  },
  function (error) {
    if (error && error.response && error.response.status === 401) {
      window.location.href = "/customer-login";
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
