import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../Images/logo1.png";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";

import axios from "../../Services/axios";
const ForgotPassword = () => {
  const loginSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
  });
  return (
    <>
      <div className="mainSection customerPortal">
        <section className="page__section">
          <div className="container">
            <Formik
              enableReinitialize={true}
              initialValues={{
                email: "",
              }}
              validationSchema={loginSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                let loginObj = {
                  email: values.email,
                };
                axios
                  .post("/rmpt/user/send/link", loginObj)

                  .then((response) => {
                    let res = JSON.parse(response.data);
                    if (res.status === 200) {
                      setSubmitting(false);
                      Swal.fire({
                        position: "center",
                        icon: "success",
                        title: res.message,
                        showConfirmButton: true,
                        timer: 1500,
                      });
                      resetForm();
                      // window.location.href = "/profile-data";
                      // window.open("/profile-data", "_self");
                    } else {
                      Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: res.message,
                        showConfirmButton: false,
                        timer: 1500,
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch((error) => {
                    setSubmitting(false);
                  })
                  .then(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit} className="form">
                  {/* Logo */}
                  <div className="form__brand mb_50">
                    <NavLink to="/">
                      <img
                        className="form__logo"
                        src={Logo}
                        alt="Logo | Rocky Mountain Reference Materials, LLC"
                        width="120px"
                      />
                    </NavLink>
                  </div>

                  <div className="form__title">Forgot Password</div>

                  <div className="form__description">
                    Please fill in the email you've used to create a account and
                    we'll send you a reset link
                  </div>

                  <div className="form__group">
                    {/* <label className='form__label'>Email Id</label> */}
                    <div className="form__input--group">
                      <TextField
                        label="Email ID"
                        size="small"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        fullWidth
                      />
                      <div className="form__input--error">
                        {errors.email && touched.email && errors.email}
                      </div>
                      {/* <input required type="email" className="form__input" placeholder='Enter Email' name='email' /> */}
                    </div>
                  </div>

                  <div className="form__group" style={{ marginTop: "30px" }}>
                    <button type="submit" className="btn btn--red">
                      Send reset link
                    </button>
                  </div>

                  <div className="form__group text__align--center mt_0">
                    <NavLink to="/customer-login" className="form__link">
                      Back to Log In
                    </NavLink>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </section>
      </div>
    </>
  );
};

export default ForgotPassword;
