import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { postDataWithOutAccessToken } from "../Utils/utils";
import Swal from "sweetalert2";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      © 2022, Rocky Mountain Reference Materials, LLC. All Rights Reserved.
    </Typography>
  );
}

const theme = createTheme();

const Login = ({ setLoginUser }) => {
  const [obj, setObj] = useState({
    email: "",
    password: "",
  });
  const handleSubmit = async (event) => {
    event.preventDefault();
    //   const data = new FormData(event.currentTarget),
    let email = obj.email;
    let password = obj.password;
    if (email && password) {
      const { data: { access_token, first_name, last_name = "" } = {} } =
        (await postDataWithOutAccessToken(
          { email, password },
          "/admin/login"
        )) || {};

      localStorage.removeItem("useradmin");
      if (rememberMe) {
        localStorage.setItem(
          "useradmin",
          JSON.stringify({
            email: email,
            password: password,
          })
        );
      }
      if (access_token) {
        setLoginUser(true);
        window.localStorage.setItem("access_token", access_token);
        window.localStorage.setItem("name", first_name + " " + last_name);
      }
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Email and Password is required",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  useEffect(() => {
    if (
      localStorage.getItem("useradmin") !== undefined &&
      localStorage.getItem("useradmin") !== null
    ) {
      setObj({
        email: JSON.parse(localStorage.getItem("useradmin")).email,
        password: JSON.parse(localStorage.getItem("useradmin")).password,
      });
    }
  }, []);
  const [rememberMe, setRememberMe] = useState(true);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              onChange={(e) => {
                setObj({ ...obj, email: e.target.value });
              }}
              value={obj.email}
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              onChange={(e) => {
                setObj({ ...obj, password: e.target.value });
              }}
              value={obj.password}
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/*<FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />*/}

            <div className="form__group">
              <label className="checkbox-container checkbox-primary">
                Remember me!
                <input
                  checked={rememberMe}
                  onChange={() => {
                    setRememberMe(!rememberMe);
                  }}
                  type="checkbox"
                  className="form-checkbox"
                />
                <span className="checkmark"></span>
              </label>
            </div>

            <Button
              type="submit"
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              sx={{ mt: 1, height: 56 }}
            >
              Sign In
            </Button>
            {/*                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>*/}
          </Box>
        </Box>
        <Copyright sx={{ mt: 10, mb: 6 }} />
      </Container>
    </ThemeProvider>
  );
};

export default Login;
