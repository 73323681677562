import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ReactPaginate from "react-paginate";
import "./pagination.css";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormControl, InputLabel } from "@mui/material";
import axios from "axios";
import { MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import { postData } from "../../Utils/utils";

// import Paper from '@mui/material/Paper';
import InputBase from "@mui/material/InputBase";

import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CustomizedDialogs({
  handleClickOpen,
  handleClose,
  open,
  setLoading,
  pageData: pageStore,
  setPageData: setPageStore,
}) {
  const [isError, setError] = useState(false),
    {
      id = 0,
      customerNumber = "",
      name = "",
      company_website = "",
      address = "",
      city = "",
      state = "",
      fax = "",
      country = "",
      zip = "",
    } = pageStore,
    onPageChange = async (e, name) => {
      if (name === "customerNumber" || name === "name") {
        setPageStore({ ...pageStore, [name]: e.target.value });
      }
    },
    handleSubmit = async (event) => {
      event.preventDefault();
      if (!name) {
        setError(true);
        return;
      }

      const obj = { customerNumber, name };
      if (!!id) {
        obj.id = id;
      }

      setLoading(true);
      await postData(obj, "/company/add");
      setTimeout(() => {
        handleClose();
      }, 1000);
      setLoading(false);
    };

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    // Fetch countries
    axios
      .get("https://countriesnow.space/api/v0.1/countries/iso")
      .then((res) => {
        const countryOptions = res.data.data.map((country) => ({
          value: country.Iso2,
          label: country.name,
        }));
        setCountries(countryOptions);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      // Fetch states when a country is selected
      axios
        .post("https://countriesnow.space/api/v0.1/countries/states", {
          country: selectedCountry,
        })
        .then((res) => {
          console.log("sddsds", res);

          const stateOptions = res.data.data.states.map((state) => ({
            value: state.name,
            label: state.name,
          }));
          setStates(stateOptions);
        })
        .catch((error) => console.error("Error fetching states:", error));
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      // Fetch cities when a state is selected
      axios
        .post("https://countriesnow.space/api/v0.1/countries/state/cities", {
          country: selectedCountry,
          state: selectedState,
        })
        .then((res) => {
          const cityOptions = res.data.data.map((city) => ({
            value: city,
            label: city,
          }));
          setCities(cityOptions);
        })
        .catch((error) => console.error("Error fetching cities:", error));
    }
  }, [selectedState]);
  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9\s\-.,'&()]+$/, "Please enter a valid Name")
      .max(40, "Must be 40 characters or less")
      .required("Required"),
    fax: Yup.string(),
    companyWebsite: Yup.string()
      .matches(
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
        "Please enter a valid Website"
      )
      .required("Required"),

    address: Yup.string().required("Required"),
    zip: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      companyWebsite: "",
      fax: "",
      address: "",

      zip: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const obj = {
        ...values,
        id,
        company_website: values.companyWebsite,
        companyWebsite: undefined,
        customerNumber: undefined,
        country: selectedCountry,
        city: selectedCity,
        state: selectedState,
      };

      try {
        console.log("calledhere");
        await postData(obj, "/company/add");
        handleClose();
      } catch (error) {
        console.log("Err", error);
        // handle error
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("name", name);
    formik.setFieldValue("customerNumber", customerNumber);
    formik.setFieldValue("companyWebsite", company_website);
    formik.setFieldValue("address", address);
    formik.setFieldValue("zip", zip);
    formik.setFieldValue("fax", fax);
    setSelectedCountry(country);
    setSelectedState(state);
    setSelectedCity(city);
  }, [pageStore]);
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit Company
        </DialogTitle>
        <DialogContent dividers>
          <Box component="form" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  fullWidth
                  required
                  size="small"
                  label="Company Name"
                  variant="outlined"
                  {...formik.getFieldProps("name")}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="companyWebsite"
                  fullWidth
                  required
                  size="small"
                  label="Company Website"
                  variant="outlined"
                  {...formik.getFieldProps("companyWebsite")}
                  error={
                    formik.touched.companyWebsite &&
                    Boolean(formik.errors.companyWebsite)
                  }
                  helperText={
                    formik.touched.companyWebsite &&
                    formik.errors.companyWebsite
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="fax"
                  fullWidth
                  size="small"
                  label="Fax"
                  variant="outlined"
                  {...formik.getFieldProps("fax")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="address"
                  fullWidth
                  required
                  size="small"
                  label="Mailing Address"
                  variant="outlined"
                  {...formik.getFieldProps("address")}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ minWidth: 120, maxHeight: 40 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Country
                    </InputLabel>
                    <Select
                      label="Country"
                      value={selectedCountry}
                      MenuProps={{
                        classes: { paper: "selectCountryRoot" },
                      }}
                      onChange={(e) => setSelectedCountry(e.target.value)}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.value} value={country.label}>
                          {country.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ minWidth: 120, maxHeight: 40 }}>
                  <FormControl
                    fullWidth
                    size="small"
                    disabled={!selectedCountry}
                  >
                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                    <Select
                      label="State"
                      value={selectedState}
                      MenuProps={{
                        classes: { paper: "selectCountryRoot" },
                      }}
                      onChange={(e) => setSelectedState(e.target.value)}
                    >
                      {states.map((city) => (
                        <MenuItem key={city.value} value={city.label}>
                          {city.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ minWidth: 120, maxHeight: 40 }}>
                  <FormControl fullWidth size="small" disabled={!selectedState}>
                    <InputLabel id="demo-simple-select-label">City</InputLabel>
                    <Select
                      label="City"
                      value={selectedCity}
                      MenuProps={{
                        classes: { paper: "selectCountryRoot" },
                      }}
                      onChange={(e) => setSelectedCity(e.target.value)}
                    >
                      {cities.map((city) => (
                        <MenuItem key={city.value} value={city.label}>
                          {city.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="zip"
                  fullWidth
                  required
                  size="small"
                  label="Zip"
                  variant="outlined"
                  {...formik.getFieldProps("zip")}
                  error={formik.touched.zip && Boolean(formik.errors.zip)}
                  helperText={formik.touched.zip && formik.errors.zip}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="customerNumber"
                  fullWidth
                  required
                  disabled
                  size="small"
                  label="Customer Number"
                  variant="outlined"
                  {...formik.getFieldProps("customerNumber")}
                  error={
                    formik.touched.customerNumber &&
                    Boolean(formik.errors.customerNumber)
                  }
                  helperText={
                    formik.touched.customerNumber &&
                    formik.errors.customerNumber
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions style={{ alignSelf: "center" }}>
          <Button
            autoFocus
            onClick={formik.handleSubmit}
            className={"btnClass"}
            variant="contained"
            sx={{ mt: 3, mb: 2, ml: 2 }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const EnhancedTableToolbar = ({
  name,
  handleClickOpen,
  handleClose,
  open,
  isLoading,
  setLoading,
  pageData,
  setPageData,
  search,
  setSearch,
}) => {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {name} Page Listing
      </Typography>
      <Paper
        component="form"
        sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
        className="input__search"
      >
        <InputBase
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Company"
          inputProps={{ "aria-label": "search Company" }}
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      <CustomizedDialogs
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        isLoading={isLoading}
        setLoading={setLoading}
        pageData={pageData}
        setPageData={setPageData}
      />
    </Toolbar>
  );
};

export default function TableListing({
  rows,
  name,
  link,
  isLoading,
  setLoading,
  fetchData,
  pageNumber,
  setPageNumber,
  totalCount,
  search,
  setSearch,
}) {
  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setPageNumber(selectedPage + 1);
  };

  const [open, setOpen] = React.useState(false),
    [pageData, setPageData] = React.useState({
      id: 0,
      category: "",
      file: "",
      content: "",
      name: "",
      revision_date: "",
    }),
    handleClickOpen = (data) => {
      data && setPageData(data);
      setOpen(true);
    },
    handleClose = () => {
      setPageData({
        id: 0,
        name: "",
        customerNumber: "",
      });
      setOpen(false);
      fetchData();
    };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer component={Paper}>
          <EnhancedTableToolbar
            name={name}
            link={link}
            search={search}
            setSearch={setSearch}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            open={open}
            isLoading={isLoading}
            setLoading={setLoading}
            pageData={pageData}
            setPageData={setPageData}
          />
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className={"table__user UserWrap"}
          >
            <TableHead>
              <TableRow>
                <TableCell>Company Name</TableCell>
                <TableCell>Company Id</TableCell>

                <TableCell>Customer Number</TableCell>

                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.customerNumber}
                  </TableCell>

                  <TableCell align="right">
                    <Fab
                      color="primary"
                      onClick={() => handleClickOpen(row)}
                      aria-label="add"
                      size="small"
                      sx={{ mr: 1 }}
                    >
                      <EditIcon />
                    </Fab>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={Math.ceil(totalCount / 10)}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          containerClassName={"pagination"}
          subContainerClassName={"page-item"}
          activeClassName={"active"}
        />
      </Paper>
    </Box>
  );
}
