import React from 'react';
import Certificate from './CertificateCard/CertificateCard';
import "./certificateCards.scss";

const CertificateCards = ({inputValue, selectValue, certificates}) => {
    
    const filterCertificate = certificates.filter(item => {
        let newItem = item.content.concat(" "+ item.name);
        return newItem.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
    });

    const filterFromSelect = filterCertificate.filter(item => {
        return item.category.toLowerCase().indexOf(selectValue.toLowerCase()) !== -1;
    });
    return (
        <>
            {filterFromSelect.map((lists, index) => (
                <Certificate key={index} index={index} lists={lists} />
            ))}
        </>
    );
};

export default CertificateCards;


// const CertificateLists = [
    //     {
    //         title: "RM 925-A",
    //         text: "Alloy 925, Incoloy 925, Ni-Fe-Cr Alloy, Precipitation Hardenable, UNS N09925",
    //         link: "/pdf/925-A.pdf",
    //         category:"Aluminum Alloys",
    //     },
    //     {
    //         title: "RM 600-B",
    //         text: "Alloy 600, Inconel 600, Ni-Cr Alloy,  Solid Solution Strengthened,  UNS N06600",
    //         link: "/pdf/600-B.pdf",
    //         category:"Carbon Steels",
    //     },
    //     {
    //         title: "RM Nit135-A",
    //         text: "135,  135m,  AISI E71400,  Low-Alloy,  Nit. 135,  Nitralloy,  Nitriding,  Steel,  UNS K24065",
    //         link: "/pdf/Nit135-A.pdf",
    //         category:"Cobalt Alloys",
    //     },
    //     {
    //         title: "RM 1040-B",
    //         text: "AISI 1040,  Carbon Steel,  UNS G10400",
    //         link: "/pdf/1040-B.pdf",
    //         category:"Copper Alloys",
    //     },
    //     {
    //         title: "RM 1026-A",
    //         text: "AISI 1026,  Carbon Steel,  UNS G10260",
    //         link: "/pdf/1026-A.pdf",
    //         category:"Low-Alloy Steels",
    //     },
    //     {
    //         title: "RM 1030-D",
    //         text: "AISI 1030,  Carbon Steel,  UNS G10300",
    //         link: "/pdf/1030-D.pdf",
    //         category:"Nickel Alloys",
    //     },
    //     {
    //         title: "RM 1022-A",
    //         text: "AISI 1022,  Carbon Steel,  UNS G10220",
    //         link: "/pdf/1022-A.pdf",
    //         category:"Stainless & High Temp. Steels",
    //     },
    //     {
    //         title: "RM R405-A",
    //         text: "Alloy R405,  Monel R405,  UNS N04405",
    //         link: "/pdf/R405-A.pdf",
    //         category:"Titanium Alloys",
    //     },
    //     {
    //         title: "RM 347-C",
    //         text: "AISI 347,  Austenitic,  Cr-Ni-Cb,  Stainless Steel,  UNS S34700",
    //         link: "/pdf/347-C.pdf",
    //         category:"Tool Steels",
    //     },
    //     {
    //         title: "RM 304L-B",
    //         text: "AISI 304L,  Austenitic,  Cr-Ni Stainless Steel,  UNS S30403",
    //         link: "/pdf/304L-B.pdf",
    //         category:"Nickel Alloys",
    //     },
    //     {
    //         title: "RM F11-B",
    //         text: "1¼Cr-½Mo Alloy Steel,  Chromium Molybdenum Steel,  F11,  Structural Steel,  UNS K11572",
    //         link: "/pdf/F11-B.pdf",
    //         category:"Aluminum Alloys",
    //     },
    //     {
    //         title: "RM F11-A",
    //         text: "1¼Cr-½Mo Alloy Steel,  Chromium Molybdenum Steel,  F11,  Low-Alloy Steel,  Structural Steel,  UNS K11572",
    //         link: "/pdf/F11-A.pdf",
    //         category:"Carbon Steels",
    //     },
    //     {
    //         title: "RM 300M-A",
    //         text: "300M,  4340M,  Low-Alloy Steel,  UNS K44220",
    //         link: "/pdf/300M-A.pdf",
    //         category:"Copper Alloys",
    //     },
    //     {
    //         title: "RM 1018-D",
    //         text: "AISI 1018,  Carbon Steel,  UNS G10180",
    //         link: "/pdf/1018-D.pdf",
    //         category:"Nickel Alloys",
    //     },
    //     {
    //         title: "RM 4340H-B",
    //         text: "4340H,  AISI 4340H,  Ni-Cr-Mo H-Alloy Steel,  UNS H43400",
    //         link: "/pdf/4340H-B.pdf",
    //         category:"Carbon Steels",
    //     },
    // ];
