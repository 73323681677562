import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ReactPaginate from "react-paginate";
import "./pagination.css";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import PictureAsPdfTwoToneIcon from "@mui/icons-material/PictureAsPdfTwoTone";
import { postData, uploadFile } from "../../Utils/utils";

import Swal from "sweetalert2";

// import Paper from '@mui/material/Paper';
import InputBase from "@mui/material/InputBase";

import SearchIcon from "@mui/icons-material/Search";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CustomizedDialogs({
  handleClickOpen,
  handleClose,
  open,
  setLoading,
  pageData: pageStore,
  setPageData: setPageStore,
}) {
  const [isError, setError] = useState(false),
    [isInvalidDate, setInvalidDate] = useState(false),
    {
      id = 0,
      file = "",
      category = "",
      content = "",
      name = "",
      revision_date = "",
    } = pageStore,
    onPageChange = async (e, name) => {
      if (name === "file" && e?.target?.files?.[0]) {
        const image = e.target.files[0] || {};
        if (image.name && !image.name.match(/\.(pdf)$/)) {
          setPageStore({ ...pageStore, file: "" });
          return false;
        } else {
          setLoading(true);
          const { data: { url = "" } = {} } =
            (await uploadFile(
              e.target.files[0],
              "/common/upload/file",
              "certificates"
            )) || {};
          setPageStore({ ...pageStore, file: url });
          setLoading(false);
        }
      } else if (
        name === "category" ||
        name === "name" ||
        name === "content" ||
        name === "file"
      ) {
        setPageStore({ ...pageStore, [name]: e.target.value });
      } else if (name === "revision_date") {
        const date = new Date(e.target.value);
        if (date === "Invalid Date") {
          setInvalidDate(true);
        } else {
          setInvalidDate(false);
        }
        setPageStore({ ...pageStore, [name]: e.target.value });
      }
    },
    handleSubmit = async (event) => {
      event.preventDefault();
      if (
        !category ||
        !content ||
        !file ||
        !name ||
        !revision_date ||
        isInvalidDate
      ) {
        setError(true);
        return;
      }

      const obj = { file, category, content, name, revision_date };
      if (!!id) {
        obj.id = id;
      }

      setLoading(true);
      await postData(obj, "/certificates/add");
      setTimeout(() => {
        handleClose();
      }, 1000);
      setLoading(false);
    };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Certificate Item
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={name}
                  onChange={(e) => onPageChange(e, "name")}
                  label="Name"
                  variant="outlined"
                  error={isError && !name}
                  helperText={isError && !name && "name is required"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={content}
                  onChange={(e) => onPageChange(e, "content")}
                  label="Details"
                  variant="outlined"
                  error={isError && !content}
                  helperText={isError && !content && "content is required"}
                />
              </Grid>
              {/* <Grid item xs={12} >
                                <FormControl fullWidth size="small">
                                    <InputLabel id="select-label">Select Category</InputLabel>
                                    <Select labelId="select-label" id="select" value={category} label="Select Category" onChange={(e) => onPageChange(e, "category")} error={isError && !category}>
                                        <MenuItem value={"Aluminum Alloys"}>Aluminum Alloys</MenuItem>
                                        <MenuItem value={"Carbon Steels"}>Carbon Steels</MenuItem>
                                        <MenuItem value={"Cobalt Alloys"}>Cobalt Alloys</MenuItem>
                                        <MenuItem value={"Copper Alloys"}>Copper Alloys</MenuItem>
                                        <MenuItem value={"Low-Alloy Steels"}>Low-Alloy Steels</MenuItem>
                                        <MenuItem value={"Nickel Alloys"}>Nickel Alloys</MenuItem>
                                        <MenuItem value={"Stainless & High Temp. Steels"}>Stainless &amp; High Temp. Steels</MenuItem>
                                        <MenuItem value={"Titanium Alloys"}>Titanium Alloys</MenuItem>
                                        <MenuItem value={"Tool Steels"}>Tool Steels</MenuItem>
                                    </Select>
                                    {isError && !category ? <FormHelperText>category is required</FormHelperText> : null}
                                </FormControl>
                            </Grid> */}
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={category}
                  onChange={(e) => onPageChange(e, "category")}
                  label="Category"
                  variant="outlined"
                  error={isError && !category}
                  helperText={isError && !category && "category is required"}
                />
              </Grid>
              <Grid item xs={12} style={{ position: "relative" }}>
                <TextField
                  style={{ backgroundColor: "white" }}
                  onChange={(e) => onPageChange(e, "file")}
                  name="upload-photo"
                  fullWidth
                  type="file"
                  error={isError && !content}
                  helperText={
                    isError && !file && "file is required only with pdf format"
                  }
                />
                {file && (
                  <div className="iconWrap">
                    <PictureAsPdfTwoToneIcon />
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={file}
                  onChange={(e) => onPageChange(e, "file")}
                  label="File"
                  variant="outlined"
                  error={isError && !file}
                  helperText={isError && !file && "file is required"}
                />
              </Grid>
              {/* <Grid item xs={12} >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker label="Revision Date" views={['month', 'day', 'year']} value={revision_date} onChange={(e) => onPageChange(e, "revision_date")} renderInput={(params) => <TextField {...params} error={isError && (!revision_date || isInvalidDate)} helperText={isError && (isInvalidDate ? "Invalid Date" : !revision_date && "revision date is required")} />} />
                                </LocalizationProvider>
                            </Grid> */}
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={revision_date}
                  onChange={(e) => onPageChange(e, "revision_date")}
                  label="Revision Date"
                  variant="outlined"
                  error={isError && (!revision_date || isInvalidDate)}
                  helperText={
                    isError &&
                    (isInvalidDate
                      ? "Invalid Date"
                      : !revision_date && "revision date is required")
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions style={{ alignSelf: "center" }}>
          <Button
            autoFocus
            onClick={handleSubmit}
            className={"btnClass"}
            variant="contained"
            sx={{ mt: 3, mb: 2, ml: 2 }}
          >
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

const EnhancedTableToolbar = ({
  name,
  handleClickOpen,
  handleClose,
  open,
  isLoading,
  setLoading,
  pageData,
  setPageData,
  search,
  setSearch,
}) => {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {name} Page Listing
      </Typography>
      <Paper
        component="form"
        sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
        className="input__search"
      >
        <InputBase
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Users"
          inputProps={{ "aria-label": "search users" }}
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      <CustomizedDialogs
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        isLoading={isLoading}
        setLoading={setLoading}
        pageData={pageData}
        setPageData={setPageData}
      />
    </Toolbar>
  );
};

export default function TableListing({
  rows,
  name,
  link,
  isLoading,
  setLoading,
  fetchData,
  pageNumber,
  setPageNumber,
  totalCount,
  search,
  setSearch,
}) {
  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setPageNumber(selectedPage + 1);
  };
  const [open, setOpen] = React.useState(false),
    [pageData, setPageData] = React.useState({
      id: 0,
      category: "",
      file: "",
      content: "",
      name: "",
      revision_date: "",
    }),
    handleClickOpen = (data) => {
      data && setPageData(data);
      setOpen(true);
    },
    handleChange = async (event, data) => {
      let title = "";
      if (event.target.checked) {
        title = "Are you sure you want to make this user active?";
      } else {
        title = "Are you sure you want to make this user inactive?";
      }
      Swal.fire({
        title: title,
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await postData(
            {
              id: data.id,
              status: event.target.checked ? "inactive" : "active",
            },
            "/users/updateStatus"
          );
          fetchData();
          Swal.fire("Status Updated!", "", "success");
        }
      });
    },
    handleDelete = async (data) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await postData(
            { id: data.id, company_id: data.company_id },
            "/users/delete"
          );
          fetchData();
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    handleClose = () => {
      setPageData({
        id: 0,
        category: "",
        content: "",
        file: "",
        name: "",
        revision_date: "",
      });
      setOpen(false);
      fetchData();
    };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer component={Paper}>
          <EnhancedTableToolbar
            name={name}
            link={link}
            search={search}
            setSearch={setSearch}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            open={open}
            isLoading={isLoading}
            setLoading={setLoading}
            pageData={pageData}
            setPageData={setPageData}
          />
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className={"table__user UserWrap"}
          >
            <TableHead>
              <TableRow>
                <TableCell>Company Name</TableCell>

                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>User Type</TableCell>
                <TableCell align="left">Action</TableCell>

                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.first_name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.company_name}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.first_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.user_type}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={row.status === "active"}
                            onChange={(e) => handleChange(e, row)}
                            name="gilad"
                          />
                        }
                        label="Active"
                      />
                    </FormGroup>
                  </TableCell>

                  <TableCell align="right">
                    <div className="buttonWrap">
                      <Fab
                        color="error"
                        aria-label="delete"
                        size="small"
                        onClick={() => handleDelete(row)}
                        style={{
                          maxWidth: "30px",
                          maxHeight: "30px",
                          minWidth: "30px",
                          minHeight: "30px",
                          zIndex: "99",
                        }}
                      >
                        <DeleteIcon />
                      </Fab>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={Math.ceil(totalCount / 10)}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          containerClassName={"pagination"}
          subContainerClassName={"page-item"}
          activeClassName={"active"}
        />
      </Paper>
    </Box>
  );
}
