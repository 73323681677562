import React, { useEffect, useState } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Banner from "../Common/Banner/Banner";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { getData } from "../../Utils/utils";
import LoadingOverlay from "react-loading-overlay";

const Home = () => {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var emailList = [
    { email: "Daniel@RMRMs.com" },
    { email: "Sheryl@RMRMs.com" },
    // { email: 'araceli@rmrms.com' },
  ];

  const [isLoading, setLoading] = useState(false),
    [homeData, setHomeData] = useState({}),
    {
      home_title = "",
      default_content = "",
      home_sub_title = "",
    } = homeData || {},
    fetchProductsData = async () => {
      setLoading(true);
      const result = await getData("/home/get");
      setHomeData(result);
      setLoading(false);
    };
  const [postData, setPostData] = useState([]),
    fetchData = async () => {
      const { catalogs } = await getData("/catalog/get");
      setPostData(catalogs);
    };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchProductsData();
    window.scrollTo(0, 0);
  }, []);

  LoadingOverlay.propTypes = undefined;
  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <Header email={"Info@RMRMs.com"} subject={"General Inquiry"} />
        <div className="mainSection">
          <Banner
            bgPath={"url(/images/banner.jpg)"}
            bannerContent={
              <div className="banner__section--content">
                <h1 className="title title--lg">{home_title}</h1>
                <h2 className="title title--red">{home_sub_title}</h2>
              </div>
            }
            bannerHeight={"banner__section--height"}
            bgPosition={"banner__section--backgroundPosition"}
          />

          <div
            dangerouslySetInnerHTML={{ __html: default_content }}
            className="reset-props"
          ></div>

          <section className="slider-section">
            <div className="container">
              <Slider {...settings}>
                {postData &&
                  postData.map((value, index) => {
                    return (
                      <div>
                        <a href={value.file} target="_blank" rel="noreferrer">
                          <img src={value.icon} alt="" />
                        </a>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </section>
        </div>
        <Footer footerImg={true} footerEmails={true} emailList={emailList} />
      </LoadingOverlay>
    </>
  );
};

export default Home;
