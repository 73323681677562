import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfTwoToneIcon from "@mui/icons-material/PictureAsPdfTwoTone";

const PdfComponent = ({ pageStore, onPageChange }) => {
  const handlePdfGen = () => {
      onPageChange("", "push");
    },
    handlePdfRemove = (index) => {
      onPageChange("", "pop", index);
    },
    onFileNameChange = (e, index) => {
      onPageChange(e, "file_name", index);
    },
    onFileChange = (e, index) => {
      const image = e.target.files[0];
      if (!image.name.match(/\.(pdf)$/)) {
        alert("format file are not correct");
        return false;
      } else {
        onPageChange(e, "file", index);
      }
    };

  return (
    <>
      {pageStore.files?.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Grid item xs={4} className={"pdfInputText"}>
              <TextField
                label="PDF text"
                value={item.file_name}
                onChange={(e) => onFileNameChange(e, index)}
                id="outlined-basic"
                fullWidth
                variant="outlined"
                style={{ marginTop: "6px", backgroundColor: "white" }}
              />
            </Grid>
            <Grid
              item
              xs={4}
              className={"pdfInputUpload"}
              style={{ position: "relative" }}
            >
              <TextField
                style={{ backgroundColor: "white" }}
                onChange={(e) => onFileChange(e, index)}
                name="upload-photo"
                fullWidth
                type="file"
              />
              {item.file && (
                <div className="iconWrap" style={{ top: "20px" }}>
                  <PictureAsPdfTwoToneIcon />
                </div>
              )}
            </Grid>
            <Grid item xs={2} className={"pdfInputBtn"}>
              <Fab
                color="primary"
                aria-label="add"
                size="small"
                sx={{ mr: 1 }}
                onClick={() => handlePdfGen()}
              >
                <AddIcon />
              </Fab>
              {pageStore.files.length === 1 ? (
                ""
              ) : (
                <Fab
                  color="error"
                  aria-label="delete"
                  size="small"
                  onClick={() => handlePdfRemove(index)}
                >
                  <DeleteIcon />
                </Fab>
              )}
            </Grid>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default PdfComponent;
