import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Fab from "@mui/material/Fab";
import Button from '@mui/material/Button';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import { Link } from "react-router-dom";

const EnhancedTableToolbar = ({ name, link }) => {
    return (
        <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">{name} Page Listing</Typography>
            <Button variant="contained" component={Link} to={`/admin/${link}`} className={"btnPrimary"}>
                + New Page
            </Button>
        </Toolbar>
    );
};

export default function TableListing({ rows, name, link, handleDelete }) {
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer component={Paper}>
                    <EnhancedTableToolbar name={name} link={link} />
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" className={"tableWrap"}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow
                                    key={row.Heading + " " + index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{row.title}</TableCell>
                                    <TableCell align="right">
                                        <Fab color="primary" component={Link} to={`/admin/${link}`} state={{ content: row }} aria-label="add" size="small" sx={{ mr: 1 }}><EditIcon /></Fab>
                                        <Fab color="error" onClick={() => handleDelete(row)} aria-label="delete" size="small"><DeleteIcon /></Fab>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>

    );
}
