import React from "react";
import { Link } from "react-router-dom";
import ArrowRightIcon from "../../../Icon/ArrowRight";

const Card = ({
  lists: { category_name, contents, url, description },
  productMenu,
}) => {
  const [content = {}] = contents,
    // eslint-disable-next-line
    { default_content: { html = "" } = {} } = content,
    extractContent = (html) => {
      const text = new DOMParser().parseFromString(html, "text/html")
        .documentElement.textContent;
      return text;
    };

  return (
    <>
      <div className="card">
        <h4 className="card__title">{category_name}</h4>
        <Link
          to={"/products/contents/"}
          className="card__link"
          state={{ content, productMenu }}
        >
          <p className="card__text">{extractContent(description)}</p>
          Read More <ArrowRightIcon />
        </Link>
      </div>
    </>
  );
};

export default Card;
