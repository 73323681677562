import React from "react";
// import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const GalleryComponent = ({ pageStore, onPageChange,setPageStore }) => {

const [list, setList] = React.useState([]);
  
  const dragItem = React.useRef();
  const dragOverItem = React.useRef();
  const dragStart = (e, position) => {
    dragItem.current = position;
    console.log(e.target.innerHTML);
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
  };

  const drop = (e) => {
    const copyListItems = [...list];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    copyListItems.forEach((e, index) => {
      e.position = index + 1;

    });
    setPageStore({
      ...pageStore,
     images: copyListItems
    })
    setList(copyListItems);
  };

  React.useEffect(() => {
    console.log("images--->", pageStore?.images)
    debugger
    if (pageStore && pageStore.images && pageStore.images.length > 0 && pageStore.images[0].position)
    {
      let sortedList = pageStore.images.sort((a, b) => a.position - b.position)
      setList(sortedList)
    }
    else
    setList(pageStore?.images);
  }, [pageStore]);

  const handlePdfGen = () => {
      onPageChange("", "push");
    },
    handlePdfRemove = (index) => {
      onPageChange("", "pop", index);
    },
    onFileChange = (e, index) => {
      const image = e.target.files[0];
      if (image.name.match(/\.(pdf)$/)) {
        alert("format file are not correct");
        return false;
      } else {
        const objectUrl = URL.createObjectURL(image);
        onPageChange(objectUrl, "preview", index);
        onPageChange(e, "file", index);
      }
    };

  return (
    <>
      {pageStore.images?.map((item, index) => {
        return (
          <div
           
            key={index}>
            <div  onDragStart={(e) => dragStart(e, index)}
                  onDragEnter={(e) => dragEnter(e, index)}
                  onDragEnd={drop}
            draggable className="gallery">
              <div className="gallery__upload">
                <label
                  htmlFor={"uploadImage-" + index}
                  className="gallery__upload--label"
                >
                  <span>
                    <svg
                      fill="none"
                      height="30"
                      viewBox="0 0 31 30"
                      width="31"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.125 6.25V23.75M6.375 15H23.875"
                        stroke="#111927"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      ></path>
                    </svg>
                  </span>
                  <span>Upload Gallery Image</span>
                  <TextField
                    style={{ backgroundColor: "white" }}
                    onChange={(e) => onFileChange(e, index)}
                    name="upload-photo"
                    fullWidth
                    type="file"
                    id={"uploadImage-" + index}
                    className="input-file"
                  />
                </label>

                {/* {item.file && (
                  <div className="iconWrap" style={{ top: "20px" }}>
                    <PictureAsPdfTwoToneIcon />
                  </div>
                )} */}
              </div>

              <div className="gallery__image">
                 {item.file && (
                <img alt="" src={item.file}></img>
                )}
                {/* {item.preview && <img alt="" src={item.preview}></img>} */}
              </div>

              <div className={"pdfInputBtn"}>
                <Fab
                  color="primary"
                  aria-label="add"
                  size="small"
                  sx={{ mr: 1 }}
                  onClick={() => handlePdfGen()}
                >
                  <AddIcon />
                </Fab>
                {pageStore.images.length === 1 ? (
                  ""
                ) : (
                  <Fab
                    color="error"
                    aria-label="delete"
                    size="small"
                    onClick={() => handlePdfRemove(index)}
                  >
                    <DeleteIcon />
                  </Fab>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default GalleryComponent;
