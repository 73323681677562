import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "./../components/Header";
import DrawerCom from "../components/Drawer";
import { postData, uploadFile } from "../../Utils/utils";
import PictureAsPdfTwoToneIcon from "@mui/icons-material/PictureAsPdfTwoTone";
// import FormLabel from "@mui/material/FormLabel";
import LoadingOverlay from "react-loading-overlay";
import TextEditor from "../components/Editor";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const mdTheme = createTheme();

function DashboardContent() {
  const [pageStore, setPageStore] = useState({
      id: 0,
      title: "",
      default_content: "",
      hidden_content: "",
      certificate_url: "",
      file: "",
    }),
    [open, setOpen] = useState(true),
    [isError, setError] = useState(false),
    [isLoading, setLoading] = React.useState(false),
    { id, title, default_content, hidden_content, certificate_url, file } =
      pageStore,
    navigate = useNavigate(),
    location = useLocation(),
    { content } = location.state || {};

  useEffect(() => {
    content && setPageStore(content);
    content && setValue(dayjs(content.date));
  }, [content]);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const formattedDate = months[month] + " " + day + ", " + year;
    return formattedDate;
  }
  const onPageChange = async (e, name, index) => {
      const { target: { value = "" } = {} } = e;
      if (name === "title") {
        setPageStore({ ...pageStore, [name]: value });
      } /* else if (name === "file" && e?.target?.files?.[0]) {
        const image = e.target.files[0] || {};
        if (image.name && !image.name.match(/\.(pdf)$/)) {
          setPageStore({ ...pageStore, file: "" });
          return false;
        } else {
          setLoading(true);
          const { data: { url = "" } = {} } =
            (await uploadFile(e.target.files[0], "/common/upload/file")) || {};
          setPageStore({ ...pageStore, file: url });
          setLoading(false);
        }
        
      }  */ else if (name === "file" && e?.target?.files?.[0]) {
        debugger;
        const image = e.target.files[0];
        if (image.name && !image.name.match(/\.(pdf)$/)) {
          setPageStore({ ...pageStore, file: "" });
          return false;
        } else {
          setLoading(true);
          const { data: { url = "" } = {} } =
            (await uploadFile(e.target.files[0], "/common/upload/file")) || {};
          setPageStore({ ...pageStore, file: url });
          setLoading(false);
        }
      } else if (name === "file_link") {
        setPageStore({ ...pageStore, file: e.target.value });
      } else if (name === "certificate_url") {
        setPageStore({ ...pageStore, [name]: value });
      } else if (name === "default_content" || name === "hidden_content") {
        setPageStore({ ...pageStore, [name]: e });
      }
    },
    toggleDrawer = () => {
      setOpen(!open);
    },
    handleSubmit = async (event) => {
      event.preventDefault();
      if (!title) {
        setError(true);
        return;
      }

      const obj = {
        title,
        default_content,
        hidden_content,
        date: formatDate(value),
        certificate_url: certificate_url === null ? "" : certificate_url,
        file: file === null ? "" : file,
      };
      if (!!id) {
        obj.id = id;
      }

      setLoading(true);
      await postData(obj, "/aboutus/add/latestnews");
      setLoading(false);
      setTimeout(() => {
        navigate("/admin/latestNewsListing");
      }, 1000);
    };
  LoadingOverlay.propTypes = undefined;

  const [value, setValue] = React.useState(dayjs(new Date()));

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading ...">
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Header open={open} toggleDrawer={toggleDrawer} />
          <DrawerCom open={open} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      minHeight: 240,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ pb: 2 }}
                    >
                      News
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            size="small"
                            value={title}
                            onChange={(e) => onPageChange(e, "title")}
                            label="Title"
                            variant="outlined"
                            error={isError && !title}
                            helperText={
                              isError && !title && "title is required"
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        sx={{ width: "100%", marginTop: "1px" }}
                      >
                        <Grid item xs={12}>
                          <label>Default Content</label>
                          <TextEditor
                            editorValue={pageStore.default_content}
                            onChange={(newContent) =>
                              onPageChange(newContent, "default_content")
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <label>Hide Content</label>
                          <TextEditor
                            editorValue={pageStore.hidden_content}
                            onChange={(newContent) =>
                              onPageChange(newContent, "hidden_content")
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Date"
                              value={value}
                              onChange={(newValue) => setValue(newValue)}
                              renderInput={(props) => (
                                <TextField
                                  {...props}
                                  size="small"
                                  sx={{ width: "100%", marginTop: "18px" }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <Grid
                          sx={{ width: "100%", marginTop: "10px" }}
                          item
                          xs={4}
                        >
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            size="small"
                            value={certificate_url}
                            onChange={(e) => onPageChange(e, "certificate_url")}
                            label="Certificate Url"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid> */}

                      {/* <Grid item xs={12} style={{ position: "relative", marginTop:"8px" }}>
                        <TextField
                          style={{ backgroundColor: "white" }}
                          onChange={(e) => onPageChange(e, "file")}
                          name="upload-photo"
                          fullWidth
                          type="file"
                          error={isError && !content}
                          helperText={
                            isError &&
                            !file &&
                            "file is required only with pdf format"
                          }
                        />
                        {file && (
                          <div className="iconWrap">
                            <PictureAsPdfTwoToneIcon />
                          </div>
                        )}
                      </Grid> */}
                      <Grid
                        item
                        style={{ position: "relative", marginTop: "22px" }}
                        xs={8}
                      >
                        <div className="input__group--radio">
                          {/* <input type="radio" name="fileUpload" /> */}
                          <TextField
                            style={{ backgroundColor: "white" }}
                            onChange={(e) => onPageChange(e, "file")}
                            name="upload-photo"
                            fullWidth
                            type="file"
                            error={isError && !file}
                            helperText={isError && !file && "file is required"}
                            label="Upload File"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {file && (
                            <a href={file} target="blank" className="iconWrap">
                              <PictureAsPdfTwoToneIcon />
                            </a>
                          )}
                        </div>

                        {/* <TextField style={{ backgroundColor: "white" }} onChange={e => onPageChange(e, "file")} name="upload-photo" fullWidth type="file" error={isError && !file} helperText={isError && !file && "file is required only with pdf format"} />
                                    {file && <div className="iconWrap"><PictureAsPdfTwoToneIcon /></div>} */}
                      </Grid>
                      <Grid
                        style={{ position: "relative", marginTop: "22px" }}
                        item
                        xs={8}
                      >
                        <div className="input__group--radio">
                          {/* <input type="radio" name="fileUpload" /> */}
                          <TextField
                            style={{ backgroundColor: "white" }}
                            value={file}
                            onChange={(e) => onPageChange(e, "file_link")}
                            fullWidth
                            label="Upload Link"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={isError && !file}
                            helperText={isError && !file && "file is required"}
                            className="fileUpload"
                          />
                          {file && (
                            <a href={file} target="blank" className="iconWrap">
                              <PictureAsPdfTwoToneIcon />
                            </a>
                          )}
                        </div>
                      </Grid>
                      {/* <Grid item xs={12}
                      sx={{marginTop: "10px"}}
                      >
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          required
                          size="small"
                          value={file}
                          onChange={(e) => onPageChange(e, "file")}
                          label="File"
                          variant="outlined"
                        />
                      </Grid> */}
                      <Button
                        type="submit"
                        className={"btnClass"}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Save
                      </Button>
                      <Button
                        component={Link}
                        to="/admin/latestNewsListing"
                        variant="outlined"
                        sx={{ mt: 3, mb: 2, ml: 2 }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </LoadingOverlay>
  );
}

export default function LatestNews() {
  return <DashboardContent />;
}
