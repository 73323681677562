import React, { useEffect } from "react";
import Header from "../../../Common/Header/Header";
import Footer from "../../../Common/Footer/Footer";
import Banner from "../../../Common/Banner/Banner";
import Breadcrumb from "../../../Common/Breadcrumb/Breadcrumb";
import SideNavbar from "../../../Templates/SideNavbar/ProductSideNavbar";
import PDFIcon from "../../../Icon/Pdf";
import ArrowDown from "../../../Icon/ArrowDown";

const BrammerCastIrons = () => {
  var arrDown = `<svg xmlns="http://www.w3.org/2000/svg" width="11.987" height="5.986" viewBox="0 0 11.987 5.986">
            <path id="arrow-ios-downward-outline" d="M13.482,19.482a.855.855,0,0,1-.547-.2L7.807,15.012A.856.856,0,0,1,8.9,13.7l4.581,3.829,4.581-3.692a.876.876,0,1,1,1.086,1.376l-5.128,4.128A.855.855,0,0,1,13.482,19.482Z" transform="translate(-7.498 -13.498)" fill="#900" />
        </svg>`;
  const accordionHandler = (event) => {
    event.target.classList.toggle("active");
    var accordionBody = event.target.previousElementSibling;
    if (accordionBody.style.maxHeight) {
      accordionBody.style.maxHeight = null;
      event.target.innerHTML = "Show more" + arrDown;
    } else {
      accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
      event.target.innerHTML = "Show less" + arrDown;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var emailList = [{ email: "sales@rmrms.com" }, { email: "" }, { email: "" }];

  return (
    <>
      <Header />
      <div className="mainSection">
        <Banner
          bgPath={"url(/images/HeaderDark.jpg)"}
          breadcrumb={
            <Breadcrumb
              title="Brammer Cast Irons"
              listItem="Home"
              listItemPath="/"
              listLink="Products"
              listLinkPath="/products"
            />
          }
        />

        <div className="container">
          <div className="row">
            <div className="col_sm_12 col_md_5 col_lg_3">
              <SideNavbar />
            </div>

            <div className="col_sm_12 col_md_7 col_lg_9">
              <section className="page__section" id="brammerCast">
                <h3 className="page__section--heading text__align--left">
                  Brammer Standards Cast Iron Materials
                </h3>
                <h4 className="page__section--sub-heading text__align--left">
                  Low-Alloy Cast Iron CRMs
                </h4>
                <h4 className="page__section--sub-heading text__align--left">
                  Material Information
                </h4>
                <p className="page__section--para">
                  The Materials listed have been selected to meet most of the
                  day-to-day calibration needs for chemical and spectrochemical
                  analysis techniques. These include Optical Emission, Glow
                  Discharge, XRF, ICP, and AA spectroscopy, as well as
                  photometric and other "wet" methods.
                </p>
                <p className="page__section--para">
                  With the increasing importance of quality assurance procedures
                  and national and international accreditation such as ISO/IEC
                  17025, the use of Certified Reference Materials for the
                  calibration and standardization of analytical instrumentation
                  is essential to validate measurements. Many of the materials
                  listed are classified as Certified Reference Materials and
                  supplied with comprehensive support certification.
                </p>
              </section>

              <section className="page__section p_0">
                <div className="accordion accordion--show-more m_0">
                  <div className="accordion__item">
                    <div className="accordion__item--body">
                      <h4 className="page__section--sub-heading text__align--left">
                        Lorem ipsum
                      </h4>
                      <p className="page__section--para">
                        Lorem ipsum is a placeholder text commonly used to
                        demonstrate the visual form of a document or a typeface
                        without relying on meaningful content. Lorem ipsum is a
                        placeholder text commonly used to demonstrate the visual
                        form of a document or a typeface without relying on
                        meaningful content. Lorem ipsum is a placeholder text
                        commonly used to demonstrate the visual form of a
                        document or a typeface without relying on meaningful
                        content. Lorem ipsum is a placeholder text commonly used
                        to demonstrate the visual form of a document or a
                        typeface without relying on meaningful content. Lorem
                        ipsum is a placeholder text commonly used to demonstrate
                        the visual form of a document or a typeface without
                        relying on meaningful content. Lorem ipsum is a
                        placeholder text commonly used to demonstrate the visual
                        form of a document or a typeface without relying on
                        meaningful content.
                      </p>
                    </div>
                    <button
                      className="accordion__item--btn"
                      onClick={accordionHandler}
                    >
                      Show more <ArrowDown />
                    </button>
                  </div>
                </div>
              </section>

              <section className="page__section bg--light p_3 mt_50">
                <h4 className="page__section--sub-heading text__align--left">
                  Brammer Standards Cast Iron Materials
                </h4>
                <ul className="catalog__list">
                  <li className="catalog__list--item">
                    <a href="!#">
                      <PDFIcon />
                      Low-Alloy Cast Irons
                    </a>
                  </li>
                </ul>
              </section>

              <section className="page__section pb_0">
                <div className="row">
                  <div className="col_sm_12 col_md_12 col_lg_8">
                    <div className="product__info">
                      <h4 className="page__section--sub-heading text__align--left">
                        Lorem ipsum is a placeholder
                      </h4>
                      <ul className="product__info--list">
                        <li className="product__info--list-item">
                          Lorem ipsum is a placeholder text commonly used to
                          demonstrate the visual form of a document or a
                          typeface without relying on meaningful content.
                        </li>
                        <li className="product__info--list-item">
                          Lorem ipsum is a placeholder text commonly used to
                          demonstrate the visual form of a document or a
                          typeface without relying on meaningful content.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col_sm_12 col_md_12 col_lg_4">
                    <div className="product__pricing">
                      <h4 className="page__section--sub-heading text__align--left">
                        General Pricing
                      </h4>
                      <p className="page__section--para">
                        Please Call for Pricing :{" "}
                        <a href="tel:(720) 943-7676">(720) 943-7676</a>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <Footer footerEmails={true} emailList={emailList} />
    </>
  );
};

export default BrammerCastIrons;
