import React , { useEffect } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Banner from "../Common/Banner/Banner";

const SearchEngine = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    return(
        <>
            <Header />
            <div className="mainSection">
                <Banner bgPath={"url(/images/banner.jpg)"} 
                    bannerContent={(<div className="banner__section--content">
                        <h1 className="title">Coming Soon!</h1>
                    </div>)}
                    bannerHeight={"banner__section--height"}
                />

                <section className="page__section">
                    
                </section>

            </div>
            <Footer />
        </>
    )
}

export default SearchEngine;

