import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { uploadFile } from "../../../../Utils/utils";
import axios from "../../../../Services/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "4px",
  p: 2,
};
const PoUploadModal = ({
  modalPo,
  handlePoClose,
  status,
  customerId,
  setModalPo,
  fetchData,
  setLoader,
}) => {
  const [poFile, setPoFile] = useState("");
  const [poFileError, setPoFileError] = useState("");
  const handlePoUpload = async (event) => {
    event.preventDefault();
    setLoader(true);

    if (!poFile) {
      setPoFileError(true);
      return;
    }

    const obj = {
      po_file: poFile,
      state: 3,

      update_status: {
        ...status,
        po_issued: new Date(),
      },
      reference_id: customerId,
    };

    axios
      .post("/rmpt/update/calender/data", obj)

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          setTimeout(() => {
            handlePoModalClose();
          }, 1000);
          setLoader(false);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: true,
            // timer: 1500,
          });
          setLoader(false);
        }
      })
      .catch((error) => {})
      .then(() => {});
  };
  const handlePoModalClose = () => {
    setPoFile("");
    setModalPo(false);
    fetchData();
  };
  const onPoUpload = async (e, name) => {
    if (name === "file" && e?.target?.files?.[0]) {
      const image = e.target.files[0] || {};
      if (image.name && !image.name.match(/\.(pdf)$/)) {
        setPoFile("");
        return false;
      } else {
        setLoader(true);
        const { data: { url = "" } = {} } =
          (await uploadFile(
            e.target.files[0],
            "/common/upload/file",
            "pofile"
          )) || {};
        setPoFile(url);

        setLoader(false);
      }
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalPo}
      onClose={handlePoClose}
      closeAfterTransition
      className="uploadModal"
    >
      <Fade in={modalPo}>
        <Box sx={style} component="form">
          <Typography
            id=""
            variant="h6"
            component="h2"
            style={{
              marginBottom: "10px",
            }}
          >
            Upload PO
            <IconButton
              aria-label="close"
              onClick={handlePoClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                onChange={(e) => onPoUpload(e, "file")}
                error={poFileError && !poFile}
                helperText={poFileError && !poFile && "File is Required"}
                inputProps={{
                  accept: "application/pdf",
                }}
                style={{
                  backgroundColor: "white",
                }}
                name="upload-photo"
                fullWidth
                type="file"
              />
            </Grid>
            <Button
              autoFocus
              onClick={handlePoUpload}
              className={"btnClass"}
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                ml: 2,
              }}
            >
              Save
            </Button>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default PoUploadModal;
