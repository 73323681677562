import React from 'react';

const PDFSvg = () => {
    return(
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="17.752" height="22.669" viewBox="0 0 17.752 22.669">
                <g id="pdf" transform="translate(0 0)">
                    <g id="Group_134" data-name="Group 134" transform="translate(0 0)">
                    <g id="Group_133" data-name="Group 133" transform="translate(0)">
                        <path id="Path_12" data-name="Path 12" d="M81.607,4.861,76.676.138A.5.5,0,0,0,76.328,0H65.972A1.934,1.934,0,0,0,64,1.889V20.78a1.934,1.934,0,0,0,1.972,1.889H79.78a1.934,1.934,0,0,0,1.972-1.889V5.195A.462.462,0,0,0,81.607,4.861ZM76.821,1.612l3.248,3.11H77.807a.967.967,0,0,1-.986-.945ZM80.766,20.78a.967.967,0,0,1-.986.945H65.972a.967.967,0,0,1-.986-.945V1.889a.967.967,0,0,1,.986-.945h9.862V3.778a1.934,1.934,0,0,0,1.972,1.889h2.959Z" transform="translate(-64)" fill="#6d7c90"/>
                        <path id="Path_13" data-name="Path 13" d="M136.431,177.252a14.292,14.292,0,0,1-1.179-1.018c-.376-.376-.71-.74-1-1.086a11.04,11.04,0,0,0,.653-2.51c0-1.637-.591-1.972-1.479-1.972-.675,0-1.479.351-1.479,2.02a4.728,4.728,0,0,0,1.2,2.667c-.2.6-.425,1.285-.683,2.061-.124.372-.259.717-.4,1.036-.116.052-.229.1-.338.158-.392.2-.764.372-1.109.536-1.575.745-2.615,1.239-2.615,2.212,0,.707.768,1.145,1.479,1.145.917,0,2.3-1.225,3.313-3.288a19.619,19.619,0,0,1,3.385-.913,5.657,5.657,0,0,0,2.179,1.242c1.223,0,1.479-.707,1.479-1.3,0-1.166-1.332-1.166-1.972-1.166A11.679,11.679,0,0,0,136.431,177.252Zm-6.952,4.262a.779.779,0,0,1-.493-.158c0-.35,1.043-.844,2.051-1.321l.2-.092C130.492,181.017,129.759,181.514,129.479,181.514Zm3.452-8.829c0-1.033.321-1.033.493-1.033.349,0,.493,0,.493.986a7.906,7.906,0,0,1-.392,1.54A3.03,3.03,0,0,1,132.931,172.686Zm.378,5.3q.046-.129.09-.26c.183-.549.348-1.042.495-1.486q.307.338.662.692c.092.092.322.3.627.561C134.574,177.625,133.927,177.79,133.309,177.986Zm5.539.257c0,.222,0,.313-.457.316a3.635,3.635,0,0,1-.828-.473c.139-.015.242-.023.3-.023C138.591,178.063,138.8,178.134,138.848,178.242Z" transform="translate(-125.041 -163.443)" fill="#6d7c90"/>
                    </g>
                    </g>
                </g>
            </svg>
        </>
    );
}

export default PDFSvg;