import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../Common/Header/Header";
import Footer from "../../Common/Footer/Footer";
import Banner from "../../Common/Banner/Banner";
import Breadcrumb from "../../Common/Breadcrumb/Breadcrumb";
import SideNavbar from "../../Templates/SideNavbar/ProductSideNavbar";
import PDFIcon from "../../Icon/Pdf";
import ArrowDown from "../../Icon/ArrowDown";

const DynamicPage = () => {
  var arrDown = `<svg xmlns="http://www.w3.org/2000/svg" width="11.987" height="5.986" viewBox="0 0 11.987 5.986">
            <path id="arrow-ios-downward-outline" d="M13.482,19.482a.855.855,0,0,1-.547-.2L7.807,15.012A.856.856,0,0,1,8.9,13.7l4.581,3.829,4.581-3.692a.876.876,0,1,1,1.086,1.376l-5.128,4.128A.855.855,0,0,1,13.482,19.482Z" transform="translate(-7.498 -13.498)" fill="#900" />
        </svg>`;
  const accordionHandler = (event) => {
    event.target.classList.toggle("active");
    var accordionBody = event.target.previousElementSibling;
    if (accordionBody.style.maxHeight) {
      accordionBody.style.maxHeight = null;
      event.target.innerHTML = "Show more" + arrDown;
    } else {
      accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
      event.target.innerHTML = "Show less" + arrDown;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation(),
    { content } = location.state || {};

  var emailList = [{ email: content.email }, { email: "" }, { email: "" }];
  console.log(content, "Dynamic_product_content");
  return (
    <>
      <Header content={content} />

      <div className="mainSection">
        <Banner
          bgPath={"url(/images/HeaderDark.jpg)"}
          breadcrumb={
            <Breadcrumb
              title={content.page_title}
              listItem="Home"
              listItemPath="/"
              listLink="Products"
              listLinkPath="/products"
            />
          }
        />

        <div className="container">
          <div className="row">
            <div className="col_sm_12 col_md_5 col_lg_3">
              <SideNavbar />
            </div>

            <div className="col_sm_12 col_md_7 col_lg_9">
              <section className="page__section">
                <h3 className="page__section--heading text__align--left">
                  About {content.page_title}
                </h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.default_content.html,
                  }}
                  className="reset-props"
                ></div>
              </section>

              <section className="page__section p_0">
                <div className="accordion accordion--show-more m_0">
                  <div className="accordion__item">
                    <div className="accordion__item--body">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content.hidden_content.html,
                        }}
                        className="reset-props"
                      ></div>
                    </div>
                    <button
                      className="accordion__item--btn"
                      onClick={accordionHandler}
                    >
                      Show more <ArrowDown />
                    </button>
                  </div>
                </div>
              </section>

              <section className="page__section bg--light page__section--catalogs">
                <div className="page__section--sub-heading text__align--left">
                  {content.files_title}
                </div>
                <ul className="catalog__list reset-props">
                  {content.files
                    ? content.files.map((item) => {
                        return (
                          <>
                            {item.file ? (
                              <li className="catalog__list--item">
                                <a
                                  href={item.file}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <PDFIcon />
                                  {item.file_name}
                                </a>
                              </li>
                            ) : null}
                          </>
                        );
                      })
                    : ""}
                </ul>
              </section>

              <section className="page__section pb_0 pt_0">
                <div className="row">
                  <div className="col_sm_12">
                    <div className="product__info">
                      <div className="product__info--title">
                        {content.left_footer_title}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content.left_footer.html,
                        }}
                        className="reset-props"
                      ></div>
                    </div>
                  </div>
                  <div className="col_sm_12">
                    <div className="product__pricing">
                      <div className="product__info--title">
                        {content.right_footer_title}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content.right_footer.html,
                        }}
                        className="reset-props"
                      ></div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <Footer content={content} footerEmails={true} emailList={emailList} />
    </>
  );
};

export default DynamicPage;
