import React from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PdfComponent from "./PdfComponent";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TextEditor from "./Editor";



const ProductSection = ({ onPageChange, pageStore }) => {

    const onPdfTitleChange = (e) => {
        onPageChange(e, "files_title")
    }

    return (
        <Grid item xs={12}>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <Typography gutterBottom variant="h6" component="div">
                    Page Detail:
                </Typography>
                {/*<DeleteIcon color="error" style={{cursor:"pointer"}} onClick={()=>handleSectionRemove(index)} />*/}
            </div>
            <div className={"groupAdd"}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <label>Default Content</label>
                            <TextEditor editorValue={pageStore.default_content} onChange={(newContent) => onPageChange(newContent, "default_content")} />
                        </Grid>
                        <Grid item xs={12}>
                            <label>Hide Content</label>
                            <TextEditor editorValue={pageStore.hidden_content} onChange={(newContent) => onPageChange(newContent, "hidden_content")} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} sx={{mb: 5 }}>
                        <Grid item xs={12}>
                            <label style={{ marginTop: "15px", display: "inline-block" }}>PDF Upload</label>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField value={pageStore.files_title} onChange={(e) => { onPdfTitleChange(e) }} id="outlined-basic" fullWidth size="small" label="PDF Heading" variant="outlined" style={{ marginBottom: "6px", backgroundColor: "white" }} />
                        </Grid>
                        <PdfComponent pageStore={pageStore} onPageChange={onPageChange} />
                    </Grid>
                    <Grid container spacing={3} sx={{mb: 5 }}>
                        <Grid item xs={9}>
                            <TextField value={pageStore.left_footer_title} onChange={(e) => onPageChange(e, "left_footer_title")} id="outlined-basic" fullWidth size="small" label="Material Content Title" variant="outlined" style={{ marginBottom: "6px", backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <label>Material Content</label>
                            <TextEditor editorValue={pageStore.left_footer} onChange={(newContent) => onPageChange(newContent, "left_footer")} />
                        </Grid>
                        <Grid item xs={9}>
                            <TextField value={pageStore.right_footer_title} onChange={(e) => onPageChange(e, "right_footer_title")} id="outlined-basic" fullWidth size="small" label="Pricing Content Title" variant="outlined" style={{ marginBottom: "6px", backgroundColor: "white" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <label>Pricing Content</label>
                            <TextEditor editorValue={pageStore.right_footer} onChange={(newContent) => onPageChange(newContent, "right_footer")} />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    )
}

export default ProductSection;
