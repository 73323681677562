import React, { useEffect, useState } from "react";
import Header from "../../Common/Header/Header";
import Grid from "@mui/material/Grid";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";
import { Formik } from "formik";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";

// import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";

import axios from "../../../Services/axios";
import { Field } from "formik";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ProfileData = () => {
  const [profile, setProfile] = useState(null);
  const [imgBase64, setImgBase64] = useState("");
  const [name, setName] = useState("");
  // eslint-disable-next-line
  const [value, setValue] = React.useState(dayjs(new Date()));

  const generateBase64Img = (img) => {
    setImgBase64(img);
  };
  useEffect(() => {
    axios
      .get("/rmpt/user/get/profile")

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          setProfile(res.data);
          setValue(res.data.updatedAt);
          // if (res.data.other_details !== null) {
          //   window.open("/form-status", "_self");
          //   return 0;
          // }
          if (res.data.other_details && res.data.other_details.signature) {
            const canvas = document.getElementById("canvasComponent");
            const ctx = canvas.getContext("2d");

            var image = new Image();
            image.onload = () => {
              ctx.drawImage(image, 0, 0);
            };
            setImgBase64(res.data.other_details.signature);
            image.src = res.data.other_details.signature;
          }
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      })
      .catch((error) => {})
      .then(() => {});
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = React.useState(false);
  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  function createSign() {
    // sign.innerText = name;
    let canvasTxt = document.getElementById("canvasComponent").getContext("2d");
    canvasTxt.reset();

    canvasTxt.font = "30px cursive";
    // canvasTxt.canvas.width = canvasTxt.measureText(name).width;
    // canvasTxt.canvas.height = 68;
    canvasTxt.fillText(name, 0, 40);
    generateBase64Img(canvasTxt.canvas.toDataURL());
    setName("");
    setOpen(false);
  }

  return (
    <>
      <Header themeWhite={true} contactBtn={true} bgLight={true} />
      <div className="mainSection mainPortal">
        <section className="page__section">
          <div className="container">
            <div className="form__container">
              <div className="form__steps">
                <div class="form__steps--item active">
                  <div class="form__steps--item-circle"></div>
                  <div class="form__steps--item-name">Profile Data Sheet</div>
                </div>
                <div class="form__steps--item">
                  <div class="form__steps--item-circle"></div>
                  <div class="form__steps--item-name">Sample Calendar</div>
                </div>
              </div>
              <Formik
                initialValues={{
                  customer_classification: {
                    general_industry:
                      profile &&
                      profile.other_details &&
                      profile.other_details.customer_classification &&
                      profile.other_details.customer_classification
                        .general_industry
                        ? profile.other_details.customer_classification
                            .general_industry
                        : false,
                    commercial_lab:
                      profile &&
                      profile.other_details &&
                      profile.other_details.customer_classification &&
                      profile.other_details.customer_classification
                        .commercial_lab
                        ? profile.other_details.customer_classification
                            .commercial_lab
                        : false,
                    foundry:
                      profile &&
                      profile.other_details &&
                      profile.other_details.customer_classification &&
                      profile.other_details.customer_classification.foundry
                        ? profile.other_details.customer_classification.foundry
                        : false,
                    producer:
                      profile &&
                      profile.other_details &&
                      profile.other_details.customer_classification &&
                      profile.other_details.customer_classification.producer
                        ? profile.other_details.customer_classification.producer
                        : false,
                    rnd:
                      profile &&
                      profile.other_details &&
                      profile.other_details.customer_classification &&
                      profile.other_details.customer_classification.rnd
                        ? profile.other_details.customer_classification.rnd
                        : false,
                    aerospace:
                      profile &&
                      profile.other_details &&
                      profile.other_details.customer_classification &&
                      profile.other_details.customer_classification.aerospace
                        ? profile.other_details.customer_classification
                            .aerospace
                        : false,
                    aircraft:
                      profile &&
                      profile.other_details &&
                      profile.other_details.customer_classification &&
                      profile.other_details.customer_classification.aircraft
                        ? profile.other_details.customer_classification.aircraft
                        : false,
                    government_agency:
                      profile &&
                      profile.other_details &&
                      profile.other_details.customer_classification &&
                      profile.other_details.customer_classification
                        .government_agency
                        ? profile.other_details.customer_classification
                            .government_agency
                        : false,
                    government_lab:
                      profile &&
                      profile.other_details &&
                      profile.other_details.customer_classification &&
                      profile.other_details.customer_classification
                        .government_lab
                        ? profile.other_details.customer_classification
                            .government_lab
                        : false,
                    government_contractor:
                      profile &&
                      profile.other_details &&
                      profile.other_details.customer_classification &&
                      profile.other_details.customer_classification
                        .government_contractor
                        ? profile.other_details.customer_classification
                            .government_contractor
                        : false,
                    academia:
                      profile &&
                      profile.other_details &&
                      profile.other_details.customer_classification &&
                      profile.other_details.customer_classification.academia
                        ? profile.other_details.customer_classification.academia
                        : false,
                    other:
                      profile &&
                      profile.other_details &&
                      profile.other_details.customer_classification &&
                      profile.other_details.customer_classification.other
                        ? profile.other_details.customer_classification.other
                        : "",
                  },
                  methods_of_analysis: {
                    other:
                      profile &&
                      profile.other_details &&
                      profile.other_details.methods_of_analysis &&
                      profile.other_details.methods_of_analysis.other
                        ? profile.other_details.methods_of_analysis.other
                        : "",
                    aes:
                      profile &&
                      profile.other_details &&
                      profile.other_details.methods_of_analysis &&
                      profile.other_details.methods_of_analysis.aes
                        ? profile.other_details.methods_of_analysis.aes
                        : false,
                    xrf:
                      profile &&
                      profile.other_details &&
                      profile.other_details.methods_of_analysis &&
                      profile.other_details.methods_of_analysis.xrf
                        ? profile.other_details.methods_of_analysis.xrf
                        : false,
                    icp:
                      profile &&
                      profile.other_details &&
                      profile.other_details.methods_of_analysis &&
                      profile.other_details.methods_of_analysis.icp
                        ? profile.other_details.methods_of_analysis.icp
                        : false,
                    gds:
                      profile &&
                      profile.other_details &&
                      profile.other_details.methods_of_analysis &&
                      profile.other_details.methods_of_analysis.gds
                        ? profile.other_details.methods_of_analysis.gds
                        : false,
                    aa:
                      profile &&
                      profile.other_details &&
                      profile.other_details.methods_of_analysis &&
                      profile.other_details.methods_of_analysis.aa
                        ? profile.other_details.methods_of_analysis.aa
                        : false,
                    dcp:
                      profile &&
                      profile.other_details &&
                      profile.other_details.methods_of_analysis &&
                      profile.other_details.methods_of_analysis.dcp
                        ? profile.other_details.methods_of_analysis.dcp
                        : false,
                    combustion:
                      profile &&
                      profile.other_details &&
                      profile.other_details.methods_of_analysis &&
                      profile.other_details.methods_of_analysis.combustion
                        ? profile.other_details.methods_of_analysis.combustion
                        : false,
                    inert_gas_fusion:
                      profile &&
                      profile.other_details &&
                      profile.other_details.methods_of_analysis &&
                      profile.other_details.methods_of_analysis.inert_gas_fusion
                        ? profile.other_details.methods_of_analysis
                            .inert_gas_fusion
                        : false,
                    classical_wet_chemistry:
                      profile &&
                      profile.other_details &&
                      profile.other_details.methods_of_analysis &&
                      profile.other_details.methods_of_analysis
                        .classical_wet_chemistry
                        ? profile.other_details.methods_of_analysis
                            .classical_wet_chemistry
                        : false,
                  },
                  instrument_brand: {
                    other:
                      profile &&
                      profile.other_details &&
                      profile.other_details.instrument_brand &&
                      profile.other_details.instrument_brand.other
                        ? profile.other_details.instrument_brand.other
                        : "",
                    thermofisher_arl:
                      profile &&
                      profile.other_details &&
                      profile.other_details.instrument_brand &&
                      profile.other_details.instrument_brand.thermofisher_arl
                        ? profile.other_details.instrument_brand
                            .thermofisher_arl
                        : false,

                    spectro:
                      profile &&
                      profile.other_details &&
                      profile.other_details.instrument_brand &&
                      profile.other_details.instrument_brand.spectro
                        ? profile.other_details.instrument_brand.spectro
                        : false,
                    oblf:
                      profile &&
                      profile.other_details &&
                      profile.other_details.instrument_brand &&
                      profile.other_details.instrument_brand.oblf
                        ? profile.other_details.instrument_brand.oblf
                        : false,
                    panalytical_philips:
                      profile &&
                      profile.other_details &&
                      profile.other_details.instrument_brand &&
                      profile.other_details.instrument_brand.panalytical_philips
                        ? profile.other_details.instrument_brand
                            .panalytical_philips
                        : false,
                    brucker_siemens:
                      profile &&
                      profile.other_details &&
                      profile.other_details.instrument_brand &&
                      profile.other_details.instrument_brand.brucker_siemens
                        ? profile.other_details.instrument_brand.brucker_siemens
                        : false,
                    rigaku:
                      profile &&
                      profile.other_details &&
                      profile.other_details.instrument_brand &&
                      profile.other_details.instrument_brand.rigaku
                        ? profile.other_details.instrument_brand.rigaku
                        : false,
                    tja:
                      profile &&
                      profile.other_details &&
                      profile.other_details.instrument_brand &&
                      profile.other_details.instrument_brand.tja
                        ? profile.other_details.instrument_brand.tja
                        : false,
                    angstorm:
                      profile &&
                      profile.other_details &&
                      profile.other_details.instrument_brand &&
                      profile.other_details.instrument_brand.angstorm
                        ? profile.other_details.instrument_brand.angstorm
                        : false,
                    baird:
                      profile &&
                      profile.other_details &&
                      profile.other_details.instrument_brand &&
                      profile.other_details.instrument_brand.baird
                        ? profile.other_details.instrument_brand.baird
                        : false,
                    perkin_elmer:
                      profile &&
                      profile.other_details &&
                      profile.other_details.instrument_brand &&
                      profile.other_details.instrument_brand.perkin_elmer
                        ? profile.other_details.instrument_brand.perkin_elmer
                        : false,
                    vg:
                      profile &&
                      profile.other_details &&
                      profile.other_details.instrument_brand &&
                      profile.other_details.instrument_brand.vg
                        ? profile.other_details.instrument_brand.vg
                        : false,
                    leco:
                      profile &&
                      profile.other_details &&
                      profile.other_details.instrument_brand &&
                      profile.other_details.instrument_brand.leco
                        ? profile.other_details.instrument_brand.leco
                        : false,
                    metorex_outokumpu:
                      profile &&
                      profile.other_details &&
                      profile.other_details.instrument_brand &&
                      profile.other_details.instrument_brand.metorex_outokumpu
                        ? profile.other_details.instrument_brand
                            .metorex_outokumpu
                        : false,
                    oxford:
                      profile &&
                      profile.other_details &&
                      profile.other_details.instrument_brand &&
                      profile.other_details.instrument_brand.oxford
                        ? profile.other_details.instrument_brand.oxford
                        : false,
                    hitachi:
                      profile &&
                      profile.other_details &&
                      profile.other_details.instrument_brand &&
                      profile.other_details.instrument_brand.hitachi
                        ? profile.other_details.instrument_brand.hitachi
                        : false,
                  },
                  specific_area_0f_interest: {
                    carbon_low_alloy_steels:
                      profile &&
                      profile.other_details &&
                      profile.other_details.specific_area_0f_interest &&
                      profile.other_details.specific_area_0f_interest
                        .carbon_low_alloy_steels
                        ? profile.other_details.specific_area_0f_interest
                            .carbon_low_alloy_steels
                        : false,
                    tool_steels:
                      profile &&
                      profile.other_details &&
                      profile.other_details.specific_area_0f_interest &&
                      profile.other_details.specific_area_0f_interest
                        .tool_steels
                        ? profile.other_details.specific_area_0f_interest
                            .tool_steels
                        : false,
                    stainless_high_temperature:
                      profile &&
                      profile.other_details &&
                      profile.other_details.specific_area_0f_interest &&
                      profile.other_details.specific_area_0f_interest
                        .stainless_high_temperature
                        ? profile.other_details.specific_area_0f_interest
                            .stainless_high_temperature
                        : false,

                    nickel_cobalt_alloys:
                      profile &&
                      profile.other_details &&
                      profile.other_details.specific_area_0f_interest &&
                      profile.other_details.specific_area_0f_interest
                        .nickel_cobalt_alloys
                        ? profile.other_details.specific_area_0f_interest
                            .nickel_cobalt_alloys
                        : false,

                    copper_brass_bronze_alloys:
                      profile &&
                      profile.other_details &&
                      profile.other_details.specific_area_0f_interest &&
                      profile.other_details.specific_area_0f_interest
                        .copper_brass_bronze_alloys
                        ? profile.other_details.specific_area_0f_interest
                            .copper_brass_bronze_alloys
                        : false,
                    titanium_alloys:
                      profile &&
                      profile.other_details &&
                      profile.other_details.specific_area_0f_interest &&
                      profile.other_details.specific_area_0f_interest
                        .titanium_alloys
                        ? profile.other_details.specific_area_0f_interest
                            .titanium_alloys
                        : false,
                    cast_aluminium_wrought_aluminium_alloys:
                      profile &&
                      profile.other_details &&
                      profile.other_details.specific_area_0f_interest &&
                      profile.other_details.specific_area_0f_interest
                        .cast_aluminium_wrought_aluminium_alloys
                        ? profile.other_details.specific_area_0f_interest
                            .cast_aluminium_wrought_aluminium_alloys
                        : false,
                    other:
                      profile &&
                      profile.other_details &&
                      profile.other_details.specific_area_0f_interest &&
                      profile.other_details.specific_area_0f_interest.other
                        ? profile.other_details.specific_area_0f_interest.other
                        : "",
                  },
                  additional_comments:
                    profile &&
                    profile.other_details &&
                    profile.other_details.additional_comments
                      ? profile &&
                        profile.other_details &&
                        profile.other_details.additional_comments
                      : "",
                  date:
                    profile &&
                    profile.other_details &&
                    profile.other_details.date
                      ? new Date(profile.other_details.date)
                      : new Date(),
                }}
                // validationSchema={
                //   availableLink || editableLink
                //     ? registerSchemaWithLink
                //     : registerSchema
                // }
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  let obj = {
                    sendMail: true,
                    other_details: {
                      customer_classification: values.customer_classification,
                      methods_of_analysis: values.methods_of_analysis,
                      instrument_brand: values.instrument_brand,
                      specific_area_0f_interest:
                        values.specific_area_0f_interest,
                      additional_comments: values.additional_comments,
                      signature: imgBase64,
                      date: new Date(values.date),
                    },
                  };
                  axios
                    .post("/rmpt/user/update/profile", obj)

                    .then((response) => {
                      let res = JSON.parse(response.data);
                      if (res.status === 200) {
                        setSubmitting(false);
                        Swal.fire({
                          icon: "success",
                          position: "center",

                          title: res.message,
                          showConfirmButton: true,
                        }).then(() => {
                          window.open("/sample-calender", "_self");
                        });
                      } else {
                        Swal.fire({
                          position: "center",
                          icon: "error",
                          title: res.message,
                          showConfirmButton: true,
                          // timer: 1500,
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch((error) => {
                      setSubmitting(false);
                    })
                    .then(() => {
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  resetForm,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit} className="form form__user">
                    <h1 className="form__title">
                      Please checkmark your requirements
                    </h1>
                    <h2 className="form__subtitle mb_0">
                      Customer Classification
                    </h2>
                    <div className="form__section flex flex--wrap">
                      <label className="checkbox-container">
                        General Industry
                        <Field
                          name="customer_classification.general_industry"
                          type="checkbox"
                          className="form-checkbox"
                        />
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Commercial Lab
                        <Field
                          name="customer_classification.commercial_lab"
                          type="checkbox"
                          className="form-checkbox"
                        />
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Foundry
                        <Field
                          name="customer_classification.foundry"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Producer
                        <Field
                          name="customer_classification.producer"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        R & D
                        <Field
                          name="customer_classification.rnd"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Aerospace
                        <Field
                          name="customer_classification.aerospace"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Aircraft
                        <Field
                          name="customer_classification.aircraft"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Government Agency
                        <Field
                          name="customer_classification.government_agency"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Government Lab
                        <Field
                          name="customer_classification.government_lab"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Government Contractor
                        <Field
                          name="customer_classification.government_contractor"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Academia
                        <Field
                          name="customer_classification.academia"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <div className="form__group--flex-full mb_0">
                      <div className="form__group">
                        <TextField
                          name="customer_classification.other"
                          label="Other"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.customer_classification.other}
                          size="small"
                          fullWidth
                        />
                        {/* <input required type="text" className="form__input" placeholder='Other' /> */}
                      </div>
                    </div>

                    <h2 className="form__subtitle">Methods of Analysis</h2>
                    <div className="form__section flex flex--wrap">
                      <label className="checkbox-container">
                        AES
                        <Field
                          name="methods_of_analysis.aes"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        XRF
                        <Field
                          name="methods_of_analysis.xrf"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        ICP
                        <Field
                          name="methods_of_analysis.icp"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        GDS
                        <Field
                          name="methods_of_analysis.gds"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        AA
                        <Field
                          name="methods_of_analysis.aa"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        DCP
                        <Field
                          name="methods_of_analysis.dcp"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Combustion
                        <Field
                          name="methods_of_analysis.combustion"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Inert Gas Fusion
                        <Field
                          name="methods_of_analysis.inert_gas_fusion"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Classical Wet Chemistry
                        <Field
                          name="methods_of_analysis.classical_wet_chemistry"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <div className="form__group--flex-full mb_0">
                      <div className="form__group">
                        <TextField
                          name="methods_of_analysis.other"
                          label="Other"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.methods_of_analysis.other}
                          size="small"
                          fullWidth
                        />
                        {/* <input required type="text" className="form__input" placeholder='Other' /> */}
                      </div>
                    </div>

                    <h2 className="form__subtitle">Instrument Brand's</h2>
                    <div className="form__section flex flex--wrap">
                      <label className="checkbox-container">
                        Thermofisher (ARL)
                        <Field
                          name="instrument_brand.thermofisher_arl"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Spectro
                        <Field
                          name="instrument_brand.spectro"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        OBLF
                        <Field
                          name="instrument_brand.oblf"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Panalytical (Philips)
                        <Field
                          name="instrument_brand.panalytical_philips"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Brucker (Siemens)
                        <Field
                          name="instrument_brand.brucker_siemens"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Rigaku
                        <Field
                          name="instrument_brand.rigaku"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        TJA
                        <Field
                          name="instrument_brand.tja"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Angstrom
                        <Field
                          name="instrument_brand.angstorm"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Baird
                        <Field
                          name="instrument_brand.baird"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Perkin Elmer
                        <Field
                          name="instrument_brand.perkin_elmer"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        VG
                        <Field
                          name="instrument_brand.vg"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>
                      <label className="checkbox-container">
                        Leco
                        <Field
                          name="instrument_brand.leco"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>
                      <label className="checkbox-container">
                        Metorex (Outokumpu)
                        <Field
                          name="instrument_brand.metorex_outokumpu"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>
                      <label className="checkbox-container">
                        Oxford
                        <Field
                          name="instrument_brand.oxford"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>
                      <label className="checkbox-container">
                        Hitachi
                        <Field
                          name="instrument_brand.hitachi"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <div className="form__group--flex-full mb_0">
                      <div className="form__group">
                        <TextField
                          name="instrument_brand.other"
                          label="Other"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.instrument_brand.other}
                          size="small"
                          fullWidth
                        />
                        {/* <input required type="text" className="form__input" placeholder='Other' /> */}
                      </div>
                    </div>

                    <h2 className="form__subtitle">
                      Specific Area's of Interest
                    </h2>
                    <div className="form__section flex flex--wrap">
                      <label className="checkbox-container">
                        Carbon / Low-Alloy Steels
                        <Field
                          name="specific_area_0f_interest.carbon_low_alloy_steels"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Tool Steels
                        <Field
                          name="specific_area_0f_interest.tool_steels"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Stainless / High Temperature
                        <Field
                          name="specific_area_0f_interest.stainless_high_temperature"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Nickel / Cobalt Alloys
                        <Field
                          name="specific_area_0f_interest.nickel_cobalt_alloys"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Copper / Brass / Bronze Alloys
                        <Field
                          name="specific_area_0f_interest.copper_brass_bronze_alloys"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Titanium Alloys
                        <Field
                          name="specific_area_0f_interest.titanium_alloys"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Cast Aluminum / Wrought Aluminum Alloys
                        <Field
                          name="specific_area_0f_interest.cast_aluminium_wrought_aluminium_alloys"
                          type="checkbox"
                          className="form-checkbox"
                        />{" "}
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <div className="form__group--flex-full mb_0">
                      <div className="form__group">
                        <TextField
                          name="specific_area_0f_interest.other"
                          label="Other"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.specific_area_0f_interest.other}
                          size="small"
                          fullWidth
                        />
                        {/* <input required type="text" className="form__input" placeholder='Other' /> */}
                      </div>
                    </div>

                    <div className="form__group--flex mb_0 mt_10">
                      <div className="form__group">
                        <TextField
                          label="Additional Comments"
                          size="small"
                          fullWidth
                          sx={{ height: "100%" }}
                          className="form__textarea"
                          name="additional_comments"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.additional_comments}
                          type="text"
                          placeholder="Your comments here"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {/* <label className="form__label">
                          Additional Comments
                        </label> */}
                        {/* <textarea
                          name="additionalComments"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.additionalComments}
                          type="text"
                          className="form__input"
                          placeholder="Your comments here"
                        ></textarea> */}
                      </div>
                      <div className="form__group">
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            {/* <label className="form__label">Date</label> */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDatePicker
                                label="Date"
                                inputFormat="MM/DD/YYYY"
                                value={values.date}
                                onChange={(e) => {
                                  setFieldValue("date", e);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} fullWidth />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </div>
                    </div>

                    <div className="form__group--flex">
                      <div className="form__group">
                        <label className="form__label">Sign</label>
                        <button
                          className="btn btn--gray btn--sign"
                          onClick={(e) => handleOpen(e)}
                        >
                          Add Signature
                        </button>
                        {/* <canvas id="canvasComponent" /> */}
                        {imgBase64.length > 0 ? (
                          <canvas id="canvasComponent" />
                        ) : (
                          //   <img
                          //     className="ml-5 mt-5"
                          //     id="imageComponent"
                          //     src={imgBase64}
                          //     alt=""
                          //   />
                          <canvas
                            id="canvasComponent"
                            style={{ height: "0" }}
                          />
                        )}
                        {/* <div style={{marginTop:"5px",display:"flex"}}>
                            <Button variant="contained" component="label" className="btn btn--gray">
                                Choose files
                                <input hidden accept="image/*" multiple type="file" />
                            </Button>
                        </div> */}
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          id="signature-modal"
                        >
                          <Box sx={style}>
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              Please enter your name signature
                            </Typography>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              <div className="form__group--flex-full mb_0">
                                <div className="form__group">
                                  <input
                                    value={name}
                                    onChange={(e) => {
                                      setName(e.target.value);
                                    }}
                                    type="text"
                                    id="name"
                                    className="form__input"
                                  />
                                </div>
                              </div>

                              <div className="form__group--flex-full mb_0">
                                <div className="form__group">
                                  <button
                                    value="submit"
                                    onClick={() => {
                                      createSign();
                                    }}
                                    className="btn btn--gray"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>

                              {/* <div>
                                <p id="sign"></p>
                              </div> */}
                            </Typography>
                          </Box>
                        </Modal>
                      </div>
                    </div>

                    <div className="form__group form__group--btn flex justifyspace--between">
                      <button
                        type="submit"
                        className="btn btn--primary"
                        // onClick={() => window.open("/sample-calender", "_self")}
                      >
                        Save & Next
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          resetForm();
                          let canvasTxt = document
                            .getElementById("canvasComponent")
                            .getContext("2d");
                          canvasTxt.reset();
                          setImgBase64("");
                        }}
                        className="btn btn--transparent btn--reset"
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProfileData;
