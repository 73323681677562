import React from "react";
import DownloadIcon from "../../Icon/Download";
import "./certificateSidebar.scss";

const Sidebar = ({ onChangeInput, onChangeSelect, certificates }) => {
  return (
    <>
      <div className="sidebar">
        <div className="form__group">
          <label className="form__label title--h4">Search RMs</label>
          <input
            type="search"
            className="form__search"
            placeholder="Search"
            onChange={onChangeInput}
          />
        </div>
        <div className="form__group">
          <label className="form__label title--h4">Search RMs</label>
          <select className="form__select" onChange={onChangeSelect}>
            <option>Select Category</option>
            <option>Aluminum Alloys</option>
            <option>Carbon Steels</option>
            <option>Cobalt Alloys</option>
            <option>Copper Alloys</option>
            <option>Low-Alloy Steels</option>
            <option>Nickel Alloys</option>
            <option>Stainless &amp; High Temp. Steels</option>
            <option>Titanium Alloys</option>
            <option>Tool Steels</option>
          </select>
        </div>
        <div className="sidebar__list">
          <h4 className="title--h4">Recently Added RMs</h4>
          <ul className="sidebar__list--items">
            {certificates && certificates.length > 0
              ? certificates.map((listItem, index) => {
                  if (index < 12) {
                    return (
                      <>
                        <li key={index} className="sidebar__list--item">
                          <div className="list--title flex justifyspace--between alignitems--center mb_10">
                            <h6 className="title--h6">{listItem.name}</h6>
                            <a
                              href={listItem.file}
                              className="btn--download-icon"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <DownloadIcon />
                            </a>
                          </div>
                          <div className="list--description">
                            <p>{listItem.content}</p>
                          </div>
                        </li>
                      </>
                    );
                  } else {
                    return null;
                  }
                })
              : null}
            {/* <li className='sidebar__list--item'>
                            <div className='list--title flex justifyspace--between alignitems--center mb_10'>
                                <h6 className='title--h6'>RM 1040-B</h6>
                                <button className='btn--download-icon'>
                                    <DownloadIcon />
                                </button>
                            </div>
                            <div className='list--description'>
                                <p>AISI 1040, Carbon Steel, UNS G10400</p>
                            </div>
                        </li>
                        <li className='sidebar__list--item'>
                            <div className='list--title flex justifyspace--between alignitems--center mb_10'>
                                <h6 className='title--h6'>RM 1040-B</h6>
                                <button className='btn--download-icon'>
                                    <DownloadIcon />
                                </button>
                            </div>
                            <div className='list--description'>
                                <p>AISI 1040, Carbon Steel, UNS G10400</p>
                            </div>
                        </li>
                        <li className='sidebar__list--item'>
                            <div className='list--title flex justifyspace--between alignitems--center mb_10'>
                                <h6 className='title--h6'>RM 1040-B</h6>
                                <button className='btn--download-icon'>
                                    <DownloadIcon />
                                </button>
                            </div>
                            <div className='list--description'>
                                <p>AISI 1040, Carbon Steel, UNS G10400</p>
                            </div>
                        </li>
                        <li className='sidebar__list--item'>
                            <div className='list--title flex justifyspace--between alignitems--center mb_10'>
                                <h6 className='title--h6'>RM 1040-B</h6>
                                <button className='btn--download-icon'>
                                    <DownloadIcon />
                                </button>
                            </div>
                            <div className='list--description'>
                                <p>AISI 1040, Carbon Steel, UNS G10400</p>
                            </div>
                        </li>
                        <li className='sidebar__list--item'>
                            <div className='list--title flex justifyspace--between alignitems--center mb_10'>
                                <h6 className='title--h6'>RM 1040-B</h6>
                                <button className='btn--download-icon'>
                                    <DownloadIcon />
                                </button>
                            </div>
                            <div className='list--description'>
                                <p>AISI 1040, Carbon Steel, UNS G10400</p>
                            </div>
                        </li>
                        <li className='sidebar__list--item'>
                            <div className='list--title flex justifyspace--between alignitems--center mb_10'>
                                <h6 className='title--h6'>RM 1040-B</h6>
                                <button className='btn--download-icon'>
                                    <DownloadIcon />
                                </button>
                            </div>
                            <div className='list--description'>
                                <p>AISI 1040, Carbon Steel, UNS G10400</p>
                            </div>
                        </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
