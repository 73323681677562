import React from "react";
import { NavLink } from 'react-router-dom';
import Logo from "../../Images/logo1.png";

const CustomerRegister = () => {

    return (
        <>
            <div className="mainSection customerPortal">
                <section className="page__section">
                    <div className="container">
                        <form className="form">
                            {/* Logo */}
                            <div className='form__brand'>
                                <NavLink to='/'>
                                    <img className='form__logo' src={Logo} alt='Logo | Rocky Mountain Reference Materials, LLC' width="120px" />
                                </NavLink>
                            </div>

                            <div className='form__title'>Sign Up</div>

                            <div className='form__group'>
                                <label className='form__label'>Name</label>
                                <div className='form__input--group'>
                                    <input required type="text" className="form__input" placeholder='Enter Name' name='name' />
                                </div>
                            </div>

                            <div className='form__group'>
                                <label className='form__label'>Email Id</label>
                                <div className='form__input--group'>
                                    <input required type="email" className="form__input" placeholder='Enter Email' name='email' />
                                </div>
                            </div>

                            <div className='form__group'>
                                <label className='form__label'>Password</label>
                                <div className='form__input--group'>
                                    <input required type="password" className="form__input" placeholder='Enter Password' name='password' />
                                    <div className="form__input--icon">
                                        <button><img src="/images/gray-eye.svg" alt="Eye" width="18px" /></button>
                                    </div>
                                </div>
                            </div>

                            <div className='form__group'>
                                <label className='form__label'>Confirm Password</label>
                                <div className='form__input--group'>
                                    <input required type="password" className="form__input" placeholder='Confirm Password' name='password' />
                                    <div className="form__input--icon">
                                        <button><img src="/images/gray-eye.svg" alt="Eye" width="18px" /></button>
                                    </div>
                                </div>
                            </div>

                            <div className='form__group'>
                                <button type="submit" className="btn btn--red">Create Account</button>
                            </div>
                            
                            <div className='form__group mt_0'>
                                <div className='form__action--group'>
                                    Already have an account?&nbsp;<NavLink to='/customer-login' className="form__link">Sign In</NavLink>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </>
    )
}

export default CustomerRegister;

