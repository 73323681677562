import React , { useEffect } from "react";
import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';
import Banner from '../../Common/Banner/Banner';
import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';
import SideNavbar from '../../Templates/SideNavbar/ServiceSideNavbar';
import ArrowDown from '../../Icon/ArrowDown';

const RMCP = () => {

    var arrDown = 
        `<svg xmlns="http://www.w3.org/2000/svg" width="11.987" height="5.986" viewBox="0 0 11.987 5.986">
            <path id="arrow-ios-downward-outline" d="M13.482,19.482a.855.855,0,0,1-.547-.2L7.807,15.012A.856.856,0,0,1,8.9,13.7l4.581,3.829,4.581-3.692a.876.876,0,1,1,1.086,1.376l-5.128,4.128A.855.855,0,0,1,13.482,19.482Z" transform="translate(-7.498 -13.498)" fill="#900" />
        </svg>`
    ;

    const accordionHandler = (event) => {
        event.target.classList.toggle('active');
        var accordionBody = event.target.previousElementSibling;
        if (accordionBody.style.maxHeight) {
            accordionBody.style.maxHeight = null;
            event.target.innerHTML = "Show more" + arrDown;
        } else {
            accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
            event.target.innerHTML = "Show less" + arrDown;
        }
    }

    useEffect(() => {
        window.scrollTo(0,0);
    },[]);

    var emailList = [
        {email: 'RMCP@RMRMs.com'},
        {email: ''},
        {email: ''},
    ]
    
    return(
        <>
            <Header />
            <div className='mainSection'>

                <Banner bgPath={'url(/images/HeaderDark.jpg)'} 
                    breadcrumb={<Breadcrumb title='RMCP - RM Production' listItem='Home' listItemPath='/' listLink='Services' listLinkPath='/services' />}
                />

                <div className='container'>
                    <div className='row'>
                        <div className='col_sm_12 col_md_5 col_lg_3'>
                            <SideNavbar/>
                        </div>

                        <div className='col_sm_12 col_md_7 col_lg_9'>
                            <section className='page__section'>
                                <h3 className='page__section--heading text__align--left'>Rocky Mountain Cooperative Program - Program Protocol</h3>
                                <h4 className='page__section--sub-heading text__align--left'>Scope</h4>
                                <p className='page__section--para'>The purpose and scope of this document is to define certain terminology, procedural techniques, criteria and protocol to be utilized by members of the ongoing "Rocky Mountain Cooperative Program" (RMCP) conducted by Rocky Mountain Reference Materials International as it applies to the development, certification and production of certified reference materials.</p>
                            
                                <h4 className='page__section--sub-heading text__align--left'>Definitions</h4>
                                <p className='page__section--para'>The International Standards Organization (ISO) definitions, expressed in ISO Guide 30 lists the following:</p>
                                <div className="product__info">
                                    <ul className="product__info--list">
                                        <li className="product__info--list-item">Thick - General purpose size (~38mm or ~31mm diameter x ~19mm thick) intended for use in consumptive techniques such as OE and GD Spectroscopy.</li>
                                        <li className="product__info--list-item">Thin - X-Ray sizes (~38mm or ~31mm diameter x ~3mm thin discs or ~8mm thick discs) designed specifically for use with portable and lab-based XRF Instruments.</li>
                                        <li className="product__info--list-item">Chips - Bottles containing ~100 g or 65 g of uniformly sized (sieved) chips for use with combustion techniques and dissolution techniques such as ICP, DCP, and AA Spectroscopy.</li>
                                    </ul>
                                </div>
                                <h4 className='page__section--sub-heading text__align--left'>Rocky Mountain Cooperative Program Design</h4>
                                <p className='page__section--para'>ASTM Standard E691 applies to Inter-laboratory studies to "Determine the Precision of a Single Test Method". This standard is also a well thought out and logical plan for conducting an Inter-laboratory program involving multiple techniques. Therefore, the planning, conducting, analyzing, protocol and treatment of data resulting from the RMRMs-RMCP is performed utilizing the guidelines established in ASTM E691 where applicable.</p>
                                <h4 className='page__section--sub-heading text__align--left'>Selection of Participating Laboratories</h4>
                                <p className='page__section--para'>RMCP membership is totally voluntary and consists of approximately 50% actual producers of the materials being analyzed, 40% technically qualified commercial laboratories and 10% representing general industry and academia. All volunteer cooperative labs are invited to participate on each and every candidate material they are deemed competent to analyze. All cooperative labs evaluate only the materials they agree to.</p>
                            </section>

                            <section className='page__section p_0'>
                                <div className='accordion accordion--show-more m_0'>
                                    <div className='accordion__item'>
                                        <div className='accordion__item--body'>
                                            <h4 className='page__section--sub-heading text__align--left'>Source Materials
                                            </h4>
                                            <p className='page__section--para'>Materials are typically selected from standard industry stocks so as to emulate material types found in the real-life circumstances dealt with by users in their daily Rocky Mountain endeavors. Whenever possible, program materials are secured in a wrought condition. Other methods of manufacture such as continuous cast, sand or static castings are utilized as a last resort and only in the case of those materials being unavailable in wrought condition.</p>

                                            <h4 className='page__section--sub-heading text__align--left'>Batch Determination
                                            </h4>
                                            <p className='page__section--para'>Since most metals are available in bar stock or rod form, a typical "batch" or "series" is defined as a single length of material or a single bar of one continuous length.</p>

                                            <h4 className='page__section--sub-heading text__align--left'>Sampling Procedure
                                            </h4>
                                            <p className='page__section--para'>The "batch" samples are taken by dividing the bar into a minimum of twelve sub-sections. A 1¼ inch solid test sample is removed from each sub-section. Each 1¼ inch test sample is further subdivided as follows: The "batch" samples are taken by dividing the bar into a minimum of twelve sub-sections. A 1¼ inch solid test sample is removed from each sub-section. Each 1¼ inch test sample is further subdivided as follows:</p>
                                            <div className="product__info">
                                                <ul className="product__info--list">
                                                    <li className="product__info--list-item">Approximately ~10 % (~⅛ in.) of the total sample is converted to chip form (~25 g) for analysis by classical wet chemistry, ICP, AA, and combustion procedures.</li>
                                                    <li className="product__info--list-item">An additional ~10 % (~⅛ in.) is converted to the form of a solid pin disk for determination of C, S, N2 and O2 by combustion or inert gas fusion procedures.</li>
                                                    <li className="product__info--list-item">The remaining 80 % (~1 in.) of each sample remains in a solid disk form for Optical Emission and X-Ray analysis where applicable.</li>
                                                </ul>
                                            </div>
                                            <p className='page__section--para'>Each member of the "Rocky Mountain Cooperative Program" receives the three sample types and is therefore responsible for a specific sub-section of the batch bar. All sub-sections and corresponding test samples are sequentially marked with matching and unique internal identification numbers so that if abnormalities occur during the RMCP process, each test sample can be traced to the original sub-section. Due to this extensive sampling procedure, homogeneity of the total batch is therefore reflected as a product of the overall statistics and the resulting certified data.</p>

                                            <h4 className='page__section--sub-heading text__align--left'>Specific Within Sample Determination of Homogeneity
                                            </h4>
                                            <p className='page__section--para'>All materials are assessed for homogeneity. Specific homogeneity testing is determined by the RMCP program management, and incorporates homogeneity test procedures as outlined in ASTM E826, NBS Handbook 91, "Experimental Statistics", as well as other established practices for determination of practical homogeneity.</p>

                                            <h4 className='page__section--sub-heading text__align--left'>Methods of Analysis
                                            </h4>
                                            <p className='page__section--para'>The RMCP "Rocky Mountain Cooperative Program" encompasses an extremely wide variety of materials. No single method would provide optimum data results. Therefore, the methods utilized are a combination of methods for classical wet chemistry, ICP, AA, Optical Emission and X-Ray spectrometric methods in addition to combustion/inert gas fusion procedures for the determinations of Carbon, Sulfur, Nitrogen and Oxygen.</p>

                                            <h4 className='page__section--sub-heading text__align--left'>Extent of Data Reported
                                            </h4>
                                            <p className='page__section--para'>It is the policy of RMCP to report data submitted by two or more labs. However, in accordance with the guidelines established by the RMCP membership protocol, final certification occurs only for those elements in which a minimum of six data points remain after final critique. Therefore, data in parentheses ( ) is not certified but provided for information only. Certified Data that was selected by the RMCP membership for additional testing is indicated by brackets [ ].</p>

                                            <h4 className='page__section--sub-heading text__align--left'>Traceability
                                            </h4>
                                            <p className='page__section--para'>Traceability to the SI through an NMI is approached in two distinct ways within the RMRMs\RMCP.</p>
                                            <div className="product__info">
                                                <ul className="product__info--list">
                                                    <li className="product__info--list-item">In the case of some matrix groups, specific SRMs are not always available. In this case, the RMCP program members voluntarily make available to the RMCP a variety of SRMs produced by (NBS\NIST), some of which have been collected over almost two decades. In addition to validating test methods and instrument performance, where possible, these resident SRMs are also used in the instrumental calibration curves for that portion of the RMCP which requires instrumental analysis. Affidavits of the RMCP member labs' SRM inventory and their applications are on file and archived in the RMRMs historical database.</li>
                                                </ul>
                                            </div>
                                            <p className='page__section--para'>The Rocky Mountain data results for the RMCP developed under this program are therefore "Traceable" to the U.S. Department of Commerce, National Institute of Standards &amp; Technology, (NIST). The specific SRMs applicable to a certification are referenced on the face of the "Certificate of Analyses" for all RMCP certified materials.</p>
                                        </div>
                                        <button className='accordion__item--btn mb_50' onClick={accordionHandler}>Show more <ArrowDown/>
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>                
            </div>
            <Footer footerEmails={true} emailList={emailList}/>
        </>
    )
}

export default RMCP;

