import React, { useEffect, useState } from "react";
import Header from "../../Common/Header/Header";
import { Formik } from "formik";
import Swal from "sweetalert2";

import * as Yup from "yup";
import axios from "../../../Services/axios";

import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import EditIcon from "../../Icon/Edit";

const MyProfileAnalyst = () => {
  const [linkData, setLinkData] = useState("");
  const [availableLink, setAvailableLink] = useState(false);
  const [editableLink, setEditableLink] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get("/rmpt/analyst/get/profile")

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          setLinkData(res.data.basic_details);
          setAvailableLink(false);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      })
      .catch((error) => {})
      .then(() => {});
  }, []);
  const [passwordValues, setPasswordValues] = React.useState({
    confirmPassword: false,
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickConfirmPassword = () => {
    setPasswordValues({
      ...passwordValues,
      confirmPassword: !passwordValues.confirmPassword,
    });
  };

  const registerSchema = Yup.object({
    companyName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid Name")
      .max(32, "Must be 32 characters or less")
      .required("Required"),
    first_name: Yup.string().required("Required"),

    telephone: Yup.string()
      .required("Required")

      .matches(
        // eslint-disable-next-line
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        "Please Enter Valid Telephone Number"
      ),
    labContactEmail: Yup.string()
      .email("Invalid email address")
      .required("Required"),
    fax: Yup.string().matches(
      /^\+?[0-9]{6,}$/,
      "Please enter valid Fax Number"
    ),
    companyWebsite: Yup.string().matches(
      // eslint-disable-next-line
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
      "Please enter valid Website"
    ),
    state: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid State")

      .required("Required"),
    city: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid City")

      .required("Required"),
    mailingAddress: Yup.string().required("Required"),
    // newPassword: Yup.string().required("Required"),
    // confirmPassword: Yup.string().required("Required"),

    zip: Yup.string().required("Required"),
  });

  const registerSchemaWithLink = Yup.object({
    companyName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid Name")
      .max(32, "Must be 32 characters or less")
      .required("Required"),
    first_name: Yup.string().required("Required"),
    telephone: Yup.string()
      .required("Required")
      .matches(
        // eslint-disable-next-line
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        "Please Enter Valid Telephone Number"
      ),
    labContactEmail: Yup.string()
      .email("Invalid email address")
      .required("Required"),
    fax: Yup.string().matches(
      /^\+?[0-9]{6,}$/,
      "Please enter valid Fax Number"
    ),
    companyWebsite: Yup.string().matches(
      // eslint-disable-next-line
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
      "Please enter valid Website"
    ),
    state: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid State")

      .required("Required"),
    city: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid City")

      .required("Required"),
    mailingAddress: Yup.string().required("Required"),
    newPassword: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Please Enter Valid Password use at least one uppercase letter, lowercase letter, number, special character with minimum 8 characters"
      ),
    confirmPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords don't match!"),
    zip: Yup.string().required("Required"),
  });
  return (
    <>
      <Header themeWhite={true} contactBtn={true} bgLight={true} />
      <div className="mainSection mainPortal">
        <section className="page__section">
          <div className="container">
            <div className="form__container">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  companyName: linkData ? linkData.company_name : "",
                  first_name: linkData ? linkData.first_name : "",
                  labContactEmail: linkData ? linkData.email : "",
                  telephone: linkData ? linkData.telephone : "",
                  fax: linkData && linkData.fax ? linkData.fax : "",
                  companyWebsite: linkData ? linkData.company_website : "",
                  mailingAddress: linkData ? linkData.address : "",
                  state: linkData ? linkData.state : "",
                  city: linkData ? linkData.city : "",
                  zip: linkData ? linkData.zip : "",
                  preferredModeOfContact: linkData
                    ? linkData.contact_preferred_method
                    : "email",
                  newPassword: "",
                  confirmPassword: "",
                }}
                validationSchema={
                  availableLink || editableLink
                    ? registerSchemaWithLink
                    : registerSchema
                }
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  if (availableLink || editableLink) {
                    let passwordObj = {
                      user_id: linkData.id,
                      password: values.newPassword,
                      company_name: values.companyName,
                      first_name: values.first_name.toString(),
                      email: values.labContactEmail,
                      telephone: values.telephone,
                      fax: values.fax,
                      company_website: values.companyWebsite,
                      address: values.mailingAddress,
                      state: values.state,
                      city: values.city,
                      zip: values.zip,
                      contact_preferred_method: values.preferredModeOfContact,
                    };
                    axios
                      .post("rmpt/user/complete/profile", passwordObj)

                      .then((response) => {
                        let res = JSON.parse(response.data);
                        if (res.status === 200) {
                          setSubmitting(false);

                          window.open("/customer-login", "_self");
                        } else {
                          Swal.fire({
                            position: "center",
                            icon: "error",
                            title: res.message,
                            showConfirmButton: true,
                            // timer: 1500,
                          });
                          setSubmitting(false);
                        }
                      })
                      .catch((error) => {
                        setSubmitting(false);
                      })
                      .then(() => {
                        setSubmitting(false);
                      });
                  } else {
                    let registerObj = {
                      basic_details: {
                        company_name: values.companyName,
                        first_name: values.first_name.toString(),
                        // email: values.labContactEmail,
                        telephone: values.telephone,
                        fax: values.fax === "" ? undefined : values.fax,
                        company_website: values.companyWebsite,
                        address: values.mailingAddress,
                        state: values.state,
                        city: values.city,
                        zip: values.zip,
                        contact_preferred_method: values.preferredModeOfContact,
                      },
                    };
                    localStorage.setItem(
                      "firstname",
                      values.first_name.toString()
                    );

                    axios
                      .post("/rmpt/analyst/update/profile", registerObj)

                      .then((response) => {
                        let res = JSON.parse(response.data);
                        if (res.status === 200) {
                          setSubmitting(false);
                          Swal.fire({
                            icon: "success",
                            position: "center",

                            title: res.message,
                            showConfirmButton: true,
                          }).then(() => {
                            window.open("/analyst-form-status", "_self");
                          });

                          resetForm();
                        } else {
                          Swal.fire({
                            position: "center",
                            icon: "error",
                            title: res.message,
                            showConfirmButton: true,
                            // timer: 1500,
                          });
                          setSubmitting(false);
                        }
                      })
                      .catch((error) => {
                        setSubmitting(false);
                      })
                      .then(() => {
                        setSubmitting(false);
                      });
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  resetForm,
                  /* and other goodies */
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="form form__user"
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <h1 className="form__title justifyspace--between mb_10">
                      My Profile{" "}
                      {availableLink || editableLink ? (
                        <button
                          type="button"
                          onClick={() => {
                            setAvailableLink(false);
                            setEditableLink(true);
                          }}
                          className="form__edit--btn"
                        >
                          <EditIcon /> Edit
                        </button>
                      ) : null}
                    </h1>
                    <div className="form__section">
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            label="Company Name"
                            id="company-name"
                            size="small"
                            name="companyName"
                            disabled={availableLink}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.companyName}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.companyName &&
                              touched.companyName &&
                              errors.companyName}
                          </div>
                        </div>
                        <div className="form__group">
                          <TextField
                            label="First/Last Name"
                            size="small"
                            // type="number"
                            name="first_name"
                            disabled={availableLink}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.first_name}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.first_name &&
                              touched.first_name &&
                              errors.first_name}
                          </div>
                        </div>
                      </div>
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            label="Lab Contact Email"
                            size="small"
                            disabled
                            name="labContactEmail"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.labContactEmail}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.labContactEmail &&
                              touched.labContactEmail &&
                              errors.labContactEmail}
                          </div>
                        </div>
                        <div className="form__group">
                          <TextField
                            label="Telephone"
                            size="small"
                            type="text"
                            name="telephone"
                            disabled={availableLink}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.telephone}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.telephone &&
                              touched.telephone &&
                              errors.telephone}
                          </div>
                        </div>
                      </div>
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            label="Fax"
                            size="small"
                            type="number"
                            name="fax"
                            disabled={availableLink}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.fax}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.fax && touched.fax && errors.fax}
                          </div>
                        </div>
                        <div className="form__group">
                          <TextField
                            label="Company Website"
                            size="small"
                            name="companyWebsite"
                            disabled={availableLink}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.companyWebsite}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.companyWebsite &&
                              touched.companyWebsite &&
                              errors.companyWebsite}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form__section">
                      <div className="form__group--flex-full">
                        <div className="form__group">
                          <TextField
                            label="Mailing Address"
                            size="small"
                            fullWidth
                            disabled={availableLink}
                            placeholder="Your complete mailing address here"
                            name="mailingAddress"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mailingAddress}
                          />
                          <div className="form__input--error">
                            {errors.mailingAddress &&
                              touched.mailingAddress &&
                              errors.mailingAddress}
                          </div>
                        </div>
                      </div>
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            label="City"
                            disabled={availableLink}
                            size="small"
                            name="city"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.city}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.city && touched.city && errors.city}
                          </div>
                        </div>
                        <div className="form__group">
                          <TextField
                            label="State"
                            size="small"
                            disabled={availableLink}
                            name="state"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.state}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.state && touched.state && errors.state}
                          </div>
                        </div>
                      </div>
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            label="Zip"
                            size="small"
                            disabled={availableLink}
                            name="zip"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.zip}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.zip && touched.zip && errors.zip}
                          </div>
                        </div>
                        <div className="form__group--flex-full mb_0">
                          <label
                            className="form__label"
                            style={{ marginTop: "-6px" }}
                          >
                            What is your preferred method of contact
                          </label>
                          <div
                            className="form__group--flex mb_0"
                            style={{ marginTop: "-10px" }}
                          >
                            <div className="form__group">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={availableLink}
                                    defaultChecked
                                    checked={
                                      values.preferredModeOfContact === "email"
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "preferredModeOfContact",
                                        "email"
                                      )
                                    }
                                  />
                                }
                                label="Email"
                              />
                            </div>
                            <div className="form__group">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={availableLink}
                                    checked={
                                      values.preferredModeOfContact === "phone"
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "preferredModeOfContact",
                                        "phone"
                                      )
                                    }
                                  />
                                }
                                label="Phone"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {availableLink || editableLink ? (
                        <div className="form__group--flex">
                          <div className="form__group">
                            <FormControl
                              variant="outlined"
                              size="small"
                              fullWidth
                            >
                              <InputLabel htmlFor="outlined-create-password">
                                Create New Password
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-create-password"
                                type={
                                  passwordValues.showPassword
                                    ? "text"
                                    : "password"
                                }
                                name="newPassword"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.newPassword}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {passwordValues.showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Create New Password"
                              />
                            </FormControl>
                            <div className="form__input--error">
                              {errors.newPassword &&
                                touched.newPassword &&
                                errors.newPassword}
                            </div>
                          </div>
                          <div className="form__group">
                            <FormControl
                              variant="outlined"
                              size="small"
                              fullWidth
                            >
                              <InputLabel htmlFor="outlined-confirm-password">
                                Confirm New Password
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-confirm-password"
                                type={
                                  passwordValues.confirmPassword
                                    ? "text"
                                    : "password"
                                }
                                name="confirmPassword"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.confirmPassword}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickConfirmPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {passwordValues.confirmPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Confirm New Password"
                              />
                            </FormControl>
                            <div className="form__input--error">
                              {errors.confirmPassword &&
                                touched.confirmPassword &&
                                errors.confirmPassword}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form__group flex justifyspace--between">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn--primary"
                        // onClick={() =>
                        //   window.open("/customer-register", "_self")
                        // }
                      >
                        Submit
                      </button>
                      {editableLink ? (
                        <button
                          onClick={() => {
                            resetForm();
                          }}
                          type="reset"
                          className="btn btn--transparent btn--reset"
                        >
                          Reset
                        </button>
                      ) : null}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default MyProfileAnalyst;
