import React , {useEffect} from 'react';
import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';
import Banner from '../../Common/Banner/Banner';
import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';
// import ContainerToggle from '../../Templates/ContainerToggle/ContainerToggle';
import SideNavbar from '../../Templates/SideNavbar/ProductSideNavbar';
import PDFIcon from '../../Icon/Pdf';
import ArrowDown from '../../Icon/ArrowDown';

const RMRMs = () => {

    var arrDown = 
        `<svg xmlns="http://www.w3.org/2000/svg" width="11.987" height="5.986" viewBox="0 0 11.987 5.986">
            <path id="arrow-ios-downward-outline" d="M13.482,19.482a.855.855,0,0,1-.547-.2L7.807,15.012A.856.856,0,0,1,8.9,13.7l4.581,3.829,4.581-3.692a.876.876,0,1,1,1.086,1.376l-5.128,4.128A.855.855,0,0,1,13.482,19.482Z" transform="translate(-7.498 -13.498)" fill="#900" />
        </svg>`
    ;

    const accordionHandler = (event) => {
        event.target.classList.toggle('active');
        var accordionBody = event.target.previousElementSibling;
        if (accordionBody.style.maxHeight) {
            accordionBody.style.maxHeight = null;
            event.target.innerHTML = "Show more" + arrDown;
        } else {
            accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
            event.target.innerHTML = "Show less" + arrDown;
        }
    }

    useEffect(() => {
        window.scrollTo(0,0);
    },[]);

    var emailList = [
        {email: 'sales@rmrms.com'},
        {email: ''},
        {email: ''},
    ]
    
    return(
        <>
            <Header />
            <div className='mainSection'>

                <Banner bgPath={'url(/images/HeaderDark.jpg)'} 
                    breadcrumb={<Breadcrumb title='RMRMs Produced Reference Materials' listItem='Home' listItemPath='/' listLink='Products' listLinkPath='/products' />}
                />

                <div className='container'>
                    <div className='row'>
                        <div className='col_sm_12 col_md_5 col_lg_3'>
                            <SideNavbar/>
                        </div>

                        <div className='col_sm_12 col_md_7 col_lg_9'>
                            <section className='page__section'>
                                <h3 className='page__section--heading text__align--left'>About RMRMs Non-Certified Reference Materials Usages</h3>
                                <p className='page__section--para'>The Rocky Mountain Reference Materials (RMRMs) Rocky Mountain Cooperative Program (RMCP) originated as and remains today the only totally "voluntary organization" in the private industry dedicated to the production and development of quality Reference Materials</p>
                                <p className='page__section--para'>The RMCP is the classic example of an industry working together for a common goal. It is comprised of wonderfully dedicated people who are experts in the analysis of metals and metal alloys.</p>
                            
                                <h4 className='page__section--sub-heading text__align--left'>Introduction</h4>
                                <p className='page__section--para'>Rocky Mountain Reference Materials (RMRMs) was founded in 2016 for the purpose of producing Reference Materials for the metals industry. Since that time, RMRMs has expanded its reference material inventory to include RMs for the following matrices: <span className='color--primary'> Carbon &amp; Low-Alloy Steels, Tool Steels, Stainless &amp; High Temperature Steels, Nickel Alloys, Cobalt Alloys, Copper, Brass &amp; Bronze Alloys, Titanium Alloys &amp; Aluminum Alloys.</span></p>
                                <p className='page__section--para'>Reference Material, RM, n - reference material with documented homogeneity that is intended for use for quality control purposes, calibration, evaluation of a calibration, or standardization, whose values may have limited traceability and for which rigorously derived uncertainty information is not mandatory.</p>
                            </section>

                            <section className='page__section p_0'>
                                <div className='accordion accordion--show-more m_0'>
                                    <div className='accordion__item'>
                                        <div className='accordion__item--body'>
                                            <h4 className='page__section--sub-heading text__align--left'>These RMs are ideally suited for use with HHXRF &amp; HHLIBS, plus other techniques, for everyday use for the following:</h4>

                                            <h4 className='page__section--sub-heading text__align--left'>Instrument Conditioning</h4>
                                            <p className='page__section--para'>For certain test methods, the equipment must be stabilized and conditioned prior to use, typically daily.  This is done with materials similar in chemical and physical properties to the analysis samples, but it is not necessary to know accurately the compositions of the materials.  It is useful to have confidence that a conditioning material is homogeneous and stable.  However, the purpose is to show the instrument is ready for calibration, and the requirements for homogeneity and stability can be relaxed relative to the calibrants and process control materials.</p>

                                            <h4 className='page__section--sub-heading text__align--left'>Statistical Process Control (SPC)</h4>
                                            <p className='page__section--para'>For efficient, high throughput in a laboratory, chemical measurement processes, i.e., test methods, must be kept under statistical control.  Perhaps the most convenient way to accomplish this control is to measure one or more materials at appropriate time intervals.  When the material(s) can be treated as a regular sample and taken through all steps of the process, the measured results easily can be used to demonstrate statistical control of the entire chemical measurement process.  Control charts are commonly used to demonstrate statistical control.  This function requires demonstration of sufficient homogeneity of a RM, but it does not require assignment of chemical and physical property values with exhaustively evaluated uncertainty estimates.</p>

                                            <h4 className='page__section--sub-heading text__align--left'>Drift Correction </h4>
                                            <p className='page__section--para'>The purpose of a drift correction RM is to provide stable, high-precision signals for the constituents of interest.  Homogeneity and stability should be demonstrated, but it is not necessary to know the values of the amounts of the constituents.  The drift correction calculations can be done in units of the measured phenomenon on which the instrumental or chemical technique is based.  One example is the count rate of fluorescent X-rays obtained under the chosen measurement conditions.</p>

                                            <h4 className='page__section--sub-heading text__align--left'>Evaluation of Matrix Influence or Spectral Interference</h4>
                                            <p className='page__section--para'>Both phenomena involve systematic effects of one constituent on another or on itself.  To evaluate the magnitude of an effect, a laboratory may require a set of materials specially prepared to have known relationships among the values of the subject constituents within the set.  That is, the value of Constituent A in Material X may be twice the value in Material Y and three times the value of Constituent A in Material Z.  There may be multiple pairs of related constituents in a set of materials, and there must be no correlations between pairs of constituents.  The known relationships allow the laboratory to calibrate influence and interference coefficients empirically or to validate coefficients determined from first principles.  An RM for evaluation of matrix influence or spectral interference should have values obtained from an independent test method or multiple methods of analysis.</p>

                                            <h4 className='page__section--sub-heading text__align--left'>Type Standardization
                                            </h4>
                                            <p className='page__section--para'>Type standardization is often described as a form of drift correction.  In fact, it is both a drift correction and a recalibration of the sensitivity of the calibration model.  Laboratories use RMs to adjust a general calibration for a specific alloy or material type.  This function requires high quality homogeneity, assigned values and uncertainty estimates fit for the purpose of accurate calibration of instrument sensitivity.</p>

                                            <h4 className='page__section--sub-heading text__align--left'>Harmonization</h4>
                                            <p className='page__section--para'>The values of an RM can be accepted by users as the best available for the specific alloy and used to make the results from the users’ test methods agree with the test method(s) used to assign the RM values.</p>

                                            <h4 className='page__section--sub-heading text__align--left'>Positive Material identification</h4>
                                            <p className='page__section--para'>Handheld X-ray fluorescence spectrometry (HHXRF) and handheld laser-induced breakdown spectrometry (HHLIBS) are frequently used for positive material identification (PMI) in which a lot of unknown material is analyzed to identify the exact alloy specification to which the metal complies.  A RM is used to validate that the spectrum identification method of the instrument can correctly identify samples of the RM alloy.  Accurate calculation of the specimen composition is of secondary consideration.  A company can reduce the risk of misidentification of alloys by having a collection of RMs available for instant confirmation of the accuracy of identification.</p>
                                        </div>

                                        <button className='accordion__item--btn' onClick={accordionHandler}>Show more <ArrowDown/>
                                        </button>
                                    </div>
                                </div>
                            </section>

                            <section className='page__section bg--light mt_50 p_3'>
                                <h4 className='page__section--sub-heading text__align--left'>Catalog Sections</h4>
                                <ul className='catalog__list'>
                                    <li className='catalog__list--item'>
                                        <a href='/pdf/CLASCat.pdf'><PDFIcon/>Carbon &amp; Low-Alloy Steels</a>
                                    </li>
                                    <li className='catalog__list--item'>
                                        <a href='/pdf/'><PDFIcon/>Tool Steels</a>
                                    </li>
                                    <li className='catalog__list--item'>
                                        <a href='/pdf/SHTSCat.pdf'><PDFIcon/>Stainless &amp; High-Temp. Steels</a>
                                    </li>
                                    <li className='catalog__list--item'>
                                        <a href='/pdf/CBBACat.pdf'><PDFIcon/>Copper, Brass &amp; Bronze Alloys</a>
                                    </li>
                                    <li className='catalog__list--item'>
                                        <a href='/pdf/NiBACat.pdf'><PDFIcon/>Nickel Alloys</a>
                                    </li>
                                    <li className='catalog__list--item'>
                                        <a href='/pdf/CoBACat.pdf'><PDFIcon/>Cobalt Alloys</a>
                                    </li>
                                    <li className='catalog__list--item'>
                                        <a href='/pdf/TiBACat.pdf'><PDFIcon/>Titanium Alloys</a>
                                    </li>
                                    <li className='catalog__list--item'>
                                        <a href='/pdf/AlBACat.pdf'><PDFIcon/>Aluminum Alloys</a>
                                    </li>
                                </ul>
                            </section>

                            <section className='page__section'>
                                <div className='row'>
                                    <div className='col_sm_12 col_md_12 col_lg_8'>
                                        <div className='product__info'>
                                            <h4 className='page__section--sub-heading text__align--left'>Material Forms and Dimensions
                                            </h4>
                                            <ul className='product__info--list'>
                                                <li className='product__info--list-item'>Thick - General purpose size (~38mm or ~31mm diameter x ~19mm thick) intended for use in consumptive techniques such as OE and GD Spectroscopy.</li>
                                                <li className='product__info--list-item'>Thin - X-Ray sizes (~38mm or ~31mm diameter x ~3mm thin discs or ~8mm thick discs) designed specifically for use with portable and lab-based XRF Instruments.</li>
                                                <li className='product__info--list-item'>Chips - Bottles containing ~100 g or 65 g of uniformly sized (sieved) chips for use with combustion techniques and dissolution techniques such as ICP, DCP, and AA Spectroscopy.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col_sm_12 col_md_12 col_lg_4'>
                                        <div className='product__pricing'>
                                            <h4 className='page__section--sub-heading text__align--left'>General Pricing</h4>
                                            <p className='page__section--para'>Please Call for Pricing : <a href='tel:(720) 943-7676'>(720) 943-7676</a></p>
                                            <ul className='product__pricing--list'>
                                                <li className='product__pricing--list-title'>
                                                    <p>Description</p>
                                                    <p>Suffix #</p>
                                                </li>
                                                <li className='product__pricing--list-item'>
                                                    <p>Thick (General)</p>
                                                    <p>-19</p>
                                                </li>
                                                <li className='product__pricing--list-item'>
                                                    <p>Thin (X-Ray)</p>
                                                    <p>-3</p>
                                                </li>
                                                <li className='product__pricing--list-item'>
                                                    <p>Chips</p>
                                                    <p>-C</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </section>
                        </div>
                    </div>
                </div>                
            </div>
            <Footer footerEmails={true} emailList={emailList}/>
        </>
    )
}

export default RMRMs;

