import React , { useEffect } from "react";
import Header from "../../../Common/Header/Header";
import Footer from "../../../Common/Footer/Footer";
import Banner from "../../../Common/Banner/Banner";
import Breadcrumb from "../../../Common/Breadcrumb/Breadcrumb";
import SideNavbar from '../../../Templates/SideNavbar/ProductSideNavbar';
import PDFIcon from "../../../Icon/Pdf";
import ArrowDown from '../../../Icon/ArrowDown';

const SuisseTpCRM = () => {

    var arrDown = 
        `<svg xmlns="http://www.w3.org/2000/svg" width="11.987" height="5.986" viewBox="0 0 11.987 5.986">
            <path id="arrow-ios-downward-outline" d="M13.482,19.482a.855.855,0,0,1-.547-.2L7.807,15.012A.856.856,0,0,1,8.9,13.7l4.581,3.829,4.581-3.692a.876.876,0,1,1,1.086,1.376l-5.128,4.128A.855.855,0,0,1,13.482,19.482Z" transform="translate(-7.498 -13.498)" fill="#900" />
        </svg>`
    ;

    const accordionHandler = (event) => {
        event.target.classList.toggle('active');
        var accordionBody = event.target.previousElementSibling;
        if (accordionBody.style.maxHeight) {
            accordionBody.style.maxHeight = null;
            event.target.innerHTML = "Show more" + arrDown;
        } else {
            accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
            event.target.innerHTML = "Show less" + arrDown;
        }
    }

    useEffect(() => {
        window.scrollTo(0,0);
    },[]);

    var emailList = [
        {email: 'sales@rmrms.com'},
        {email: ''},
        {email: ''},
    ]

    return(
        <>
            <Header />
            <div className="mainSection">

                <Banner bgPath={"url(/images/HeaderDark.jpg)"} 
                    breadcrumb={<Breadcrumb title="Suisse TP CRMs" listItem="Home" listItemPath="/" listLink="Products" listLinkPath="/products" />}
                />

                <div className='container'>
                    <div className='row'>
                        <div className='col_sm_12 col_md_5 col_lg_3'>
                            <SideNavbar/>
                        </div>

                        <div className='col_sm_12 col_md_7 col_lg_9'>                            

                            <section className="page__section">
                                <h3 className="page__section--heading text__align--left">About Suisse TP CRMs</h3>
                                <h4 className="page__section--sub-heading text__align--left">How are Aluminum Reference Materials Produced?</h4>
                                <h5 className="page__section--sub-heading text__align--left">Production of Aluminum Reference Materials</h5>
                                <p className="page__section--para ">Reference materials for the analysis of aluminum alloys by spark optical emission spectroscopy or XRF are usually produced by casting. In most cases, a continuous casting process is applied followed in some cases by hot extrusion to the final diameter. The casting process is critical since it affects the homogeneity of the reference material. Cast shops for this delicate task are carefully evaluated and selected.
                                </p> 
                                <p className="page__section--para ">Casting lots can be of varied sizes. The prices vary depending on the number of certified elements as well as on the size of the lot. When the perfect lot is casted the material needs to be certified by various methods.</p>

                                <h5 className="page__section--sub-heading text__align--left">Certification Of Aluminum Standards</h5>
                                <p className="page__section--para ">Certification analyses for our standards are done in our in-house analysis lab.  Our lab is accredited by the Swiss Authorities as a test facility for the chemical composition of metallic materials per the International Standard, <span className="color--primary">ISO/IEC 17025:STS0023</span>.  The analysis methods are based on internationally accepted analytical procedures, some developed by the Aluminum section of GDMB (GDMB Gesellschaft der Metallurgen und Bergleute e. V.).  Single element standards from ISO 17034 accredited producers are used during the analysis for calibration of the analytical instruments.  During all these procedures, traceability of the analysis back to national standards is ensured.</p>

                                <p className="page__section--para ">The analytical lab of Suisse TP regularly takes part in proficiency tests from <span className="color--primary">ISO/IEC 17043</span> accredited bodies. These programs ascertain and underlie the high quality of our analyses.</p>

                                <p className="page__section--para ">The values listed in the analysis certificate of each certified reference material, accompanied with each delivery, are based on the results of <span className="color--primary">at least 2 independent analysis method</span> and multiple analyses performed with each method.</p>

                                <p className="page__section--para">Suisse TP is also able to <span className="color--primary">certify customer materials</span>. If a homogeneous rod in a suitable diameter and lengths of a special customer alloy is available, Suisse TP can analyze homogeneity and the concentration values of all or selected elements and issue a certificate.  Ask for an offer for such an analysis!</p>
                            </section>
                            
                            <section className='page__section p_0'>
                                <div className='accordion accordion--show-more m_0'>
                                    <div className='accordion__item'>
                                        <div className='accordion__item--body'>
                                            <h4 className="page__section--sub-heading text__align--left">What Makes A CRM A Good CRM?</h4>
                                            <p className="page__section--para ">If you buy certified reference materials, you expect to get a very homogeneous material. You may expect stable analysis values. Since the certification process is based on multiple wet chemical analysis results, the reported uncertainty on the reference material certificate is relatively small, compared to the measurement uncertainty of optical emission spectroscopy.  Since for the final analytical uncertainty the uncertainty margin of the CRM is also considered, a small uncertainty of the reference material is positive for the final analytical uncertainty.</p>

                                            <h5 className="page__section--sub-heading text__align--left">Uncertainty</h5>
                                            <p className="page__section--para ">The uncertainty reported on our certificates represent the half width confidence interval CI (95%), where CI (95%) = (t x SM / √n) and “t” is the appropriate two-sided Student’s t value at the 95% confidence level for “n” acceptable mean values.</p>

                                            <h5 className="page__section--sub-heading text__align--left">Homogeneity</h5>
                                            <p className="page__section--para ">Homogeneity testing is performed by spark emission spectroscopy.  Tests involve making multiple measurements on individual samples taken at regular intervals along the entire length of each cast rod.  Depending on the mass content of the element, the relative standard deviation of multiple measurements between discs or within one disc is typically found between 0.3% - 1% for alloying and other elements and 0.5% - 5% for trace elements.</p>

                                            <h5 className="page__section--sub-heading text__align--left">Traceability</h5>
                                            <p className="page__section--para ">Traceability of the certified mass contents to the SI (Système International d’Unités) is ensured by calibration using certified standard solutions (from trusted, ISO 17034 accredited sources) or pure metals or substances of known stoichiometry.</p>

                                            <h4 className="page__section--sub-heading text__align--left">How To Read The Certificate Of A CRM</h4>
                                            <h5 className="page__section--sub-heading text__align--left">Values In Brackets</h5>
                                            <p className="page__section--para ">Values in brackets () are not certified but given for information only.  These values should never be used for calibration of an analytical instrument.</p>
                                            
                                            <h5 className="page__section--sub-heading text__align--left">Values With A Range / S-Certificate</h5>
                                            <p className="page__section--para ">There are certain elements, such as Na, Ca or Li that tend to burn off during the cast.  This would increase the uncertainty of the certified values significantly and make the CRM useless for these elements.  In this case, there is a range of concentration given for these elements on the certificate.  Individually certified values for those elements for each disc are available on “S-Certificates” only.</p>

                                            <h4 className="page__section--sub-heading text__align--left">How To Correctly Use CRMs</h4>
                                            <h5 className="page__section--sub-heading text__align--left">Intended Use And Stability</h5>
                                            <p className="page__section--para ">Our certified reference materials are primarily intended for use in spark optical emission spectroscopy.  Other applications are X-ray fluorescence spectrometry (XRF) and classical wet chemical procedures.  The smallest sample size for wet chemical analysis is 0.2g.  The material will remain stable for the period given (certification validity) if it is stored in a dry and clean environment at room temperature.</p>
                                            <p className="page__section--para ">Calibration measurements should be made within a ring between 2 mm and 22 mm from the edge of the CRM face.  For wet chemical analysis chips must be prepared by turning or milling of the sample surface.  Also in this case, chips should only be taken from the white area.</p>
                                            <p className="page__section--para ">Certified Reference Materials (CRM) may be used for basic system calibration, which is usually done by a service technician of the system.  They may also be used for regular performance checks of the system.  Depending on the stability of the system, such check may be needed in varying intervals.  Drift check during long measurement series or stability check at shorter intervals may be done by measuring setting up samples.  These samples are homogeneous and deliver stable intensities for the individual element.  However, absolute values are not certified.  If such a standard is replaced by a new sample, the new sample needs to be measured together with the old and set as the new standard to which stability and drift is compared.</p>
                                            <p className="page__section--para">When using a CRM, the element concentration needs to be in a similar concentration range than the elements you want to analyze.  It may be difficult to always find exactly the CRM that matches your application.  So, a combination of several CRM for checking the performance of your instrument needs to be used.  In such a case, make sure, elements that may influence each other (interferences) need to be present in the same CRM in a reasonable concentration so you may see such behavior of your instrument.</p>
                                            <p className="page__section--para">If your application requires that you prove the absence of some elements in the alloy, make sure you use a CRM where these elements are present in a concentration at or below the limit you need to guarantee.  Only with such a CRM you may prove that your system would detect the element, should it be present.  This is the reason, why many CRM have also minor elements certified in low concentrations.</p>
                                        </div>
                                        <button className='accordion__item--btn' onClick={accordionHandler}>Show more <ArrowDown/>
                                        </button>
                                    </div>
                                </div>
                            </section>

                            <section className="page__section bg--light p_3 mt_50">
                                <h4 className="page__section--sub-heading text__align--left">Catalog Sections</h4>
                                <ul className="catalog__list">
                                    <li className="catalog__list--item">
                                        <a href='/pdf/STPWrCat.pdf'><PDFIcon/>Wrought Alloys</a>
                                    </li>
                                    <li className="catalog__list--item">
                                        <a href='/pdf/SPTSpCat.pdf'><PDFIcon/>Special Alloys</a>
                                    </li>
                                </ul>
                            </section>

                            <section className="page__section">
                                <div className="row">
                                    <div className="col_sm_12 col_md_12 col_lg_8">
                                        <div className="product__info">
                                            <h4 className="page__section--sub-heading text__align--left">Material Forms and Dimensions
                                            </h4>
                                            <ul className="product__info--list">
                                                <li className="product__info--list-item">General - General purpose size (~55-65 mm diameter x ~25-35 mm thick) intended for use in consumptive techniques such as Optical Emission and Glow Discharge Spectroscopy.</li>
                                                <li className="product__info--list-item">X-Ray - About 31 mm diameter x ~6 mm thick discs designed specifically for use with X-Ray Fluorescence Instruments</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col_sm_12 col_md_12 col_lg_4">
                                        <div className="product__pricing">
                                            <h4 className="page__section--sub-heading text__align--left">General Pricing</h4>
                                            <p className="page__section--para">Please Call for Pricing : <a href="tel:(720) 943-7676">(720) 943-7676</a></p>
                                            <ul className="product__pricing--list">
                                                <li className="product__pricing--list-title">
                                                    <p>Description</p>
                                                    <p>Suffix #</p>
                                                </li>
                                                <li className="product__pricing--list-item">
                                                    <p>General</p>
                                                    <p>-G</p>
                                                </li>
                                                <li className="product__pricing--list-item">
                                                    <p>X-Ray</p>
                                                    <p>-X</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </section>

                        </div>
                        
                    </div>
                </div>

                
            </div>
            <Footer footerEmails={true} emailList={emailList} />
        </>
    )
}

export default SuisseTpCRM;
