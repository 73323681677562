import { Outlet } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

const Layout = (props) => {
  return (
    <>
      {props.children}
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="rmrmscookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
      <Outlet />
    </>
  );
};

export default Layout;
