import React , { useEffect } from "react";
import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';
import Banner from '../../Common/Banner/Banner';
import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';
import SideNavbar from '../../Templates/SideNavbar/ServiceSideNavbar';
// import ArrowDown from '../../Icon/ArrowDown';

const RMSP = () => {

    // var arrDown = 
    //     `<svg xmlns="http://www.w3.org/2000/svg" width="11.987" height="5.986" viewBox="0 0 11.987 5.986">
    //         <path id="arrow-ios-downward-outline" d="M13.482,19.482a.855.855,0,0,1-.547-.2L7.807,15.012A.856.856,0,0,1,8.9,13.7l4.581,3.829,4.581-3.692a.876.876,0,1,1,1.086,1.376l-5.128,4.128A.855.855,0,0,1,13.482,19.482Z" transform="translate(-7.498 -13.498)" fill="#900" />
    //     </svg>`
    // ;

    // const accordionHandler = (event) => {
    //     event.target.classList.toggle('active');
    //     var accordionBody = event.target.previousElementSibling;
    //     if (accordionBody.style.maxHeight) {
    //         accordionBody.style.maxHeight = null;
    //         event.target.innerHTML = "Show more" + arrDown;
    //     } else {
    //         accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
    //         event.target.innerHTML = "Show less" + arrDown;
    //     }
    // }

    useEffect(() => {
        window.scrollTo(0,0);
    },[]);

    var emailList = [
        {email: 'rmsp@rmrms.com'},
        {email: ''},
        {email: ''},
    ]
    
    return(
        <>
            <Header />
            <div className='mainSection'>

                <Banner bgPath={'url(/images/HeaderDark.jpg)'} 
                    breadcrumb={<Breadcrumb title='RMSP' listItem='Home' listItemPath='/' listLink='Services' listLinkPath='/services' />}
                />

                <div className='container'>
                    <div className='row'>
                        <div className='col_sm_12 col_md_5 col_lg_3'>
                            <SideNavbar/>
                        </div>

                        <div className='col_sm_12 col_md_7 col_lg_9'>
                            <section className='page__section'>
                                <h3 className='page__section--heading text__align--left'>Share Program</h3>

                                <p className='page__section--para'>In that same spirit of cooperation that has become the very foundation of our efforts to develop new CRMs/RMs in the private sector, in September of 2016, the company launched its "Rocky Mountain Share Program."  Although many materials are quite common, spectrochemical standards for these materials are essentially non-existent.</p>
                                <p className='page__section--para'>
                                The "90/10, 80/20, 70/30" share program is a very simple cost-effective service offered by RMRMs allowing our company to work together with the scientific consumer by sharing the task, as well as the costs of developing new commercial CRMs.
                                </p>
                                <p className='page__section--para'>Here's how it works!  The customer furnishes RMRMs with the material of the specific alloy, usually in the form of round bar stock.  The bar stock should be a minimum of 1¼" in diameter (1½" O.D. is ideal).  The overall length should be 10' to 12'.  Forms other than bar stock is acceptable as long as the quantity is sufficient for the sampling that is needed for the laboratory examinations.  The material is then processed through RMRMs-RMCP for certification as a CRM.</p>
                                <p className='page__section--para'>The only cost to the customer is the cost of the material.  RMRMs has well-established vendors for most alloy materials and will also assist in location and acquisition.  Approximately 30% of the material is expended in the analysis process.  After certification, 10% is returned to the customer in any form desired (solids, chips, X-Ray discs, etc.), and RMRMs retains the balance for sale commercially.</p>
                                <p className='page__section--para'>That's all there is to it!  By working together, we make available one new Traceable CRM or RM for the scientific community.</p>
                            </section>
                        </div>
                    </div>
                </div>                
            </div>
            <Footer footerEmails={true} emailList={emailList}/>
        </>
    )
}

export default RMSP;

