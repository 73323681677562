import React, { useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import axiosInstance from "../../../../Services/axios";
import { MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
const increaseUniq = (uniq) => uniq + 1;

const CompanyModal = ({
  closeCompanyModal,
  companyModal,
  setCacheUniq1,
  updateCompanyName,
  prefillCompanyName,
}) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    // Fetch countries
    axios
      .get("https://countriesnow.space/api/v0.1/countries/iso")
      .then((res) => {
        const countryOptions = res.data.data.map((country) => ({
          value: country.Iso2,
          label: country.name,
        }));
        setSelectedCountry("United States");
        setCountries(countryOptions);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      // Fetch states when a country is selected
      axios
        .post("https://countriesnow.space/api/v0.1/countries/states", {
          country: selectedCountry,
        })
        .then((res) => {
          console.log("sddsds", res);

          const stateOptions = res.data.data.states.map((state) => ({
            value: state.name,
            label: state.name,
          }));
          setStates(stateOptions);
          setCities([]); // Reset cities
          setSelectedState(null); // Reset selected state
        })
        .catch((error) => console.error("Error fetching states:", error));
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      // Fetch cities when a state is selected
      axios
        .post("https://countriesnow.space/api/v0.1/countries/state/cities", {
          country: selectedCountry,
          state: selectedState,
        })
        .then((res) => {
          const cityOptions = res.data.data.map((city) => ({
            value: city,
            label: city,
          }));
          setCities(cityOptions);
        })
        .catch((error) => console.error("Error fetching cities:", error));
    }
  }, [selectedState]);
  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9\s\-.,'&()]+$/, "Please enter a valid Name")
      .max(40, "Must be 40 characters or less")
      .required("Required"),
    fax: Yup.string(),
    companyWebsite: Yup.string()
      .matches(
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
        "Please enter a valid Website"
      )
      .required("Required"),

    address: Yup.string().required("Required"),
    zip: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      companyWebsite: "",
      fax: "",
      address: "",

      zip: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const obj = {
        ...values,
        company_website: values.companyWebsite,
        companyWebsite: undefined,
        country: selectedCountry,
        city: selectedCity,
        state: selectedState,
      };

      try {
        console.log("calledhere");
        const response = await axiosInstance.post("/company/add", obj);
        const res = JSON.parse(response.data);
        if (res.status === 200) {
          setCacheUniq1(increaseUniq);
          updateCompanyName({
            label: values.name,
            value: res.data.id,
            company_website: res.data.company_website,
            fax: res.data.fax,
            address: res.data.address,
            state: res.data.state,
            city: res.data.city,
            zip: res.data.zip,
            country: res.data.country,
          });
          setTimeout(() => {
            closeCompanyModal();
          }, 1000);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: true,
          });
        }
      } catch (error) {
        console.log("Err", error);
        // handle error
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("name", prefillCompanyName);
  }, [prefillCompanyName]);

  return (
    <Dialog
      onClose={closeCompanyModal}
      aria-labelledby="customized-dialog-title"
      open={companyModal}
    >
      <DialogTitle id="customized-dialog-title" onClose={closeCompanyModal}>
        Add Company
      </DialogTitle>
      <DialogContent dividers>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="name"
                fullWidth
                required
                size="small"
                label="Company Name"
                variant="outlined"
                {...formik.getFieldProps("name")}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="companyWebsite"
                fullWidth
                required
                size="small"
                label="Company Website"
                variant="outlined"
                {...formik.getFieldProps("companyWebsite")}
                error={
                  formik.touched.companyWebsite &&
                  Boolean(formik.errors.companyWebsite)
                }
                helperText={
                  formik.touched.companyWebsite && formik.errors.companyWebsite
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="fax"
                fullWidth
                size="small"
                label="Fax"
                variant="outlined"
                {...formik.getFieldProps("fax")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address"
                fullWidth
                required
                size="small"
                label="Mailing Address"
                variant="outlined"
                {...formik.getFieldProps("address")}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ minWidth: 120, maxHeight: 40 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    label="Country"
                    value={selectedCountry}
                    MenuProps={{
                      classes: { paper: "selectCountryRoot" },
                    }}
                    onChange={(e) => setSelectedCountry(e.target.value)}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.value} value={country.label}>
                        {country.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ minWidth: 120, maxHeight: 40 }}>
                <FormControl fullWidth size="small" disabled={!selectedCountry}>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    label="State"
                    value={selectedState}
                    MenuProps={{
                      classes: { paper: "selectCountryRoot" },
                    }}
                    onChange={(e) => setSelectedState(e.target.value)}
                  >
                    {states.map((city) => (
                      <MenuItem key={city.value} value={city.label}>
                        {city.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ minWidth: 120, maxHeight: 40 }}>
                <FormControl fullWidth size="small" disabled={!selectedState}>
                  <InputLabel id="demo-simple-select-label">City</InputLabel>
                  <Select
                    label="City"
                    value={selectedCity}
                    MenuProps={{
                      classes: { paper: "selectCountryRoot" },
                    }}
                    onChange={(e) => setSelectedCity(e.target.value)}
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.value} value={city.label}>
                        {city.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="zip"
                fullWidth
                required
                size="small"
                label="Zip"
                variant="outlined"
                {...formik.getFieldProps("zip")}
                error={formik.touched.zip && Boolean(formik.errors.zip)}
                helperText={formik.touched.zip && formik.errors.zip}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions style={{ alignSelf: "center" }}>
        <Button
          autoFocus
          onClick={formik.handleSubmit}
          className={"btnClass"}
          variant="contained"
          sx={{ mt: 3, mb: 2, ml: 2 }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompanyModal;
