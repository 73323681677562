import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

import Header from "./../components/Header";
import TableListing from "./Table";
import DrawerCom from "../components/Drawer";
import LoadingOverlay from "react-loading-overlay";
import { getData } from "../../Utils/utils";

const mdTheme = createTheme();

function ListingContent(props) {
  const [open, setOpen] = React.useState(true),
    [rows, setRows] = React.useState([]),
    toggleDrawer = () => {
      setOpen(!open);
    },
    fetchData = async () => {
      const res = await getData("/photogallery/get");
      setRows(res);
    };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header open={open} toggleDrawer={toggleDrawer} />
        <DrawerCom open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
            <TableListing
              rows={rows}
              name={"PhotoGallery"}
              link={"galleryItem"}
              fetchData={fetchData}
              {...props}
            />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function CatalogListing() {
  const [isLoading, setLoading] = React.useState(false);

  LoadingOverlay.propTypes = undefined;
  return (
    <LoadingOverlay active={isLoading} spinner text="Loading ...">
      <ListingContent isLoading={isLoading} setLoading={setLoading} />
    </LoadingOverlay>
  );
}
