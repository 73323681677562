import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './sideNavbar.scss';

const SideNavbar = () => {

    const location = useLocation(),
    { productMenu = [] } = location.state || {};

    return (
        <div className='accordion accordion-sm'>
            {
                productMenu.map((item, i) => {
                    return (<>
                        <div key={i} className='accordion__item'>
                            <NavLink to={'/service/contents'} className="accordion__item--btn" state={{ content: item, productMenu }}>{item.page_title}
                            </NavLink>
                        </div>
                    </>
                    )
                })
            }
            {/* <div className='accordion__item'>
                <NavLink to='/services/rmcp' className="accordion__item--btn">RMCP - RM Production</NavLink>
            </div>

            <div className='accordion__item'>
                <NavLink to='/services/rmpt' className="accordion__item--btn">RMPT Program</NavLink>
            </div>
            <div className='accordion__item'>
                <NavLink to='/services/rmsp' className="accordion__item--btn">RMSP - Share Program</NavLink>
            </div>
            <div className='accordion__item'>
                <a href='' className="accordion__item--btn">Schools - AES, ICP, XRF</a>
            </div>
            <div className='accordion__item'>
                <a href='' className="accordion__item--btn">Machining Services</a>
            </div> */}
        </div>  
    );
}

export default SideNavbar;
