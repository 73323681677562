import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Header from "../components/Header";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import "../style.scss";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DrawerCom from "../components/Drawer";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Container,
  Toolbar,
  Box,
  Typography,
  CssBaseline,
} from "@mui/material";
import {  postData, uploadFile } from "../../Utils/utils";
import GalleryComponent from "../components/GalleryComponent";
import LoadingOverlay from "react-loading-overlay";

const mdTheme = createTheme();

function PhotoGallery() {
  const [pageStore, setPageStore] = useState({
    id: 0,
    title: "",
    images: [{ file_name: "", file: "", preview: "",position:1 }],
    
  }),
    // eslint-disable-next-line
    [categories, setCategories] = React.useState([]),
    [open, setOpen] = useState(true),
    [isError, setError] = useState(false),
    [isLoading, setLoading] = React.useState(false),
    {
      id,
      title,
      images,
    
    } = pageStore,
    navigate = useNavigate(),
    location = useLocation(),
    { content } = location.state || {};


  useEffect(() => {
    content && setPageStore(content);
  }, [content]);

  const onPageChange = async (e, name, index) => {
      const { target: { value = "" } = {} } = e;
    if (
        name === "title" 
      ) {
        setPageStore({ ...pageStore, [name]: value });
      }  else if (name === "push") {
        const images = pageStore.images || [];
        images.push({ file_name: "", file: "" });
        setPageStore({ ...pageStore, images });
      } else if (name === "pop") {
        const images = pageStore.images || [];
        images.splice(index, 1);
        setPageStore({ ...pageStore, images });
      }  else if (name === "preview") {
        const images = pageStore.images || [];
        images[index].preview = e;
        setPageStore({ ...pageStore, images });
    } else if (name === "position") {
        const images = pageStore.images || [];
        images[index].position = e;
        setPageStore({ ...pageStore, images });
      }
    
    else if (name === "file_name") {
        const images = pageStore.images || [];
        images[index].file_name = value;
        setPageStore({ ...pageStore, images });
      } else if (name === "file") {
        const image = e.target.files[0];
        if (image.name.match(/\.(pdf)$/)) {
          return false;
        } else {
          setLoading(true);
          const { data: { url = "" } = {} } =
            (await uploadFile(e.target.files[0], "/common/upload/file")) || {};
          const images = pageStore.images || [];
          images[index].file = url;
          setPageStore({ ...pageStore, images });
          setLoading(false);
        }
      }
    },
    toggleDrawer = () => {
      setOpen(!open);
    },
    handleSubmit = async (event) => {
      event.preventDefault();
      if (!title) {
        setError(true);
        return;
      }

      const obj = {
       title,
        images,
      
      };
      if (!!id) {
        obj.id = id;
      }

      setLoading(true);
      await postData(obj, "/photogallery/add");
      setLoading(false);
      setTimeout(() => {
        navigate("/admin/photoGallery");
      }, 1000);
    };
  LoadingOverlay.propTypes = undefined;
  return (
    <LoadingOverlay active={isLoading} spinner text="Loading ...">
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Header open={open} toggleDrawer={toggleDrawer} />
          <DrawerCom open={open} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      minHeight: 240,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ pb: 2 }}
                    >
                      Create Gallery
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit}>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            size="small"
                            value={title}
                            onChange={(e) => onPageChange(e, "title")}
                            label="Title"
                            variant="outlined"
                            error={isError && !title}
                            helperText={
                              isError && !title && "title is required"
                            }
                            style={{maxWidth: "380px"}}
                          />
                        </Grid>
                      </Grid>
                      <div>
                        <GalleryComponent
                           setPageStore={setPageStore}
                            pageStore={pageStore}
                            onPageChange={onPageChange}
                        />
                      </div>
                      
                      <Button
                        type="submit"
                        className={"btnClass"}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Save
                      </Button>
                      <Button
                        component={Link}
                        to="/admin/photoGallery"
                        variant="outlined"
                        sx={{ mt: 3, mb: 2, ml: 2 }}
                      >
                        Cancel
                      </Button>
                      {/* <Button variant="outlined" sx={{ mt: 3, mb: 2, ml:2 }} onClick={handleSectionGen}>Add New Section</Button>*/}
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </LoadingOverlay>
  );
}

export default function CreateGallery() {
  return <PhotoGallery />;
}
