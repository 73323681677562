import React , { useEffect, useState } from "react";
import Header from '../Common/Header/Header';
import Footer from '../Common/Footer/Footer';
import Banner from '../Common/Banner/Banner';
import Breadcrumb from '../Common/Breadcrumb/Breadcrumb';
import Cards from '../Templates/Cards/ProductCards';
import ArrowDown from '../Icon/ArrowDown';
// import { useLocation } from "react-router-dom";
import { getData } from "../../Utils/utils";


const Products = () => {

     
    const[productMenu,setProductMenu]=useState([])
     // eslint-disable-next-line
     const [isLoading, setLoading] = React.useState(false),
    [content, setProduct] = useState({}),
   
    fetchProductsData = async () => {
      setLoading(true);
      const result = await getData("/get/contents/by/category");
        setProduct(result?.page);
        setProductMenu(result?.product_categories)
      setLoading(false);
    };

  useEffect(() => {
    fetchProductsData();

  
  }, []);
    var arrDown = 
        `<svg xmlns="http://www.w3.org/2000/svg" width="11.987" height="5.986" viewBox="0 0 11.987 5.986">
            <path id="arrow-ios-downward-outline" d="M13.482,19.482a.855.855,0,0,1-.547-.2L7.807,15.012A.856.856,0,0,1,8.9,13.7l4.581,3.829,4.581-3.692a.876.876,0,1,1,1.086,1.376l-5.128,4.128A.855.855,0,0,1,13.482,19.482Z" transform="translate(-7.498 -13.498)" fill="#900" />
        </svg>`
    ;

    const accordionHandler = (event) => {
        event.target.classList.toggle('active');
        var accordionBody = event.target.previousElementSibling;
        if (accordionBody.style.maxHeight) {
            accordionBody.style.maxHeight = null;
            event.target.innerHTML = "Show more" + arrDown;
        } else {
            accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
            event.target.innerHTML = "Show less" + arrDown;
        }
    }

    useEffect(() => {
        window.scrollTo(0,0);
    },[]);

    var emailList = [
        {email: 'Sales@RMRMs.com'},
        {email: ''},
        {email: ''},
    ]

    return (
        <>
            <Header />
            <div className='mainSection'>
                <Banner bgPath={'url(/images/HeaderDark.jpg)'} 
                    breadcrumb={
                        <Breadcrumb 
                            title='Products' 
                            listItem='Home' 
                            listItemPath='/' 
                            listLink='Products' 
                            listLinkPath='/products' 
                        />
                    } 
                />      
                        
                <section className='page__section pt_1'>
                    <div className='container'>
                        <h3 className='page__section--heading text__align--left'>{content.page_title}</h3>
                        <div dangerouslySetInnerHTML={{__html: content.default_content}} className="reset-props"></div>
                    </div>
                </section>

                <section className='page__section p_0'>
                    <div className='container'>
                        <div className='accordion accordion--show-more m_0'>
                            <div className='accordion__item'>
                                <div className='accordion__item--body'>
                                    <div dangerouslySetInnerHTML={{__html: content.hidden_content}} className="reset-props"></div>
                                </div>
                                <button className='accordion__item--btn' onClick={accordionHandler}>Show more <ArrowDown/>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className='page__section p_0'>
                    <div className='container'>
                        <Cards productMenu={productMenu} />
                    </div>
                </section>
            </div>
            <Footer footerEmails={true} emailList={emailList}/>
        </>
    )
}

export default Products;

