import React from 'react';

const Download = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="18.154" height="14.75" viewBox="0 0 18.154 14.75">
                <g id="download" transform="translate(0 -2.25)">
                    <path id="Path_7" data-name="Path 7" d="M.567,18a.567.567,0,0,1,.567.567v3.971a1.135,1.135,0,0,0,1.135,1.135H15.885a1.135,1.135,0,0,0,1.135-1.135V18.567a.567.567,0,1,1,1.135,0v3.971a2.269,2.269,0,0,1-2.269,2.269H2.269A2.269,2.269,0,0,1,0,22.538V18.567A.567.567,0,0,1,.567,18Z" transform="translate(0 -7.808)" fill="#6d7c90" fillRule="evenodd" />
                    <path id="Path_8" data-name="Path 8" d="M11.087,16.712a.567.567,0,0,1,.8,0l2.6,2.6,2.6-2.6a.567.567,0,0,1,.8.8l-3,3a.567.567,0,0,1-.8,0l-3-3A.567.567,0,0,1,11.087,16.712Z" transform="translate(-5.414 -7.087)" fill="#6d7c90" fillRule="evenodd" />
                    <path id="Path_9" data-name="Path 9" d="M17.442,2.25a.567.567,0,0,1,.567.567v9.077a.567.567,0,0,1-1.135,0V2.817A.567.567,0,0,1,17.442,2.25Z" transform="translate(-8.365)" fill="#6d7c90" fillRule="evenodd" />
                </g>
            </svg>
        </>
    );
}

export default Download;