import React, { useEffect } from "react";
import Header from "../../Common/Header/Header";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const Zscore = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header themeWhite={true} contactBtn={true} bgLight={true} />
      <div className="mainSection mainPortal">
        <section className="page__section">
          <div className="container">
            <div className="form__container">
              <form className="form form__user">
                <h1 className="form__title">
                  Z - Score generated for RMPT
                  <span style={{ backgroundColor: "#FFFF00" }}>
                    #43392Co000
                  </span>
                </h1>
                <div style={{ height: 550, width: "100%", overflowY: "auto" }}>
                  <TableContainer className="form__table form__zscore">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Analyte</TableCell>
                          <TableCell align="center">User Data</TableCell>
                          <TableCell align="center">Certified Value</TableCell>
                          <TableCell align="center">
                            Standard deviation
                          </TableCell>
                          <TableCell align="center">Z-Score</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow hover>
                          <TableCell align="center">AI</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">B</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">C</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">Co</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">Cr</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">Cu</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">Fe</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">Mg</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">Mn</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">Mo</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">N</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">Nb</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">Ni</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">O</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">P</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">S</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">Si</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">Ta</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">Ti</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">V</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">W</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="center">Zr</TableCell>
                          <TableCell align="center">0.041</TableCell>
                          <TableCell align="center">0.035</TableCell>
                          <TableCell align="center">0.004</TableCell>
                          <TableCell align="center">1.38</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Zscore;
