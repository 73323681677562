import React from 'react';
import Toolbar from "@mui/material/Toolbar";
import logo from "../../Images/logo1.png";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import {MainListItems, SecondaryListItems} from "./ListItems";
import {styled} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";

const drawerWidth = 260;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(7),
                },
            }),
        },
    }),
);

const DrawerCom =({open,toggleDrawer})=>{
    return(
        <Drawer variant="permanent" open={open}>
            <Toolbar sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: [1],}}>
                <img src={logo} alt="logo" style={{display: !open ? "none":"inline-block", width: "50px", marginLeft: "0",marginRight:" 8px"}}/>
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
                <MainListItems/>
                <Divider sx={{ my: 1 }} />
                <SecondaryListItems/>
            </List>
        </Drawer>
    )
}

export default DrawerCom;
