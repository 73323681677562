import React, { useEffect, useState } from "react";
import Header from "../../Common/Header/Header";
import { Formik } from "formik";
import Swal from "sweetalert2";

import * as Yup from "yup";
import axios from "../../../Services/axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";

import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const AnalystSignup = () => {
  const [linkData, setLinkData] = useState("");
  const [availableLink, setAvailableLink] = useState(false);
  const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (getQueryVariable("link")) {
      axios
        .post("/rmpt/user/get/data/by/link", {
          link: getQueryVariable("link"),
        })

        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.status === 200) {
            setLinkData(res.data);
            setAvailableLink(false);
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: res.message,
              showConfirmButton: true,
              // timer: 1500,
            });
          }
        })
        .catch((error) => {})
        .then(() => {});
    }
  }, []);
  const [passwordValues, setPasswordValues] = React.useState({
    confirmPassword: false,
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickConfirmPassword = () => {
    setPasswordValues({
      ...passwordValues,
      confirmPassword: !passwordValues.confirmPassword,
    });
  };

  const registerSchemaWithLink = Yup.object({
    companyName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid Name")
      .max(40, "Must be 40 characters or less")
      .required("Required"),
    first_name: Yup.string().required("Required"),
    telephone: Yup.string().required("Required"),
    labContactEmail: Yup.string()
      .email("Invalid email address")
      .required("Required"),
    fax: Yup.string(),
    companyWebsite: Yup.string().matches(
      // eslint-disable-next-line
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
      "Please enter valid Website"
    ),
    state: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid State")

      .required("Required"),
    city: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid City")

      .required("Required"),
    mailingAddress: Yup.string().required("Required"),
    newPassword: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Please Enter Valid Password use at least one uppercase letter, lowercase letter, number, special character with minimum 8 characters"
      ),
    confirmPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords don't match!"),
    zip: Yup.string().required("Required"),
  });

  const [country, setCountry] = React.useState("US");

  const countryHandler = (event) => {
    setCountry(event.target.value);
  };

  return (
    <>
      <Header themeWhite={true} contactBtn={true} bgLight={true} />
      <div className="mainSection mainPortal">
        <section className="page__section">
          <div className="container">
            <div className="form__container">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  companyName: linkData ? linkData.company_name : "",
                  first_name: "",
                  labContactEmail: linkData ? linkData.email : "",
                  telephone: "",
                  fax: "",
                  companyWebsite: linkData ? linkData.company_website : "",
                  mailingAddress: "",
                  state: "",
                  city: "",
                  zip: "",
                  preferredModeOfContact: "email",
                  country: "",
                  newPassword: "",
                  confirmPassword: "",
                }}
                validationSchema={registerSchemaWithLink}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  let passwordObj = {
                    userType: "analyst",
                    user_id: linkData.id,
                    password: values.newPassword,
                    company_name: values.companyName,
                    first_name: values.first_name.toString(),
                    email: values.labContactEmail,
                    telephone: values.telephone,
                    fax: values.fax === "" ? undefined : values.fax,
                    company_website:
                      values.companyWebsite === ""
                        ? undefined
                        : values.companyWebsite,
                    address: values.mailingAddress,
                    state: values.state,
                    city: values.city,
                    country: country,
                    zip: values.zip,
                    contact_preferred_method: values.preferredModeOfContact,
                  };
                  axios
                    .post("rmpt/user/complete/profile", passwordObj)

                    .then((response) => {
                      let res = JSON.parse(response.data);
                      if (res.status === 200) {
                        setSubmitting(false);

                        // window.open("/customer-login", "_self");
                        Swal.fire({
                          icon: "success",
                          position: "center",

                          title: res.message,
                          showConfirmButton: true,
                        }).then(() => {
                          window.open("/customer-login", "_self");
                        });
                      } else {
                        Swal.fire({
                          position: "center",
                          icon: "error",
                          title: res.message,
                          showConfirmButton: true,
                          // timer: 1500,
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch((error) => {
                      setSubmitting(false);
                    })
                    .then(() => {
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  resetForm,
                  /* and other goodies */
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="form form__user"
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <h1 className="form__title justifyspace--between">
                      Info Request{" "}
                    </h1>
                    <h2 className="form__subtitle">
                      Please submit the following Information so we know how to
                      contact you.
                    </h2>
                    <div className="form__section">
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            disabled
                            label="Company Name"
                            id="company-name"
                            size="small"
                            name="companyName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.companyName}
                            fullWidth
                          />
                          <div className="form__input--error position--absolute">
                            {errors.companyName &&
                              touched.companyName &&
                              errors.companyName}
                          </div>
                        </div>
                        <div className="form__group">
                          <TextField
                            label="First/Last Name"
                            size="small"
                            // type="number"
                            name="first_name"
                            disabled={availableLink}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.first_name}
                            fullWidth
                          />
                          <div className="form__input--error position--absolute">
                            {errors.first_name &&
                              touched.first_name &&
                              errors.first_name}
                          </div>
                        </div>
                      </div>
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            label="Lab Contact Email"
                            size="small"
                            disabled
                            name="labContactEmail"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.labContactEmail}
                            fullWidth
                          />
                          <div className="form__input--error position--absolute">
                            {errors.labContactEmail &&
                              touched.labContactEmail &&
                              errors.labContactEmail}
                          </div>
                        </div>
                        <div className="form__group">
                          <TextField
                            label="Telephone"
                            size="small"
                            type="text"
                            name="telephone"
                            disabled={availableLink}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.telephone}
                            fullWidth
                          />
                          <div className="form__input--error position--absolute">
                            {errors.telephone &&
                              touched.telephone &&
                              errors.telephone}
                          </div>
                        </div>
                      </div>
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            label="Fax"
                            size="small"
                            type="text"
                            name="fax"
                            disabled={availableLink}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.fax}
                            fullWidth
                          />
                          <div className="form__input--error position--absolute">
                            {errors.fax && touched.fax && errors.fax}
                          </div>
                        </div>
                        <div className="form__group">
                          <TextField
                            label="Company Website"
                            size="small"
                            name="companyWebsite"
                            disabled
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.companyWebsite}
                            fullWidth
                          />
                          <div className="form__input--error position--absolute">
                            {errors.companyWebsite &&
                              touched.companyWebsite &&
                              errors.companyWebsite}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form__section">
                      <div className="form__group--flex-full">
                        <div className="form__group">
                          <TextField
                            label="Mailing Address"
                            size="small"
                            fullWidth
                            disabled={availableLink}
                            placeholder="Your complete mailing address here"
                            name="mailingAddress"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mailingAddress}
                          />
                          <div className="form__input--error position--absolute">
                            {errors.mailingAddress &&
                              touched.mailingAddress &&
                              errors.mailingAddress}
                          </div>
                        </div>
                      </div>
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            label="City"
                            disabled={availableLink}
                            size="small"
                            name="city"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.city}
                            fullWidth
                          />
                          <div className="form__input--error position--absolute">
                            {errors.city && touched.city && errors.city}
                          </div>
                        </div>
                        <div className="form__group">
                          <TextField
                            label="State/Province/Territory"
                            size="small"
                            disabled={availableLink}
                            name="state"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.state}
                            fullWidth
                          />
                          <div className="form__input--error position--absolute">
                            {errors.state && touched.state && errors.state}
                          </div>
                        </div>
                      </div>
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            label="Zip/Postal Code"
                            size="small"
                            disabled={availableLink}
                            name="zip"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.zip}
                            fullWidth
                          />
                          <div className="form__input--error position--absolute">
                            {errors.zip && touched.zip && errors.zip}
                          </div>
                        </div>
                        <div className="form__group">
                          <Box sx={{ minWidth: 120, maxHeight: 40 }}>
                            <FormControl fullWidth size="small">
                              <InputLabel id="demo-simple-select-label">
                                Country
                              </InputLabel>
                              <Select
                                label="Country"
                                value={country}
                                onChange={countryHandler}
                                defaultValue={"US"}
                                MenuProps={{
                                  classes: { paper: "selectCountryRoot" },
                                }}
                              >
                                <MenuItem value="AF">Afghanistan</MenuItem>
                                <MenuItem value="AX">Aland Islands</MenuItem>
                                <MenuItem value="AL">Albania</MenuItem>
                                <MenuItem value="DZ">Algeria</MenuItem>
                                <MenuItem value="AS">American Samoa</MenuItem>
                                <MenuItem value="AD">Andorra</MenuItem>
                                <MenuItem value="AO">Angola</MenuItem>
                                <MenuItem value="AI">Anguilla</MenuItem>
                                <MenuItem value="AQ">Antarctica</MenuItem>
                                <MenuItem value="AG">
                                  Antigua and Barbuda
                                </MenuItem>
                                <MenuItem value="AR">Argentina</MenuItem>
                                <MenuItem value="AM">Armenia</MenuItem>
                                <MenuItem value="AW">Aruba</MenuItem>
                                <MenuItem value="AU">Australia</MenuItem>
                                <MenuItem value="AT">Austria</MenuItem>
                                <MenuItem value="AZ">Azerbaijan</MenuItem>
                                <MenuItem value="BS">Bahamas</MenuItem>
                                <MenuItem value="BH">Bahrain</MenuItem>
                                <MenuItem value="BD">Bangladesh</MenuItem>
                                <MenuItem value="BB">Barbados</MenuItem>
                                <MenuItem value="BY">Belarus</MenuItem>
                                <MenuItem value="BE">Belgium</MenuItem>
                                <MenuItem value="BZ">Belize</MenuItem>
                                <MenuItem value="BJ">Benin</MenuItem>
                                <MenuItem value="BM">Bermuda</MenuItem>
                                <MenuItem value="BT">Bhutan</MenuItem>
                                <MenuItem value="BO">Bolivia</MenuItem>
                                <MenuItem value="BQ">
                                  Bonaire, Sint Eustatius and Saba
                                </MenuItem>
                                <MenuItem value="BA">
                                  Bosnia and Herzegovina
                                </MenuItem>
                                <MenuItem value="BW">Botswana</MenuItem>
                                <MenuItem value="BV">Bouvet Island</MenuItem>
                                <MenuItem value="BR">Brazil</MenuItem>
                                <MenuItem value="IO">
                                  British Indian Ocean Territory
                                </MenuItem>
                                <MenuItem value="BN">
                                  Brunei Darussalam
                                </MenuItem>
                                <MenuItem value="BG">Bulgaria</MenuItem>
                                <MenuItem value="BF">Burkina Faso</MenuItem>
                                <MenuItem value="BI">Burundi</MenuItem>
                                <MenuItem value="KH">Cambodia</MenuItem>
                                <MenuItem value="CM">Cameroon</MenuItem>
                                <MenuItem value="CA">Canada</MenuItem>
                                <MenuItem value="CV">Cape Verde</MenuItem>
                                <MenuItem value="KY">Cayman Islands</MenuItem>
                                <MenuItem value="CF">
                                  Central African Republic
                                </MenuItem>
                                <MenuItem value="TD">Chad</MenuItem>
                                <MenuItem value="CL">Chile</MenuItem>
                                <MenuItem value="CN">China</MenuItem>
                                <MenuItem value="CX">Christmas Island</MenuItem>
                                <MenuItem value="CC">
                                  Cocos (Keeling) Islands
                                </MenuItem>
                                <MenuItem value="CO">Colombia</MenuItem>
                                <MenuItem value="KM">Comoros</MenuItem>
                                <MenuItem value="CG">Congo</MenuItem>
                                <MenuItem value="CD">
                                  Congo, Democratic Republic of the Congo
                                </MenuItem>
                                <MenuItem value="CK">Cook Islands</MenuItem>
                                <MenuItem value="CR">Costa Rica</MenuItem>
                                <MenuItem value="CI">Cote D'Ivoire</MenuItem>
                                <MenuItem value="HR">Croatia</MenuItem>
                                <MenuItem value="CU">Cuba</MenuItem>
                                <MenuItem value="CW">Curacao</MenuItem>
                                <MenuItem value="CY">Cyprus</MenuItem>
                                <MenuItem value="CZ">Czech Republic</MenuItem>
                                <MenuItem value="DK">Denmark</MenuItem>
                                <MenuItem value="DJ">Djibouti</MenuItem>
                                <MenuItem value="DM">Dominica</MenuItem>
                                <MenuItem value="DO">
                                  Dominican Republic
                                </MenuItem>
                                <MenuItem value="EC">Ecuador</MenuItem>
                                <MenuItem value="EG">Egypt</MenuItem>
                                <MenuItem value="SV">El Salvador</MenuItem>
                                <MenuItem value="GQ">
                                  Equatorial Guinea
                                </MenuItem>
                                <MenuItem value="ER">Eritrea</MenuItem>
                                <MenuItem value="EE">Estonia</MenuItem>
                                <MenuItem value="ET">Ethiopia</MenuItem>
                                <MenuItem value="FK">
                                  Falkland Islands (Malvinas)
                                </MenuItem>
                                <MenuItem value="FO">Faroe Islands</MenuItem>
                                <MenuItem value="FJ">Fiji</MenuItem>
                                <MenuItem value="FI">Finland</MenuItem>
                                <MenuItem value="FR">France</MenuItem>
                                <MenuItem value="GF">French Guiana</MenuItem>
                                <MenuItem value="PF">French Polynesia</MenuItem>
                                <MenuItem value="TF">
                                  French Southern Territories
                                </MenuItem>
                                <MenuItem value="GA">Gabon</MenuItem>
                                <MenuItem value="GM">Gambia</MenuItem>
                                <MenuItem value="GE">Georgia</MenuItem>
                                <MenuItem value="DE">Germany</MenuItem>
                                <MenuItem value="GH">Ghana</MenuItem>
                                <MenuItem value="GI">Gibraltar</MenuItem>
                                <MenuItem value="GR">Greece</MenuItem>
                                <MenuItem value="GL">Greenland</MenuItem>
                                <MenuItem value="GD">Grenada</MenuItem>
                                <MenuItem value="GP">Guadeloupe</MenuItem>
                                <MenuItem value="GU">Guam</MenuItem>
                                <MenuItem value="GT">Guatemala</MenuItem>
                                <MenuItem value="GG">Guernsey</MenuItem>
                                <MenuItem value="GN">Guinea</MenuItem>
                                <MenuItem value="GW">Guinea-Bissau</MenuItem>
                                <MenuItem value="GY">Guyana</MenuItem>
                                <MenuItem value="HT">Haiti</MenuItem>
                                <MenuItem value="HM">
                                  Heard Island and Mcdonald Islands
                                </MenuItem>
                                <MenuItem value="VA">
                                  Holy See (Vatican City State)
                                </MenuItem>
                                <MenuItem value="HN">Honduras</MenuItem>
                                <MenuItem value="HK">Hong Kong</MenuItem>
                                <MenuItem value="HU">Hungary</MenuItem>
                                <MenuItem value="IS">Iceland</MenuItem>
                                <MenuItem value="IN">India</MenuItem>
                                <MenuItem value="ID">Indonesia</MenuItem>
                                <MenuItem value="IR">
                                  Iran, Islamic Republic of
                                </MenuItem>
                                <MenuItem value="IQ">Iraq</MenuItem>
                                <MenuItem value="IE">Ireland</MenuItem>
                                <MenuItem value="IM">Isle of Man</MenuItem>
                                <MenuItem value="IL">Israel</MenuItem>
                                <MenuItem value="IT">Italy</MenuItem>
                                <MenuItem value="JM">Jamaica</MenuItem>
                                <MenuItem value="JP">Japan</MenuItem>
                                <MenuItem value="JE">Jersey</MenuItem>
                                <MenuItem value="JO">Jordan</MenuItem>
                                <MenuItem value="KZ">Kazakhstan</MenuItem>
                                <MenuItem value="KE">Kenya</MenuItem>
                                <MenuItem value="KI">Kiribati</MenuItem>
                                <MenuItem value="KP">
                                  Korea, Democratic People's Republic of
                                </MenuItem>
                                <MenuItem value="KR">
                                  Korea, Republic of
                                </MenuItem>
                                <MenuItem value="XK">Kosovo</MenuItem>
                                <MenuItem value="KW">Kuwait</MenuItem>
                                <MenuItem value="KG">Kyrgyzstan</MenuItem>
                                <MenuItem value="LA">
                                  Lao People's Democratic Republic
                                </MenuItem>
                                <MenuItem value="LV">Latvia</MenuItem>
                                <MenuItem value="LB">Lebanon</MenuItem>
                                <MenuItem value="LS">Lesotho</MenuItem>
                                <MenuItem value="LR">Liberia</MenuItem>
                                <MenuItem value="LY">
                                  Libyan Arab Jamahiriya
                                </MenuItem>
                                <MenuItem value="LI">Liechtenstein</MenuItem>
                                <MenuItem value="LT">Lithuania</MenuItem>
                                <MenuItem value="LU">Luxembourg</MenuItem>
                                <MenuItem value="MO">Macao</MenuItem>
                                <MenuItem value="MK">
                                  Macedonia, the Former Yugoslav Republic of
                                </MenuItem>
                                <MenuItem value="MG">Madagascar</MenuItem>
                                <MenuItem value="MW">Malawi</MenuItem>
                                <MenuItem value="MY">Malaysia</MenuItem>
                                <MenuItem value="MV">Maldives</MenuItem>
                                <MenuItem value="ML">Mali</MenuItem>
                                <MenuItem value="MT">Malta</MenuItem>
                                <MenuItem value="MH">Marshall Islands</MenuItem>
                                <MenuItem value="MQ">Martinique</MenuItem>
                                <MenuItem value="MR">Mauritania</MenuItem>
                                <MenuItem value="MU">Mauritius</MenuItem>
                                <MenuItem value="YT">Mayotte</MenuItem>
                                <MenuItem value="MX">Mexico</MenuItem>
                                <MenuItem value="FM">
                                  Micronesia, Federated States of
                                </MenuItem>
                                <MenuItem value="MD">
                                  Moldova, Republic of
                                </MenuItem>
                                <MenuItem value="MC">Monaco</MenuItem>
                                <MenuItem value="MN">Mongolia</MenuItem>
                                <MenuItem value="ME">Montenegro</MenuItem>
                                <MenuItem value="MS">Montserrat</MenuItem>
                                <MenuItem value="MA">Morocco</MenuItem>
                                <MenuItem value="MZ">Mozambique</MenuItem>
                                <MenuItem value="MM">Myanmar</MenuItem>
                                <MenuItem value="NA">Namibia</MenuItem>
                                <MenuItem value="NR">Nauru</MenuItem>
                                <MenuItem value="NP">Nepal</MenuItem>
                                <MenuItem value="NL">Netherlands</MenuItem>
                                <MenuItem value="AN">
                                  Netherlands Antilles
                                </MenuItem>
                                <MenuItem value="NC">New Caledonia</MenuItem>
                                <MenuItem value="NZ">New Zealand</MenuItem>
                                <MenuItem value="NI">Nicaragua</MenuItem>
                                <MenuItem value="NE">Niger</MenuItem>
                                <MenuItem value="NG">Nigeria</MenuItem>
                                <MenuItem value="NU">Niue</MenuItem>
                                <MenuItem value="NF">Norfolk Island</MenuItem>
                                <MenuItem value="MP">
                                  Northern Mariana Islands
                                </MenuItem>
                                <MenuItem value="NO">Norway</MenuItem>
                                <MenuItem value="OM">Oman</MenuItem>
                                <MenuItem value="PK">Pakistan</MenuItem>
                                <MenuItem value="PW">Palau</MenuItem>
                                <MenuItem value="PS">
                                  Palestinian Territory, Occupied
                                </MenuItem>
                                <MenuItem value="PA">Panama</MenuItem>
                                <MenuItem value="PG">Papua New Guinea</MenuItem>
                                <MenuItem value="PY">Paraguay</MenuItem>
                                <MenuItem value="PE">Peru</MenuItem>
                                <MenuItem value="PH">Philippines</MenuItem>
                                <MenuItem value="PN">Pitcairn</MenuItem>
                                <MenuItem value="PL">Poland</MenuItem>
                                <MenuItem value="PT">Portugal</MenuItem>
                                <MenuItem value="PR">Puerto Rico</MenuItem>
                                <MenuItem value="QA">Qatar</MenuItem>
                                <MenuItem value="RE">Reunion</MenuItem>
                                <MenuItem value="RO">Romania</MenuItem>
                                <MenuItem value="RU">
                                  Russian Federation
                                </MenuItem>
                                <MenuItem value="RW">Rwanda</MenuItem>
                                <MenuItem value="BL">Saint Barthelemy</MenuItem>
                                <MenuItem value="SH">Saint Helena</MenuItem>
                                <MenuItem value="KN">
                                  Saint Kitts and Nevis
                                </MenuItem>
                                <MenuItem value="LC">Saint Lucia</MenuItem>
                                <MenuItem value="MF">Saint Martin</MenuItem>
                                <MenuItem value="PM">
                                  Saint Pierre and Miquelon
                                </MenuItem>
                                <MenuItem value="VC">
                                  Saint Vincent and the Grenadines
                                </MenuItem>
                                <MenuItem value="WS">Samoa</MenuItem>
                                <MenuItem value="SM">San Marino</MenuItem>
                                <MenuItem value="ST">
                                  Sao Tome and Principe
                                </MenuItem>
                                <MenuItem value="SA">Saudi Arabia</MenuItem>
                                <MenuItem value="SN">Senegal</MenuItem>
                                <MenuItem value="RS">Serbia</MenuItem>
                                <MenuItem value="CS">
                                  Serbia and Montenegro
                                </MenuItem>
                                <MenuItem value="SC">Seychelles</MenuItem>
                                <MenuItem value="SL">Sierra Leone</MenuItem>
                                <MenuItem value="SG">Singapore</MenuItem>
                                <MenuItem value="SX">Sint Maarten</MenuItem>
                                <MenuItem value="SK">Slovakia</MenuItem>
                                <MenuItem value="SI">Slovenia</MenuItem>
                                <MenuItem value="SB">Solomon Islands</MenuItem>
                                <MenuItem value="SO">Somalia</MenuItem>
                                <MenuItem value="ZA">South Africa</MenuItem>
                                <MenuItem value="GS">
                                  South Georgia and the South Sandwich Islands
                                </MenuItem>
                                <MenuItem value="SS">South Sudan</MenuItem>
                                <MenuItem value="ES">Spain</MenuItem>
                                <MenuItem value="LK">Sri Lanka</MenuItem>
                                <MenuItem value="SD">Sudan</MenuItem>
                                <MenuItem value="SR">Suriname</MenuItem>
                                <MenuItem value="SJ">
                                  Svalbard and Jan Mayen
                                </MenuItem>
                                <MenuItem value="SZ">Swaziland</MenuItem>
                                <MenuItem value="SE">Sweden</MenuItem>
                                <MenuItem value="CH">Switzerland</MenuItem>
                                <MenuItem value="SY">
                                  Syrian Arab Republic
                                </MenuItem>
                                <MenuItem value="TW">
                                  Taiwan, Province of China
                                </MenuItem>
                                <MenuItem value="TJ">Tajikistan</MenuItem>
                                <MenuItem value="TZ">
                                  Tanzania, United Republic of
                                </MenuItem>
                                <MenuItem value="TH">Thailand</MenuItem>
                                <MenuItem value="TL">Timor-Leste</MenuItem>
                                <MenuItem value="TG">Togo</MenuItem>
                                <MenuItem value="TK">Tokelau</MenuItem>
                                <MenuItem value="TO">Tonga</MenuItem>
                                <MenuItem value="TT">
                                  Trinidad and Tobago
                                </MenuItem>
                                <MenuItem value="TN">Tunisia</MenuItem>
                                <MenuItem value="TR">Turkey</MenuItem>
                                <MenuItem value="TM">Turkmenistan</MenuItem>
                                <MenuItem value="TC">
                                  Turks and Caicos Islands
                                </MenuItem>
                                <MenuItem value="TV">Tuvalu</MenuItem>
                                <MenuItem value="UG">Uganda</MenuItem>
                                <MenuItem value="UA">Ukraine</MenuItem>
                                <MenuItem value="AE">
                                  United Arab Emirates
                                </MenuItem>
                                <MenuItem value="GB">United Kingdom</MenuItem>
                                <MenuItem value="US">United States</MenuItem>
                                <MenuItem value="UM">
                                  United States Minor Outlying Islands
                                </MenuItem>
                                <MenuItem value="UY">Uruguay</MenuItem>
                                <MenuItem value="UZ">Uzbekistan</MenuItem>
                                <MenuItem value="VU">Vanuatu</MenuItem>
                                <MenuItem value="VE">Venezuela</MenuItem>
                                <MenuItem value="VN">Viet Nam</MenuItem>
                                <MenuItem value="VG">
                                  Virgin Islands, British
                                </MenuItem>
                                <MenuItem value="VI">
                                  Virgin Islands, U.s.
                                </MenuItem>
                                <MenuItem value="WF">
                                  Wallis and Futuna
                                </MenuItem>
                                <MenuItem value="EH">Western Sahara</MenuItem>
                                <MenuItem value="YE">Yemen</MenuItem>
                                <MenuItem value="ZM">Zambia</MenuItem>
                                <MenuItem value="ZW">Zimbabwe</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      </div>
                      <div className="form__group--flex">
                        <div className="form__group">
                          <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <InputLabel htmlFor="outlined-create-password">
                              Create New Password
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-create-password"
                              type={
                                passwordValues.showPassword
                                  ? "text"
                                  : "password"
                              }
                              name="newPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.newPassword}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {passwordValues.showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Create New Password"
                            />
                          </FormControl>
                          <div className="form__input--error">
                            {touched.newPassword || errors.newPassword}
                            {touched.newPassword && errors.newPassword}
                          </div>
                        </div>
                        <div className="form__group">
                          <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <InputLabel htmlFor="outlined-confirm-password">
                              Confirm New Password
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-confirm-password"
                              type={
                                passwordValues.confirmPassword
                                  ? "text"
                                  : "password"
                              }
                              name="confirmPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {passwordValues.confirmPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Confirm New Password"
                            />
                          </FormControl>
                          <div className="form__input--error">
                            {touched.confirmPassword || errors.confirmPassword}
                            {touched.confirmPassword && errors.confirmPassword}
                          </div>
                        </div>
                      </div>

                      <div className="form__group--flex mb_0">
                        <div className="form__group flex justifyspace--between">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn--primary"
                            // onClick={() =>
                            //   window.open("/customer-register", "_self")
                            // }
                          >
                            Create an Account
                          </button>
                        </div>
                        <div className="form__group--flex-full mb_0">
                          <label
                            className="form__label"
                            style={{ marginTop: "-6px" }}
                          >
                            What is your preferred method of contact
                          </label>
                          <div className="form__group--flex mb_0 form__group--checkbox">
                            <div className="form__group">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={availableLink}
                                    defaultChecked
                                    checked={
                                      values.preferredModeOfContact === "email"
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "preferredModeOfContact",
                                        "email"
                                      )
                                    }
                                  />
                                }
                                label="Email"
                              />
                            </div>
                            <div className="form__group">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={availableLink}
                                    checked={
                                      values.preferredModeOfContact === "phone"
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "preferredModeOfContact",
                                        "phone"
                                      )
                                    }
                                  />
                                }
                                label="Phone"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AnalystSignup;
