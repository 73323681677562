import React from "react";
import "./aboutCard.scss";

const AboutCard = ({ icon, title, description }) => {
  return (
    <div className="about__card">
      <div className="about__card--icon">
        {icon}
        <h2 className="about__card--title title--h2 color--black">{title}</h2>
      </div>
      <div>
        <p className="about__card--para color--gray" dangerouslySetInnerHTML={{ __html: description }}></p>
      </div>
    </div>
  );
};

export default AboutCard;
