import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from './components/Header';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useState } from "react";
import DrawerCom from './components/Drawer';
import LoadingOverlay from 'react-loading-overlay';
import TextEditor from './components/Editor';
import { getData, postData } from '../Utils/utils';

const mdTheme = createTheme();

function DashboardContent() {
    const [open, setOpen] = React.useState(true),
        [isLoading, setLoading] = React.useState(false),
        [isError, setError] = useState(false),
        [pageStore, setPageStore] = useState({ id: 0, page_title: "", default_content: "", hidden_content: "", best_solution_heading: "", best_solution_content: "", mission_heading: "", mission_content: "", quality_heading: "", quality_content: "" }),

        onPageChange = (e, name) => {
            if (name === "page_title" || name === "best_solution_heading" || name === "best_solution_content" || name === "mission_heading" || name === "quality_heading") {
                setPageStore({ ...pageStore, [name]: e.target.value })
            } else if (name === "default_content" || name === "hidden_content" || name === "mission_content" || name === "quality_content") {
                setPageStore({ ...pageStore, [name]: e })
            }
        },

        handleSubmit = async (event) => {
            event.preventDefault();
            const { id = 0, page_title = "", default_content = "", hidden_content = "", mission_content = "", mission_heading = "", best_solution_content = "", best_solution_heading = "", quality_content = "", quality_heading = "" } = pageStore;
            if (!page_title) {
                setError(true);
                return;
            };

            const obj = { default_content, hidden_content, page_title, best_solution_content, best_solution_heading, mission_content, mission_heading, quality_content, quality_heading };
            if (!!id) {
                obj.id = id
            };

            setLoading(true);
            await postData(obj, '/aboutus/add');
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
            setLoading(false);
        },

        toggleDrawer = () => {
            setOpen(!open);
        },

        fetchProduct = async () => {
            const { id = 0, page_title = "", default_content = "", hidden_content = "", best_solution_content = "", best_solution_heading = "", mission_content = "", mission_heading = "", quality_content = "", quality_heading = "" } = await getData('/aboutus/get');
            setPageStore({ id, page_title, default_content, hidden_content, best_solution_content, best_solution_heading, mission_content, mission_heading, quality_content, quality_heading });
        };

    React.useEffect(() => {
        fetchProduct();
    }, [])

    LoadingOverlay.propTypes = undefined;

    return (
        <LoadingOverlay active={isLoading} spinner text='Loading ...'>
            <ThemeProvider theme={mdTheme}>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <Header open={open} toggleDrawer={toggleDrawer} />
                    <DrawerCom open={open} toggleDrawer={toggleDrawer} />
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={8} lg={12}>
                                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                        <Typography gutterBottom variant="h5" component="div" sx={{ pb: 2 }}>
                                            About Us Page
                                        </Typography>
                                        <Box component="form" onSubmit={handleSubmit}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={4} style={{ marginBottom: "-20px" }}>
                                                    <TextField value={pageStore.page_title} onChange={(e) => onPageChange(e, "page_title")} label="Page Heading" variant="outlined" fullWidth size="small" style={{ backgroundColor: "white" }} id="outlined-basic" error={isError && !pageStore.page_title} helperText={isError && !pageStore.page_title && "title is required"} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <label style={{ marginTop: "25px", display: "inline-block" }}>Default Content</label>
                                                    <TextEditor editorValue={pageStore.default_content} onChange={(newContent) => onPageChange(newContent, "default_content")} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <label>Hide Content</label>
                                                    <TextEditor editorValue={pageStore.hidden_content} onChange={(newContent) => onPageChange(newContent, "hidden_content")} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography gutterBottom variant="h6" component="div" sx={{ pt: 3 }}>
                                                        Best Solution
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField value={pageStore.best_solution_heading} onChange={(e) => onPageChange(e, "best_solution_heading")} label="Best Solution Heading" variant="outlined" fullWidth size="small" style={{ backgroundColor: "white" }} id="outlined-basic" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField value={pageStore.best_solution_content} onChange={(e) => onPageChange(e, "best_solution_content")} label="Best Solution Details" variant="outlined" fullWidth size="small" style={{ backgroundColor: "white" }} id="outlined-basic" />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography gutterBottom variant="h6" component="div" sx={{ pt: 3 }}>
                                                        Missions
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField value={pageStore.mission_heading} onChange={(e) => onPageChange(e, "mission_heading")} label="Mission Heading" variant="outlined" fullWidth size="small" style={{ backgroundColor: "white" }} id="outlined-basic" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <label>Mission Content</label>
                                                    <TextEditor editorValue={pageStore.mission_content} onChange={(newContent) => onPageChange(newContent, "mission_content")} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField value={pageStore.quality_heading} onChange={(e) => onPageChange(e, "quality_heading")} label="Quality Heading" variant="outlined" fullWidth size="small" style={{ backgroundColor: "white" }} id="outlined-basic" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {/* <TextField value={pageStore.quality_content} onChange={(e) => onPageChange(e, "quality_content")} label="Quality Content" variant="outlined" fullWidth size="small" style={{ backgroundColor: "white" }} id="outlined-basic" /> */}
                                                    <label>Quality Content</label>
                                                    <TextEditor editorValue={pageStore.quality_content} onChange={(newContent) => onPageChange(newContent, "quality_content")} />
                                                </Grid>
                                            </Grid>

                                            <Button type="submit" className={"btnClass"} variant="contained" sx={{ mt: 3, mb: 2, ml: 2 }}>Save</Button>
                                            <Button component={Link} to="/admin/listing" variant="outlined" sx={{ mt: 3, mb: 2, ml: 2 }}>Cancel</Button>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Box>
            </ThemeProvider>
        </LoadingOverlay>
    );
}

export default function AboutAdmin() {
    return <DashboardContent />;
}

