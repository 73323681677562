import * as React from "react";
import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Swal from "sweetalert2";

import Header from "../../components/Header";
import DrawerCom from "../../components/Drawer";
import LoadingOverlay from "react-loading-overlay";
import { getData, env } from "../../../Utils/utils";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";

import Tooltip from "@mui/material/Tooltip";
import UploadIcon from "../../../Components/Icon/UploadIcon";
import EyeIcon from "../../../Components/Icon/EyeIcon";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";

import Collapse from "@mui/material/Collapse";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import SampleSentUploadModal from "./modal/sampleSentUploadModal";
import ChartModal from "./modal/chartModal";
import UploadQuoteModal from "./modal/uploadQuoteModal";
import PoUploadModal from "./modal/poUploadModal";
import SampleSentViewModal from "./modal/sampleSentViewModal";
import AnalysisCompleteModal from "./modal/analysisCompleteModal";
import AppealCompleteModal from "./modal/appealCompleteModal";
import AppealLetterModal from "./modal/appealLetterModal";
import UploadSample from "./modal/uploadSample";
import CustomerNumberUpdateModal from "./modal/customerNumberUpdateModal";

const mdTheme = createTheme();

function ListingContent(props) {
  const [status, setstatus] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [companyId, setCompanyId] = useState(null);
  const [activeMasterIndex, setActiveMasterIndex] = useState(null);

  const [calenderData, setCalenderData] = useState([]);
  const [matrixData, setMatrixData] = useState([]);
  const [userId, setUserId] = useState("");
  const [viewSampleSentModal, setViewSampleSentModal] = useState(false);
  const handleViewSampleSentClose = () => {
    setViewSampleSentModal(false);
  };
  const [quoteIssue, setQuoteIssue] = useState({
    order_number: "",
    customer_number: "",
    order_date: new Date(),
    pin: "",
    quote_file: "",
  });
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const formattedDate = months[month] + " " + day + ", " + year;
    return formattedDate;
  }

  const getSampleCloseChartChecked = (value) => {
    let checkedAll = true;
    if (value.data.length === 0) {
      checkedAll = false;
    }
    value.data.forEach((e) => {
      if (!e.isAppeal && !e.isRejected && !e.isReappeal && !e.isAccepted) {
        checkedAll = false;
      }
      if (e.isAppeal && e.isRejected && !e.isReappeal) {
        checkedAll = false;
      } else if (e.isAppeal && !e.isRejected && !e.isAccepted) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };
  const modifyData = (data) => {
    const calculateJulianDate = (date) => {
      const date1 = new Date("12/30/1899");
      const date2 = new Date(date);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    };

    let materials = data.map(
      (x) => calculateJulianDate(x.sample_date) + x.material + data.pin
    );
    materials = [...new Set(materials)];
    let data12 = [];
    for (let i = 0; i < materials.length; i++) {
      const newData = data.filter(
        (x) =>
          calculateJulianDate(x.sample_date) + x.material + data.pin ===
          materials[i]
      );
      let item = {};
      let arr = [];
      for (let index = 0; index < newData.length; index++) {
        if (index === 0) {
          item = newData[index];
        }
        arr.push(newData[index]);
      }

      item.child = arr;
      let counting = 0;
      let analyst = "";
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index];
        if (!index) {
          analyst = element.analyst;
          counting = 1;
          arr[index].counting = counting;
        } else if (analyst === element.analyst) {
          arr[index].counting = counting;
        } else {
          analyst = element.analyst;
          counting = counting + 1;
          arr[index].counting = counting;
        }
      }
      data12.push(item);
    }
    return data12;
  };

  function getLatestDate(data) {
    // convert to timestamp and sort
    var sorted_ms = data
      .map(function (item) {
        return new Date(item).getTime();
      })
      .sort();
    // take latest
    var latest_ms = sorted_ms[sorted_ms.length - 1];
    // convert to js date object
    return new Date(latest_ms);
  }

  const masterCheckedRmpt188 = (value) => {
    let checkedAll = true;
    if (value.calenderData.length === 0) {
      checkedAll = false;
    }
    value.calenderData.forEach((e) => {
      if (!e.rmpt188) {
        checkedAll = false;
      }
      e.child.forEach((x) => {
        if (!x.rmpt188) {
          checkedAll = false;
        }
      });
    });
    return checkedAll;
  };

  const masterCheckedChildRmpt188 = (value) => {
    let checkedAll = true;
    if (value.child.length === 0) {
      checkedAll = false;
    }
    value.child.forEach((e) => {
      if (!e.rmpt188) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };

  const getMasterLastUpdatedDateRmpt188 = (value) => {
    const calenderDateArray = [];
    value.calenderData.forEach((e) => {
      calenderDateArray.push(e.rmpt188updated);
      e.child.forEach((x) => {
        calenderDateArray.push(x.rmpt188updated);
      });
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };

  const getMasterChildLastUpdatedDateRmpt188 = (value) => {
    const calenderDateArray = [];
    value.child.forEach((e) => {
      calenderDateArray.push(e.rmpt188updated);
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };

  const [open, setOpen] = React.useState(true),
    [rows, setRows] = React.useState([]),
    toggleDrawer = () => {
      setOpen(!open);
    },
    fetchData = async () => {
      const result = await getData("/admin/get/rmpt/users/data");
      for (let index = 0; index < result.length; index++) {
        if (result[index].basic_details.company_id === null) {
          result[index].basic_details.company_id =
            result[index].basic_details.id;
        }
      }
      const newresult = makeUniqueCompanyArray(result);

      for (let index = 0; index < newresult.length; index++) {
        for (let j = 0; j < newresult[index].firstChild.length; j++) {
          newresult[index].firstChild[j].calenderData = modifyData(
            newresult[index].firstChild[j].data
          );
        }
      }

      console.log("sdjjds", newresult);

      setRows(newresult);
    };
  const getValue = (value) => {
    let arr = [
      {
        label: "1 - 8 mm Disk Sample",
        value: "1",
      },
      {
        label: "2 - Chip Pack (10 g)",
        value: "2",
      },
      {
        label: "3 - Pin Pack (1 Qtr.)",
        value: "3",
      },
      {
        label: "4 - Chip Sample(20 g)",
        value: "4",
      },
    ];

    let index = arr.findIndex((x) => x.label === value);
    if (index === -1) return "";
    else return arr[index].value;
  };
  const getMethod = (value) => {
    let arr = [
      {
        label: "1 - AES",
        value: "1",
      },
      {
        label: "2 - XRF",
        value: "2",
      },
      {
        label: "3 - ICP",
        value: "3",
      },
      {
        label: "4 - Com",
        value: "4",
      },
      {
        label: "5 - IGF",
        value: "5",
      },
      {
        label: "6 - GDS",
        value: "6",
      },

      {
        label: "7 - FAA",
        value: "7",
      },
      {
        label: "8 - GFAA",
        value: "8",
      },

      {
        label: "9 - Other",
        value: "9",
      },
    ];
    let index = arr.findIndex((x) => x.label === value);
    if (index === -1) return "";
    else return arr[index].value;
  };
  const makeUniqueCompanyArray = (data) => {
    let company = data.map((x) => x.basic_details.company_id);
    company = [...new Set(company)];
    let data12 = [];
    for (let i = 0; i < company.length; i++) {
      const newData = data.filter(
        (x) => x.basic_details.company_id === company[i]
      );
      let item = {};
      let arr = [];
      for (let index = 0; index < newData.length; index++) {
        if (index === 0) {
          item = newData[index];
        }
        arr.push(newData[index]);
      }

      item.firstChild = arr;

      data12.push(item);
    }

    return data12;
  };

  React.useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, []);

  const [companyNumberModal, setCompanyNumberModal] = useState(false);
  const handleCustomerNumberOpen = () => setCompanyNumberModal(true);
  const handleCustomerNumberClose = () => setCompanyNumberModal(false);
  const [modalQuote, setModalQuote] = React.useState(false);
  const handleQuoteOpen = () => setModalQuote(true);
  const handleQuoteClose = () => setModalQuote(false);

  const [loader, setLoader] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [modalPo, setModalPo] = React.useState(false);
  const handlePoOpen = () => setModalPo(true);
  const handlePoClose = () => setModalPo(false);

  const [modalSample, setModalSample] = React.useState(false);
  const handleSampleOpen = () => setModalSample(true);
  const handleSampleClose = () => setModalSample(false);

  const [viewSampleModal, setViewSampleModal] = React.useState(false);
  const handleViewSampleOpen = () => setViewSampleModal(true);
  const handleViewSampleClose = () => setViewSampleModal(false);

  const getSampleClosedLastUpdatedDate = (value) => {
    const calenderDateArray = [];
    value.data.forEach((e) => {
      if (e.isReappeal) {
        if (e.reappealupdated && e.reappealupdated !== null)
          calenderDateArray.push(e.reappealupdated);
      } else if (e.isAccepted) {
        if (e.appealupdated && e.appealupdated !== null)
          calenderDateArray.push(e.appealupdated);
      }
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };

  const generateMasterRmptNumber = (val, value) => {
    const date1 = new Date("12/30/1899");
    const date2 = new Date(val.sample_date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (value !== null) {
      const rmpt = parseInt(diffDays);
      return rmpt.toString() + val.material + value;
    } else {
      return "";
    }
  };

  const generateChildRmptNumber = (val, value) => {
    const date1 = new Date("12/30/1899");
    const date2 = new Date(val.sample_date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (value !== null) {
      const rmpt = parseInt(diffDays);
      return rmpt.toString() + val.material + value;
    } else {
      return "";
    }
  };
  const generateNewReferenceId = (customer_number, order_date) => {
    const date1 = new Date("12/30/1899");
    const date2 = new Date(order_date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    const rmpt = customer_number + "-" + parseInt(diffDays);
    return rmpt.toString();
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [viewAnalysisFormCompleteModal, setViewAnalysisFormCompleteModal] =
    React.useState(false);
  const handleViewAnalysisFormCompleteOpen = () =>
    setViewAnalysisFormCompleteModal(true);
  const handleViewAnalysisFormCompleteClose = () =>
    setViewAnalysisFormCompleteModal(false);

  const [chartModal, setChartModal] = React.useState(false);
  const handleChartModalOpen = () => setChartModal(true);
  const handleChartModalClose = () => setChartModal(false);

  const [viewAppealLetterModal, setViewAppealLetterModal] =
    React.useState(false);
  const handleViewAppealLetterOpen = () => setViewAppealLetterModal(true);
  const handleViewAppealLetterClose = () => setViewAppealLetterModal(false);

  const [viewAppealCompleteModal, setViewAppealCompleteModal] =
    React.useState(false);
  const handleViewAppealCompleteOpen = () => setViewAppealCompleteModal(true);
  const handleViewAppealCompleteClose = () => setViewAppealCompleteModal(false);
  const openAccordian = (index) => {
    setActiveIndex(null);
    if (activeMasterIndex === index) {
      setActiveMasterIndex(null);
    } else {
      setActiveMasterIndex(index);
    }
  };

  const openAccordianChild = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  const masterCheckedChildRmpt189 = (value) => {
    let checkedAll = true;
    if (value?.child?.length === 0) {
      checkedAll = false;
    }
    value?.child?.forEach((e) => {
      if (!e.rmpt189) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };
  const getMasterChildLastUpdatedDateRmpt189 = (value) => {
    const calenderDateArray = [];
    value?.child?.forEach((e) => {
      calenderDateArray.push(e.rmpt189updated);
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };
  const masterCheckedRmpt189 = (value) => {
    let checkedAll = true;
    if (value.data.length === 0) {
      checkedAll = false;
    }
    value.data.forEach((e) => {
      if (!e.rmpt189) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };

  const getMasterLastUpdatedDateRmpt189 = (value) => {
    const calenderDateArray = [];
    value.data.forEach((e) => {
      calenderDateArray.push(e.rmpt189updated);
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };
  const masterCheckedChildRmptReappeal = (value) => {
    let checkedAll = true;
    if (value?.child?.length === 0) {
      checkedAll = false;
    }
    value?.child?.forEach((e) => {
      if (!e.isReappeal) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };
  const getMasterChildLastUpdatedDateRmptReappeal = (value) => {
    const calenderDateArray = [];
    value?.child?.forEach((e) => {
      calenderDateArray.push(e.reappealupdated);
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };
  const masterCheckedRmptReappeal = (value) => {
    let checkedAll = true;
    if (value.data.length === 0) {
      checkedAll = false;
    }
    value.data.forEach((e) => {
      if (!e.isReappeal) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };

  const getMasterLastUpdatedDateRmptReappeal = (value) => {
    const calenderDateArray = [];
    value.data.forEach((e) => {
      calenderDateArray.push(e.reappealupdated);
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };

  const previewRmptReappeal = (val, value) => {
    axios
      .get(
        env +
          `/common/get/preview?type=9&id=${value.user_id}&reference_id=${value.reference_id}&analyst_id=${val.id}`,
        {
          headers: {
            Authorization: localStorage.getItem("access_token"),
          },
        },
        setLoader(true)
      )

      .then((response) => {
        let res = response.data;
        if (res.status === 200) {
          setLoader(false);
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(res.data) +
              "'></iframe>"
          );
        } else {
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthorized!") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session Expired",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .then(() => {});
  };
  const previewRmpt189 = (val, value) => {
    axios
      .get(
        env +
          `/common/get/preview?type=6&id=${value.user_id}&reference_id=${value.reference_id}&analyst_id=${val.id}`,
        {
          headers: {
            Authorization: localStorage.getItem("access_token"),
          },
        },
        setLoader(true)
      )

      .then((response) => {
        let res = response.data;
        if (res.status === 200) {
          setLoader(false);
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(res.data) +
              "'></iframe>"
          );
        } else {
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthorized!") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session Expired",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .then(() => {});
  };
  const previewInfoPacketRequest = (value) => {
    axios
      .get(
        env + `/common/get/preview?type=1&id=${value.user_id}`,
        {
          headers: {
            Authorization: localStorage.getItem("access_token"),
          },
        },
        setLoader(true)
      )

      .then((response) => {
        let res = response.data;
        if (res.status === 200) {
          setLoader(false);
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(res.data) +
              "'></iframe>"
          );
        } else {
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthorized!") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session Expired",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .then(() => {});
  };
  const previewAccountCreated = (value) => {
    axios
      .get(
        env + `/common/get/preview?type=2&id=${value.user_id}`,
        {
          headers: {
            Authorization: localStorage.getItem("access_token"),
          },
        },
        setLoader(true)
      )

      .then((response) => {
        let res = response.data;
        if (res.status === 200) {
          setLoader(false);
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(res.data) +
              "'></iframe>"
          );
        } else {
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthorized!") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session Expired",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .then(() => {});
  };

  const masterCheckedChildRmptAppeal = (value) => {
    let checkedAll = true;
    if (value?.child?.length === 0) {
      checkedAll = false;
    }
    value?.child?.forEach((e) => {
      if (!e.isAppeal) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };
  const getMasterChildLastUpdatedDateAppeal = (value) => {
    const calenderDateArray = [];
    value?.child?.forEach((e) => {
      calenderDateArray.push(e.appealupdated);
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };
  const masterCheckedRmptAppeal = (value) => {
    let checkedAll = true;
    if (value.data.length === 0) {
      checkedAll = false;
    }
    value.data.forEach((e) => {
      if (!e.isAppeal) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };

  const getMasterLastUpdatedDateAppeal = (value) => {
    const calenderDateArray = [];
    value.data.forEach((e) => {
      calenderDateArray.push(e.appealupdated);
    });

    return `Status Updated On ${formatDate(getLatestDate(calenderDateArray))}`;
  };

  const previewRmpt191 = (val, value) => {
    axios
      .get(
        env +
          `/common/get/preview?type=8&id=${value.user_id}&reference_id=${value.reference_id}&analyst_id=${val.id}`,
        {
          headers: {
            Authorization: localStorage.getItem("access_token"),
          },
        },
        setLoader(true)
      )

      .then((response) => {
        let res = response.data;
        if (res.status === 200) {
          setLoader(false);
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(res.data) +
              "'></iframe>"
          );
        } else {
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthorized!") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session Expired",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .then(() => {});
  };
  const previewProfileDataSheet = (value) => {
    axios
      .get(
        env + `/common/get/preview?type=2&id=${value.user_id}`,
        {
          headers: {
            Authorization: localStorage.getItem("access_token"),
          },
        },
        setLoader(true)
      )

      .then((response) => {
        let res = response.data;
        if (res.status === 200) {
          setLoader(false);
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(res.data) +
              "'></iframe>"
          );
        } else {
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthorized!") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session Expired",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .then(() => {});
  };
  const previewQuoteRequested = (value) => {
    axios
      .get(
        env +
          `/common/get/preview?type=3&id=${value.user_id}&reference_id=${value.reference_id}`,
        {
          headers: {
            Authorization: localStorage.getItem("access_token"),
          },
        },
        setLoader(true)
      )

      .then((response) => {
        let res = response.data;
        if (res.status === 200) {
          setLoader(false);
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(res.data) +
              "'></iframe>"
          );
        } else {
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthorized!") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session Expired",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .then(() => {});
  };
  const previewRmpt188 = (value, val) => {
    axios
      .get(
        env +
          `/common/get/preview?type=5&id=${value.user_id}&reference_id=${value.reference_id}&analyst_id=${val.id}`,
        {
          headers: {
            Authorization: localStorage.getItem("access_token"),
          },
        },
        setLoader(true)
      )

      .then((response) => {
        let res = response.data;
        if (res.status === 200) {
          setLoader(false);
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(res.data) +
              "'></iframe>"
          );
        } else {
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthorized!") {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Session Expired",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        }
      })
      .then(() => {});
  };
  return (
    <ThemeProvider theme={mdTheme}>
      {loader ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : null}

      <Box sx={{ display: "flex" }} className="drawerBox">
        <CssBaseline />
        <Header open={open} toggleDrawer={toggleDrawer} />
        <DrawerCom open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
            {/* UploadSample */}
            <UploadSample
              handleSampleClose={handleSampleClose}
              fetchData={fetchData}
              modalSample={modalSample}
              setModalSample={setModalSample}
              status={status}
              calenderData={calenderData}
              setLoader={setLoader}
              customerId={customerId}
            />
            <SampleSentUploadModal
              viewSampleSentModal={viewSampleSentModal}
              handleViewSampleSentClose={handleViewSampleSentClose}
              setViewSampleSentModal={setViewSampleSentModal}
              handleSampleOpen={handleSampleOpen}
              calenderData={calenderData}
            />
            <ChartModal
              chartModal={chartModal}
              handleChartModalClose={handleChartModalClose}
              calenderData={calenderData}
              userId={userId}
              customerId={customerId}
            />

            {/* Upload Quote Modal */}
            <UploadQuoteModal
              quoteIssue={quoteIssue}
              setQuoteIssue={setQuoteIssue}
              fetchData={fetchData}
              setModalQuote={setModalQuote}
              status={status}
              customerId={customerId}
              matrixData={matrixData}
              modalQuote={modalQuote}
              handleQuoteClose={handleQuoteClose}
              setLoader={setLoader}
            />

            {/* Po Upload Modal */}
            <PoUploadModal
              modalPo={modalPo}
              handlePoClose={handlePoClose}
              setModalPo={setModalPo}
              fetchData={fetchData}
              status={status}
              customerId={customerId}
              setLoader={setLoader}
            />

            {/* Analysis Complete Modal */}
            <AnalysisCompleteModal
              viewAnalysisFormCompleteModal={viewAnalysisFormCompleteModal}
              handleViewAnalysisFormCompleteClose={
                handleViewAnalysisFormCompleteClose
              }
              calenderData={calenderData}
              userId={userId}
              customerId={customerId}
              setLoader={setLoader}
            />

            {/* Appeal Letter Modal */}
            <AppealLetterModal
              viewAppealLetterModal={viewAppealLetterModal}
              handleViewAppealLetterClose={handleViewAppealLetterClose}
              calenderData={calenderData}
              userId={userId}
              customerId={customerId}
              setLoader={setLoader}
            />

            {/* Appeal Complete Modal */}
            <AppealCompleteModal
              viewAppealCompleteModal={viewAppealCompleteModal}
              handleViewAppealCompleteClose={handleViewAppealCompleteClose}
              calenderData={calenderData}
              userId={userId}
              customerId={customerId}
              setLoader={setLoader}
            />

            <div className="admin__form--status">
              <h2 className="admin__form--title">RMPT Form Status</h2>

              {rows && rows.length > 0
                ? rows.map((value, index) => {
                    return (
                      <Accordion
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        className="table__accordion"
                      >
                        <AccordionSummary
                          onClick={() => {
                            openAccordian(index);
                          }}
                          expandIcon={
                            activeMasterIndex === index ? (
                              <RemoveIcon />
                            ) : (
                              <AddIcon />
                            )
                          }
                          aria-controls={`panel${index}a-content`}
                          id={`panel${index}a - header`}
                        >
                          <Typography>
                            RMPT Form Status for:{" "}
                            {value.basic_details?.company_name} –
                            {value.basic_details?.state},{" "}
                            {value.basic_details?.city}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer className="form__table">
                            <Table className="form__rmpt--status form__head--fixed">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="left"
                                    padding="none"
                                  ></TableCell>
                                  <TableCell
                                    align="left"
                                    style={{ padding: "10px 0" }}
                                  >
                                    <div>Reference ID</div>
                                  </TableCell>
                                  <TableCell align="center" padding="none">
                                    Info Packet Requested
                                  </TableCell>
                                  <TableCell align="center" padding="none">
                                    Account Created
                                  </TableCell>
                                  <TableCell align="center" padding="none">
                                    Profile Data Sheet
                                  </TableCell>
                                  <TableCell align="center" padding="none">
                                    Calendar Complete
                                  </TableCell>
                                  <TableCell align="center" padding="none">
                                    Quote Requested
                                  </TableCell>
                                  <TableCell align="center" padding="none">
                                    Quote Issued
                                  </TableCell>
                                  <TableCell align="center" padding="none">
                                    PO Issued
                                  </TableCell>
                                  <TableCell align="center" padding="none">
                                    Sample Sent
                                  </TableCell>
                                  <TableCell align="center" padding="none">
                                    Analysis Complete
                                  </TableCell>
                                  <TableCell align="center" padding="none">
                                    Appeal Letter
                                  </TableCell>
                                  <TableCell align="center" padding="none">
                                    Appeal Complete
                                  </TableCell>
                                  <TableCell align="center" padding="none">
                                    Sample Complete
                                  </TableCell>
                                  <TableCell align="center" padding="none">
                                    View All Details
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {value.firstChild && value.firstChild.length > 0
                                  ? value.firstChild.map((value, index) => {
                                      return (
                                        <>
                                          <TableRow
                                            hover
                                            className="table__row"
                                          >
                                            {/* Plus Icon */}
                                            <TableCell
                                              align="left"
                                              padding="none"
                                            >
                                              <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                disabled={
                                                  (value.calenderData &&
                                                    value.calenderData
                                                      .length === 0) ||
                                                  value.quote_issued === false
                                                }
                                                onClick={() => {
                                                  openAccordianChild(index);
                                                }}
                                              >
                                                {activeIndex === index ? (
                                                  <RemoveIcon />
                                                ) : (
                                                  <AddIcon />
                                                )}
                                              </IconButton>
                                            </TableCell>

                                            {/* Reference ID */}
                                            <TableCell
                                              align="left"
                                              padding="none"
                                            >
                                              {value.customer_number === null
                                                ? value.reference_id
                                                : generateNewReferenceId(
                                                    value.customer_number,
                                                    value.order_date
                                                  )}{" "}
                                              <br />(
                                              {value.basic_details?.first_name})
                                            </TableCell>

                                            {/* Info Request Packet */}
                                            <TableCell
                                              align="center"
                                              padding="none"
                                            >
                                              <div
                                                className="flex alignitems--center"
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <Tooltip
                                                  title={
                                                    value.info_packet_requested
                                                      ? `Status Updated On: ${formatDate(
                                                          value?.basic_details
                                                            ?.createdAt
                                                        )}`
                                                      : ""
                                                  }
                                                >
                                                  <Checkbox
                                                    checked={
                                                      value.info_packet_requested
                                                    }
                                                  />
                                                </Tooltip>
                                                <div className="btn--wrap">
                                                  <Tooltip
                                                    title={
                                                      value.info_packet_requested
                                                        ? `View/Download`
                                                        : ""
                                                    }
                                                  >
                                                    <button
                                                      disabled={
                                                        value.info_packet_requested ===
                                                        false
                                                      }
                                                      onClick={() => {
                                                        previewInfoPacketRequest(
                                                          value
                                                        );
                                                      }}
                                                      className="view-btn"
                                                    >
                                                      <EyeIcon />
                                                    </button>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </TableCell>

                                            {/* Account Created */}
                                            <TableCell
                                              align="center"
                                              padding="none"
                                            >
                                              <div
                                                className="flex alignitems--center"
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <Tooltip
                                                  title={
                                                    value.account_created
                                                      ? `Status Updated On: ${formatDate(
                                                          value?.basic_details
                                                            ?.updatedAt
                                                        )}`
                                                      : ""
                                                  }
                                                >
                                                  <Checkbox
                                                    checked={
                                                      value.account_created
                                                    }
                                                  />
                                                </Tooltip>
                                                <div className="btn--wrap">
                                                  <Tooltip
                                                    title={
                                                      value.account_created
                                                        ? `View/Download`
                                                        : ""
                                                    }
                                                  >
                                                    <button
                                                      disabled={
                                                        value.account_created ===
                                                        false
                                                      }
                                                      onClick={() => {
                                                        previewAccountCreated(
                                                          value
                                                        );
                                                      }}
                                                      className="view-btn"
                                                    >
                                                      <EyeIcon />
                                                    </button>
                                                  </Tooltip>
                                                </div>
                                                <div
                                                  style={{
                                                    display:
                                                      value.customer_number !==
                                                      null
                                                        ? "none"
                                                        : undefined,
                                                  }}
                                                  className="button--icon"
                                                >
                                                  <button
                                                    disabled={
                                                      value.customer_number !==
                                                      null
                                                    }
                                                    onClick={() => {
                                                      setCustomerId(
                                                        value.reference_id
                                                      );
                                                      setCompanyId(
                                                        value.company_id
                                                      );
                                                      handleCustomerNumberOpen();
                                                    }}
                                                  >
                                                    <UploadIcon />
                                                  </button>
                                                </div>
                                              </div>
                                              <CustomerNumberUpdateModal
                                                status={status}
                                                handleCustomerNumberClose={
                                                  handleCustomerNumberClose
                                                }
                                                companyId={companyId}
                                                customerId={customerId}
                                                companyNumberModal={
                                                  companyNumberModal
                                                }
                                                setLoader={setLoader}
                                                fetchData={fetchData}
                                              />
                                            </TableCell>

                                            {/*Profile Data Sheet */}
                                            <TableCell
                                              align="center"
                                              padding="none"
                                            >
                                              <div
                                                className="flex alignitems--center"
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <Tooltip
                                                  title={
                                                    value.profile_data_sheet
                                                      ? `Status Updated On: ${formatDate(
                                                          value?.basic_details
                                                            ?.updatedAt
                                                        )}`
                                                      : ""
                                                  }
                                                >
                                                  <Checkbox
                                                    checked={
                                                      value.profile_data_sheet
                                                    }
                                                  />
                                                </Tooltip>
                                                <div className="btn--wrap">
                                                  <Tooltip
                                                    title={
                                                      value.profile_data_sheet
                                                        ? `View/Download`
                                                        : ""
                                                    }
                                                  >
                                                    <button
                                                      disabled={
                                                        value.profile_data_sheet ===
                                                        false
                                                      }
                                                      onClick={() => {
                                                        previewProfileDataSheet(
                                                          value
                                                        );
                                                      }}
                                                      className="view-btn"
                                                    >
                                                      <EyeIcon />
                                                    </button>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </TableCell>

                                            {/* Calendar Complete */}
                                            <TableCell>
                                              <div
                                                className="flex alignitems--center"
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <Tooltip
                                                  title={
                                                    value.quote_requested
                                                      ? `Status Updated On: ${formatDate(
                                                          value?.update_status
                                                            ?.quote_requested
                                                        )}`
                                                      : ""
                                                  }
                                                >
                                                  <Checkbox
                                                    checked={
                                                      value.quote_requested
                                                    }
                                                  />
                                                </Tooltip>
                                                <div className="btn--wrap">
                                                  <Tooltip
                                                    title={
                                                      value.quote_requested
                                                        ? "View/Download"
                                                        : ""
                                                    }
                                                  >
                                                    <button
                                                      disabled={
                                                        value.quote_requested ===
                                                        false
                                                      }
                                                      onClick={() => {
                                                        previewQuoteRequested(
                                                          value
                                                        );
                                                      }}
                                                      className="view-btn"
                                                    >
                                                      <EyeIcon />
                                                    </button>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </TableCell>

                                            {/* Quote Requested */}
                                            <TableCell
                                              align="center"
                                              padding="none"
                                            >
                                              <div
                                                className="flex alignitems--center"
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <Tooltip
                                                  title={
                                                    value.quote_requested
                                                      ? `Status Updated On: ${formatDate(
                                                          value?.update_status
                                                            ?.quote_requested
                                                        )}`
                                                      : ""
                                                  }
                                                >
                                                  <Checkbox
                                                    checked={
                                                      value.quote_requested
                                                    }
                                                  />
                                                </Tooltip>
                                                <div className="btn--wrap">
                                                  <Tooltip
                                                    title={
                                                      value.quote_requested
                                                        ? `View/Download`
                                                        : ""
                                                    }
                                                  >
                                                    <button
                                                      disabled={
                                                        value.quote_requested ===
                                                        false
                                                      }
                                                      onClick={() => {
                                                        previewQuoteRequested(
                                                          value
                                                        );
                                                      }}
                                                      className="view-btn"
                                                    >
                                                      <EyeIcon />
                                                    </button>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </TableCell>

                                            {/* Quote Issued */}
                                            <TableCell
                                              align="center"
                                              padding="none"
                                            >
                                              <div
                                                className="flex alignitems--center"
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <Tooltip
                                                  title={
                                                    value.quote_issued
                                                      ? `Status Updated On: ${formatDate(
                                                          value?.update_status
                                                            ?.quote_issued
                                                        )}`
                                                      : ""
                                                  }
                                                >
                                                  <Checkbox
                                                    checked={value.quote_issued}
                                                  />
                                                </Tooltip>
                                                <div className="btn--wrap">
                                                  <Tooltip
                                                    title={
                                                      value.quote_issued
                                                        ? `View/Download`
                                                        : ""
                                                    }
                                                  >
                                                    <button
                                                      disabled={
                                                        value.quote_issued ===
                                                        false
                                                      }
                                                      onClick={() => {
                                                        window.open(
                                                          value.quote_file,
                                                          "_blank",
                                                          "noreferrer"
                                                        );
                                                      }}
                                                      className="view-btn"
                                                    >
                                                      <EyeIcon />
                                                    </button>
                                                  </Tooltip>
                                                </div>
                                                <div
                                                  style={{
                                                    display:
                                                      value.quote_issued ===
                                                      true
                                                        ? "none"
                                                        : undefined,
                                                  }}
                                                  className="button--icon"
                                                >
                                                  <button
                                                    disabled={
                                                      value.quote_requested ===
                                                        false ||
                                                      value.customer_number ===
                                                        null
                                                    }
                                                    onClick={() => {
                                                      setCustomerId(
                                                        value.reference_id
                                                      );

                                                      setstatus(
                                                        value.update_status
                                                      );
                                                      let mappedData = [];
                                                      value.data.forEach(
                                                        (e) => {
                                                          mappedData.push({
                                                            matrix: e.material,
                                                            id: e.id,
                                                            pin: "",
                                                          });
                                                        }
                                                      );

                                                      setQuoteIssue({
                                                        ...quoteIssue,
                                                        pin: mappedData,
                                                        customer_number:
                                                          value.customer_number,
                                                      });
                                                      setMatrixData(value.data);
                                                      handleQuoteOpen();
                                                    }}
                                                  >
                                                    <UploadIcon />
                                                  </button>
                                                </div>
                                              </div>
                                            </TableCell>

                                            {/* PO Issued */}
                                            <TableCell
                                              align="center"
                                              padding="none"
                                            >
                                              <div
                                                className="flex alignitems--center"
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <Tooltip
                                                  title={
                                                    value.po_posted
                                                      ? `Status Updated On: ${formatDate(
                                                          value?.update_status
                                                            ?.po_issued
                                                        )}`
                                                      : ""
                                                  }
                                                >
                                                  <Checkbox
                                                    checked={value.po_posted}
                                                  />
                                                </Tooltip>
                                                <div className="btn--wrap">
                                                  <Tooltip
                                                    title={
                                                      value.po_posted
                                                        ? "View/Download"
                                                        : ""
                                                    }
                                                  >
                                                    <button
                                                      disabled={
                                                        value.po_posted ===
                                                        false
                                                      }
                                                      onClick={() => {
                                                        window.open(
                                                          value.po_file,
                                                          "_blank",
                                                          "noreferrer"
                                                        );
                                                      }}
                                                      className="view-btn"
                                                    >
                                                      <EyeIcon />
                                                    </button>
                                                  </Tooltip>
                                                </div>
                                                <div
                                                  style={{
                                                    display:
                                                      value.po_posted === true
                                                        ? "none"
                                                        : undefined,
                                                  }}
                                                  className="button--icon"
                                                >
                                                  <button
                                                    disabled={
                                                      value.quote_issued ===
                                                      false
                                                    }
                                                    onClick={() => {
                                                      setCustomerId(
                                                        value.reference_id
                                                      );
                                                      setstatus(
                                                        value.update_status
                                                      );
                                                      handlePoOpen();
                                                    }}
                                                  >
                                                    <UploadIcon />
                                                  </button>
                                                </div>
                                              </div>
                                            </TableCell>

                                            {/* Sample Sent */}
                                            <TableCell>
                                              <div
                                                className="flex alignitems--center"
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <Tooltip
                                                  title={
                                                    masterCheckedRmpt188(value)
                                                      ? getMasterLastUpdatedDateRmpt188(
                                                          value
                                                        )
                                                      : ""
                                                  }
                                                >
                                                  <Checkbox
                                                    checked={masterCheckedRmpt188(
                                                      value
                                                    )}
                                                  />
                                                </Tooltip>
                                                <div className="btn--wrap">
                                                  <Tooltip
                                                    title={
                                                      masterCheckedRmpt188(
                                                        value
                                                      )
                                                        ? "View/Download"
                                                        : ""
                                                    }
                                                  >
                                                    <button
                                                      disabled={
                                                        masterCheckedRmpt188(
                                                          value
                                                        ) === false
                                                      }
                                                      className="view-btn"
                                                      onClick={() => {
                                                        setCustomerId(
                                                          value.reference_id
                                                        );
                                                        setUserId(
                                                          value.user_id
                                                        );

                                                        const arr = [];
                                                        value.data.forEach(
                                                          (e) => {
                                                            arr.push(e);
                                                          }
                                                        );
                                                        setCalenderData(arr);

                                                        handleViewSampleOpen();
                                                      }}
                                                    >
                                                      <EyeIcon />
                                                    </button>
                                                  </Tooltip>

                                                  {/* Sample Sent View Modal */}
                                                  <SampleSentViewModal
                                                    value={value}
                                                    setLoader={setLoader}
                                                    viewSampleModal={
                                                      viewSampleModal
                                                    }
                                                    handleViewSampleClose={
                                                      handleViewSampleClose
                                                    }
                                                    calenderData={calenderData}
                                                    userId={userId}
                                                    customerId={customerId}
                                                  />
                                                </div>
                                              </div>
                                            </TableCell>

                                            {/* Analysis Complete */}
                                            <TableCell>
                                              <div
                                                className="flex alignitems--center"
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <Tooltip
                                                  title={
                                                    masterCheckedRmpt189(value)
                                                      ? getMasterLastUpdatedDateRmpt189(
                                                          value
                                                        )
                                                      : ""
                                                  }
                                                >
                                                  <Checkbox
                                                    checked={masterCheckedRmpt189(
                                                      value
                                                    )}
                                                  />
                                                </Tooltip>
                                                <div className="btn--wrap">
                                                  <Tooltip title="">
                                                    <button
                                                      className="view-btn"
                                                      disabled={
                                                        masterCheckedRmpt189(
                                                          value
                                                        ) === false
                                                      }
                                                      onClick={() => {
                                                        setCustomerId(
                                                          value.reference_id
                                                        );
                                                        setUserId(
                                                          value.user_id
                                                        );

                                                        const arr = [];
                                                        value.data.forEach(
                                                          (e) => {
                                                            arr.push(e);
                                                          }
                                                        );
                                                        setCalenderData(arr);
                                                        handleViewAnalysisFormCompleteOpen();
                                                      }}
                                                    >
                                                      <EyeIcon />
                                                    </button>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </TableCell>

                                            {/* Appeal Letter */}
                                            <TableCell>
                                              <div
                                                className="flex alignitems--center"
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <Tooltip
                                                  title={
                                                    masterCheckedRmptAppeal(
                                                      value
                                                    )
                                                      ? getMasterLastUpdatedDateAppeal(
                                                          value
                                                        )
                                                      : ""
                                                  }
                                                >
                                                  <Checkbox
                                                    checked={masterCheckedRmptAppeal(
                                                      value
                                                    )}
                                                  />
                                                </Tooltip>
                                                <div className="btn--wrap">
                                                  <Tooltip
                                                    title={
                                                      masterCheckedRmptAppeal(
                                                        value
                                                      )
                                                        ? "View/Download"
                                                        : ""
                                                    }
                                                  >
                                                    <button
                                                      disabled={
                                                        masterCheckedRmptAppeal(
                                                          value
                                                        ) === false
                                                      }
                                                      onClick={() => {
                                                        setCustomerId(
                                                          value.reference_id
                                                        );
                                                        setUserId(
                                                          value.user_id
                                                        );

                                                        const arr = [];
                                                        value.data.forEach(
                                                          (e) => {
                                                            arr.push(e);
                                                          }
                                                        );
                                                        setCalenderData(arr);

                                                        handleViewAppealLetterOpen();
                                                      }}
                                                      className="view-btn"
                                                    >
                                                      <EyeIcon />
                                                    </button>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </TableCell>

                                            {/* Appeal Complete */}
                                            <TableCell>
                                              <div
                                                className="flex alignitems--center"
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <Tooltip
                                                  title={
                                                    masterCheckedRmptReappeal(
                                                      value
                                                    )
                                                      ? getMasterLastUpdatedDateRmptReappeal(
                                                          value
                                                        )
                                                      : ""
                                                  }
                                                >
                                                  <Checkbox
                                                    checked={masterCheckedRmptReappeal(
                                                      value
                                                    )}
                                                  />
                                                </Tooltip>
                                                <div className="btn--wrap">
                                                  <Tooltip
                                                    title={
                                                      masterCheckedRmptReappeal(
                                                        value
                                                      )
                                                        ? "View/Download"
                                                        : ""
                                                    }
                                                  >
                                                    <button
                                                      disabled={
                                                        masterCheckedRmptReappeal(
                                                          value
                                                        ) === false
                                                      }
                                                      onClick={() => {
                                                        setCustomerId(
                                                          value.reference_id
                                                        );
                                                        setUserId(
                                                          value.user_id
                                                        );
                                                        const arr = [];
                                                        value.data.forEach(
                                                          (e) => {
                                                            arr.push(e);
                                                          }
                                                        );
                                                        setCalenderData(arr);

                                                        handleViewAppealCompleteOpen();
                                                      }}
                                                      className="view-btn"
                                                    >
                                                      <EyeIcon />
                                                    </button>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </TableCell>

                                            {/* Sample Complete */}
                                            <TableCell>
                                              <div
                                                className="flex alignitems--center"
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <Tooltip
                                                  title={
                                                    getSampleCloseChartChecked(
                                                      value
                                                    )
                                                      ? getSampleClosedLastUpdatedDate(
                                                          value
                                                        )
                                                      : ""
                                                  }
                                                >
                                                  <Checkbox
                                                    checked={getSampleCloseChartChecked(
                                                      value
                                                    )}
                                                  />
                                                </Tooltip>
                                                <div className="btn--wrap">
                                                  <Tooltip
                                                    title={
                                                      getSampleCloseChartChecked(
                                                        value
                                                      )
                                                        ? "View/Download"
                                                        : ""
                                                    }
                                                  >
                                                    <button
                                                      disabled={
                                                        !getSampleCloseChartChecked(
                                                          value
                                                        )
                                                      }
                                                      onClick={() => {
                                                        setCustomerId(
                                                          value.reference_id
                                                        );
                                                        setUserId(
                                                          value.user_id
                                                        );

                                                        const arr = [];
                                                        value.data.forEach(
                                                          (e) => {
                                                            arr.push(e);
                                                          }
                                                        );
                                                        setCalenderData(arr);

                                                        handleChartModalOpen();
                                                      }}
                                                      className="view-btn"
                                                    >
                                                      <EyeIcon />
                                                    </button>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </TableCell>

                                            {/* View All Details */}
                                            <TableCell
                                              align="center"
                                              padding="none"
                                            >
                                              <div
                                                className="flex alignitems--center"
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <div className="btn--wrap">
                                                  <Tooltip title="">
                                                    <button className="view-btn">
                                                      <EyeIcon />
                                                    </button>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                          <TableRow className="table__row">
                                            <TableCell colSpan={15}>
                                              <Collapse
                                                in={activeIndex === index}
                                                timeout="auto"
                                                unmountOnExit
                                              >
                                                {value.calenderData &&
                                                value.calenderData.length > 0
                                                  ? value.calenderData.map(
                                                      (val, index) => {
                                                        return (
                                                          <Table>
                                                            <TableBody>
                                                              <TableRow
                                                                hover
                                                                className="table__subRow"
                                                              >
                                                                <TableCell
                                                                  align="left"
                                                                  padding="none"
                                                                ></TableCell>

                                                                <TableCell
                                                                  align="left"
                                                                  padding="none"
                                                                >
                                                                  {val.sample_date ===
                                                                  null
                                                                    ? value.reference_id
                                                                    : generateMasterRmptNumber(
                                                                        val,
                                                                        val.pin
                                                                      )}
                                                                </TableCell>

                                                                <TableCell
                                                                  align="center"
                                                                  padding="none"
                                                                >
                                                                  <div
                                                                    className="flex alignitems--center"
                                                                    style={{
                                                                      justifyContent:
                                                                        "center",
                                                                    }}
                                                                  ></div>
                                                                </TableCell>

                                                                <TableCell
                                                                  align="center"
                                                                  padding="none"
                                                                >
                                                                  <div
                                                                    className="flex alignitems--center"
                                                                    style={{
                                                                      justifyContent:
                                                                        "center",
                                                                    }}
                                                                  ></div>
                                                                </TableCell>
                                                                {/*Profile Data Sheet */}
                                                                <TableCell
                                                                  align="center"
                                                                  padding="none"
                                                                >
                                                                  <div
                                                                    className="flex alignitems--center"
                                                                    style={{
                                                                      justifyContent:
                                                                        "center",
                                                                    }}
                                                                  ></div>
                                                                </TableCell>
                                                                <TableCell
                                                                  align="center"
                                                                  padding="none"
                                                                >
                                                                  <div
                                                                    className="flex alignitems--center"
                                                                    style={{
                                                                      justifyContent:
                                                                        "center",
                                                                    }}
                                                                  ></div>
                                                                </TableCell>
                                                                <TableCell
                                                                  align="center"
                                                                  padding="none"
                                                                >
                                                                  <div
                                                                    className="flex alignitems--center"
                                                                    style={{
                                                                      justifyContent:
                                                                        "center",
                                                                    }}
                                                                  ></div>
                                                                </TableCell>

                                                                <TableCell
                                                                  align="center"
                                                                  padding="none"
                                                                >
                                                                  <div
                                                                    className="flex alignitems--center"
                                                                    style={{
                                                                      justifyContent:
                                                                        "center",
                                                                    }}
                                                                  ></div>
                                                                </TableCell>

                                                                <TableCell
                                                                  align="center"
                                                                  padding="none"
                                                                >
                                                                  <div
                                                                    className="flex alignitems--center"
                                                                    style={{
                                                                      justifyContent:
                                                                        "center",
                                                                    }}
                                                                  ></div>
                                                                </TableCell>

                                                                <TableCell
                                                                  align="center"
                                                                  padding="none"
                                                                >
                                                                  <div
                                                                    className="flex alignitems--center"
                                                                    style={{
                                                                      justifyContent:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    <Tooltip
                                                                      title={
                                                                        masterCheckedChildRmpt188(
                                                                          val
                                                                        )
                                                                          ? getMasterChildLastUpdatedDateRmpt188(
                                                                              val
                                                                            )
                                                                          : ""
                                                                      }
                                                                    >
                                                                      <Checkbox
                                                                        checked={masterCheckedChildRmpt188(
                                                                          val
                                                                        )}
                                                                      />
                                                                    </Tooltip>
                                                                    <div className="btn--wrap">
                                                                      <Tooltip
                                                                        title={
                                                                          masterCheckedChildRmpt188(
                                                                            val
                                                                          )
                                                                            ? "View/Download"
                                                                            : ""
                                                                        }
                                                                      >
                                                                        <button
                                                                          disabled={
                                                                            masterCheckedChildRmpt188(
                                                                              val
                                                                            ) ===
                                                                            false
                                                                          }
                                                                          className="view-btn"
                                                                          onClick={() => {
                                                                            setCustomerId(
                                                                              value.reference_id
                                                                            );
                                                                            setUserId(
                                                                              value.user_id
                                                                            );

                                                                            const arr =
                                                                              [];
                                                                            val.child.forEach(
                                                                              (
                                                                                e
                                                                              ) => {
                                                                                arr.push(
                                                                                  e
                                                                                );
                                                                              }
                                                                            );
                                                                            setCalenderData(
                                                                              arr
                                                                            );
                                                                            handleViewSampleOpen();
                                                                          }}
                                                                        >
                                                                          <EyeIcon />
                                                                        </button>
                                                                      </Tooltip>
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        display:
                                                                          masterCheckedChildRmpt188(
                                                                            val
                                                                          ) ===
                                                                          true
                                                                            ? "none"
                                                                            : undefined,
                                                                      }}
                                                                      className="button--icon"
                                                                    >
                                                                      <button
                                                                        disabled={
                                                                          value.po_posted ===
                                                                          false
                                                                        }
                                                                        onClick={() => {
                                                                          setstatus(
                                                                            value.update_status
                                                                          );

                                                                          setCustomerId(
                                                                            value.reference_id
                                                                          );
                                                                          setUserId(
                                                                            value.user_id
                                                                          );

                                                                          const arr =
                                                                            [];
                                                                          val.child.forEach(
                                                                            (
                                                                              e
                                                                            ) => {
                                                                              arr.push(
                                                                                e
                                                                              );
                                                                            }
                                                                          );
                                                                          setCalenderData(
                                                                            arr
                                                                          );
                                                                          setViewSampleSentModal(
                                                                            true
                                                                          );
                                                                        }}
                                                                      >
                                                                        <UploadIcon />
                                                                      </button>
                                                                    </div>
                                                                  </div>
                                                                </TableCell>

                                                                {/* Analysis Complete */}
                                                                <TableCell>
                                                                  <div
                                                                    className="flex alignitems--center"
                                                                    style={{
                                                                      justifyContent:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    <Tooltip
                                                                      title={
                                                                        masterCheckedChildRmpt189(
                                                                          val
                                                                        )
                                                                          ? getMasterChildLastUpdatedDateRmpt189(
                                                                              val
                                                                            )
                                                                          : ""
                                                                      }
                                                                    >
                                                                      <Checkbox
                                                                        checked={masterCheckedChildRmpt189(
                                                                          val
                                                                        )}
                                                                      />
                                                                    </Tooltip>
                                                                    <div className="btn--wrap">
                                                                      <Tooltip title="">
                                                                        <button
                                                                          disabled={
                                                                            masterCheckedChildRmpt189(
                                                                              val
                                                                            ) ===
                                                                            false
                                                                          }
                                                                          className="view-btn"
                                                                          onClick={() => {
                                                                            setCustomerId(
                                                                              value.reference_id
                                                                            );
                                                                            setUserId(
                                                                              value.user_id
                                                                            );

                                                                            const arr =
                                                                              [];
                                                                            val.child.forEach(
                                                                              (
                                                                                e
                                                                              ) => {
                                                                                arr.push(
                                                                                  e
                                                                                );
                                                                              }
                                                                            );
                                                                            setCalenderData(
                                                                              arr
                                                                            );

                                                                            handleViewAnalysisFormCompleteOpen();
                                                                          }}
                                                                        >
                                                                          <EyeIcon />
                                                                        </button>
                                                                      </Tooltip>
                                                                    </div>
                                                                  </div>
                                                                </TableCell>

                                                                {/* Appeal Letter */}
                                                                <TableCell>
                                                                  <div
                                                                    className="flex alignitems--center"
                                                                    style={{
                                                                      justifyContent:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    <Tooltip
                                                                      title={
                                                                        masterCheckedChildRmptAppeal(
                                                                          val
                                                                        )
                                                                          ? getMasterChildLastUpdatedDateAppeal(
                                                                              val
                                                                            )
                                                                          : ""
                                                                      }
                                                                    >
                                                                      <Checkbox
                                                                        checked={masterCheckedChildRmptAppeal(
                                                                          val
                                                                        )}
                                                                      />
                                                                    </Tooltip>
                                                                    <div className="btn--wrap">
                                                                      <Tooltip
                                                                        title={
                                                                          masterCheckedChildRmptAppeal(
                                                                            val
                                                                          )
                                                                            ? "View/Download"
                                                                            : ""
                                                                        }
                                                                      >
                                                                        <button
                                                                          disabled={
                                                                            masterCheckedChildRmptAppeal(
                                                                              val
                                                                            ) ===
                                                                            false
                                                                          }
                                                                          onClick={() => {
                                                                            setCustomerId(
                                                                              value.reference_id
                                                                            );
                                                                            setUserId(
                                                                              value.user_id
                                                                            );

                                                                            const arr =
                                                                              [];
                                                                            val.child.forEach(
                                                                              (
                                                                                e
                                                                              ) => {
                                                                                arr.push(
                                                                                  e
                                                                                );
                                                                              }
                                                                            );
                                                                            setCalenderData(
                                                                              arr
                                                                            );

                                                                            handleViewAppealLetterOpen();
                                                                          }}
                                                                          className="view-btn"
                                                                        >
                                                                          <EyeIcon />
                                                                        </button>
                                                                      </Tooltip>
                                                                    </div>
                                                                  </div>
                                                                </TableCell>
                                                                {/* Appeal Complete */}
                                                                <TableCell>
                                                                  <div
                                                                    className="flex alignitems--center"
                                                                    style={{
                                                                      justifyContent:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    <Tooltip
                                                                      title={
                                                                        masterCheckedChildRmptReappeal(
                                                                          val
                                                                        )
                                                                          ? getMasterChildLastUpdatedDateRmptReappeal(
                                                                              val
                                                                            )
                                                                          : ""
                                                                      }
                                                                    >
                                                                      <Checkbox
                                                                        checked={masterCheckedChildRmptReappeal(
                                                                          val
                                                                        )}
                                                                      />
                                                                    </Tooltip>
                                                                    <div className="btn--wrap">
                                                                      <Tooltip
                                                                        title={
                                                                          masterCheckedChildRmptReappeal(
                                                                            val
                                                                          )
                                                                            ? "View/Download"
                                                                            : ""
                                                                        }
                                                                      >
                                                                        <button
                                                                          disabled={
                                                                            masterCheckedChildRmptReappeal(
                                                                              val
                                                                            ) ===
                                                                            false
                                                                          }
                                                                          onClick={() => {
                                                                            setCustomerId(
                                                                              value.reference_id
                                                                            );
                                                                            setUserId(
                                                                              value.user_id
                                                                            );
                                                                            const arr =
                                                                              [];
                                                                            val.child.forEach(
                                                                              (
                                                                                e
                                                                              ) => {
                                                                                arr.push(
                                                                                  e
                                                                                );
                                                                              }
                                                                            );
                                                                            setCalenderData(
                                                                              arr
                                                                            );

                                                                            handleViewAppealCompleteOpen();
                                                                          }}
                                                                          className="view-btn"
                                                                        >
                                                                          <EyeIcon />
                                                                        </button>
                                                                      </Tooltip>
                                                                    </div>
                                                                  </div>
                                                                </TableCell>

                                                                {/* Sample Complete */}
                                                                <TableCell>
                                                                  <div
                                                                    className="flex alignitems--center"
                                                                    style={{
                                                                      justifyContent:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    <Tooltip
                                                                      title={
                                                                        getSampleCloseChartChecked(
                                                                          value
                                                                        )
                                                                          ? getSampleClosedLastUpdatedDate(
                                                                              value
                                                                            )
                                                                          : ""
                                                                      }
                                                                    >
                                                                      <Checkbox
                                                                        checked={getSampleCloseChartChecked(
                                                                          value
                                                                        )}
                                                                      />
                                                                    </Tooltip>
                                                                    <div className="btn--wrap">
                                                                      <Tooltip
                                                                        title={
                                                                          getSampleCloseChartChecked(
                                                                            value
                                                                          )
                                                                            ? "View/Download"
                                                                            : ""
                                                                        }
                                                                      >
                                                                        <button
                                                                          disabled={
                                                                            !getSampleCloseChartChecked(
                                                                              value
                                                                            )
                                                                          }
                                                                          onClick={() => {
                                                                            setCustomerId(
                                                                              value.reference_id
                                                                            );
                                                                            setUserId(
                                                                              value.user_id
                                                                            );

                                                                            const arr =
                                                                              [];
                                                                            value.data.forEach(
                                                                              (
                                                                                e
                                                                              ) => {
                                                                                arr.push(
                                                                                  e
                                                                                );
                                                                              }
                                                                            );
                                                                            setCalenderData(
                                                                              arr
                                                                            );

                                                                            handleChartModalOpen();
                                                                          }}
                                                                          className="view-btn"
                                                                        >
                                                                          <EyeIcon />
                                                                        </button>
                                                                      </Tooltip>
                                                                    </div>
                                                                  </div>
                                                                </TableCell>

                                                                <TableCell
                                                                  align="center"
                                                                  padding="none"
                                                                >
                                                                  <div className="btn--wrap">
                                                                    <Tooltip title="">
                                                                      <button className="view-btn">
                                                                        <EyeIcon />
                                                                      </button>
                                                                    </Tooltip>
                                                                  </div>
                                                                </TableCell>
                                                              </TableRow>
                                                              <TableRow
                                                                hover
                                                                className="table__subRow"
                                                              >
                                                                <TableCell
                                                                  colSpan={15}
                                                                >
                                                                  <Table>
                                                                    <TableBody>
                                                                      {val.child &&
                                                                      val.child
                                                                        .length >
                                                                        0
                                                                        ? val.child.map(
                                                                            (
                                                                              val,
                                                                              idx
                                                                            ) => {
                                                                              return (
                                                                                <TableRow
                                                                                  hover
                                                                                  className="table__childRow"
                                                                                >
                                                                                  <TableCell
                                                                                    align="left"
                                                                                    padding="none"
                                                                                  ></TableCell>

                                                                                  <TableCell
                                                                                    align="left"
                                                                                    padding="none"
                                                                                  >
                                                                                    {val.order_date ===
                                                                                    null ? (
                                                                                      value.reference_id
                                                                                    ) : (
                                                                                      <Tooltip
                                                                                        title={
                                                                                          generateChildRmptNumber(
                                                                                            val,
                                                                                            val.pin
                                                                                          ) +
                                                                                          "-" +
                                                                                          val.counting +
                                                                                          val.method +
                                                                                          getValue(
                                                                                            val.sample_type
                                                                                          )
                                                                                        }
                                                                                      >
                                                                                        <div>
                                                                                          {" "}
                                                                                          {generateChildRmptNumber(
                                                                                            val,
                                                                                            val.pin
                                                                                          ) +
                                                                                            "-" +
                                                                                            val.counting +
                                                                                            getMethod(
                                                                                              val.method
                                                                                            ) +
                                                                                            getValue(
                                                                                              val.sample_type
                                                                                            )}
                                                                                        </div>
                                                                                      </Tooltip>
                                                                                    )}
                                                                                  </TableCell>

                                                                                  <TableCell
                                                                                    align="center"
                                                                                    padding="none"
                                                                                  >
                                                                                    <div
                                                                                      className="flex alignitems--center"
                                                                                      style={{
                                                                                        justifyContent:
                                                                                          "center",
                                                                                      }}
                                                                                    ></div>
                                                                                  </TableCell>

                                                                                  <TableCell
                                                                                    align="center"
                                                                                    padding="none"
                                                                                  >
                                                                                    <div
                                                                                      className="flex alignitems--center"
                                                                                      style={{
                                                                                        justifyContent:
                                                                                          "center",
                                                                                      }}
                                                                                    ></div>
                                                                                  </TableCell>
                                                                                  {/*Profile Data Sheet */}
                                                                                  <TableCell
                                                                                    align="center"
                                                                                    padding="none"
                                                                                  >
                                                                                    <div
                                                                                      className="flex alignitems--center"
                                                                                      style={{
                                                                                        justifyContent:
                                                                                          "center",
                                                                                      }}
                                                                                    ></div>
                                                                                  </TableCell>
                                                                                  {/* Calendar Complete */}
                                                                                  <TableCell>
                                                                                    <div
                                                                                      className="flex alignitems--center"
                                                                                      style={{
                                                                                        justifyContent:
                                                                                          "center",
                                                                                      }}
                                                                                    ></div>
                                                                                  </TableCell>

                                                                                  <TableCell
                                                                                    align="center"
                                                                                    padding="none"
                                                                                  >
                                                                                    <div
                                                                                      className="flex alignitems--center"
                                                                                      style={{
                                                                                        justifyContent:
                                                                                          "center",
                                                                                      }}
                                                                                    ></div>
                                                                                  </TableCell>

                                                                                  <TableCell
                                                                                    align="center"
                                                                                    padding="none"
                                                                                  >
                                                                                    <div
                                                                                      className="flex alignitems--center"
                                                                                      style={{
                                                                                        justifyContent:
                                                                                          "center",
                                                                                      }}
                                                                                    ></div>
                                                                                  </TableCell>

                                                                                  <TableCell
                                                                                    align="center"
                                                                                    padding="none"
                                                                                  >
                                                                                    <div
                                                                                      className="flex alignitems--center"
                                                                                      style={{
                                                                                        justifyContent:
                                                                                          "center",
                                                                                      }}
                                                                                    ></div>
                                                                                  </TableCell>

                                                                                  <TableCell
                                                                                    align="center"
                                                                                    padding="none"
                                                                                  >
                                                                                    <div
                                                                                      className="flex alignitems--center"
                                                                                      style={{
                                                                                        justifyContent:
                                                                                          "center",
                                                                                      }}
                                                                                    ></div>
                                                                                  </TableCell>

                                                                                  {/* Analysis Complete */}
                                                                                  <TableCell>
                                                                                    <div
                                                                                      className="flex alignitems--center"
                                                                                      style={{
                                                                                        justifyContent:
                                                                                          "center",
                                                                                      }}
                                                                                    >
                                                                                      <Tooltip
                                                                                        title={
                                                                                          val.rmpt189
                                                                                            ? `Status Updated On: ${formatDate(
                                                                                                val.rmpt189updated
                                                                                              )}`
                                                                                            : ""
                                                                                        }
                                                                                      >
                                                                                        <Checkbox
                                                                                          checked={
                                                                                            val.rmpt189
                                                                                          }
                                                                                        />
                                                                                      </Tooltip>
                                                                                      <div className="btn--wrap">
                                                                                        <Tooltip title="">
                                                                                          <button
                                                                                            className="view-btn"
                                                                                            disabled={
                                                                                              !(
                                                                                                val.rmpt189 ===
                                                                                                true
                                                                                              )
                                                                                            }
                                                                                            onClick={() => {
                                                                                              previewRmpt189(
                                                                                                val,
                                                                                                value
                                                                                              );
                                                                                            }}
                                                                                          >
                                                                                            <EyeIcon />
                                                                                          </button>
                                                                                        </Tooltip>
                                                                                      </div>
                                                                                    </div>
                                                                                  </TableCell>
                                                                                  {/* Appeal Letter */}
                                                                                  <TableCell>
                                                                                    <div
                                                                                      className="flex alignitems--center"
                                                                                      style={{
                                                                                        justifyContent:
                                                                                          "center",
                                                                                      }}
                                                                                    >
                                                                                      <Tooltip
                                                                                        title={
                                                                                          val.isAppeal
                                                                                            ? `Status Updated On ${formatDate(
                                                                                                val.appealupdated
                                                                                              )}`
                                                                                            : ""
                                                                                        }
                                                                                      >
                                                                                        <Checkbox
                                                                                          checked={
                                                                                            val.isAppeal
                                                                                          }
                                                                                        />
                                                                                      </Tooltip>
                                                                                      <div className="btn--wrap">
                                                                                        <Tooltip
                                                                                          title={
                                                                                            val.isAppeal
                                                                                              ? "View/Download"
                                                                                              : ""
                                                                                          }
                                                                                        >
                                                                                          <button
                                                                                            disabled={
                                                                                              !(
                                                                                                val.isAppeal ===
                                                                                                true
                                                                                              )
                                                                                            }
                                                                                            onClick={() => {
                                                                                              previewRmpt191(
                                                                                                val,
                                                                                                value
                                                                                              );
                                                                                            }}
                                                                                            className="view-btn"
                                                                                          >
                                                                                            <EyeIcon />
                                                                                          </button>
                                                                                        </Tooltip>
                                                                                      </div>
                                                                                    </div>
                                                                                  </TableCell>
                                                                                  {/* Appeal Complete */}
                                                                                  <TableCell>
                                                                                    <div
                                                                                      className="flex alignitems--center"
                                                                                      style={{
                                                                                        justifyContent:
                                                                                          "center",
                                                                                      }}
                                                                                    >
                                                                                      <Tooltip
                                                                                        title={
                                                                                          val.isReappeal
                                                                                            ? `Status Updated On ${formatDate(
                                                                                                val.reappealupdated
                                                                                              )}`
                                                                                            : ""
                                                                                        }
                                                                                      >
                                                                                        <Checkbox
                                                                                          checked={
                                                                                            val.isReappeal
                                                                                          }
                                                                                        />
                                                                                      </Tooltip>
                                                                                      <div className="btn--wrap">
                                                                                        <Tooltip
                                                                                          title={
                                                                                            val.isReappeal
                                                                                              ? "View/Download"
                                                                                              : ""
                                                                                          }
                                                                                        >
                                                                                          <button
                                                                                            disabled={
                                                                                              !(
                                                                                                val.isReappeal ===
                                                                                                true
                                                                                              )
                                                                                            }
                                                                                            onClick={() => {
                                                                                              previewRmptReappeal(
                                                                                                val,
                                                                                                value
                                                                                              );
                                                                                            }}
                                                                                            className="view-btn"
                                                                                          >
                                                                                            <EyeIcon />
                                                                                          </button>
                                                                                        </Tooltip>
                                                                                      </div>
                                                                                    </div>
                                                                                  </TableCell>

                                                                                  {/* Sample Complete */}
                                                                                  <TableCell>
                                                                                    <div
                                                                                      className="flex alignitems--center"
                                                                                      style={{
                                                                                        justifyContent:
                                                                                          "center",
                                                                                      }}
                                                                                    >
                                                                                      <Tooltip
                                                                                        title={
                                                                                          getSampleCloseChartChecked(
                                                                                            value
                                                                                          )
                                                                                            ? getSampleClosedLastUpdatedDate(
                                                                                                value
                                                                                              )
                                                                                            : ""
                                                                                        }
                                                                                      >
                                                                                        <Checkbox
                                                                                          checked={getSampleCloseChartChecked(
                                                                                            value
                                                                                          )}
                                                                                        />
                                                                                      </Tooltip>
                                                                                      <div className="btn--wrap">
                                                                                        <Tooltip
                                                                                          title={
                                                                                            getSampleCloseChartChecked(
                                                                                              value
                                                                                            )
                                                                                              ? "View/Download"
                                                                                              : ""
                                                                                          }
                                                                                        >
                                                                                          <button
                                                                                            disabled={
                                                                                              !getSampleCloseChartChecked(
                                                                                                value
                                                                                              )
                                                                                            }
                                                                                            onClick={() => {
                                                                                              setCustomerId(
                                                                                                value.reference_id
                                                                                              );
                                                                                              setUserId(
                                                                                                value.user_id
                                                                                              );

                                                                                              const arr =
                                                                                                [];
                                                                                              value.data.forEach(
                                                                                                (
                                                                                                  e
                                                                                                ) => {
                                                                                                  arr.push(
                                                                                                    e
                                                                                                  );
                                                                                                }
                                                                                              );
                                                                                              setCalenderData(
                                                                                                arr
                                                                                              );

                                                                                              handleChartModalOpen();
                                                                                            }}
                                                                                            className="view-btn"
                                                                                          >
                                                                                            <EyeIcon />
                                                                                          </button>
                                                                                        </Tooltip>
                                                                                      </div>
                                                                                    </div>
                                                                                  </TableCell>

                                                                                  <TableCell
                                                                                    align="center"
                                                                                    padding="none"
                                                                                  >
                                                                                    <div className="btn--wrap">
                                                                                      <Tooltip title="">
                                                                                        <button className="view-btn">
                                                                                          <EyeIcon />
                                                                                        </button>
                                                                                      </Tooltip>
                                                                                    </div>
                                                                                  </TableCell>
                                                                                </TableRow>
                                                                              );
                                                                            }
                                                                          )
                                                                        : null}
                                                                    </TableBody>
                                                                  </Table>
                                                                </TableCell>
                                                              </TableRow>
                                                            </TableBody>
                                                          </Table>
                                                        );
                                                      }
                                                    )
                                                  : null}
                                              </Collapse>
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      );
                                    })
                                  : null}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                : null}
            </div>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function FormStatusAdmin() {
  const [isLoading, setLoading] = React.useState(false);
  LoadingOverlay.propTypes = undefined;
  return (
    <LoadingOverlay active={isLoading} spinner text="Loading ...">
      <ListingContent isLoading={isLoading} setLoading={setLoading} />
    </LoadingOverlay>
  );
}
