import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "../../../Images/logo.png";
import { getData } from "../../../Utils/utils";
import LoadingOverlay from "react-loading-overlay";

import "./footer.scss";

const Footer = ({ footerImg, footerEmails, emailList, content }) => {
  const [product, setProduct] = useState({});
  const location = useLocation();
  const [selectedService, setSelectedService] = useState(null);
  useEffect(() => {
    if (content && content.email) {
      setSelectedService(content);
    }
  }, [content]);
  const { product_categories = [] } = product || {};
  const [isLoading, setLoading] = React.useState(false);
  const fetchProductsData = async () => {
    setLoading(true);
    const result = await getData("/get/contents/by/category");
    setProduct(result);
    setLoading(false);
  };

  useEffect(() => {
    fetchProductsData();
  }, []);
  const handleContactClick = (email) => {
    console.log(location.pathname, "Pathname_check");

    if (location.pathname === "/") {
      const hardcodedSubject = "General Inquiry";
      const hardcodedEmail = email ? email : "Info@RMRMs.com";
      return {
        state: {
          email: hardcodedEmail,
          subject: hardcodedSubject,
          alert: true,
        },
      };
    } else if (location.pathname === "/products") {
      const hardcodedSubject = "Products Inquiry";
      const hardcodedEmail = email ? email : "Sales@RMRMs.com";
      return {
        state: {
          email: hardcodedEmail,
          subject: hardcodedSubject,
          alert: true,
        },
      };
    } else if (location.pathname === "/services") {
      const hardcodedSubject = "Services Inquiry";
      const hardcodedEmail = email ? email : "Info@RMRMs.com";
      return {
        state: {
          email: hardcodedEmail,
          subject: hardcodedSubject,
          alert: true,
        },
      };
    } else if (location.pathname === "/products/contents/") {
      const hardcodedSubject = selectedService?.subject;
      const hardcodedEmail = email ? email : selectedService?.email;
      return {
        state: {
          email: hardcodedEmail,
          subject: hardcodedSubject,
          fromproduct: true,
          alert: true,
        },
      };
    } else if (location.pathname === "/service/contents") {
      const hardcodedSubject = selectedService?.subject;
      const hardcodedEmail = email ? email : selectedService?.email;
      return {
        state: {
          email: hardcodedEmail,
          subject: hardcodedSubject,
          fromservice: true,
          alert: true,
        },
      };
    } else if (location.pathname === "/certificates") {
      const hardcodedSubject = "Certificate/Product Information Sheet Inquiry";
      const hardcodedEmail = email ? email : "Certs@RMRMs.com";
      return {
        state: {
          email: hardcodedEmail,
          subject: hardcodedSubject,
          alert: true,
        },
      };
    } else if (location.pathname === "/industry-catalogs") {
      const hardcodedSubject = "Industry Catalog Inquiry";
      const hardcodedEmail = email ? email : "Sales@RMRMs.com";
      return {
        state: {
          email: hardcodedEmail,
          subject: hardcodedSubject,
          alert: true,
        },
      };
    } else if (location.pathname === "/about") {
      const hardcodedSubject = "About Us Inquiry";
      const hardcodedEmail = email ? email : "Info@RMRMs.com";
      return {
        state: {
          email: hardcodedEmail,
          subject: hardcodedSubject,
          alert: true,
        },
      };
    } else if (location.pathname === "/photo-gallery") {
      const hardcodedSubject = "Photo Gallery Inquiry";
      const hardcodedEmail = email ? email : "Info@RMRMs.com";
      return {
        state: {
          email: hardcodedEmail,
          subject: hardcodedSubject,
          alert: true,
        },
      };
    } else if (location.pathname === "/latest-news") {
      const hardcodedSubject = "Latest News Inquiry";
      const hardcodedEmail = email ? email : "Info@RMRMs.com";
      return {
        state: {
          email: hardcodedEmail,
          subject: hardcodedSubject,
          alert: true,
        },
      };
    } else if (location.pathname === "/ourQuality") {
      const hardcodedSubject = "Quality Inquiry";
      const hardcodedEmail = email ? email : "Info@RMRMs.com";
      return {
        state: {
          email: hardcodedEmail,
          subject: hardcodedSubject,
          alert: true,
        },
      };
    } else if (location.pathname === "/search-engine") {
      const hardcodedEmail = email ? email : "Info@RMRMs.com";
      return {
        state: {
          email: hardcodedEmail,
          alert: true,
        },
      };
    } else {
      const hardcodedEmail = "Info@RMRMs.com";
      return { state: { email: hardcodedEmail, alert: true } };
    }
  };
  let footerImgWrap = (
    <div className="container">
      <div className="footer__images">
        <ul className="footer__images--list flex justifyspace--between">
          <li className="footer__images--list-item bg--white">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://rmrms.s3.amazonaws.com/Images/ISO+17034.pdf"
            >
              {" "}
              <img src="/images/anab-material.png" alt="" />
            </a>
          </li>
          <li className="footer__images--list-item ">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://rmrms.s3.amazonaws.com/Images/ISO+9001.pdf"
            >
              {" "}
              {/* <img src="/images/qas.png" alt="" /> */}
              <img src="/images/US4165.png" alt="" />
            </a>
          </li>
          <li className="footer__images--list-item bg--white">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://rmrms.s3.amazonaws.com/Images/ISO+17043.pdf"
            >
              <img src="/images/anab-testing.png" alt="" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
  LoadingOverlay.propTypes = undefined;

  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <footer className="mainFooter">
          <div className="container mb_20">
            <div className="row">
              <div className="col_xs_12 col_sm_12 col_md_12 col_lg_3">
                <div className="footer__branding">
                  <div className="footer__branding--logo flex alignitems--center">
                    <Link to="/">
                      <img src={Logo} alt="Logo" width={100} />
                    </Link>
                    <h4 className="title--h4 color--white text__align--left opacity-8">
                      Rocky Mountain <br />
                      Reference Materials
                    </h4>
                  </div>
                  <p className="color--text">Customer Satisfaction Survey</p>
                  <a
                    href="/pdf/QSF-108A.pdf"
                    className="btn btn--red"
                    target="_blank"
                  >
                    Download Survey
                  </a>
                </div>
              </div>

              <div className="col_xs_12 col_sm_12 col_md_12 col_lg_3">
                <div className="footer__menu">
                  <h4 className="title--h4 color--white opacity-8">Products</h4>
                  <ul className="footer__menu--list">
                    {product_categories && product_categories.length > 0
                      ? product_categories.map((value, index) => {
                          return (
                            <li className="footer__menu--list-item">
                              {/* <a href="/products/rmrms" className="color--text"> */}
                              <NavLink
                                className="color--text"
                                key={index}
                                to={"/products/contents/"}
                                component={Link}
                                state={{
                                  content: value?.contents?.[0],
                                  productMenu: product_categories,
                                }}
                              >
                                {value.category_name}
                              </NavLink>

                              {/* </a> */}
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
              </div>

              <div className="col_xs_12 col_sm_12 col_md_12 col_lg_3">
                <div className="footer__menu">
                  <h4 className="title--h4 color--white opacity-8">Contact</h4>
                  <ul className="footer__menu--list">
                    <li className="footer__menu--list-item flex">
                      <span>
                        <img src="/images/location.svg" alt="Location" />
                      </span>
                      <div className="flex flex--column alignitems--start">
                        <a
                          href="https://goo.gl/maps/f6QGeVuJ2DnSNE6h8"
                          className="color--text text__align--left"
                          target="_blank"
                          rel="noreferrer"
                        >
                          521 Violet St., Golden,
                          <br /> CO 80401-6714
                        </a>
                      </div>
                    </li>
                    <li className="footer__menu--list-item flex">
                      <span>
                        <img src="/images/call.svg" alt="Call" />
                      </span>
                      <div className="flex flex--column alignitems--start">
                        <a href="tel:(855)-943-7676" className="color--text">
                          Toll Free: (855) 943-7676
                        </a>
                        <a href="tel:(720)-943-7676" className="color--text">
                          Phone: (720) 943-7676
                        </a>
                        <Link to="#" className="color--text">
                          Fax: (720) 943-7670
                        </Link>
                      </div>
                    </li>
                    <li className="footer__menu--list-item flex">
                      <span>
                        <img src="/images/mail.svg" alt="Mail" />
                      </span>
                      <div className="flex flex--column alignitems--start">
                        {/* <a
                          href={`mailto:${
                            footerEmails
                              ? emailList[0].email
                              : "Sales@RMRMs.com"
                          }`}
                          className="color--text"

                        > */}
                        <NavLink
                          className="color--text"
                          to={"/contact"}
                          component={Link}
                          state={handleContactClick(
                            footerEmails
                              ? emailList[0].email
                              : "Sales@RMRMs.com"
                          )}
                        >
                          {footerEmails
                            ? emailList[0].email
                            : "Sales@RMRMs.com"}
                        </NavLink>

                        <NavLink
                          className="color--text"
                          to={"/contact"}
                          component={Link}
                          state={handleContactClick(
                            footerEmails
                              ? emailList[1].email
                              : "certs@rmrms.com"
                          )}
                        >
                          {footerEmails
                            ? emailList[1].email
                            : "certs@rmrms.com"}
                        </NavLink>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col_xs_12 col_sm_12 col_md_12 col_lg_3">
                <div className="footer__menu">
                  <h4 className="title--h4 color--white opacity-8">
                    Subscribe to our Newsletter
                  </h4>
                  <form className="footer__menu--form w-100">
                    <input
                      type="email"
                      placeholder="Enter your email address"
                      className="footer__menu--form-input w-100"
                    />
                    <button
                      type="submit"
                      className="footer__menu--form-button"
                    ></button>
                  </form>
                  <ul className="footer__menu--icon-list">
                    <li className="footer__menu--icon-list-item">
                      <a
                        href="https://www.linkedin.com/company/rocky-mountain-reference-materials-llc"
                        className="color--text"
                      >
                        <img src="/images/linkedin.svg" alt="LinkedIn" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {footerImg ? footerImgWrap : ""}

          <div className="container">
            <div className="copyright__text">
              © 2022, Rocky Mountain Reference Materials, LLC. All Rights
              Reserved.
            </div>
          </div>
        </footer>
      </LoadingOverlay>
    </>
  );
};

export default Footer;
