import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import PropTypes from "prop-types";

import DialogTitle from "@mui/material/DialogTitle";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


import { postData } from "../../Utils/utils";

import Swal from "sweetalert2";



const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CustomizedDialogs({
name
}) {



  return (
    <>
      <div>
        <Button
          // variant="contained"
          component={Link}
          to={`/admin/createGallery`}
          className={"btnPrimary"}
          style={{color: "#FFFFFF"}}
        >
          + Create Gallery
        </Button>

      </div>
    </>
  );
}

const EnhancedTableToolbar = ({
  name,
  handleClickOpen,
  handleClose,
  open,
  isLoading,
  setLoading,
  pageData,
  setPageData,
  sortingOrder,
  fetchData,
}) => {
  return (
    <Toolbar
      sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}
      style={{ marginTop: "20px" }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {name} Page Listing
      </Typography>
      <CustomizedDialogs
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        isLoading={isLoading}
        setLoading={setLoading}
        pageData={pageData}
        setPageData={setPageData}
        sortingOrder={sortingOrder}
        fetchData={fetchData}
      />
    </Toolbar>
  );
};

export default function TableListing({
  rows,
  name,
  link,
  isLoading,
  setLoading,
  fetchData,
  sortingOrder,
}) {

  const [list, setList] = React.useState([]);
  
  const dragItem = React.useRef();
  const dragOverItem = React.useRef();
  const dragStart = (e, position) => {
    dragItem.current = position;
    console.log(e.target.innerHTML);
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
  };

  const drop = (e) => {
    const copyListItems = [...list];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    // console.log("kfdkfkkfkf", copyListItems);
    copyListItems.forEach((e, index) => {
      e.position = index + 1;
      updateItems(e);
    });
    setList(copyListItems);
    // fetchData();
  };
  const updateItems = async (data) => {
   
      const { id, images, position, title } =
        data;
      await postData(
        {
          id, images, position:parseInt(position), title
        },
        "/photogallery/add"
      );
    
    
  };
  React.useEffect(() => {
    setList(rows);
  }, [rows]);

  const [open, setOpen] = React.useState(false),
    [pageData, setPageData] = React.useState({
      id: 0,
      title: "",
      images: "",
     
    }),
    handleClickOpen = (data) => {
      data && setPageData(data);
      setOpen(true);
    },
    handleDelete = (data) => {
      const { id, title, images } = data;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await postData(
            { id, title, images, active: 0, },
            "/photogallery/add"
          );
          fetchData();
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    handleClose = () => {
      setPageData({
        id: 0,
       
        title: "",
        images: "",
      
      });
      setOpen(false);
      fetchData();
    };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer component={Paper}>
          <EnhancedTableToolbar
            name={name}
            link={link}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            open={open}
            isLoading={isLoading}
            setLoading={setLoading}
            pageData={pageData}
            setPageData={setPageData}
            sortingOrder={sortingOrder}
            fetchData={fetchData}
          />
          <Table
            sx={{ minWidth: 650 }}
            className={"tableWrap"}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list &&
                list.map((row,index) => (
                  <TableRow
                      onDragStart={(e) => dragStart(e, index)}
                  onDragEnter={(e) => dragEnter(e, index)}
                  onDragEnd={drop}
                  draggable
                    key={row.Name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell align="right">
                      <Fab
                        color="primary"
                        component={Link}
                        to={`/admin/createGallery`}
                        state={{ content: row }}
                        aria-label="add"
                        size="small"
                        sx={{ mr: 1 }}
                      >
                        <EditIcon />
                      </Fab>
                      <Fab
                        color="error"
                        aria-label="delete"
                        onClick={() => handleDelete(row)}
                        size="small"
                      >
                        <DeleteIcon />
                      </Fab>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
