import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { getData } from "../../../Utils/utils";

import "./sideNavbar.scss";

const SideNavbar = () => {
  const [product, setProduct] = useState({}),
    { aboutUs: aboutUsPage = {}, latestNews = [] } = product || {},
    fetchProductsData = async () => {
      const result = await getData("/get/contents/by/category");
      setProduct(result);
    };

  useEffect(() => {
    fetchProductsData();
  }, []);

  return (
    <div className="accordion accordion-sm">
      <div className="accordion__item">
        <NavLink
          component={Link}
          state={{ content: aboutUsPage }}
          to={"/about"}
          className="accordion__item--btn"
        >
          Home
        </NavLink>
      </div>
      <div className="accordion__item">
        <NavLink to={"/photo-gallery"} className="accordion__item--btn">
          Photo Gallery
        </NavLink>
      </div>
      <div className="accordion__item">
        <NavLink
          component={Link}
          state={{ content: latestNews }}
          to={"/latest-news"}
          className="accordion__item--btn"
        >
          Latest News
        </NavLink>
      </div>
      <div className="accordion__item">
        <NavLink to={"/contact"} className="accordion__item--btn">
          Contact Us
        </NavLink>
      </div>
       <div className="accordion__item">
        <NavLink to={"/ourQuality"} className="accordion__item--btn">
          Our Quality
        </NavLink>
      </div>
    </div>
  );
};

export default SideNavbar;
