import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "./../components/Header";
import DrawerCom from "../components/Drawer";
import { postData } from "../../Utils/utils";

import LoadingOverlay from "react-loading-overlay";
import TextEditor from "../components/Editor";

const mdTheme = createTheme();

function DashboardContent() {
  const [pageStore, setPageStore] = useState({
      id: 0,
      page_title: "",
      default_content: "",
    hidden_content: "",
      description:"",
      email:"",
      subject:""
    }),
    [open, setOpen] = useState(true),
    [isError, setError] = useState(false),
    [isLoading, setLoading] = React.useState(false),
    { id, page_title, default_content, hidden_content,description,email,subject } = pageStore,
    navigate = useNavigate(),
    location = useLocation(),
    { content } = location.state || {};

  useEffect(() => {
    content && setPageStore(content);
  }, [content]);

  const onPageChange = async (e, name, index) => {
      const { target: { value = "" } = {} } = e;
      if (name === "page_title") {
        setPageStore({ ...pageStore, [name]: value });
      } else if (name === "default_content" || name === "hidden_content"||name==="description") {
        setPageStore({ ...pageStore, [name]: e });
      }
      else if (name === 'email') {
        setPageStore({ ...pageStore, [name]: value });
      } else if (name === 'subject') {
        setPageStore({ ...pageStore, [name]: value });
      }
    },
    toggleDrawer = () => {
      setOpen(!open);
    },
    handleSubmit = async (event) => {
      event.preventDefault();
      if (!page_title) {
        setError(true);
        return;
      }

      const obj = { page_title, default_content, hidden_content,description,email,subject };
      if (!!id) {
        obj.id = id;
      }

      setLoading(true);
      await postData(obj, "/service/add/category");
      setLoading(false);
      setTimeout(() => {
        navigate("/admin/serviceListing");
      }, 1000);
    };
  LoadingOverlay.propTypes = undefined;

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading ...">
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Header open={open} toggleDrawer={toggleDrawer} />
          <DrawerCom open={open} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      minHeight: 240,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ pb: 2 }}
                    >
                      Service Page
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            size="small"
                            value={page_title}
                            onChange={(e) => onPageChange(e, "page_title")}
                            label="Title"
                            variant="outlined"
                            error={isError && !page_title}
                            helperText={
                              isError && !page_title && "title is required"
                            }
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                       <Grid item xs={4}>
                       <TextField
                        id="outlined-basic-email"
                        fullWidth
                        size="small"
                        value={email}
                        onChange={(e) => onPageChange(e, 'email')}
                        label="Email"
                        variant="outlined"
                        sx={{ mb: 2 }}
                        />
                   </Grid>
                        </Grid>
                   <Grid container spacing={2}>
                   <Grid item xs={4}>
                       <TextField
                       id="outlined-basic-subject"
                        fullWidth
                        size="small"
                        value={subject}
                       onChange={(e) => onPageChange(e, 'subject')}
                       label="Subject"
                       variant="outlined"
                       sx={{ mb: 2 }}
                        />
                     </Grid>
                    </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <label>Default Content</label>
                          <TextEditor
                            editorValue={pageStore.default_content}
                            onChange={(newContent) =>
                              onPageChange(newContent, "default_content")
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <label>Hide Content</label>
                          <TextEditor
                            editorValue={pageStore.hidden_content}
                            onChange={(newContent) =>
                              onPageChange(newContent, "hidden_content")
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                           <label>Description</label>
                          <TextEditor
                            editorValue={pageStore.description}
                            onChange={(newContent) =>
                              onPageChange(newContent, "description")
                            }
                          />
</Grid>
                      </Grid>

                      <Button
                        type="submit"
                        className={"btnClass"}
                        variant="contained"
                        sx={{ mt: 3, mb: 2, ml: 2 }}
                      >
                        Save
                      </Button>
                      <Button
                        component={Link}
                        to="/admin/serviceListing"
                        variant="outlined"
                        sx={{ mt: 3, mb: 2, ml: 2 }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </LoadingOverlay>
  );
}

export default function ServicePage() {
  return <DashboardContent />;
}
