import React from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import logo from './../../Images/logo.png';

const drawerWidth = 260;


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
const Header=({open, toggleDrawer})=>{
    return(
        <AppBar position="absolute" open={open} className={"headerWrapper"}>
            <Toolbar sx={{pr: '24px',}}>
                <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer}
                    sx={{marginRight: '36px',
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <img src={logo} alt="logo" style={{display: open ? "none":"inline-block", width: "50px", marginLeft: "-35px",marginRight:" 8px"}}/>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    Admin Panel
                </Typography>

            </Toolbar>
        </AppBar>
    )
}

export default Header;
