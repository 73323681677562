import React, { useEffect, useState } from "react";
import Header from "../../Common/Header/Header";
import { Formik } from "formik";
import Swal from "sweetalert2";

import * as Yup from "yup";
import axios from "../../../Services/axios";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import EditIcon from "../../Icon/Edit";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const MyProfile = () => {
  const [linkData, setLinkData] = useState("");
  const [availableLink, setAvailableLink] = useState(false);
  const [editableLink, setEditableLink] = useState(false);
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get("/rmpt/user/get/profile")

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          setLinkData(res.data.basic_details);
          setAvailableLink(false);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      })
      .catch((error) => {})
      .then(() => {});
  }, []);
  const [passwordValues, setPasswordValues] = React.useState({
    confirmPassword: false,
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickConfirmPassword = () => {
    setPasswordValues({
      ...passwordValues,
      confirmPassword: !passwordValues.confirmPassword,
    });
  };

  const profileSchema = Yup.object({
    first_name: Yup.string().required("Required"),

    telephone: Yup.string().required("Required"),
    labContactEmail: Yup.string()
      .email("Invalid email address")
      .required("Required"),
  });

  const [newEmail, setNewEmail] = useState("");
  const handleChangeEmail = () => {
    if (isError === false) {
      let registerObj = {
        email: newEmail,
      };

      axios
        .post("/rmpt/user/update/email", registerObj)

        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.status === 200) {
            // setSubmitting(false);
            setLinkData(res.data.basic_details);

            Swal.fire({
              icon: "success",
              position: "center",

              title: res.message,
              showConfirmButton: true,
            }).then(() => {
              setOpen(false);
            });
          } else {
            // setIsError(res.data);

            Swal.fire({
              position: "center",
              icon: "error",
              title: res.data,
              showConfirmButton: true,
              // timer: 1500,
            });
            // setSubmitting(false);
          }
        })
        .catch((error) => {
          // setSubmitting(false);
        })
        .then(() => {
          // setSubmitting(false);
        });
    }
  };

  const validateEmail = (email) => {
    var re =
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  return (
    <>
      <Header themeWhite={true} contactBtn={true} bgLight={true} />
      <div className="mainSection mainPortal">
        <section className="page__section">
          <div className="container">
            <div className="form__container">
              <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={handleClose}
                >
                  Change Email
                </BootstrapDialogTitle>
                <DialogContent dividers>
                  <Box component="form" onSubmit={handleChangeEmail}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          required
                          size="small"
                          value={newEmail}
                          onChange={(e) => {
                            setIsError(false);
                            if (e.target.value.length === 0) {
                              setIsError("Email is Required");
                            }
                            if (
                              e.target.value.length > 0 &&
                              !validateEmail(e.target.value)
                            ) {
                              setIsError("Invalid Email");
                            }

                            setNewEmail(e.target.value);
                          }}
                          label="Email"
                          variant="outlined"
                          error={isError && !newEmail}
                          helperText={
                            isError && !newEmail ? "Email is required" : isError
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions style={{ alignSelf: "center" }}>
                  <Button
                    autoFocus
                    onClick={handleChangeEmail}
                    className={"btnClass"}
                    variant="contained"
                    sx={{ mt: 3, mb: 2, ml: 2 }}
                  >
                    Save
                  </Button>
                </DialogActions>
              </BootstrapDialog>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  companyName: linkData ? linkData.company_name : "",
                  first_name: linkData ? linkData.first_name : "",
                  username: linkData ? linkData.username : "",

                  labContactEmail: linkData ? linkData.email : "",
                  telephone: linkData ? linkData.telephone : "",
                  fax: linkData && linkData.fax ? linkData.fax : "",
                  companyWebsite: linkData ? linkData.company_website : "",
                  mailingAddress: linkData ? linkData.address : "",
                  state: linkData ? linkData.state : "",
                  city: linkData ? linkData.city : "",
                  zip: linkData ? linkData.zip : "",
                  preferredModeOfContact: linkData
                    ? linkData.contact_preferred_method
                    : "email",
                  newPassword: "",
                  confirmPassword: "",
                }}
                validationSchema={profileSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  let registerObj = {
                    basic_details: {
                      company_name: values.companyName,
                      first_name: values.first_name.toString(),
                      // email: values.labContactEmail,
                      telephone: values.telephone,
                      fax: values.fax === "" ? undefined : values.fax,
                      company_website: values.companyWebsite,
                      address: values.mailingAddress,
                      state: values.state,
                      city: values.city,
                      zip: values.zip,
                      contact_preferred_method: values.preferredModeOfContact,
                    },
                  };
                  localStorage.setItem(
                    "firstname",
                    values.first_name.toString()
                  );

                  axios
                    .post("/rmpt/user/update/profile", registerObj)

                    .then((response) => {
                      let res = JSON.parse(response.data);
                      if (res.status === 200) {
                        setSubmitting(false);
                        Swal.fire({
                          icon: "success",
                          position: "center",

                          title: res.message,
                          showConfirmButton: true,
                        }).then(() => {
                          window.open("/form-status", "_self");
                        });

                        // resetForm();
                      } else {
                        Swal.fire({
                          position: "center",
                          icon: "error",
                          title: res.message,
                          showConfirmButton: true,
                          // timer: 1500,
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch((error) => {
                      setSubmitting(false);
                    })
                    .then(() => {
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  resetForm,
                  /* and other goodies */
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="form form__user"
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <h1 className="form__title justifyspace--between mb_10">
                      My Profile{" "}
                    </h1>
                    <div className="form__section">
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            label="Company Name"
                            id="company-name"
                            size="small"
                            name="companyName"
                            disabled
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.companyName}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.companyName &&
                              touched.companyName &&
                              errors.companyName}
                          </div>
                        </div>
                        <div className="form__group">
                          <TextField
                            label="First/Last Name"
                            size="small"
                            // type="number"
                            name="first_name"
                            disabled={availableLink}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.first_name}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.first_name &&
                              touched.first_name &&
                              errors.first_name}
                          </div>
                        </div>
                      </div>
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            label="Lab Contact Email"
                            size="small"
                            disabled
                            name="labContactEmail"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.labContactEmail}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.labContactEmail &&
                              touched.labContactEmail &&
                              errors.labContactEmail}
                          </div>
                        </div>
                        <div className="form_group">
                          <Button
                            onClick={() => {
                              setNewEmail(values.labContactEmail);
                              handleClickOpen();
                            }}
                            type="button"
                            className={"btnClass"}
                            variant="contained"
                            sx={{ mb: 2, ml: 2 }}
                          >
                            Change Email
                          </Button>
                        </div>

                        {/* <div className="form__group">
                          <TextField
                            label="Username"
                            size="small"
                            disabled
                            name="Username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                            fullWidth
                          />
                        </div> */}
                        <div className="form__group">
                          <TextField
                            label="Telephone"
                            size="small"
                            type="text"
                            name="telephone"
                            disabled={availableLink}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.telephone}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.telephone &&
                              touched.telephone &&
                              errors.telephone}
                          </div>
                        </div>
                      </div>
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            label="Fax"
                            size="small"
                            type="text"
                            name="fax"
                            disabled
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.fax}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.fax && touched.fax && errors.fax}
                          </div>
                        </div>
                        <div className="form__group">
                          <TextField
                            label="Company Website"
                            size="small"
                            name="companyWebsite"
                            disabled
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.companyWebsite}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.companyWebsite &&
                              touched.companyWebsite &&
                              errors.companyWebsite}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form__section">
                      <div className="form__group--flex-full">
                        <div className="form__group">
                          <TextField
                            label="Mailing Address"
                            size="small"
                            fullWidth
                            disabled
                            placeholder="Your complete mailing address here"
                            name="mailingAddress"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mailingAddress}
                          />
                          <div className="form__input--error">
                            {errors.mailingAddress &&
                              touched.mailingAddress &&
                              errors.mailingAddress}
                          </div>
                        </div>
                      </div>
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            label="City"
                            disabled
                            size="small"
                            name="city"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.city}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.city && touched.city && errors.city}
                          </div>
                        </div>
                        <div className="form__group">
                          <TextField
                            label="State"
                            size="small"
                            disabled
                            name="state"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.state}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.state && touched.state && errors.state}
                          </div>
                        </div>
                      </div>
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            label="Zip"
                            size="small"
                            disabled
                            name="zip"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.zip}
                            fullWidth
                          />
                          <div className="form__input--error">
                            {errors.zip && touched.zip && errors.zip}
                          </div>
                        </div>
                        <div className="form__group--flex-full mb_0">
                          <label
                            className="form__label"
                            style={{ marginTop: "-6px" }}
                          >
                            What is your preferred method of contact
                          </label>
                          <div
                            className="form__group--flex mb_0"
                            style={{ marginTop: "-10px" }}
                          >
                            <div className="form__group">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    defaultChecked
                                    checked={
                                      values.preferredModeOfContact === "email"
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "preferredModeOfContact",
                                        "email"
                                      )
                                    }
                                  />
                                }
                                label="Email"
                              />
                            </div>
                            <div className="form__group">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.preferredModeOfContact === "phone"
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "preferredModeOfContact",
                                        "phone"
                                      )
                                    }
                                  />
                                }
                                label="Phone"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form__group flex justifyspace--between">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn--primary"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default MyProfile;
