import * as React from "react";
import { useEffect, useState } from "react";
import Header from "../../Common/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import EyeIcon from "../../Icon/EyeIcon";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "../../../Components/Icon/UploadIcon";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import axios from "../../../Services/axios";
import { uploadFile } from "../../../Utils/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "4px",
  p: 2,
};

const FormStatus = () => {
  const navigate = useNavigate();

  const [status, setstatus] = React.useState("");
  const [customerId, setCustomerId] = React.useState("");
  const [poFile, setPoFile] = React.useState("");
  const [poFileError, setPoFileError] = useState("");
  const [calenderData, setCalenderData] = useState([]);
  const checkedRmpt188 = (value) => {
    let checkedAll = true;
    if (value.data.length === 0) {
      checkedAll = false;
    }
    value.data.forEach((e) => {
      if (!e.rmpt188) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };

  const getMasterLastUpdatedDateRmpt188 = (value) => {
    const calenderDateArray = [];
    value.data.forEach((e) => {
      calenderDateArray.push(e.rmpt188updated);
    });

    return formatDate(getLatestDate(calenderDateArray));
  };
  const onPoUpload = async (e, name) => {
    if (name === "file" && e?.target?.files?.[0]) {
      const image = e.target.files[0] || {};
      if (image.name && !image.name.match(/\.(pdf)$/)) {
        setPoFile("");
        return false;
      } else {
        setLoader(true);
        const { data: { url = "" } = {} } =
          (await uploadFile(
            e.target.files[0],
            "/common/upload/file",
            "quote"
          )) || {};
        setPoFile(url);

        setLoader(false);
      }
    }
  };

  const handleClose = () => {
    setPoFile("");

    setModalPo(false);
    //  fetchData();
    axios
      .get("/rmpt/user/get/calenders")

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          setCalender(res.data);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      })
      .catch((error) => {})
      .then(() => {});
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);

    if (!poFile) {
      setPoFileError(true);
      return;
    }

    const obj = {
      po_file: poFile,
      state: 3,

      update_status: {
        ...status,
        po_issued: new Date(),
      },
      reference_id: customerId,
    };

    axios
      .post("/rmpt/update/calender/data", obj)

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          setTimeout(() => {
            handleClose();
          }, 1000);
          setLoader(false);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: true,
            // timer: 1500,
          });
          setLoader(false);
        }
      })
      .catch((error) => {})
      .then(() => {});
  };
  const [userId, setUserId] = useState("");
  const [calender, setCalender] = React.useState([]);
  useEffect(() => {
    axios
      .get("/rmpt/user/get/calenders")

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          setCalender(res.data);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      })
      .catch((error) => {})
      .then(() => {});
  }, []);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const formattedDate = months[month] + " " + day + ", " + year;
    return formattedDate;
  }
  const [modalPo, setModalPo] = React.useState(false);
  const handlePoOpen = () => setModalPo(true);
  const handlePoClose = () => setModalPo(false);
  const generateNewReferenceId = (customer_number, order_date) => {
    const date1 = new Date("12/30/1899");
    const date2 = new Date(order_date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    const rmpt = customer_number + "-" + parseInt(diffDays);
    return rmpt.toString();
  };

  const getAppealLastUpdatedDate = (value) => {
    const calenderDateArray = [];
    value.data.forEach((e) => {
      calenderDateArray.push(e.appealupdated);
    });

    return formatDate(getLatestDate(calenderDateArray));
  };

  const getReAppealLastUpdatedDate = (value) => {
    const calenderDateArray = [];
    value.data.forEach((e) => {
      if (e.reappealupdated && e.reappealupdated !== null)
        calenderDateArray.push(e.reappealupdated);
    });

    return formatDate(getLatestDate(calenderDateArray));
  };

  function getLatestDate(data) {
    // convert to timestamp and sort
    var sorted_ms = data
      .map(function (item) {
        return new Date(item).getTime();
      })
      .sort();
    // take latest
    var latest_ms = sorted_ms[sorted_ms.length - 1];
    // convert to js date object
    return new Date(latest_ms);
  }

  const [loader, setLoader] = useState(false);

  const masterChecked = (value) => {
    let checkedAll = true;
    if (value.data.length === 0) {
      checkedAll = false;
    }
    value.data.forEach((e) => {
      if (!e.rmpt189) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };

  const masterCheckedAppeal = (value) => {
    let checkedAll = true;
    if (value.data.length === 0) {
      checkedAll = false;
    }
    value.data.forEach((e) => {
      if (!e.isAppeal) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };

  const masterCheckedReAppeal = (value) => {
    let checkedAll = true;
    if (value.data.length === 0) {
      checkedAll = false;
    }
    value.data.forEach((e) => {
      if (!e.isReappeal) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };

  const getMasterLastUpdatedDate = (value) => {
    const calenderDateArray = [];
    value.data.forEach((e) => {
      calenderDateArray.push(e.rmpt189updated);
    });

    return formatDate(getLatestDate(calenderDateArray));
  };

  const [viewSampleModal, setViewSampleModal] = React.useState(false);
  const handleViewSampleOpen = () => setViewSampleModal(true);
  const handleViewSampleClose = () => setViewSampleModal(false);

  const [viewAppealLetterModal, setViewAppealLetterModal] =
    React.useState(false);
  const handleViewAppealLetterOpen = () => setViewAppealLetterModal(true);
  const handleViewAppealLetterClose = () => setViewAppealLetterModal(false);

  const [viewAnalysisFormCompleteModal, setViewAnalysisFormCompleteModal] =
    React.useState(false);
  const handleViewAnalysisFormCompleteOpen = () =>
    setViewAnalysisFormCompleteModal(true);
  const handleViewAnalysisFormCompleteClose = () =>
    setViewAnalysisFormCompleteModal(false);

  const [chartModal, setChartModal] = React.useState(false);
  const handleChartModalOpen = () => setChartModal(true);
  const handleChartModalClose = () => setChartModal(false);

  const [viewAppealCompleteModal, setViewAppealCompleteModal] =
    React.useState(false);
  const handleViewAppealCompleteOpen = () => setViewAppealCompleteModal(true);
  const handleViewAppealCompleteClose = () => setViewAppealCompleteModal(false);
  const getSampleCloseChartChecked = (value) => {
    let checkedAll = true;
    if (value.data.length === 0) {
      checkedAll = false;
    }
    value.data.forEach((e) => {
      if (!e.isAppeal && !e.isRejected && !e.isReappeal && !e.isAccepted) {
        checkedAll = false;
      }
      if (e.isAppeal && e.isRejected && !e.isReappeal) {
        checkedAll = false;
      } else if (e.isAppeal && !e.isRejected && !e.isAccepted) {
        checkedAll = false;
      }
    });
    return checkedAll;
  };
  const getSampleClosedLastUpdatedDate = (value) => {
    const calenderDateArray = [];
    value.data.forEach((e) => {
      if (e.isReappeal) {
        if (e.reappealupdated && e.reappealupdated !== null)
          calenderDateArray.push(e.reappealupdated);
      } else if (e.isAccepted) {
        if (e.appealupdated && e.appealupdated !== null)
          calenderDateArray.push(e.appealupdated);
      }
    });

    return formatDate(getLatestDate(calenderDateArray));
  };

  return (
    <>
      {loader ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : null}
      <Header themeWhite={true} contactBtn={true} bgLight={true} />
      <div className="mainSection mainPortal">
        <section className="page__section">
          <div className="container">
            <div className="form__container">
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                }}
                className="form form__user"
              >
                <h1 className="form__title form__title--row">
                  Client Manager Form Status
                  <div className="btn--wrap">
                    <button className="btn btn--print">
                      <svg
                        id="printer-line"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="27"
                        viewBox="0 0 30 27"
                      >
                        <path
                          id="Path_54"
                          data-name="Path 54"
                          d="M29,9H27V5H9V9H7a4,4,0,0,0-4,4V24H6.92V22.09H5V13a2,2,0,0,1,2-2H29a2,2,0,0,1,2,2v9H29.08v2H33V13A4,4,0,0,0,29,9ZM25,9H11V7H25Z"
                          transform="translate(-3 -5)"
                          fill="rgba(109,124,144,0.62)"
                        />
                        <path
                          id="Path_55"
                          data-name="Path 55"
                          d="M28,18H8a1,1,0,0,0,0,2H9V32H27V20h1a1,1,0,0,0,0-2ZM25,30H11V20H25Z"
                          transform="translate(-3 -5)"
                          fill="rgba(109,124,144,0.62)"
                        />
                        <path
                          id="Path_56"
                          data-name="Path 56"
                          d="M27,13.04h2v2H27Z"
                          transform="translate(-3 -5)"
                          fill="rgba(109,124,144,0.62)"
                        />
                      </svg>
                    </button>
                    <button
                      onClick={() => {
                        navigate("/sample-calender");
                      }}
                      className="btn btn--primary"
                    >
                      Renewal
                    </button>
                  </div>
                </h1>
                <div style={{ height: 550, width: "100%", overflowY: "auto" }}>
                  <TableContainer className="form__table">
                    <Table className="form__rmpt--status">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" padding="none">
                            Reference ID
                          </TableCell>
                          <TableCell align="center" padding="none">
                            Calendar Complete
                          </TableCell>
                          <TableCell align="center" padding="none">
                            Quote Issued
                          </TableCell>
                          <TableCell align="center" padding="none">
                            PO Issued
                          </TableCell>
                          <TableCell align="center" padding="none">
                            Sample Sent
                          </TableCell>
                          <TableCell align="center" padding="none">
                            Analysis Complete
                          </TableCell>
                          <TableCell align="center" padding="none">
                            Appeal Letter
                          </TableCell>
                          <TableCell align="center" padding="none">
                            Appeal Complete
                          </TableCell>
                          <TableCell align="center" padding="none">
                            Sample Complete
                          </TableCell>
                          <TableCell align="center" padding="none">
                            View Details
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <Modal
                          aria-labelledby="transition-modal-title"
                          aria-describedby="transition-modal-description"
                          open={chartModal}
                          onClose={handleChartModalClose}
                          closeAfterTransition
                          className="uploadModal"
                        >
                          <Fade in={chartModal}>
                            <Box
                              sx={style}
                              component="form"
                              className="uploadModalForm"
                            >
                              <Typography
                                id=""
                                variant="h6"
                                component="h2"
                                style={{
                                  fontWeight: "700",
                                }}
                              >
                                Sample Complete
                                <IconButton
                                  aria-label="close"
                                  onClick={handleChartModalClose}
                                  sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Typography>

                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <div
                                    className="sample__modal"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      padding: "0 16px",
                                    }}
                                  >
                                    <h2
                                      style={{
                                        backgroundColor: "transparent",
                                        padding: "0",
                                      }}
                                    >
                                      Final Report(s) for RMPT# -{" "}
                                      {`(RMPT - 194)`}
                                    </h2>
                                    <div className="button--icon modal--icon">
                                      <Tooltip title="View/Download">
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          <EyeIcon />
                                        </button>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12}>
                                  <div className="sample__modal">
                                    <Typography
                                      id=""
                                      variant="h6"
                                      component="h2"
                                      className=""
                                    >
                                      Z-Score Summary Chart & Graph -{" "}
                                      {`(RMPT - 190 & 190G)`}
                                    </Typography>

                                    <ul className="sample__list">
                                      {calenderData && calenderData.length > 0
                                        ? calenderData.map((val, index) => {
                                            return (
                                              <li className="sample__list--item">
                                                <div className="flex alignitems--center justifyspace--between">
                                                  <p>
                                                    <span>{index + 1}. </span>
                                                    <span className="analyst">
                                                      {val.analyst}
                                                    </span>
                                                    <span>{val.method}</span>
                                                    <span>{val.material}</span>
                                                    <span>
                                                      {val.sample_type}
                                                    </span>
                                                  </p>

                                                  <div
                                                    style={{
                                                      display: "flex",
                                                    }}
                                                  >
                                                    {val.isAppeal ? (
                                                      <div className="button--icon modal--icon">
                                                        <Tooltip title="View/Download">
                                                          <button
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              localStorage.removeItem(
                                                                "type"
                                                              );
                                                              localStorage.setItem(
                                                                "UserId",
                                                                userId
                                                              );
                                                              localStorage.setItem(
                                                                "referenceId",
                                                                customerId
                                                              );
                                                              localStorage.setItem(
                                                                "analystId",
                                                                val.id
                                                              );
                                                              window.location.href =
                                                                "/sampleClosedChart";
                                                            }}
                                                          >
                                                            <EyeIcon />
                                                          </button>
                                                        </Tooltip>
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          })
                                        : null}
                                    </ul>
                                  </div>
                                </Grid>
                                <Grid item xs={12}>
                                  <div className="sample__modal">
                                    <Typography
                                      id=""
                                      variant="h6"
                                      component="h2"
                                      className=""
                                    >
                                      Appealed Z-Score Summary Chart & Graph –{" "}
                                      {`(RMPT - 193 & 193G)`}
                                    </Typography>

                                    <ul className="sample__list">
                                      {calenderData && calenderData.length > 0
                                        ? calenderData.map((value, index) => {
                                            return (
                                              <li className="sample__list--item">
                                                <div className="flex alignitems--center justifyspace--between">
                                                  <p>
                                                    <span>{index + 1}. </span>
                                                    <span className="analyst">
                                                      {value.analyst}
                                                    </span>
                                                    <span>{value.method}</span>
                                                    <span>
                                                      {value.material}
                                                    </span>
                                                    <span>
                                                      {value.sample_type}
                                                    </span>
                                                  </p>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                    }}
                                                  >
                                                    <div className="button--icon modal--icon">
                                                      {value.isReappeal ? (
                                                        <Tooltip title="View/Download">
                                                          <button
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              localStorage.setItem(
                                                                "UserId",
                                                                userId
                                                              );
                                                              localStorage.setItem(
                                                                "referenceId",
                                                                customerId
                                                              );
                                                              localStorage.removeItem(
                                                                "type"
                                                              );
                                                              localStorage.setItem(
                                                                "type",
                                                                "reappeal"
                                                              );
                                                              localStorage.setItem(
                                                                "analystId",
                                                                value.id
                                                              );
                                                              window.location.href =
                                                                "/sampleClosedChart";
                                                            }}
                                                          >
                                                            <EyeIcon />
                                                          </button>
                                                        </Tooltip>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          })
                                        : null}
                                    </ul>
                                  </div>
                                </Grid>
                              </Grid>
                            </Box>
                          </Fade>
                        </Modal>
                        {calender && calender.length > 0
                          ? calender.map((value, index) => {
                              return (
                                <TableRow hover>
                                  {/* Reference ID */}
                                  <TableCell align="left" padding="none">
                                    {/* {value.reference_id} */}
                                    {value.customer_number === null
                                      ? value.reference_id
                                      : generateNewReferenceId(
                                          value.customer_number,
                                          value.order_date
                                        )}{" "}
                                  </TableCell>

                                  {/* Calendar Complete */}
                                  <TableCell align="center" padding="none">
                                    <div
                                      className="flex alignitems--center"
                                      style={{ justifyContent: "center" }}
                                    >
                                      <Tooltip
                                        title={
                                          value.quote_requested
                                            ? `Status Updated On: ${formatDate(
                                                value?.update_status
                                                  ?.quote_requested
                                              )}`
                                            : ""
                                        }
                                      >
                                        <Checkbox
                                          checked={value.quote_requested}
                                        />
                                      </Tooltip>
                                      <div className="btn--wrap">
                                        <Tooltip
                                          title={
                                            value.quote_requested
                                              ? "View/Download"
                                              : ""
                                          }
                                        >
                                          <button
                                            disabled={
                                              value.quote_requested === false
                                            }
                                            onClick={() => {
                                              axios
                                                .get(
                                                  `/rmpt/get/previewCustomer?type=3&id=${value.user_id}&reference_id=${value.reference_id}`,
                                                  setLoader(true)
                                                )

                                                .then((response) => {
                                                  let res = JSON.parse(
                                                    response.data
                                                  );
                                                  if (res.status === 200) {
                                                    setLoader(false);
                                                    let pdfWindow =
                                                      window.open("");
                                                    pdfWindow.document.write(
                                                      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                                        encodeURI(res.data) +
                                                        "'></iframe>"
                                                    );
                                                  } else {
                                                  }
                                                })
                                                .catch((error) => {})
                                                .then(() => {});
                                            }}
                                            className="view-btn"
                                          >
                                            <EyeIcon />
                                          </button>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </TableCell>

                                  {/* Quote Issued */}
                                  <TableCell align="center" padding="none">
                                    <div
                                      className="flex alignitems--center"
                                      style={{ justifyContent: "center" }}
                                    >
                                      <Tooltip
                                        title={
                                          value.quote_issued
                                            ? `Status Updated On: ${formatDate(
                                                value?.update_status
                                                  ?.quote_issued
                                              )}`
                                            : ""
                                        }
                                      >
                                        <Checkbox
                                          checked={value.quote_issued}
                                        />
                                      </Tooltip>
                                      <div className="btn--wrap">
                                        <Tooltip
                                          title={
                                            value.quote_issued
                                              ? "View/Download"
                                              : ""
                                          }
                                        >
                                          <button
                                            disabled={
                                              value.quote_issued === false
                                            }
                                            onClick={() => {
                                              window.open(
                                                value.quote_file,
                                                "_blank",
                                                "noreferrer"
                                              );
                                            }}
                                            className="view-btn"
                                          >
                                            <EyeIcon />
                                          </button>
                                        </Tooltip>
                                      </div>
                                      {/* <div className="button--icon">
                                        <button>
                                          <DownloadIcon />
                                        </button>
                                      </div> */}
                                    </div>
                                  </TableCell>

                                  {/* PO Issued */}
                                  <TableCell align="center" padding="none">
                                    <div
                                      className="flex alignitems--center"
                                      style={{ justifyContent: "center" }}
                                    >
                                      <Tooltip
                                        title={
                                          value.po_posted
                                            ? `Status Updated On: ${formatDate(
                                                value?.update_status?.po_issued
                                              )}`
                                            : ""
                                        }
                                      >
                                        <Checkbox checked={value.po_posted} />
                                      </Tooltip>

                                      <div className="btn--wrap">
                                        <Tooltip
                                          title={
                                            value.po_posted
                                              ? "View/Download"
                                              : ""
                                          }
                                        >
                                          <button
                                            disabled={value.po_posted === false}
                                            onClick={() => {
                                              window.open(
                                                value.po_file,
                                                "_blank",
                                                "noreferrer"
                                              );
                                            }}
                                            className="view-btn"
                                          >
                                            <EyeIcon />
                                          </button>
                                        </Tooltip>
                                      </div>
                                      {!value.po_posted ? (
                                        <div className="button--icon">
                                          <button
                                            disabled={
                                              value.quote_issued === false
                                            }
                                            onClick={() => {
                                              setCustomerId(value.reference_id);
                                              setstatus(value.update_status);
                                              handlePoOpen();
                                            }}
                                          >
                                            <UploadIcon />
                                          </button>
                                          <Modal
                                            aria-labelledby="transition-modal-title"
                                            aria-describedby="transition-modal-description"
                                            open={modalPo}
                                            onClose={handlePoClose}
                                            closeAfterTransition
                                            className="uploadModal"
                                          >
                                            <Fade in={modalPo}>
                                              <Box sx={style} component="form">
                                                <Typography
                                                  id=""
                                                  variant="h6"
                                                  component="h2"
                                                  style={{
                                                    marginBottom: "10px",
                                                  }}
                                                >
                                                  Upload PO
                                                  <IconButton
                                                    aria-label="close"
                                                    onClick={handlePoClose}
                                                    sx={{
                                                      position: "absolute",
                                                      right: 8,
                                                      top: 8,
                                                      color: (theme) =>
                                                        theme.palette.grey[500],
                                                    }}
                                                  >
                                                    <CloseIcon />
                                                  </IconButton>
                                                </Typography>

                                                <Grid container spacing={2}>
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                      position: "relative",
                                                    }}
                                                  >
                                                    <TextField
                                                      onChange={(e) =>
                                                        onPoUpload(e, "file")
                                                      }
                                                      inputProps={{
                                                        accept:
                                                          "application/pdf",
                                                      }}
                                                      error={
                                                        poFileError && !poFile
                                                      }
                                                      helperText={
                                                        poFileError &&
                                                        !poFile &&
                                                        "file is required"
                                                      }
                                                      style={{
                                                        backgroundColor:
                                                          "white",
                                                      }}
                                                      name="upload-photo"
                                                      fullWidth
                                                      type="file"
                                                    />
                                                  </Grid>
                                                  <Button
                                                    autoFocus
                                                    onClick={handleSubmit}
                                                    className={"btnClass"}
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2, ml: 2 }}
                                                  >
                                                    Save
                                                  </Button>
                                                </Grid>
                                              </Box>
                                            </Fade>
                                          </Modal>
                                        </div>
                                      ) : null}
                                    </div>
                                  </TableCell>

                                  {/* Sample Sent */}
                                  <TableCell align="center" padding="none">
                                    <div
                                      className="flex alignitems--center"
                                      style={{ justifyContent: "center" }}
                                    >
                                      <Tooltip
                                        title={
                                          checkedRmpt188(value)
                                            ? `Status Updated On: ${getMasterLastUpdatedDateRmpt188(
                                                value
                                              )}`
                                            : ""
                                        }
                                      >
                                        <Checkbox
                                          checked={checkedRmpt188(value)}
                                        />
                                      </Tooltip>
                                      <div className="btn--wrap">
                                        <Tooltip
                                          title={
                                            checkedRmpt188(value)
                                              ? "View/Download"
                                              : ""
                                          }
                                        >
                                          <button
                                            disabled={
                                              checkedRmpt188(value) === false
                                            }
                                            className="view-btn"
                                            onClick={() => {
                                              setCustomerId(value.reference_id);
                                              setUserId(value.user_id);

                                              const arr = [];
                                              value.data.forEach((e) => {
                                                arr.push(e);
                                              });
                                              setCalenderData(arr);

                                              handleViewSampleOpen();
                                            }}
                                          >
                                            <EyeIcon />
                                          </button>
                                        </Tooltip>
                                        <Modal
                                          aria-labelledby="transition-modal-title"
                                          aria-describedby="transition-modal-description"
                                          open={viewSampleModal}
                                          onClose={handleViewSampleClose}
                                          closeAfterTransition
                                          className="uploadModal"
                                        >
                                          <Fade in={viewSampleModal}>
                                            <Box
                                              sx={style}
                                              component="form"
                                              className="uploadModalForm uploadModalOverflow"
                                            >
                                              <Typography
                                                id=""
                                                variant="h6"
                                                component="h2"
                                                style={{
                                                  fontWeight: "700",
                                                }}
                                              >
                                                Sample Sent
                                                <IconButton
                                                  aria-label="close"
                                                  onClick={
                                                    handleViewSampleClose
                                                  }
                                                  sx={{
                                                    position: "absolute",
                                                    right: 8,
                                                    top: 8,
                                                    color: (theme) =>
                                                      theme.palette.grey[500],
                                                  }}
                                                >
                                                  <CloseIcon />
                                                </IconButton>
                                              </Typography>

                                              <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                  <div
                                                    className="sample__modal"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "space-between",
                                                      padding: "0 16px",
                                                    }}
                                                  >
                                                    <h2
                                                      style={{
                                                        backgroundColor:
                                                          "transparent",
                                                        padding: "0",
                                                      }}
                                                    >
                                                      Scheme Design -{" "}
                                                      {`(RMPT 187)`}
                                                    </h2>
                                                    <div className="button--icon modal--icon">
                                                      <Tooltip title="View/Download">
                                                        <button
                                                          onClick={(e) => {
                                                            e.preventDefault();

                                                            axios
                                                              .get(
                                                                `https://api.rmrms.com/rmpt/get/previewCustomer?type=5&id=${value.user_id}&reference_id=${value.reference_id}`,
                                                                {
                                                                  headers: {
                                                                    Authorization:
                                                                      localStorage.getItem(
                                                                        "access_token"
                                                                      ),
                                                                  },
                                                                },
                                                                setLoader(true)
                                                              )

                                                              .then(
                                                                (response) => {
                                                                  let res =
                                                                    JSON.parse(
                                                                      response.data
                                                                    );

                                                                  if (
                                                                    res.status ===
                                                                    200
                                                                  ) {
                                                                    setLoader(
                                                                      false
                                                                    );
                                                                    let pdfWindow =
                                                                      window.open(
                                                                        ""
                                                                      );
                                                                    pdfWindow.document.write(
                                                                      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                                                        encodeURI(
                                                                          res.data
                                                                        ) +
                                                                        "'></iframe>"
                                                                    );
                                                                  } else {
                                                                  }
                                                                }
                                                              )
                                                              .catch(
                                                                (error) => {
                                                                  if (
                                                                    error
                                                                      .response
                                                                      .data
                                                                      .message ===
                                                                    "Unauthorized!"
                                                                  ) {
                                                                    Swal.fire({
                                                                      position:
                                                                        "top-end",
                                                                      icon: "error",
                                                                      title:
                                                                        "Session Expired",
                                                                      showConfirmButton: false,
                                                                      timer: 1500,
                                                                    });
                                                                    localStorage.clear();
                                                                    setTimeout(
                                                                      () => {
                                                                        window.location.reload(
                                                                          false
                                                                        );
                                                                      },
                                                                      1500
                                                                    );
                                                                  }
                                                                }
                                                              )
                                                              .then(() => {});
                                                          }}
                                                        >
                                                          <EyeIcon />
                                                        </button>
                                                      </Tooltip>
                                                    </div>
                                                  </div>
                                                </Grid>

                                                <Grid item xs={12}>
                                                  <div
                                                    className="sample__modal"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "space-between",
                                                      padding: "0 16px",
                                                    }}
                                                  >
                                                    <Typography
                                                      id=""
                                                      variant="h6"
                                                      component="h2"
                                                      className=""
                                                      style={{
                                                        backgroundColor:
                                                          "transparent",
                                                        padding: "0",
                                                      }}
                                                    >
                                                      Subscriber Analysis
                                                      Instructions -{" "}
                                                      {`(RMPT 188)`}
                                                    </Typography>
                                                    <br />
                                                    {/* <div className="button--icon modal--icon">
                                                                <Tooltip title="View/Download">
                                                                  <button
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.preventDefault();

                                                                      axios
                                                                        .get(
                                                                          `https://api.rmrms.com/common/get/preview?type=6&id=${userId}&reference_id=${customerId}`,
                                                                          {
                                                                            headers:
                                                                              {
                                                                                Authorization:
                                                                                  localStorage.getItem(
                                                                                    "access_token"
                                                                                  ),
                                                                              },
                                                                          },
                                                                          setLoader(
                                                                            true
                                                                          )
                                                                        )

                                                                        .then(
                                                                          (
                                                                            response
                                                                          ) => {
                                                                            let res =
                                                                              response.data;
                                                                            if (
                                                                              res.status ===
                                                                              200
                                                                            ) {
                                                                              setLoader(
                                                                                false
                                                                              );
                                                                              let pdfWindow =
                                                                                window.open(
                                                                                  ""
                                                                                );
                                                                              pdfWindow.document.write(
                                                                                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                                                                  encodeURI(
                                                                                    res.data
                                                                                  ) +
                                                                                  "'></iframe>"
                                                                              );
                                                                            } else {
                                                                            }
                                                                          }
                                                                        )
                                                                        .catch(
                                                                          (
                                                                            error
                                                                          ) => {
                                                                            if (
                                                                              error
                                                                                .response
                                                                                .data
                                                                                .message ===
                                                                              "Unauthorized!"
                                                                            ) {
                                                                              Swal.fire(
                                                                                {
                                                                                  position:
                                                                                    "top-end",
                                                                                  icon: "error",
                                                                                  title:
                                                                                    "Session Expired",
                                                                                  showConfirmButton: false,
                                                                                  timer: 1500,
                                                                                }
                                                                              );
                                                                              localStorage.clear();
                                                                              setTimeout(
                                                                                () => {
                                                                                  window.location.reload(
                                                                                    false
                                                                                  );
                                                                                },
                                                                                1500
                                                                              );
                                                                            }
                                                                          }
                                                                        )
                                                                        .then(
                                                                          () => {}
                                                                        );
                                                                    }}
                                                                  >
                                                                    <EyeIcon />
                                                                  </button>
                                                                </Tooltip>
                                                              </div> */}
                                                  </div>
                                                  <ul className="sample__list">
                                                    {calenderData &&
                                                    calenderData.length > 0
                                                      ? calenderData.map(
                                                          (value, index) => {
                                                            return (
                                                              <li className="sample__list--item">
                                                                <div className="flex alignitems--center justifyspace--between">
                                                                  <p>
                                                                    <span>
                                                                      {index +
                                                                        1}
                                                                      .{" "}
                                                                    </span>
                                                                    <span className="analyst">
                                                                      {
                                                                        value.analyst
                                                                      }
                                                                    </span>
                                                                    <span>
                                                                      {
                                                                        value.method
                                                                      }
                                                                    </span>
                                                                    <span>
                                                                      {
                                                                        value.material
                                                                      }
                                                                    </span>
                                                                    <span>
                                                                      {
                                                                        value.sample_type
                                                                      }
                                                                    </span>
                                                                  </p>
                                                                  <div className="button--icon modal--icon">
                                                                    <Tooltip title="View/Download">
                                                                      <button
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.preventDefault();
                                                                          axios
                                                                            .get(
                                                                              `https://api.rmrms.com/rmpt/get/preview?type=5&id=${userId}&reference_id=${customerId}&analyst_id=${value.id}`,
                                                                              {
                                                                                headers:
                                                                                  {
                                                                                    Authorization:
                                                                                      localStorage.getItem(
                                                                                        "access_token"
                                                                                      ),
                                                                                  },
                                                                              },
                                                                              setLoader(
                                                                                true
                                                                              )
                                                                            )

                                                                            .then(
                                                                              (
                                                                                response
                                                                              ) => {
                                                                                let res =
                                                                                  JSON.parse(
                                                                                    response.data
                                                                                  );

                                                                                if (
                                                                                  res.status ===
                                                                                  200
                                                                                ) {
                                                                                  setLoader(
                                                                                    false
                                                                                  );
                                                                                  let pdfWindow =
                                                                                    window.open(
                                                                                      ""
                                                                                    );
                                                                                  pdfWindow.document.write(
                                                                                    "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                                                                      encodeURI(
                                                                                        res.data
                                                                                      ) +
                                                                                      "'></iframe>"
                                                                                  );
                                                                                } else {
                                                                                }
                                                                              }
                                                                            )
                                                                            .catch(
                                                                              (
                                                                                error
                                                                              ) => {
                                                                                console.log(
                                                                                  "dsds",
                                                                                  error
                                                                                );
                                                                                if (
                                                                                  error
                                                                                    .response
                                                                                    .data
                                                                                    .message ===
                                                                                  "Unauthorized!"
                                                                                ) {
                                                                                  Swal.fire(
                                                                                    {
                                                                                      position:
                                                                                        "top-end",
                                                                                      icon: "error",
                                                                                      title:
                                                                                        "Session Expired",
                                                                                      showConfirmButton: false,
                                                                                      timer: 1500,
                                                                                    }
                                                                                  );
                                                                                  localStorage.clear();
                                                                                  setTimeout(
                                                                                    () => {
                                                                                      window.location.reload(
                                                                                        false
                                                                                      );
                                                                                    },
                                                                                    1500
                                                                                  );
                                                                                }
                                                                              }
                                                                            )
                                                                            .then(
                                                                              () => {}
                                                                            );
                                                                        }}
                                                                      >
                                                                        <EyeIcon />
                                                                      </button>
                                                                    </Tooltip>
                                                                  </div>
                                                                </div>
                                                              </li>
                                                            );
                                                          }
                                                        )
                                                      : null}
                                                  </ul>
                                                </Grid>
                                              </Grid>
                                            </Box>
                                          </Fade>
                                        </Modal>
                                      </div>
                                    </div>
                                  </TableCell>

                                  {/* Analysis Complete */}
                                  <TableCell align="center" padding="none">
                                    <div
                                      className="flex alignitems--center"
                                      style={{ justifyContent: "center" }}
                                    >
                                      <Tooltip
                                        title={
                                          masterChecked(value)
                                            ? getMasterLastUpdatedDate(value)
                                            : ""
                                        }
                                      >
                                        <Checkbox
                                          checked={masterChecked(value)}
                                        />
                                      </Tooltip>
                                      <div className="btn--wrap">
                                        <Tooltip title="">
                                          <button
                                            className="view-btn"
                                            onClick={() => {
                                              setCustomerId(value.reference_id);
                                              setUserId(value.user_id);

                                              const arr = [];
                                              value.data.forEach((e) => {
                                                arr.push(e);
                                              });
                                              setCalenderData(arr);

                                              handleViewAnalysisFormCompleteOpen();
                                            }}
                                          >
                                            <EyeIcon />
                                          </button>
                                        </Tooltip>
                                        <Modal
                                          aria-labelledby="transition-modal-title"
                                          aria-describedby="transition-modal-description"
                                          open={viewAnalysisFormCompleteModal}
                                          onClose={
                                            handleViewAnalysisFormCompleteClose
                                          }
                                          closeAfterTransition
                                          className="uploadModal"
                                        >
                                          <Fade
                                            in={viewAnalysisFormCompleteModal}
                                          >
                                            <Box
                                              sx={style}
                                              component="form"
                                              className="uploadModalForm uploadModalOverflow"
                                            >
                                              <Typography
                                                id=""
                                                variant="h6"
                                                component="h2"
                                                style={{
                                                  fontWeight: "700",
                                                }}
                                              >
                                                Analysis Complete
                                                <IconButton
                                                  aria-label="close"
                                                  onClick={
                                                    handleViewAnalysisFormCompleteClose
                                                  }
                                                  sx={{
                                                    position: "absolute",
                                                    right: 8,
                                                    top: 8,
                                                    color: (theme) =>
                                                      theme.palette.grey[500],
                                                  }}
                                                >
                                                  <CloseIcon />
                                                </IconButton>
                                              </Typography>

                                              <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                  <div className="sample__modal">
                                                    <Typography
                                                      id=""
                                                      variant="h6"
                                                      component="h2"
                                                      className=""
                                                    >
                                                      RMPT Submitted Data Report
                                                    </Typography>

                                                    <ul className="sample__list">
                                                      {calenderData &&
                                                      calenderData.length > 0
                                                        ? calenderData.map(
                                                            (val, index) => {
                                                              return (
                                                                <li className="sample__list--item">
                                                                  <div className="flex alignitems--center justifyspace--between">
                                                                    <p>
                                                                      <span>
                                                                        {index +
                                                                          1}
                                                                        .{" "}
                                                                      </span>
                                                                      <span className="analyst">
                                                                        {
                                                                          val.analyst
                                                                        }
                                                                      </span>
                                                                      <span>
                                                                        {
                                                                          val.method
                                                                        }
                                                                      </span>
                                                                      <span>
                                                                        {
                                                                          val.material
                                                                        }
                                                                      </span>
                                                                      <span>
                                                                        {
                                                                          val.sample_type
                                                                        }
                                                                      </span>
                                                                    </p>

                                                                    <div
                                                                      style={{
                                                                        display:
                                                                          "flex",
                                                                      }}
                                                                    >
                                                                      {val.rmpt189 ? (
                                                                        <div className="button--icon modal--icon">
                                                                          <Tooltip title="View/Download">
                                                                            <button
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                e.preventDefault();
                                                                                axios
                                                                                  .get(
                                                                                    `/rmpt/get/previewCustomer?type=6&id=${userId}&reference_id=${customerId}&analyst_id=${val.id}`,
                                                                                    setLoader(
                                                                                      true
                                                                                    )
                                                                                  )

                                                                                  .then(
                                                                                    (
                                                                                      response
                                                                                    ) => {
                                                                                      let res =
                                                                                        JSON.parse(
                                                                                          response.data
                                                                                        );
                                                                                      if (
                                                                                        res.status ===
                                                                                        200
                                                                                      ) {
                                                                                        setLoader(
                                                                                          false
                                                                                        );
                                                                                        let pdfWindow =
                                                                                          window.open(
                                                                                            ""
                                                                                          );
                                                                                        pdfWindow.document.write(
                                                                                          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                                                                            encodeURI(
                                                                                              res.data
                                                                                            ) +
                                                                                            "'></iframe>"
                                                                                        );
                                                                                      } else {
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                  .catch(
                                                                                    (
                                                                                      error
                                                                                    ) => {}
                                                                                  )
                                                                                  .then(
                                                                                    () => {}
                                                                                  );
                                                                              }}
                                                                            >
                                                                              <EyeIcon />
                                                                            </button>
                                                                          </Tooltip>
                                                                        </div>
                                                                      ) : null}
                                                                    </div>
                                                                  </div>
                                                                </li>
                                                              );
                                                            }
                                                          )
                                                        : null}
                                                    </ul>
                                                  </div>
                                                </Grid>
                                              </Grid>
                                            </Box>
                                          </Fade>
                                        </Modal>
                                      </div>
                                    </div>
                                  </TableCell>

                                  {/* Appeal Letter */}
                                  <TableCell align="center" padding="none">
                                    <div
                                      className="flex alignitems--center"
                                      style={{ justifyContent: "center" }}
                                    >
                                      <Tooltip
                                        title={
                                          masterCheckedAppeal(value)
                                            ? getAppealLastUpdatedDate(value)
                                            : ""
                                        }
                                      >
                                        <Checkbox
                                          checked={masterCheckedAppeal(value)}
                                        />
                                      </Tooltip>
                                      <div className="btn--wrap">
                                        <Tooltip
                                          title={
                                            masterCheckedAppeal(value)
                                              ? "View/Download"
                                              : ""
                                          }
                                        >
                                          <button
                                            onClick={() => {
                                              setCustomerId(value.reference_id);
                                              setUserId(value.user_id);

                                              const arr = [];
                                              value.data.forEach((e) => {
                                                arr.push(e);
                                              });
                                              setCalenderData(arr);

                                              handleViewAppealLetterOpen();
                                            }}
                                            className="view-btn"
                                          >
                                            <EyeIcon />
                                          </button>
                                        </Tooltip>
                                        <Modal
                                          aria-labelledby="transition-modal-title"
                                          aria-describedby="transition-modal-description"
                                          open={viewAppealLetterModal}
                                          onClose={handleViewAppealLetterClose}
                                          closeAfterTransition
                                          className="uploadModal"
                                        >
                                          <Fade in={viewAppealLetterModal}>
                                            <Box
                                              sx={style}
                                              component="form"
                                              className="uploadModalForm uploadModalOverflow"
                                            >
                                              <Typography
                                                id=""
                                                variant="h6"
                                                component="h2"
                                                style={{
                                                  fontWeight: "700",
                                                }}
                                              >
                                                Appeal Letter
                                                <IconButton
                                                  aria-label="close"
                                                  onClick={
                                                    handleViewAppealLetterClose
                                                  }
                                                  sx={{
                                                    position: "absolute",
                                                    right: 8,
                                                    top: 8,
                                                    color: (theme) =>
                                                      theme.palette.grey[500],
                                                  }}
                                                >
                                                  <CloseIcon />
                                                </IconButton>
                                              </Typography>

                                              <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                  <div className="sample__modal">
                                                    <Typography
                                                      id=""
                                                      variant="h6"
                                                      component="h2"
                                                      className=""
                                                    >
                                                      RMPT Appeal Data Report
                                                      Form - {`(RMPT - 191)`}
                                                    </Typography>

                                                    <ul className="sample__list">
                                                      {calenderData &&
                                                      calenderData.length > 0
                                                        ? calenderData.map(
                                                            (value, index) => {
                                                              return (
                                                                <li className="sample__list--item">
                                                                  <div className="flex alignitems--center justifyspace--between">
                                                                    <p>
                                                                      <span>
                                                                        {index +
                                                                          1}
                                                                        .{" "}
                                                                      </span>
                                                                      <span className="analyst">
                                                                        {
                                                                          value.analyst
                                                                        }
                                                                      </span>
                                                                      <span>
                                                                        {
                                                                          value.method
                                                                        }
                                                                      </span>
                                                                      <span>
                                                                        {
                                                                          value.material
                                                                        }
                                                                      </span>
                                                                      <span>
                                                                        {
                                                                          value.sample_type
                                                                        }
                                                                      </span>
                                                                    </p>
                                                                    <div
                                                                      style={{
                                                                        display:
                                                                          "flex",
                                                                      }}
                                                                    >
                                                                      <div className="button--icon modal--icon">
                                                                        {value.isAppeal ? (
                                                                          <Tooltip title="View/Download">
                                                                            <button
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                e.preventDefault();
                                                                                axios
                                                                                  .get(
                                                                                    `/rmpt/get/previewCustomer?type=8&id=${userId}&reference_id=${customerId}&analyst_id=${value.id}`,
                                                                                    setLoader(
                                                                                      true
                                                                                    )
                                                                                  )

                                                                                  .then(
                                                                                    (
                                                                                      response
                                                                                    ) => {
                                                                                      let res =
                                                                                        JSON.parse(
                                                                                          response.data
                                                                                        );
                                                                                      if (
                                                                                        res.status ===
                                                                                        200
                                                                                      ) {
                                                                                        setLoader(
                                                                                          false
                                                                                        );
                                                                                        let pdfWindow =
                                                                                          window.open(
                                                                                            ""
                                                                                          );
                                                                                        pdfWindow.document.write(
                                                                                          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                                                                            encodeURI(
                                                                                              res.data
                                                                                            ) +
                                                                                            "'></iframe>"
                                                                                        );
                                                                                      } else {
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                  .catch(
                                                                                    (
                                                                                      error
                                                                                    ) => {}
                                                                                  )
                                                                                  .then(
                                                                                    () => {
                                                                                      console.log(
                                                                                        "-----always executes"
                                                                                      );
                                                                                    }
                                                                                  );
                                                                              }}
                                                                            >
                                                                              <EyeIcon />
                                                                            </button>
                                                                          </Tooltip>
                                                                        ) : null}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </li>
                                                              );
                                                            }
                                                          )
                                                        : null}
                                                    </ul>
                                                  </div>
                                                </Grid>
                                              </Grid>
                                            </Box>
                                          </Fade>
                                        </Modal>
                                      </div>
                                    </div>
                                  </TableCell>

                                  {/* Appeal Complete */}
                                  <TableCell align="center" padding="none">
                                    <div
                                      className="flex alignitems--center"
                                      style={{ justifyContent: "center" }}
                                    >
                                      <Tooltip
                                        title={
                                          masterCheckedReAppeal(value)
                                            ? getReAppealLastUpdatedDate(value)
                                            : ""
                                        }
                                      >
                                        <Checkbox
                                          checked={masterCheckedReAppeal(value)}
                                        />
                                      </Tooltip>
                                      <div className="btn--wrap">
                                        <Tooltip
                                          title={
                                            masterCheckedReAppeal(value)
                                              ? "View/Download"
                                              : ""
                                          }
                                        >
                                          <button
                                            onClick={() => {
                                              setCustomerId(value.reference_id);
                                              setUserId(value.user_id);
                                              const arr = [];
                                              value.data.forEach((e) => {
                                                arr.push(e);
                                              });
                                              setCalenderData(arr);

                                              handleViewAppealCompleteOpen();
                                            }}
                                            className="view-btn"
                                          >
                                            <EyeIcon />
                                          </button>
                                        </Tooltip>
                                      </div>
                                    </div>

                                    <Modal
                                      aria-labelledby="transition-modal-title"
                                      aria-describedby="transition-modal-description"
                                      open={viewAppealCompleteModal}
                                      onClose={handleViewAppealCompleteClose}
                                      closeAfterTransition
                                      className="uploadModal"
                                    >
                                      <Fade in={viewAppealCompleteModal}>
                                        <Box
                                          sx={style}
                                          component="form"
                                          className="uploadModalForm"
                                        >
                                          <Typography
                                            id=""
                                            variant="h6"
                                            component="h2"
                                            style={{
                                              fontWeight: "700",
                                            }}
                                          >
                                            Appeal Complete
                                            <IconButton
                                              aria-label="close"
                                              onClick={
                                                handleViewAppealCompleteClose
                                              }
                                              sx={{
                                                position: "absolute",
                                                right: 8,
                                                top: 8,
                                                color: (theme) =>
                                                  theme.palette.grey[500],
                                              }}
                                            >
                                              <CloseIcon />
                                            </IconButton>
                                          </Typography>

                                          <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                              <div className="sample__modal sample__modal--analyst">
                                                <Typography
                                                  id=""
                                                  variant="h6"
                                                  component="h2"
                                                  className=""
                                                >
                                                  RMPT Submitted Appealed Data
                                                  Report
                                                </Typography>

                                                <ul className="sample__list">
                                                  {calenderData &&
                                                  calenderData.length > 0
                                                    ? calenderData.map(
                                                        (val, index) => {
                                                          return (
                                                            <li className="sample__list--item">
                                                              <div className="flex alignitems--center justifyspace--between">
                                                                <p>
                                                                  <span>
                                                                    {index + 1}.{" "}
                                                                  </span>
                                                                  <span className="analyst">
                                                                    {
                                                                      val.analyst
                                                                    }
                                                                  </span>
                                                                  <span>
                                                                    {val.method}
                                                                  </span>
                                                                  <span>
                                                                    {
                                                                      val.material
                                                                    }
                                                                  </span>
                                                                  <span>
                                                                    {
                                                                      val.sample_type
                                                                    }
                                                                  </span>
                                                                </p>

                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                  }}
                                                                >
                                                                  {val.isReappeal ? (
                                                                    <div className="button--icon modal--icon">
                                                                      <Tooltip title="View/Download">
                                                                        <button
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            e.preventDefault();
                                                                            axios
                                                                              .get(
                                                                                `/rmpt/get/previewCustomer?type=9&id=${userId}&reference_id=${customerId}&analyst_id=${val.id}`,
                                                                                setLoader(
                                                                                  true
                                                                                )
                                                                              )

                                                                              .then(
                                                                                (
                                                                                  response
                                                                                ) => {
                                                                                  let res =
                                                                                    JSON.parse(
                                                                                      response.data
                                                                                    );
                                                                                  if (
                                                                                    res.status ===
                                                                                    200
                                                                                  ) {
                                                                                    setLoader(
                                                                                      false
                                                                                    );
                                                                                    let pdfWindow =
                                                                                      window.open(
                                                                                        ""
                                                                                      );
                                                                                    pdfWindow.document.write(
                                                                                      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                                                                        encodeURI(
                                                                                          res.data
                                                                                        ) +
                                                                                        "'></iframe>"
                                                                                    );
                                                                                  } else {
                                                                                  }
                                                                                }
                                                                              )
                                                                              .catch(
                                                                                (
                                                                                  error
                                                                                ) => {}
                                                                              )
                                                                              .then(
                                                                                () => {}
                                                                              );
                                                                          }}
                                                                        >
                                                                          <EyeIcon />
                                                                        </button>
                                                                      </Tooltip>
                                                                    </div>
                                                                  ) : null}
                                                                </div>
                                                              </div>
                                                            </li>
                                                          );
                                                        }
                                                      )
                                                    : null}
                                                </ul>
                                              </div>
                                            </Grid>

                                            {/* <Grid item xs={12}>
                                              <div className="sample__modal sample__modal--analyst">
                                                <Typography
                                                  id=""
                                                  variant="h6"
                                                  component="h2"
                                                  className=""
                                                >
                                                  Amended RMPT Z-Score Summary
                                                  Report Form
                                                </Typography>

                                                <ul className="sample__list">
                                                  {calenderData &&
                                                  calenderData.length > 0
                                                    ? calenderData.map(
                                                        (val, index) => {
                                                          return (
                                                            <li className="sample__list--item">
                                                              <div className="flex alignitems--center justifyspace--between">
                                                                <p>
                                                                  <span>
                                                                    {index + 1}.{" "}
                                                                  </span>
                                                                  <span className="analyst">
                                                                    {
                                                                      val.analyst
                                                                    }
                                                                  </span>
                                                                  <span>
                                                                    {val.method}
                                                                  </span>
                                                                  <span>
                                                                    {
                                                                      val.material
                                                                    }
                                                                  </span>
                                                                  <span>
                                                                    {
                                                                      val.sample_type
                                                                    }
                                                                  </span>
                                                                </p>

                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                  }}
                                                                >
                                                                  {val.isReappeal ? (
                                                                    <div className="button--icon modal--icon">
                                                                      <Tooltip title="View/Download">
                                                                        <button
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            e.preventDefault();
                                                                            axios
                                                                              .get(
                                                                                `/rmpt/get/preview?type=10&id=${userId}&reference_id=${customerId}&analyst_id=${val.id}`,
                                                                                setLoader(
                                                                                  true
                                                                                )
                                                                              )

                                                                              .then(
                                                                                (
                                                                                  response
                                                                                ) => {
                                                                                  let res =
                                                                                    JSON.parse(
                                                                                      response.data
                                                                                    );
                                                                                  if (
                                                                                    res.status ===
                                                                                    200
                                                                                  ) {
                                                                                    setLoader(
                                                                                      false
                                                                                    );
                                                                                    let pdfWindow =
                                                                                      window.open(
                                                                                        ""
                                                                                      );
                                                                                    pdfWindow.document.write(
                                                                                      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                                                                        encodeURI(
                                                                                          res.data
                                                                                        ) +
                                                                                        "'></iframe>"
                                                                                    );
                                                                                  } else {
                                                                                  }
                                                                                }
                                                                              )
                                                                              .catch(
                                                                                (
                                                                                  error
                                                                                ) => {}
                                                                              )
                                                                              .then(
                                                                                () => {}
                                                                              );
                                                                          }}
                                                                        >
                                                                          <EyeIcon />
                                                                        </button>
                                                                      </Tooltip>
                                                                    </div>
                                                                  ) : null}
                                                                </div>
                                                              </div>
                                                            </li>
                                                          );
                                                        }
                                                      )
                                                    : null}
                                                </ul>
                                              </div>
                                            </Grid> */}
                                          </Grid>
                                        </Box>
                                      </Fade>
                                    </Modal>
                                  </TableCell>

                                  {/* Sample Complete */}
                                  <TableCell align="center" padding="none">
                                    <div
                                      className="flex alignitems--center"
                                      style={{ justifyContent: "center" }}
                                    >
                                      <Tooltip
                                        title={
                                          getSampleCloseChartChecked(value)
                                            ? getSampleClosedLastUpdatedDate(
                                                value
                                              )
                                            : ""
                                        }
                                      >
                                        <Checkbox
                                          checked={getSampleCloseChartChecked(
                                            value
                                          )}
                                        />
                                      </Tooltip>
                                      <div className="btn--wrap">
                                        <Tooltip
                                          title={
                                            getSampleCloseChartChecked(value)
                                              ? "View/Download"
                                              : ""
                                          }
                                        >
                                          <button
                                            disabled={
                                              !getSampleCloseChartChecked(value)
                                            }
                                            onClick={() => {
                                              setCustomerId(value.reference_id);
                                              setUserId(value.user_id);

                                              const arr = [];
                                              value.data.forEach((e) => {
                                                arr.push(e);
                                              });
                                              setCalenderData(arr);

                                              handleChartModalOpen();
                                            }}
                                            className="view-btn"
                                          >
                                            <EyeIcon />
                                          </button>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </TableCell>
                                  {/* View Details */}
                                  <TableCell align="center" padding="none">
                                    <div className="btn--wrap">
                                      <Tooltip title="View/Download">
                                        <button className="view-btn">
                                          <EyeIcon />
                                        </button>
                                      </Tooltip>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </form>
            </div>
          </div>
        </section>

        {/* <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={true}
            onClose={handleViewSampleClose}
            closeAfterTransition
            className="uploadModal"
        >
            <Fade in={true}>
            <Box sx={style} component="form" className="uploadModalForm">
                <Typography
                id=""
                variant="h6"
                component="h2"
                style={{
                    marginBottom: "10px",
                    fontWeight: "700",
                }}
                >
                Download Samples
                <IconButton
                    aria-label="close"
                    onClick={
                    handleViewSampleClose
                    }
                    sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) =>
                        theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="sample__modal">
                            <Typography
                                id=""
                                variant="h6"
                                component="h2"
                            >
                                RMPT 187
                            </Typography>
                            <h3 className="sample__list--title">Analyst List</h3>
                            <ul className="sample__list">
                                <li className="sample__list--item">
                                    <div className="flex alignitems--center justifyspace--between">
                                        <p>1. abc@gmail.com</p>
                                        <div className="button--icon modal--icon">
                                            <Tooltip title="View/Download">
                                                <button
                                                    onClick={(e) => {
                                                    e.preventDefault();
                                                    axios
                                                        .get(
                                                        `xsd`
                                                        )

                                                        .then(
                                                        (response) => {
                                                            let res =
                                                            JSON.parse(
                                                                response.data
                                                            );
                                                            if (
                                                            res.status ===
                                                            200
                                                            ) {
                                                            let pdfWindow =
                                                                window.open(
                                                                ""
                                                                );
                                                            pdfWindow.document.write(
                                                                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                                                encodeURI(
                                                                    res.data
                                                                ) +
                                                                "'></iframe>"
                                                            );
                                                            } else {
                                                            }
                                                        }
                                                        )
                                                        .catch(
                                                        (error) => {}
                                                        )
                                                        .then(() => {
                                                        console.log(
                                                            "-----always executes"
                                                        );
                                                        });
                                                    }}
                                                >
                                                    <EyeIcon />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </li>
                                <li className="sample__list--item">
                                    <div className="flex alignitems--center justifyspace--between">
                                        <p>2. xyz@gmail.com</p>
                                        <div className="button--icon modal--icon">
                                            <Tooltip title="View/Download">
                                                <button
                                                    onClick={(e) => {
                                                    e.preventDefault();
                                                    axios
                                                        .get(
                                                        `xsd`
                                                        )

                                                        .then(
                                                        (response) => {
                                                            let res =
                                                            JSON.parse(
                                                                response.data
                                                            );
                                                            if (
                                                            res.status ===
                                                            200
                                                            ) {
                                                            let pdfWindow =
                                                                window.open(
                                                                ""
                                                                );
                                                            pdfWindow.document.write(
                                                                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                                                encodeURI(
                                                                    res.data
                                                                ) +
                                                                "'></iframe>"
                                                            );
                                                            } else {
                                                            }
                                                        }
                                                        )
                                                        .catch(
                                                        (error) => {}
                                                        )
                                                        .then(() => {
                                                        console.log(
                                                            "-----always executes"
                                                        );
                                                        });
                                                    }}
                                                >
                                                    <EyeIcon />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className="sample__modal">
                            <Typography
                                id=""
                                variant="h6"
                                component="h2"
                                className=""
                            >
                                RMPT 188
                            </Typography>
                            <h3 className="sample__list--title">Analyst List</h3>
                            <ul className="sample__list">
                                <li className="sample__list--item">
                                    <div className="flex alignitems--center justifyspace--between">
                                        <p>1. abc@gmail.com</p>
                                        <div className="button--icon modal--icon">
                                            <Tooltip title="View/Download">
                                                <button
                                                    onClick={(e) => {
                                                    e.preventDefault();
                                                    axios
                                                        .get(
                                                        `xsd`
                                                        )

                                                        .then(
                                                        (response) => {
                                                            let res =
                                                            JSON.parse(
                                                                response.data
                                                            );
                                                            if (
                                                            res.status ===
                                                            200
                                                            ) {
                                                            let pdfWindow =
                                                                window.open(
                                                                ""
                                                                );
                                                            pdfWindow.document.write(
                                                                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                                                encodeURI(
                                                                    res.data
                                                                ) +
                                                                "'></iframe>"
                                                            );
                                                            } else {
                                                            }
                                                        }
                                                        )
                                                        .catch(
                                                        (error) => {}
                                                        )
                                                        .then(() => {
                                                        console.log(
                                                            "-----always executes"
                                                        );
                                                        });
                                                    }}
                                                >
                                                    <EyeIcon />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </li>
                                <li className="sample__list--item">
                                    <div className="flex alignitems--center justifyspace--between">
                                        <p>2. xyz@gmail.com</p>
                                        <div className="button--icon modal--icon">
                                            <Tooltip title="View/Download">
                                                <button
                                                    onClick={(e) => {
                                                    e.preventDefault();
                                                    axios
                                                        .get(
                                                        `xsd`
                                                        )

                                                        .then(
                                                        (response) => {
                                                            let res =
                                                            JSON.parse(
                                                                response.data
                                                            );
                                                            if (
                                                            res.status ===
                                                            200
                                                            ) {
                                                            let pdfWindow =
                                                                window.open(
                                                                ""
                                                                );
                                                            pdfWindow.document.write(
                                                                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                                                encodeURI(
                                                                    res.data
                                                                ) +
                                                                "'></iframe>"
                                                            );
                                                            } else {
                                                            }
                                                        }
                                                        )
                                                        .catch(
                                                        (error) => {}
                                                        )
                                                        .then(() => {
                                                        console.log(
                                                            "-----always executes"
                                                        );
                                                        });
                                                    }}
                                                >
                                                    <EyeIcon />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            
                        </div>
                    </Grid>
                </Grid>
            </Box>
            </Fade>
        </Modal> */}
      </div>
    </>
  );
};

export default FormStatus;
