import React, { useEffect, useState } from "react";
import Header from "../../Common/Header/Header";
import { Formik } from "formik";
import Swal from "sweetalert2";
import randomstring from "randomstring";

import * as Yup from "yup";
import axios from "axios";
import axiosInstance from "../../../Services/axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Creatable from "react-select/creatable";
import { withAsyncPaginate } from "react-select-async-paginate";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";

import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import EditIcon from "../../Icon/Edit";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { getData } from "../../../Utils/utils";
import CompanyModal from "./modal/companyModal";
const CreatableAsyncPaginate = withAsyncPaginate(Creatable);
const InfoRequest = () => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "white" : "white",
      zIndex: 99999,
      borderColor: state.isFocused ? "#80bdff" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #80bdff" : provided.boxShadow,
    }),
    option: (provided, state) => ({
      ...provided,
      zIndex: 99999,

      backgroundColor: state.isSelected ? "#990000" : "white",
      color: state.isSelected ? "inherit" : provided.color,
    }),
  };
  const [linkData, setLinkData] = useState("");
  const [cacheUniq1, setCacheUniq1] = useState(0);
  const [totalCompany, setTotalCompany] = useState(0);
  const [availableLink, setAvailableLink] = useState(false);
  const [editableLink, setEditableLink] = useState(false);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    // Fetch countries
    axios
      .get("https://countriesnow.space/api/v0.1/countries/iso")
      .then((response) => {
        const res = response.data;

        const countryOptions = res.data.map((country) => ({
          value: country.Iso2,
          label: country.name,
        }));
        setCountries(countryOptions);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      // Fetch states when a country is selected
      axios
        .post("https://countriesnow.space/api/v0.1/countries/states", {
          country: selectedCountry,
        })
        .then((response) => {
          const res = response.data;
          console.log("sddsds", res);

          const stateOptions = res.data.states.map((state) => ({
            value: state.name,
            label: state.name,
          }));
          setStates(stateOptions);
          // setCities([]); // Reset cities
        })
        .catch((error) => console.error("Error fetching states:", error));
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      // Fetch cities when a state is selected
      axios
        .post("https://countriesnow.space/api/v0.1/countries/state/cities", {
          country: selectedCountry,
          state: selectedState,
        })
        .then((response) => {
          const res = response.data;

          const cityOptions = res.data.map((city) => ({
            value: city,
            label: city,
          }));
          setCities(cityOptions);
        })
        .catch((error) => console.error("Error fetching cities:", error));
    }
  }, [selectedState]);

  async function loadCompany(search, loadedOptions, { page }) {
    let has_more = true;

    let newarray = [];
    let skip = (page - 1) * 10;
    let limit = 10;
    let name = search;
    let url = "";
    if (loadedOptions.length === 0 || loadedOptions.length < totalCompany) {
      if (name.length > 0) {
        url = `/company/get?name=${name}&skip=0&limit=10`;
      } else {
        url = `/company/get?skip=${skip}&limit=${limit}`;
      }
      const res = await getData(url);
      console.log("sdjsj", res);
      setTotalCompany(res.totalCount);
      for (var i = 0; i < res.company.length; i++) {
        newarray.push({
          label: res.company[i].name,
          value: res.company[i].id,
          company_website: res.company[i].company_website,
          fax: res.company[i].fax,
          address: res.company[i].address,
          city: res.company[i].city,
          state: res.company[i].state,
          country: res.company[i].country,
          zip: res.company[i].zip,
        });
      }
    }

    const responseJSON = {
      results: newarray,
      has_more: newarray.length > 0 ? has_more : false,
    };

    return {
      options: responseJSON.results,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  }

  const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (getQueryVariable("link")) {
      axiosInstance
        .post("/rmpt/user/get/data/by/link", {
          link: getQueryVariable("link"),
        })

        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.status === 200) {
            setLinkData(res.data);
            setSelectedCountry(res.data.country);
            setSelectedState(res.data.state);
            setSelectedCity(res.data.city);

            setAvailableLink(true);
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: res.message,
              showConfirmButton: true,
              // timer: 1500,
            });
          }
        })
        .catch((error) => {})
        .then(() => {});
    }
  }, []);
  const [passwordValues, setPasswordValues] = React.useState({
    confirmPassword: false,
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickConfirmPassword = () => {
    setPasswordValues({
      ...passwordValues,
      confirmPassword: !passwordValues.confirmPassword,
    });
  };

  const registerSchema = Yup.object({
    companyName: Yup.object().shape({
      label: Yup.string()
        .matches(/^[a-zA-Z0-9\s\-.,'&()]+$/, "Please enter valid Name")
        .required("Required"),
      value: Yup.number().required("Required"),
    }),
    first_name: Yup.string().required("Required"),

    telephone: Yup.string().required("Required"),
    labContactEmail: Yup.string()
      .email("Invalid email address")
      .required("Required"),
    fax: Yup.string(),
    companyWebsite: Yup.string().matches(
      // eslint-disable-next-line
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
      "Please enter valid Website"
    ),

    mailingAddress: Yup.string().required("Required"),

    zip: Yup.string().required("Required"),
  });

  const registerSchemaWithLink = Yup.object({
    companyName: Yup.object().shape({
      label: Yup.string()
        .matches(/^[a-zA-Z0-9\s\-.,'&()]+$/, "Please enter valid Name")
        .required("Required"),
      value: Yup.number().required("Required"),
    }),
    first_name: Yup.string().required("Required"),
    telephone: Yup.string().required("Required"),
    labContactEmail: Yup.string()
      .email("Invalid email address")
      .required("Required"),
    fax: Yup.string(),
    companyWebsite: Yup.string().matches(
      // eslint-disable-next-line
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
      "Please enter valid Website"
    ),

    mailingAddress: Yup.string().required("Required"),
    newPassword: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Please Enter Valid Password use at least one uppercase letter, lowercase letter, number, special character with minimum 8 characters"
      ),
    confirmPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords don't match!"),
    zip: Yup.string().required("Required"),
  });

  const [companyModal, setCompanyModal] = useState(false);
  const [prefillCompanyName, setPrefillCompanyName] = useState("");
  const formatOptionLabel = ({ label, city }) =>
    `${label} ${city ? "-" : ""} ${city ? city : ""}`;

  const updateCompanyName = (obj) => {
    setLinkData({
      ...linkData,
      company_name: obj.label,
      company_id: obj.value,
      company_website: obj.company_website,
      fax: obj.fax,
      address: obj.address,
      zip: obj.zip,
    });
    console.log("sdjdsj", obj);
    setSelectedCity(obj.city);
    setSelectedState(obj.state);
    setSelectedCountry(obj.country);
  };
  const closeCompanyModal = () => {
    setCompanyModal(false);
  };
  return (
    <>
      <Header themeWhite={true} contactBtn={true} bgLight={true} />
      <div className="mainSection mainPortal">
        <section className="page__section">
          <div className="container">
            <CompanyModal
              updateCompanyName={updateCompanyName}
              prefillCompanyName={prefillCompanyName}
              setCacheUniq1={setCacheUniq1}
              companyModal={companyModal}
              closeCompanyModal={closeCompanyModal}
            />
            <div className="form__container">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  companyName: linkData
                    ? {
                        label: linkData.company_name,
                        value: linkData.company_id,
                        city: selectedCity,
                      }
                    : "",
                  first_name: linkData ? linkData.first_name : "",
                  labContactEmail: linkData ? linkData.email : "",
                  telephone: linkData ? linkData.telephone : "",
                  fax: linkData && linkData.fax ? linkData.fax : "",
                  companyWebsite:
                    linkData && linkData.company_website
                      ? linkData.company_website
                      : "",
                  mailingAddress: linkData ? linkData.address : "",
                  state: selectedState,
                  city: selectedCity,
                  zip: linkData ? linkData.zip : "",
                  preferredModeOfContact: linkData
                    ? linkData.contact_preferred_method
                    : "email",
                  newPassword: "",
                  confirmPassword: "",
                }}
                validationSchema={
                  availableLink || editableLink
                    ? registerSchemaWithLink
                    : registerSchema
                }
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  if (availableLink || editableLink) {
                    let passwordObj = {
                      user_id: linkData.id,
                      password: values.newPassword,
                      company_name: values.companyName.label,
                      company_id: values.companyName.value,
                      first_name: values.first_name.toString(),
                      email: values.labContactEmail,
                      telephone: values.telephone,
                      fax: values.fax === "" ? undefined : values.fax,
                      company_website:
                        values.companyWebsite === ""
                          ? undefined
                          : values.companyWebsite,
                      address: values.mailingAddress,
                      state: selectedState,
                      city: selectedCity,
                      country: selectedCountry,
                      zip: values.zip,
                      username: linkData?.username,
                      contact_preferred_method: values.preferredModeOfContact,
                    };
                    axiosInstance
                      .post("rmpt/user/complete/profile", passwordObj)

                      .then((response) => {
                        let res = JSON.parse(response.data);
                        if (res.status === 200) {
                          setSubmitting(false);

                          // window.open("/customer-login", "_self");
                          Swal.fire({
                            icon: "success",
                            position: "center",

                            title: res.message,
                            showConfirmButton: true,
                          }).then(() => {
                            window.open("/customer-login", "_self");
                          });
                        } else {
                          Swal.fire({
                            position: "center",
                            icon: "error",
                            title: res.message,
                            showConfirmButton: true,
                            // timer: 1500,
                          });
                          setSubmitting(false);
                        }
                      })
                      .catch((error) => {
                        setSubmitting(false);
                      })
                      .then(() => {
                        setSubmitting(false);
                      });
                  } else {
                    let registerObj = {
                      company_name: values.companyName.label,
                      company_id: values.companyName.value,
                      first_name: values.first_name.toString(),
                      email: values.labContactEmail,
                      telephone: values.telephone,
                      fax: values.fax === "" ? undefined : values.fax,
                      company_website:
                        values.companyWebsite === ""
                          ? undefined
                          : values.companyWebsite,
                      address: values.mailingAddress,
                      state: selectedState,
                      city: selectedCity,
                      country: selectedCountry,

                      zip: values.zip,
                      contact_preferred_method: values.preferredModeOfContact,
                      username: randomstring.generate(6),
                    };
                    axiosInstance
                      .post("/rmpt/user/register", registerObj)

                      .then((response) => {
                        let res = JSON.parse(response.data);
                        if (res.status === 200) {
                          setSubmitting(false);

                          // window.open("/profile-data", "_self");
                          Swal.fire({
                            position: "center",
                            icon: "success",
                            title:
                              "Please click on the link sent in your email for further signup process!",
                            showConfirmButton: true,
                            // timer: 1500,
                          });
                          // resetForm();
                        } else {
                          Swal.fire({
                            position: "center",
                            icon: "error",
                            title: res.message,
                            showConfirmButton: true,
                            // timer: 1500,
                          });
                          setSubmitting(false);
                        }
                      })
                      .catch((error) => {
                        setSubmitting(false);
                      })
                      .then(() => {
                        setSubmitting(false);
                      });
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  resetForm,
                  /* and other goodies */
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="form form__user"
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <h1 className="form__title justifyspace--between">
                      Info Request{" "}
                      {availableLink || editableLink ? (
                        <button
                          type="button"
                          onClick={() => {
                            setAvailableLink(false);
                            setEditableLink(true);
                          }}
                          className="form__edit--btn"
                        >
                          <EditIcon /> Edit
                        </button>
                      ) : null}
                    </h1>
                    {console.log("sjdjsdjdjs", values)}
                    <h2 className="form__subtitle">
                      Please submit the following Information so we know how to
                      contact you.
                    </h2>
                    <div className="form__section">
                      <div className="form__group--flex">
                        <div style={{ zIndex: 10 }} className="form__group">
                          <CreatableAsyncPaginate
                            styles={customStyles}
                            isDisabled={availableLink || editableLink}
                            value={values.companyName}
                            cacheUniqs={[cacheUniq1]}
                            onCreateOption={(e) => {
                              console.log(e);
                              setPrefillCompanyName(e);
                              setCompanyModal(true);
                            }}
                            placeholder={"Select/Search Company"}
                            loadOptions={loadCompany}
                            onChange={(obj) => {
                              setLinkData({
                                ...linkData,
                                company_name: obj.label,
                                company_id: obj.value,
                                company_website: obj.company_website,
                                fax: obj.fax,
                                address: obj.address,

                                zip: obj.zip,
                              });
                              setSelectedCountry(obj.country);
                              setSelectedState(obj.state);
                              setSelectedCity(obj.city);
                            }}
                            formatOptionLabel={formatOptionLabel}
                            additional={{
                              page: 1,
                            }}
                          />
                          <div className="form__input--error position--absolute">
                            {errors.companyName &&
                              touched.companyName &&
                              errors.companyName}
                          </div>
                        </div>
                        <div className="form__group">
                          <TextField
                            label="First/Last Name"
                            size="small"
                            // type="number"
                            name="first_name"
                            disabled={availableLink}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.first_name}
                            fullWidth
                          />
                          <div className="form__input--error position--absolute">
                            {errors.first_name &&
                              touched.first_name &&
                              errors.first_name}
                          </div>
                        </div>
                      </div>
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            label="Lab Contact Email"
                            size="small"
                            disabled={availableLink || editableLink}
                            name="labContactEmail"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.labContactEmail}
                            fullWidth
                          />
                          <div className="form__input--error position--absolute">
                            {errors.labContactEmail &&
                              touched.labContactEmail &&
                              errors.labContactEmail}
                          </div>
                        </div>
                        <div className="form__group">
                          <TextField
                            label="Telephone"
                            size="small"
                            type="text"
                            name="telephone"
                            disabled={availableLink}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.telephone}
                            fullWidth
                          />
                          <div className="form__input--error position--absolute">
                            {errors.telephone &&
                              touched.telephone &&
                              errors.telephone}
                          </div>
                        </div>
                      </div>
                      <div className="form__group--flex">
                        <div className="form__group">
                          <TextField
                            label="Fax"
                            size="small"
                            type="text"
                            name="fax"
                            disabled
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.fax}
                            fullWidth
                          />
                          <div className="form__input--error position--absolute">
                            {errors.fax && touched.fax && errors.fax}
                          </div>
                        </div>
                        <div className="form__group">
                          <TextField
                            label="Company Website"
                            size="small"
                            name="companyWebsite"
                            disabled
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.companyWebsite}
                            fullWidth
                          />
                          <div className="form__input--error position--absolute">
                            {errors.companyWebsite &&
                              touched.companyWebsite &&
                              errors.companyWebsite}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form__section">
                      <div className="form__group--flex-full">
                        <div className="form__group">
                          <TextField
                            label="Mailing Address"
                            size="small"
                            fullWidth
                            disabled
                            placeholder="Your complete mailing address here"
                            name="mailingAddress"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mailingAddress}
                          />
                          <div className="form__input--error position--absolute">
                            {errors.mailingAddress &&
                              touched.mailingAddress &&
                              errors.mailingAddress}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form__group--flex">
                      <div className="form__group">
                        <Box sx={{ minWidth: 120, maxHeight: 40 }}>
                          <FormControl
                            disabled={availableLink}
                            fullWidth
                            size="small"
                          >
                            <InputLabel id="demo-simple-select-label">
                              Country
                            </InputLabel>
                            <Select
                              disabled
                              label="Country"
                              value={selectedCountry ?? ""}
                              MenuProps={{
                                classes: { paper: "selectCountryRoot" },
                              }}
                              onChange={(e) =>
                                setSelectedCountry(e.target.value)
                              }
                            >
                              {countries.map((country) => (
                                <MenuItem
                                  key={country.value}
                                  value={country.label}
                                >
                                  {country.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                      {console.log("lillo", values.zip)}
                      <div className="form__group">
                        <TextField
                          disabled
                          label="Zip/Postal Code"
                          size="small"
                          name="zip"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.zip ? values.zip : ""}
                          fullWidth
                        />
                        <div className="form__input--error position--absolute">
                          {errors.zip && touched.zip && errors.zip}
                        </div>
                      </div>
                    </div>
                    <div className="form_group--flex">
                      <div className="form__group--flex">
                        <div className="form__group">
                          <Box sx={{ minWidth: 120, maxHeight: 40 }}>
                            <FormControl fullWidth size="small" disabled>
                              <InputLabel id="demo-simple-select-label">
                                City
                              </InputLabel>
                              <Select
                                disabled
                                label="City"
                                value={selectedCity ?? ""}
                                MenuProps={{
                                  classes: { paper: "selectCountryRoot" },
                                }}
                                onChange={(e) =>
                                  setSelectedCity(e.target.value)
                                }
                              >
                                {cities.map((city) => (
                                  <MenuItem key={city.value} value={city.label}>
                                    {city.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                        <div className="form__group">
                          <Box sx={{ minWidth: 120, maxHeight: 40 }}>
                            <FormControl fullWidth size="small" disabled>
                              <InputLabel id="demo-simple-select-label">
                                State
                              </InputLabel>
                              <Select
                                disabled
                                label="State"
                                InputLabelProps={{
                                  shrink: selectedState?.length > 0,
                                }}
                                value={selectedState ?? ""}
                                MenuProps={{
                                  classes: { paper: "selectCountryRoot" },
                                }}
                                onChange={(e) =>
                                  setSelectedState(e.target.value)
                                }
                              >
                                {states.map((city) => (
                                  <MenuItem key={city.value} value={city.label}>
                                    {city.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      </div>
                      {availableLink || editableLink ? (
                        <div className="form__group--flex">
                          <div className="form__group">
                            <FormControl
                              variant="outlined"
                              size="small"
                              fullWidth
                            >
                              <InputLabel htmlFor="outlined-create-password">
                                Create New Password
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-create-password"
                                type={
                                  passwordValues.showPassword
                                    ? "text"
                                    : "password"
                                }
                                name="newPassword"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.newPassword}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {passwordValues.showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Create New Password"
                              />
                            </FormControl>
                            <div className="form__input--error">
                              {touched.newPassword || errors.newPassword}
                              {touched.newPassword && errors.newPassword}
                            </div>
                          </div>
                          <div className="form__group">
                            <FormControl
                              variant="outlined"
                              size="small"
                              fullWidth
                            >
                              <InputLabel htmlFor="outlined-confirm-password">
                                Confirm New Password
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-confirm-password"
                                type={
                                  passwordValues.confirmPassword
                                    ? "text"
                                    : "password"
                                }
                                name="confirmPassword"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.confirmPassword}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickConfirmPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {passwordValues.confirmPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Confirm New Password"
                              />
                            </FormControl>
                            <div className="form__input--error">
                              {touched.confirmPassword ||
                                errors.confirmPassword}
                              {touched.confirmPassword &&
                                errors.confirmPassword}
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="form__group--flex mb_0">
                        <div className="form__group flex justifyspace--between">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn--primary"
                            // onClick={() =>
                            //   window.open("/customer-register", "_self")
                            // }
                          >
                            {availableLink
                              ? "Create an Account"
                              : "Request Info Packet"}
                          </button>
                          {editableLink ? (
                            <button
                              onClick={() => {
                                resetForm();
                              }}
                              type="reset"
                              className="btn btn--transparent btn--reset"
                            >
                              Reset
                            </button>
                          ) : null}
                        </div>
                        <div className="form__group--flex-full mb_0">
                          <label
                            className="form__label"
                            style={{ marginTop: "-6px" }}
                          >
                            What is your preferred method of contact
                          </label>
                          <div className="form__group--flex mb_0 form__group--checkbox">
                            <div className="form__group">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={availableLink}
                                    defaultChecked
                                    checked={
                                      values.preferredModeOfContact === "email"
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "preferredModeOfContact",
                                        "email"
                                      )
                                    }
                                  />
                                }
                                label="Email"
                              />
                            </div>
                            {console.log(
                              "goaaaa",
                              selectedState,
                              selectedCity,
                              selectedCountry
                            )}
                            <div className="form__group">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={availableLink}
                                    checked={
                                      values.preferredModeOfContact === "phone"
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "preferredModeOfContact",
                                        "phone"
                                      )
                                    }
                                  />
                                }
                                label="Phone"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default InfoRequest;
