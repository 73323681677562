import React from "react";
import DownloadIcon from "../../../Icon/Download";

const Certificate = ({
  lists: { name, category, content, file, revision_date },
}) => {
  const arr = revision_date.split("-");
  const date = arr[1] + "/" + arr[2] + "/" + arr[0];

  return (
    <div className="certificates__card">
      <h4 className="color--black">{name}</h4>
      <p className="color--gray">{content}</p>
      <a
        href={file}
        className="btn--download color--gray"
        target="_blank"
        rel="noreferrer"
      >
        <DownloadIcon /> Download{" "}
      </a>
      <span className="date color--black">
        Revision Date: <span className="color--gray">{date}</span>
      </span>
    </div>
  );
};

export default Certificate;
