import React from "react";

const ViewIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="12.375"
                viewBox="0 0 18 12.375"
            >
                <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M18,11.813S14.625,5.625,9,5.625s-9,6.188-9,6.188S3.375,18,9,18,18,11.813,18,11.813Zm-16.681,0a14.776,14.776,0,0,0,1.868,2.3A8.383,8.383,0,0,0,9,16.875a8.39,8.39,0,0,0,5.814-2.764,14.776,14.776,0,0,0,1.867-2.3,14.774,14.774,0,0,0-1.867-2.3A8.392,8.392,0,0,0,9,6.75,8.39,8.39,0,0,0,3.186,9.514a14.774,14.774,0,0,0-1.867,2.3Z"
                    transform="translate(0 -5.625)"
                    fill="#6d7c90"
                    fill-rule="evenodd"
                />
                <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M14.026,11.256a2.828,2.828,0,1,0,2.787,2.827,2.807,2.807,0,0,0-2.787-2.827Zm-3.9,2.827a3.9,3.9,0,1,1,3.9,3.958A3.93,3.93,0,0,1,10.125,14.083Z"
                    transform="translate(-5.026 -7.896)"
                    fill="#6d7c90"
                    fill-rule="evenodd"
                />
            </svg>
        </>
    );
};

export default ViewIcon;
