import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useState } from "react";

import { postData, uploadFile } from "../../Utils/utils";
import PictureAsPdfTwoToneIcon from "@mui/icons-material/PictureAsPdfTwoTone";
import BrokenImageTwoToneIcon from "@mui/icons-material/BrokenImageTwoTone";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect } from "react";
import Swal from "sweetalert2";

import FormLabel from "@mui/material/FormLabel";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CustomizedDialogs({
  handleClickOpen,
  handleClose,
  open,
  isLoading,
  setLoading,
  pageData: pageStore,
  setPageData: setPageStore,
  sortingOrder: { utill: { sorting_order = "none" } = {}, id: sortOrderId = 0 },
  fetchData,
}) {
  const [isError, setError] = useState(false),
    {
      id = 0,
      icon = "",
      file = "",
      title = "",
      content = "",
      name = "",
    } = pageStore,
    [sortBy, setSortBy] = useState("none");

  useEffect(() => {
    setSortBy(sorting_order);
  }, [sorting_order]);

  const onPageChange = async (e, name) => {
      if (name === "icon") {
        const image = e.target.files[0];
        if (!image.name.match(/\.(jpg|jpeg|png|svg)$/)) {
          setPageStore({ ...pageStore, icon: "" });
          return false;
        } else {
          setLoading(true);
          const { data: { url = "" } = {} } =
            (await uploadFile(e.target.files[0], "/common/upload/file")) || {};
          setPageStore({ ...pageStore, icon: url });
          setLoading(false);
        }
      } else if (name === "file") {
        const image = e.target.files[0];
        if (!image.name.match(/\.(pdf)$/)) {
          setPageStore({ ...pageStore, file: "" });
          return false;
        } else {
          setLoading(true);
          const { data: { url = "" } = {} } =
            (await uploadFile(e.target.files[0], "/common/upload/file")) || {};
          setPageStore({ ...pageStore, file: url });
          setLoading(false);
        }
      } else if (name === "file_link") {
        setPageStore({ ...pageStore, file: e.target.value });
      } else if (name === "title") {
        setPageStore({ ...pageStore, title: e.target.value });
      } else if (name === "name") {
        setPageStore({ ...pageStore, name: e.target.value });
      } else if (name === "sort_by") {
        const obj = {
          name: "Catalogs_Sorting",
          utill: { sorting_order: e.target.value },
        };
        if (sortOrderId) {
          obj.id = sortOrderId;
        }
        setLoading(true);
        await postData(obj, "/utills/save");
        setSortBy(e.target.value);
        setLoading(false);
        fetchData();
      } else {
        setPageStore({ ...pageStore, content: e.target.value });
      }
    },
    handleSubmit = async (event) => {
      event.preventDefault();
      if (!icon || !title || !content || !file || !name) {
        setError(true);
        return;
      }

      const obj = { icon, file, title, content, name };
      if (!!id) {
        obj.id = id;
      }

      setLoading(true);
      await postData(obj, "/catalog/add");
      setTimeout(() => {
        handleClose();
      }, 1000);
      setLoading(false);
    };

  return (
    <>
      <div>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          className={"btnPrimary"}
        >
          + New Item
        </Button>

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Catalog Item
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    required
                    size="small"
                    value={name}
                    onChange={(e) => onPageChange(e, "name")}
                    label="Name"
                    variant="outlined"
                    error={isError && !name}
                    helperText={isError && !name && "name is required"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={""}
                  style={{ position: "relative" }}
                >
                  <TextField
                    style={{ backgroundColor: "white" }}
                    onChange={(e) => onPageChange(e, "icon")}
                    name="upload-photo"
                    fullWidth
                    type="file"
                    error={isError && !icon}
                    helperText={
                      isError &&
                      !icon &&
                      "image is required only with .png, .jpg, .svg format"
                    }
                    label="Logo"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {pageStore.icon && (
                    <a
                      href={pageStore.icon}
                      target="blank"
                      className="iconWrap"
                    >
                      <BrokenImageTwoToneIcon />
                    </a>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    required
                    size="small"
                    value={title}
                    onChange={(e) => onPageChange(e, "title")}
                    label="Title"
                    variant="outlined"
                    error={isError && !title}
                    helperText={isError && !title && "title is required"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    required
                    size="small"
                    value={content}
                    onChange={(e) => onPageChange(e, "content")}
                    label="Details"
                    variant="outlined"
                    error={isError && !content}
                    helperText={isError && !content && "content is required"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={""}
                  style={{ position: "relative" }}
                >
                  <FormLabel id="demo-radio-buttons-group-label">
                    Choose File Upload
                  </FormLabel>
                  <div className="input__group">
                    <div className="input__group--radio">
                      {/* <input type="radio" name="fileUpload" /> */}
                      <TextField
                        style={{ backgroundColor: "white" }}
                        onChange={(e) => onPageChange(e, "file")}
                        name="upload-photo"
                        fullWidth
                        type="file"
                        error={isError && !file}
                        helperText={isError && !file && "file is required"}
                        label="Upload File"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {file && (
                        <a href={file} target="blank" className="iconWrap">
                          <PictureAsPdfTwoToneIcon />
                        </a>
                      )}
                    </div>
                    <div className="input__group--radio">
                      {/* <input type="radio" name="fileUpload" /> */}
                      <TextField
                        style={{ backgroundColor: "white" }}
                        value={file}
                        onChange={(e) => onPageChange(e, "file_link")}
                        fullWidth
                        label="Upload Link"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={isError && !file}
                        helperText={isError && !file && "file is required"}
                        className="fileUpload"
                      />
                      {file && (
                        <a href={file} target="blank" className="iconWrap">
                          <PictureAsPdfTwoToneIcon />
                        </a>
                      )}
                    </div>
                  </div>

                  {/* <TextField style={{ backgroundColor: "white" }} onChange={e => onPageChange(e, "file")} name="upload-photo" fullWidth type="file" error={isError && !file} helperText={isError && !file && "file is required only with pdf format"} />
                                    {file && <div className="iconWrap"><PictureAsPdfTwoToneIcon /></div>} */}
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions style={{ alignSelf: "center" }}>
            <Button
              autoFocus
              onClick={handleSubmit}
              className={"btnClass"}
              variant="contained"
              sx={{ mt: 3, mb: 2, ml: 2 }}
            >
              Save
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>

      <div style={{ marginLeft: "20px" }}>
        <FormControl>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={sortBy}
            label="Sort By"
            onChange={(e) => onPageChange(e, "sort_by")}
            className="select-btn"
          >
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="name">Name</MenuItem>
            <MenuItem value="title">Title</MenuItem>
            <MenuItem value="content">Details</MenuItem>
          </Select>
        </FormControl>
      </div>
    </>
  );
}

const EnhancedTableToolbar = ({
  name,
  handleClickOpen,
  handleClose,
  open,
  isLoading,
  setLoading,
  pageData,
  setPageData,
  sortingOrder,
  fetchData,
}) => {
  return (
    <Toolbar
      sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}
      style={{ marginTop: "20px" }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {name} Page Listing
      </Typography>
      <CustomizedDialogs
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        isLoading={isLoading}
        setLoading={setLoading}
        pageData={pageData}
        setPageData={setPageData}
        sortingOrder={sortingOrder}
        fetchData={fetchData}
      />
    </Toolbar>
  );
};

export default function TableListing({
  rows,
  name,
  link,
  isLoading,
  setLoading,
  fetchData,
  sortingOrder,
}) {
  const [open, setOpen] = React.useState(false),
    [pageData, setPageData] = React.useState({
      id: 0,
      icon: "",
      title: "",
      file: "",
      content: "",
      name: "",
    }),
    handleClickOpen = (data) => {
      data && setPageData(data);
      setOpen(true);
    },
    handleDelete = (data) => {
      const { id, icon, title, file, content, name } = data;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await postData(
            { id, icon, title, file, content, active: 0, name },
            "/catalog/add"
          );
          fetchData();
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    handleClose = () => {
      setPageData({
        id: 0,
        icon: "",
        title: "",
        content: "",
        file: "",
        name: "",
      });
      setOpen(false);
      fetchData();
    };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer component={Paper}>
          <EnhancedTableToolbar
            name={name}
            link={link}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            open={open}
            isLoading={isLoading}
            setLoading={setLoading}
            pageData={pageData}
            setPageData={setPageData}
            sortingOrder={sortingOrder}
            fetchData={fetchData}
          />
          <Table
            sx={{ minWidth: 650 }}
            className={"tableWrap"}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Title</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.map((row) => (
                  <TableRow
                    key={row.Name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell align="right">
                      <Fab
                        color="primary"
                        onClick={() => handleClickOpen(row)}
                        aria-label="add"
                        size="small"
                        sx={{ mr: 1 }}
                      >
                        <EditIcon />
                      </Fab>
                      <Fab
                        color="error"
                        aria-label="delete"
                        onClick={() => handleDelete(row)}
                        size="small"
                      >
                        <DeleteIcon />
                      </Fab>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
