import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

import Header from "./../components/Header";
import TableListing from "./Table";
import DrawerCom from "../components/Drawer";
import LoadingOverlay from "react-loading-overlay";
import { getData } from "../../Utils/utils";

const mdTheme = createTheme();

function ListingContent(props) {
  const [search, setSearch] = React.useState("");
  const [totalCount, setTotalCount] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [open, setOpen] = React.useState(true),
    [rows, setRows] = React.useState([]),
    toggleDrawer = () => {
      setOpen(!open);
    },
    fetchData = async () => {
      let skip = (pageNumber - 1) * 10;
      let limit = 10;
      let name = search;
      let url = "";
      if (name.length > 0) {
        url = `/users/get?name=${name}&skip=0&limit=10`;
      } else {
        url = `/users/get?skip=${skip}&limit=${limit}`;
      }
      const res = await getData(url);
      setRows(res.users);
      setTotalCount(res.totalCount);
    };

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [pageNumber, search]);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header open={open} toggleDrawer={toggleDrawer} />
        <DrawerCom open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
          className="admin__certificates"
        >
          <Toolbar />
          <Container
            maxWidth="false"
            sx={{ mt: 4, mb: 4 }}
            className="admin__certificates--container"
          >
            <TableListing
              search={search}
              setSearch={setSearch}
              pageNumber={pageNumber}
              totalCount={totalCount}
              setPageNumber={setPageNumber}
              rows={rows}
              name={"Users"}
              link={"certificateItem"}
              fetchData={fetchData}
              {...props}
            />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function UserListing() {
  const [isLoading, setLoading] = React.useState(false);

  LoadingOverlay.propTypes = undefined;
  return (
    <LoadingOverlay active={isLoading} spinner text="Loading ...">
      <ListingContent isLoading={isLoading} setLoading={setLoading} />
    </LoadingOverlay>
  );
}
