import React from 'react';

const TwitterDark = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19.505" viewBox="0 0 24 19.505">
                <path id="social-twitter" d="M28.141,9.045a9.838,9.838,0,0,1-2.828.775A4.938,4.938,0,0,0,27.478,7.1a9.844,9.844,0,0,1-3.127,1.195,4.927,4.927,0,0,0-8.39,4.491A13.978,13.978,0,0,1,5.812,7.636a4.93,4.93,0,0,0,1.524,6.574,4.913,4.913,0,0,1-2.23-.616c0,.021,0,.041,0,.062a4.927,4.927,0,0,0,3.95,4.828,4.953,4.953,0,0,1-2.223.084,4.93,4.93,0,0,0,4.6,3.42,9.934,9.934,0,0,1-7.29,2.039A14,14,0,0,0,25.7,12.231q0-.319-.015-.638A9.984,9.984,0,0,0,28.141,9.045Z" transform="translate(-4.141 -6.736)" fill="#6d7c90" />
            </svg>
        </>
    );
}

export default TwitterDark;