import * as React from "react";
import { useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextEditor from "../../components/Editor";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import { postData } from "../../../Utils/utils";
import Swal from "sweetalert2";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CustomizedDialogs({
  handleClickOpen,
  handleClose,
  open,
  setLoading,
  pageData: pageStore,
  setPageData: setPageStore,
}) {
  const [isError, setError] = useState(false),
    { id = 0, name = "", description = "" } = pageStore,
    onPageChange = (e, name) => {
      console.log("mdmdsmdms", e, name);

      if (name === "name") {
        const {
          target: { value },
        } = e;
        setPageStore({ ...pageStore, [name]: value });
      } else if (name === "description") {
        console.log("dskkdksd", e);
        setPageStore({ ...pageStore, [name]: e });
      }
    },
    handleSubmit = async (event) => {
      event.preventDefault();
      if (!name) {
        setError(true);
        return;
      }
      console.log("kiffkk", description);
      const obj = { name, description };
      if (!!id) {
        obj.id = id;
      }

      setLoading(true);
      await postData(obj, "/product/add/category");
      setTimeout(() => {
        handleClose();
      }, 1000);
      setLoading(false);
    };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        className={"btnPrimary"}
      >
        + New Item
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Category
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            onSubmit={handleSubmit}
            style={{ width: "500px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  size="small"
                  value={pageStore.name}
                  onChange={(e) => onPageChange(e, "name")}
                  label="Name"
                  variant="outlined"
                  error={isError && !name}
                  helperText={isError && !name && "name is required"}
                />
              </Grid>

              <Grid item xs={12}>
                <label style={{ marginTop: "25px", display: "inline-block" }}>
                  Description
                </label>
                <TextEditor
                  editorValue={pageStore.description}
                  onChange={(newContent) =>
                    onPageChange(newContent, "description")
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions style={{ alignSelf: "center" }}>
          <Button
            autoFocus
            onClick={handleSubmit}
            className={"btnClass"}
            variant="contained"
            sx={{ mt: 3, mb: 2, ml: 2 }}
          >
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

const EnhancedTableToolbar = ({
  name,
  handleClickOpen,
  handleClose,
  open,
  setLoading,
  pageData,
  setPageData,
}) => {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {name}
      </Typography>
      <CustomizedDialogs
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        setLoading={setLoading}
        pageData={pageData}
        setPageData={setPageData}
      />
    </Toolbar>
  );
};

export default function TableListing({ rows, name, setLoading, fetchData }) {
  const [list, setList] = useState([]);
  React.useEffect(() => {
    setList(rows);
  }, [rows]);
  const [open, setOpen] = React.useState(false),
    [pageData, setPageData] = React.useState({
      id: 0,
      name: "",
      position: "",
      description: "",
    }),
    handleClickOpen = (data) => {
      data && setPageData(data);
      setOpen(true);
    },
    handleDelete = async (data) => {
      const { id, name, position, description } = data;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await postData(
            { id, active: 0, name, position: parseInt(position), description },
            "/product/add/category"
          );
          fetchData();
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    handleClose = () => {
      setPageData({ id: 0, name: "", position: "", description: "" });
      setOpen(false);
      fetchData();
    };

  const updateItems = async (data) => {
    const { id, name, position, description } = data;
    await postData(
      { id, name, position: parseInt(position), description },
      "/product/add/category"
    );
  };
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragStart = (e, position) => {
    dragItem.current = position;
    console.log(e.target.innerHTML);
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
  };

  const drop = (e) => {
    const copyListItems = [...list];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    // console.log("kfdkfkkfkf", copyListItems);
    copyListItems.forEach((e, index) => {
      e.position = index + 1;
      updateItems(e);
    });
    setList(copyListItems);
    // fetchData();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer component={Paper}>
          <EnhancedTableToolbar
            name={name}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            open={open}
            setLoading={setLoading}
            pageData={pageData}
            setPageData={setPageData}
          />
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className={"tableWrap"}
          >
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>

                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row, index) => (
                <TableRow
                  onDragStart={(e) => dragStart(e, index)}
                  onDragEnter={(e) => dragEnter(e, index)}
                  onDragEnd={drop}
                  draggable
                  key={row.Name + index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>

                  <TableCell align="right" className="table__actions">
                    <Fab
                      color="primary"
                      onClick={() => handleClickOpen(row)}
                      aria-label="add"
                      size="small"
                      sx={{ mr: 1 }}
                      className="action__btn"
                    >
                      <EditIcon />
                    </Fab>
                    <Fab
                      color="error"
                      aria-label="delete"
                      onClick={() => handleDelete(row)}
                      size="small"
                      className="action__btn"
                    >
                      <DeleteIcon />
                    </Fab>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
