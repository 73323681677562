import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import { AsyncPaginate } from "react-select-async-paginate";
import { useState } from "react";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { postData, uploadFile } from "../../../../Utils/utils";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { env } from "../../../../Utils/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "4px",
  p: 2,
};
const UploadQuoteModal = ({
  modalQuote,
  handleQuoteClose,
  status,
  fetchData,
  matrixData,
  setLoader,
  customerId,
  setModalQuote,
  quoteIssue,
  setQuoteIssue,
}) => {
  const [isError, setIsError] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    if (
      !quoteIssue.order_number ||
      !quoteIssue.order_date ||
      !quoteIssue.customer_number ||
      !quoteIssue.pin ||
      !quoteIssue.quote_file
    ) {
      setIsError(true);
      setLoader(false);
      return;
    }

    const obj = {
      order_number: quoteIssue.order_number,
      quote_file: quoteIssue.quote_file,
      customer_number: quoteIssue.customer_number,
      pin: quoteIssue.pin,
      state: 2,

      order_date: quoteIssue.order_date,
      update_status: {
        ...status,
        quote_issued: new Date(),
      },
      reference_id: customerId,
    };

    await postData(obj, "/update/calender/data");
    setTimeout(() => {
      handleClose();
    }, 1000);
    setLoader(false);
  };
  const handleClose = () => {
    setQuoteIssue({
      order_number: "",
      customer_number: "",
      pin: [],
      quote_file: "",
      order_date: new Date(),
    });
    setModalQuote(false);
    fetchData();
  };
  const [total, settotal] = useState(0);

  const onPageChange = async (e, name) => {
    if (name === "file" && e?.target?.files?.[0]) {
      const image = e.target.files[0] || {};
      if (image.name && !image.name.match(/\.(pdf)$/)) {
        setQuoteIssue({ ...quoteIssue, quote_file: "" });
        return false;
      } else {
        setLoader(true);
        const { data: { url = "" } = {} } =
          (await uploadFile(
            e.target.files[0],
            "/common/upload/file",
            "quote"
          )) || {};
        setQuoteIssue({ ...quoteIssue, quote_file: url });
        setLoader(false);
      }
    }
  };

  async function loadOptions(search, loadedOptions, { page, matrix }) {
    let newarray = [];
    let has_more = true;
    let obj = {
      limit: 10,
      skip: (page - 1) * 10,

      name: search,
    };
    let url = "";
    if (search.length > 0) {
      url =
        env +
        `/analytes/get?name=${search}&primaryClass=${matrix}&skip=0&limit=10&is_inventory=0`;
    } else {
      url =
        env +
        `/analytes/get?skip=${obj.skip}&primaryClass=${matrix}&limit=${obj.limit}&is_inventory=0`;
    }
    if (loadedOptions.length === 0 || loadedOptions.length < total) {
      await axios
        .get(url)
        .then(async (response) => {
          let res = response.data;
          if (res.status === 200) {
            settotal(res.data.totalCount);
            for (var i = 0; i < res.data.analyte.length; i++) {
              newarray.push({
                label: res.data.analyte[i].pin,
                value: res.data.analyte[i].pin,
              });
            }
          } else {
            has_more = false;
          }
        })
        .catch((error) => {});
    }

    const responseJSON = {
      results: newarray,
      has_more: newarray.length > 0 ? has_more : false,
    };

    return {
      options: responseJSON.results,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalQuote}
      onClose={handleQuoteClose}
      closeAfterTransition
      className="uploadModal"
    >
      <Fade in={modalQuote}>
        <Box sx={style} component="form">
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            style={{
              marginBottom: "10px",
            }}
          >
            Upload Quote
            <IconButton
              aria-label="close"
              onClick={handleQuoteClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                onChange={(e) => {
                  setQuoteIssue({
                    ...quoteIssue,
                    order_number: e.target.value,
                  });
                }}
                id="outlined-basic"
                fullWidth
                required
                size="small"
                error={isError && !quoteIssue.order_number}
                helperText={
                  isError &&
                  !quoteIssue.order_number &&
                  "Order Number is required"
                }
                label="Order Number"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled
                value={quoteIssue.customer_number}
                onChange={(e) => {
                  setQuoteIssue({
                    ...quoteIssue,
                    customer_number: e.target.value,
                  });
                }}
                id="outlined-basic"
                fullWidth
                required
                size="small"
                error={isError && !quoteIssue.customer_number}
                helperText={
                  isError &&
                  !quoteIssue.customer_number &&
                  "Customer Number is required"
                }
                label="Customer Number"
                variant="outlined"
              />
            </Grid>
            {matrixData && matrixData.length > 0
              ? matrixData.map((value, index) => {
                  return (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          value={value.material}
                          id="outlined-basic"
                          fullWidth
                          required
                          size="small"
                          label="Matrix"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          position: "relative",
                        }}
                      >
                        <div
                          className={`placeholder ${
                            isError && !quoteIssue?.pin[index]?.pin
                              ? "error"
                              : ""
                          }`}
                        >
                          PIN
                          <sup>*</sup>
                        </div>
                        <AsyncPaginate
                          className={`select-pin ${
                            isError && !quoteIssue?.pin[index]?.pin
                              ? "select-error"
                              : ""
                          }`}
                          value={{
                            label: quoteIssue?.pin[index]?.pin,
                            value: quoteIssue?.pin[index]?.pin,
                          }}
                          placeholder={"PIN"}
                          loadOptions={loadOptions}
                          onChange={(e) => {
                            const list = [...quoteIssue.pin];

                            list[index]["pin"] = e.value;
                            setQuoteIssue({
                              ...quoteIssue,
                              pin: list,
                            });
                          }}
                          additional={{
                            page: 1,
                            matrix: value.material,
                          }}
                        />
                        {isError && !quoteIssue?.pin[index]?.pin && (
                          <p className="form__input--error">Pin is Required</p>
                        )}
                      </Grid>
                    </>
                  );
                })
              : null}

            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <TextField
                style={{
                  backgroundColor: "white",
                }}
                error={isError && !quoteIssue.file}
                helperText={isError && !quoteIssue.file && "File is Required"}
                inputProps={{
                  accept: "application/pdf",
                }}
                onChange={(e) => onPageChange(e, "file")}
                name="upload-photo"
                fullWidth
                type="file"
              />
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Quote Date"
                  inputFormat="MM/DD/YYYY"
                  value={quoteIssue.order_date}
                  onChange={(e) => {
                    setQuoteIssue({
                      ...quoteIssue,
                      order_date: e,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Button
              autoFocus
              onClick={handleSubmit}
              className={"btnClass"}
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                ml: 2,
              }}
            >
              Save
            </Button>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UploadQuoteModal;
