import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import EyeIcon from "../../../../Components/Icon/EyeIcon";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "4px",
  p: 2,
};
const ChartModal = ({
  userId,
  customerId,
  chartModal,
  handleChartModalClose,

  calenderData,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={chartModal}
      onClose={handleChartModalClose}
      closeAfterTransition
      className="uploadModal"
    >
      <Fade in={chartModal}>
        <Box sx={style} component="form" className="uploadModalForm">
          <Typography
            id=""
            variant="h6"
            component="h2"
            style={{
              fontWeight: "700",
            }}
          >
            Sample Complete
            <IconButton
              aria-label="close"
              onClick={handleChartModalClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                className="sample__modal"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0 16px",
                }}
              >
                <h2
                  style={{
                    backgroundColor: "transparent",
                    padding: "0",
                  }}
                >
                  Final Report(s) for RMPT# - {`(RMPT - 194)`}
                </h2>
                <div className="button--icon modal--icon">
                  <Tooltip title="View/Download">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <EyeIcon />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="sample__modal">
                <Typography id="" variant="h6" component="h2" className="">
                  Z-Score Summary Chart & Graph - {`(RMPT - 190 & 190G)`}
                </Typography>

                <ul className="sample__list">
                  {calenderData && calenderData.length > 0
                    ? calenderData.map((val, index) => {
                        return (
                          <li className="sample__list--item">
                            <div className="flex alignitems--center justifyspace--between">
                              <p>
                                <span>{index + 1}. </span>
                                <span className="analyst">{val.analyst}</span>
                                <span>{val.method}</span>
                                <span>{val.material}</span>
                                <span>{val.sample_type}</span>
                              </p>

                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                {val.isAppeal ? (
                                  <div className="button--icon modal--icon">
                                    <Tooltip title="View/Download">
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          localStorage.removeItem("type");
                                          localStorage.setItem(
                                            "UserId",
                                            userId
                                          );
                                          localStorage.setItem(
                                            "referenceId",
                                            customerId
                                          );
                                          localStorage.setItem(
                                            "analystId",
                                            val.id
                                          );
                                          window.open(
                                            "/sampleClosedChart",
                                            "_newtab2"
                                          );
                                        }}
                                      >
                                        <EyeIcon />
                                      </button>
                                    </Tooltip>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="sample__modal">
                <Typography id="" variant="h6" component="h2" className="">
                  Appealed Z-Score Summary Chart & Graph –{" "}
                  {`(RMPT - 193 & 193G)`}
                </Typography>

                <ul className="sample__list">
                  {calenderData && calenderData.length > 0
                    ? calenderData.map((value, index) => {
                        return (
                          <li className="sample__list--item">
                            <div className="flex alignitems--center justifyspace--between">
                              <p>
                                <span>{index + 1}. </span>
                                <span className="analyst">{value.analyst}</span>
                                <span>{value.method}</span>
                                <span>{value.material}</span>
                                <span>{value.sample_type}</span>
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <div className="button--icon modal--icon">
                                  {value.isReappeal ? (
                                    <Tooltip title="View/Download">
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          localStorage.setItem(
                                            "UserId",
                                            userId
                                          );
                                          localStorage.setItem(
                                            "referenceId",
                                            customerId
                                          );
                                          localStorage.removeItem("type");
                                          localStorage.setItem(
                                            "type",
                                            "reappeal"
                                          );
                                          localStorage.setItem(
                                            "analystId",
                                            value.id
                                          );
                                          window.open(
                                            "/sampleClosedChart",
                                            "_newtab2"
                                          );
                                        }}
                                      >
                                        <EyeIcon />
                                      </button>
                                    </Tooltip>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ChartModal;
