import React, { useEffect, useRef, useState } from "react";
import Header from "../../Common/Header/Header";
import axios from "../../../Services/axios";
import Swal from "sweetalert2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import ReactToPrint from "react-to-print";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
const image = new Image();
image.src = "https://rmsrms.s3.ap-south-1.amazonaws.com/files/Group 2570.png";

const plugin = {
  id: "customCanvasBackgroundImage",
  beforeDraw: (chart) => {
    if (image.complete) {
      const ctx = chart.ctx;

      ctx.drawImage(
        image,
        chart.chartArea.left,
        chart.chartArea.top,
        chart.chartArea.width,
        chart.chartArea.height
      );
    } else {
      image.onload = () => chart.draw();
    }
  },
};

const labels = [
  "",
  "Al",
  "B",
  "C",
  "Co",
  "Cr",
  "Cu",
  "Fe",
  "Mn",
  "Mo",
  "N",
  "Nb",
  "Ni",
  "O",
  "P",
  "S",
  "Si",
  "Ti",
  "V",
  "W",
  "Zr",
  "",
];

export const data = {
  labels,

  datasets: [
    {
      borderWidth: 1,
      tension: 0.1,
      label: "Standard Deviation from the MEAN",
      data: [
        0, 1.58, -0.83, -0.65, -0.71, -0.92, -0.66, 0.61, 0.14, 0.13, 1.06,
        -1.14, -0.19, 0.25, 0.5, 1.43, -2.75, 0.34, 0.01, 2.94, 0.0, 0,
      ],
      borderColor: "#000",
      backgroundColor: "#000",
    },
  ],
};

const Chart = React.forwardRef((props, ref) => {
  const options = {
    responsive: true,

    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
        title: {
          font: {
            size: 16,
          },

          display: true,
          text: "Standard Deviations from the MEAN",
        },

        min: -5,
        max: 5,
      },
      x: {
        title: {
          font: {
            size: 16,
          },

          display: true,
          text: "Reported Elements (excluding <values)",
        },
      },
    },

    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: true,
        text: "Z-Score Summary Chart",
        font: {
          size: 36,
        },
      },
      datalabels: {
        display: function (context) {
          if (
            context.dataset.data[context.dataIndex] > -5 &&
            context.dataset.data[context.dataIndex] < 5
          ) {
            return context.dataset.data[context.dataIndex];
          } else {
            return "";
          }
        },
        color: "black",
        align: "end",
        padding: {
          right: 4,
        },
        labels: {
          padding: { top: 20 },
          title: {
            font: {
              weight: "bold",
            },
          },
          value: {
            color: "black",
          },
        },
      },
    },
  };
  const [rmptId, setRmptId] = useState("");
  const [date, setDate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [labels, setlabels] = useState([]);
  const [grade, setGrade] = useState("");
  const [rmrm, setRmrm] = useState("");
  const [series, setSeries] = useState("");
  const [uns, setUns] = useState("");
  const [data, setData] = useState([]);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const formattedDate = months[month] + " " + day + ", " + year;
    return formattedDate;
  }
  useEffect(() => {
    const userId = localStorage.getItem("UserId");
    const referenceId = localStorage.getItem("referenceId");
    const analystId = localStorage.getItem("analystId");

    axios
      .get(
        `/rmpt/getChartData?id=${userId}&reference_id=${referenceId}&analyst_id=${analystId}`
      )

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          setRmptId(res.data.rmptId);
          setDate(res.data.date);
          setSeries(res.data.series);
          setGrade(res.data.grade);
          setRmrm(res.data.rmrm);
          setUns(res.data.uns);
          setCompanyName(res.data.userData.basic_details.company_name);
          const chartLabel = [];
          chartLabel.push("");
          const chartData = [];
          chartData.push(0);
          res.data.chartData.forEach((e) => {
            if (e.zscore !== "" || e.cert !== 0) {
              chartLabel.push(e.analyte);
              chartData.push(e.zscore);
            }
          });
          chartLabel.push("");
          chartData.push(0);
          setlabels(chartLabel);
          setData(chartData);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      })
      .catch((error) => {})
      .then(() => {});
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div ref={ref} className="">
      <div className="chart">
        <div className="chart__head">
          <div className="chart__head--text">{companyName}</div>
          <div className="chart__head--text">{formatDate(date)}</div>
        </div>
        <div className="chart__desc">
          {grade} / <span>UNS {uns}</span> - RMPT#{" "}
          <span className="highlight">{rmptId}</span> - CRM#{" "}
          <span>
            CRM {rmrm}
            {series}
          </span>
        </div>
      </div>

      <Line
        plugins={[plugin]}
        options={options}
        data={{
          labels: labels,

          datasets: [
            {
              borderWidth: 1,
              lineTension: 0,

              // tension: 0.1,
              label: "Standard Deviation from the MEAN",
              data: data,
              borderColor: "#000",
              backgroundColor: "#000",
            },
          ],
        }}
      />
      <div className="chart__footer">
        Z-Score, Calculated by Comparing User Submitted Data with the Value
        Assignment of the CRM used.
      </div>
    </div>
  );
});

const AmendedChart = React.forwardRef((props, ref) => {
  const options = {
    responsive: true,

    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
        title: {
          font: {
            size: 16,
          },

          display: true,
          text: "Standard Deviations from the MEAN",
        },

        min: -5,
        max: 5,
      },
      x: {
        title: {
          font: {
            size: 16,
          },

          display: true,
          text: "Reported Elements (excluding <values)",
        },
      },
    },

    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: true,
        text: "Amended Z-Score Summary Chart",
        font: {
          size: 36,
        },
      },
      datalabels: {
        display: function (context) {
          if (
            context.dataset.data[context.dataIndex] > -5 &&
            context.dataset.data[context.dataIndex] < 5
          ) {
            return context.dataset.data[context.dataIndex];
          } else {
            return "";
          }
        },
        color: "black",
        align: "end",
        padding: {
          right: 4,
        },
        labels: {
          padding: { top: 20 },
          title: {
            font: {
              weight: "bold",
            },
          },
          value: {
            color: "black",
          },
        },
      },
    },
  };
  const [rmptId, setRmptId] = useState("");
  const [date, setDate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [labels, setlabels] = useState([]);
  const [grade, setGrade] = useState("");
  const [rmrm, setRmrm] = useState("");
  const [series, setSeries] = useState("");
  const [uns, setUns] = useState("");
  const [data, setData] = useState([]);
  const formatDate = (created) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(created);
    return (
      monthNames[date.getMonth()] +
      " " +
      date.getDay() +
      "," +
      date.getFullYear()
    );
  };
  useEffect(() => {
    const userId = localStorage.getItem("UserId");
    const referenceId = localStorage.getItem("referenceId");
    const analystId = localStorage.getItem("analystId");

    axios
      .get(
        `/rmpt/getAmendedChartData?id=${userId}&reference_id=${referenceId}&analyst_id=${analystId}`
      )

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          setRmptId(res.data.rmptId);
          setDate(res.data.date);
          setSeries(res.data.series);
          setGrade(res.data.grade);
          setRmrm(res.data.rmrm);
          setUns(res.data.uns);
          setCompanyName(res.data.userData.basic_details.company_name);
          const chartLabel = [];
          chartLabel.push("");
          const chartData = [];
          chartData.push(0);
          res.data.chartData.forEach((e) => {
            if (e.zscore !== "" || e.cert !== 0) {
              chartLabel.push(e.analyte);
              chartData.push(e.zscore);
            }
          });
          chartLabel.push("");
          chartData.push(0);
          setlabels(chartLabel);
          setData(chartData);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      })
      .catch((error) => {})
      .then(() => {});
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div ref={ref} className="">
      <div className="chart">
        <div className="chart__head">
          <div className="chart__head--text">{companyName}</div>
          <div className="chart__head--text">{formatDate(date)}</div>
        </div>
        <div className="chart__desc">
          {grade} / <span>UNS {uns}</span> - RMPT#{" "}
          <span className="highlight">
            {rmptId}{" "}
            {localStorage.getItem("type") &&
            localStorage.getItem("type") === "reappeal"
              ? "-1"
              : ""}
          </span>{" "}
          - CRM#{" "}
          <span>
            CRM {rmrm}
            {series}
          </span>
        </div>
      </div>

      <Line
        plugins={[plugin]}
        options={options}
        data={{
          labels: labels,

          datasets: [
            {
              borderWidth: 1,
              // tension: 0.1,
              lineTension: 0,

              label: "Standard Deviation from the MEAN",
              data: data,
              borderColor: "#000",
              backgroundColor: "#000",
            },
          ],
        }}
      />
      <div className="chart__footer">
        Z-Score, Calculated by Comparing User Submitted Data with the Value
        Assignment of the CRM used.
      </div>
    </div>
  );
});

const SampleClosedChart = () => {
  const componentRef = useRef();
  const amendedComponentRef = useRef();
  const [data, setData] = useState([]);
  const [rmptId, setRmptId] = useState([]);
  const [loader, setLoader] = useState(false);

  const [amendedData, setAmendedData] = useState([]);
  useEffect(() => {
    const userId = localStorage.getItem("UserId");
    const referenceId = localStorage.getItem("referenceId");
    const analystId = localStorage.getItem("analystId");

    axios
      .get(
        `/rmpt/getZScoreSummaryData?id=${userId}&reference_id=${referenceId}&analyst_id=${analystId}`
      )

      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.status === 200) {
          setData(res.data.chartData);
          setRmptId(res.data.rmptId);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      })
      .catch((error) => {})
      .then(() => {});
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem("UserId");
    const referenceId = localStorage.getItem("referenceId");
    const analystId = localStorage.getItem("analystId");
    if (
      localStorage.getItem("type") &&
      localStorage.getItem("type") === "reappeal"
    ) {
      axios
        .get(
          `/rmpt/getAmendedZScoreSummaryData?id=${userId}&reference_id=${referenceId}&analyst_id=${analystId}`
        )

        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.status === 200) {
            setAmendedData(res.data.chartData);
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: res.message,
              showConfirmButton: true,
              // timer: 1500,
            });
          }
        })
        .catch((error) => {})
        .then(() => {});
    }

    // eslint-disable-next-line
  }, []);
  return (
    <>
      {loader ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : null}
      <Header themeWhite={true} contactBtn={true} bgLight={true} />
      <div className="mainSection mainPortal">
        <section className="page__section">
          <div className="container">
            <div className="form__container">
              <form className="form form__user">
                <div className="flex alignitems--center justifyspace--between">
                  <h1 className="form__title">
                    Z - Score generated for RMPT
                    <span className="highlightedText">#{rmptId}</span>
                  </h1>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      // print(e);
                      axios
                        .get(
                          `/rmpt/get/preview?type=7&id=${localStorage.getItem(
                            "UserId"
                          )}&reference_id=${localStorage.getItem(
                            "referenceId"
                          )}&analyst_id=${localStorage.getItem("analystId")}`,
                          setLoader(true)
                        )

                        .then((response) => {
                          let res = JSON.parse(response.data);
                          if (res.status === 200) {
                            setLoader(false);
                            let pdfWindow = window.open("");
                            pdfWindow.document.write(
                              "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                encodeURI(res.data) +
                                "'></iframe>"
                            );
                          } else {
                          }
                        })
                        .catch((error) => {})
                        .then(() => {});
                    }}
                    className="btn--print"
                  >
                    <PrintOutlinedIcon />
                    Print this out!
                  </button>
                </div>

                <div
                  style={{ height: 550, width: "100%", overflowY: "auto" }}
                  id="printablediv"
                >
                  <TableContainer className="form__table form__zscore">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Analyte</TableCell>
                          <TableCell align="center">Data</TableCell>
                          <TableCell align="center">Cert</TableCell>
                          <TableCell align="center">Variance</TableCell>
                          <TableCell align="center">Z-Score</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data && data.length > 0
                          ? data.map((value, index) => {
                              return (
                                <TableRow hover>
                                  <TableCell align="center">
                                    {value.analyte}
                                  </TableCell>
                                  <TableCell align="center">
                                    {value.data}
                                  </TableCell>
                                  <TableCell align="center">
                                    {value.cert}
                                  </TableCell>
                                  <TableCell align="center">
                                    {value.variance}
                                  </TableCell>
                                  <TableCell align="center">
                                    {value.zscore}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </form>
            </div>
          </div>
          <div className="form__container chart__container mt_25">
            <ReactToPrint
              trigger={() => (
                <button className="btn--print">
                  <PrintOutlinedIcon />
                  Print this out!
                </button>
              )}
              content={() => componentRef.current}
            />
            <Chart ref={componentRef} />
          </div>
        </section>
        {localStorage.getItem("type") &&
        localStorage.getItem("type") === "reappeal" ? (
          <section className="page__section">
            <div className="container">
              <div className="form__container">
                <form className="form form__user">
                  <div className="flex alignitems--center justifyspace--between">
                    <h1 className="form__title">
                      Amended Z - Score generated for RMPT
                      <span className="highlightedText">
                        #{rmptId}
                        {localStorage.getItem("type") &&
                        localStorage.getItem("type") === "reappeal"
                          ? "-1"
                          : ""}
                      </span>
                    </h1>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        // print(e);
                        axios
                          .get(
                            `/rmpt/get/preview?type=10&id=${localStorage.getItem(
                              "UserId"
                            )}&reference_id=${localStorage.getItem(
                              "referenceId"
                            )}&analyst_id=${localStorage.getItem("analystId")}`,
                            setLoader(true)
                          )

                          .then((response) => {
                            let res = JSON.parse(response.data);
                            if (res.status === 200) {
                              setLoader(false);
                              let pdfWindow = window.open("");
                              pdfWindow.document.write(
                                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                  encodeURI(res.data) +
                                  "'></iframe>"
                              );
                            } else {
                            }
                          })
                          .catch((error) => {})
                          .then(() => {});
                      }}
                      className="btn--print"
                    >
                      <PrintOutlinedIcon />
                      Print this out!
                    </button>
                  </div>

                  <div
                    style={{ height: 550, width: "100%", overflowY: "auto" }}
                  >
                    <TableContainer className="form__table form__zscore">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Analyte</TableCell>
                            <TableCell align="center">Data</TableCell>
                            <TableCell align="center">Cert</TableCell>
                            <TableCell align="center">Variance</TableCell>
                            <TableCell align="center">Z-Score</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {amendedData && amendedData.length > 0
                            ? amendedData.map((value, index) => {
                                return (
                                  <TableRow hover>
                                    <TableCell align="center">
                                      {value.analyte}
                                    </TableCell>
                                    <TableCell align="center">
                                      {value.data}
                                    </TableCell>
                                    <TableCell align="center">
                                      {value.cert}
                                    </TableCell>
                                    <TableCell align="center">
                                      {value.variance}
                                    </TableCell>
                                    <TableCell align="center">
                                      {value.zscore}
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </form>
              </div>
            </div>
            <div className="form__container chart__container mt_25">
              <ReactToPrint
                trigger={() => (
                  <button className="btn--print">
                    <PrintOutlinedIcon />
                    Print this out!
                  </button>
                )}
                content={() => amendedComponentRef.current}
              />
              <AmendedChart ref={amendedComponentRef} />
            </div>
          </section>
        ) : null}
      </div>
    </>
  );
};

export default SampleClosedChart;
