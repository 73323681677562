import React , { useEffect } from "react";
import Header from "../../../Common/Header/Header";
import Footer from "../../../Common/Footer/Footer";
import Banner from "../../../Common/Banner/Banner";
import Breadcrumb from "../../../Common/Breadcrumb/Breadcrumb";
import SideNavbar from '../../../Templates/SideNavbar/ProductSideNavbar';
import PDFIcon from "../../../Icon/Pdf";
import ArrowDown from '../../../Icon/ArrowDown';

const ArconicCRM = () => {

    var arrDown = 
        `<svg xmlns="http://www.w3.org/2000/svg" width="11.987" height="5.986" viewBox="0 0 11.987 5.986">
            <path id="arrow-ios-downward-outline" d="M13.482,19.482a.855.855,0,0,1-.547-.2L7.807,15.012A.856.856,0,0,1,8.9,13.7l4.581,3.829,4.581-3.692a.876.876,0,1,1,1.086,1.376l-5.128,4.128A.855.855,0,0,1,13.482,19.482Z" transform="translate(-7.498 -13.498)" fill="#900" />
        </svg>`
    ;

    const accordionHandler = (event) => {
        event.target.classList.toggle('active');
        var accordionBody = event.target.previousElementSibling;
        if (accordionBody.style.maxHeight) {
            accordionBody.style.maxHeight = null;
            event.target.innerHTML = "Show more" + arrDown;
        } else {
            accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
            event.target.innerHTML = "Show less" + arrDown;
        }
    }

    useEffect(() => {
        window.scrollTo(0,0);
    },[]);

    var emailList = [
        {email: 'sales@rmrms.com'},
        {email: ''},
        {email: ''},
    ]

    return(
        <>
            <Header />
            <div className="mainSection">

                <Banner bgPath={"url(/images/HeaderDark.jpg)"} 
                    breadcrumb={<Breadcrumb title="Arconic CRMs" listItem="Home" listItemPath="/" listLink="Products" listLinkPath="/products" />}
                />

                <div className='container'>
                    <div className='row'>
                        <div className='col_sm_12 col_md_5 col_lg_3'>
                            <SideNavbar/>
                        </div>

                        <div className='col_sm_12 col_md_7 col_lg_9'>                      

                            <section className="page__section">
                                <h3 className="page__section--heading text__align--left">About Arconic CRMs</h3>
                                <h4 className="page__section--sub-heading text__align--left">Introduction</h4>
                                <p className="page__section--para">Arconic's Spectrochemical Reference Materials business has been operating for over 70 years. Accredited to ISO Guide 34:200 9, ISO 17025:2005, and ISO 9001:2015, Arconic Spectrochemical Reference Materials is the trusted manufacturer and supplier of certified reference materials (CRMs) and reference materials (RMs) for compositional analysis and is the sole manufacturer of specialty CRMs produced to customer specifications.</p>
                                <p className="page__section--para">We perform all facets of CRM production and analysis:</p>
                                <div className="product__info">
                                    <ul className="product__info--list">
                                        <li className="product__info--list-item">Casting and machining</li>
                                        <li className="product__info--list-item">Inductively Coupled Plasma Atomic Emission Spectroscopy (ICP-AES)</li>
                                        <li className="product__info--list-item">Inductively Coupled Plasma Mass Spectrometry (ICP-MS)</li>
                                        <li className="product__info--list-item">Spark Atomic Emission Spectroscopy (Spark-AES)</li>
                                        <li className="product__info--list-item">Certification</li>
                                    </ul>
                                </div>
                            </section>

                            <section className='page__section p_0'>
                                <div className='accordion accordion--show-more m_0'>
                                    <div className='accordion__item'>
                                        <div className='accordion__item--body'>
                                            <p className="page__section--para ">Arconic Spectrochemical Reference Materials offers more than 340 different aluminum alloy CRMs and RMs.  Arconic CRMs and RMs are designed to support analysis by Spark-AES, XRF, LIBS, ICP, ICP-MS, and inert gas fusion.</p>

                                            <h4 className="page__section--sub-heading text__align--left">Types of Reference Materials</h4>
                                            <h4 className="page__section--sub-heading text__align--left">Selection of CRMs</h4>
                                            <p className="page__section--para">CRMs are classified by alloy using Aluminum Association designations, when available, and Arconic designations in other cases.  Under each alloy are listed those CRMs specifically prepared for that alloy.  Among them, one will have a catalog number consisting of "SS" followed by the alloy designation.  This SS CRM has a composition typical of the alloy and is used both in the preparation of analytical curves and for the periodic adjustment of those curves.  When only an SS CRM is listed, it is to be assumed that the analytical curves can be established by this SS CRM and a combination of CRMs of other alloys for which CRMs are listed or from the CRMs listed for single elements.  Range CRMs for wrought alloys are identified with a two-letter prefix beginning with W (WA, WB, etc.).  Range CRMs for casting alloys have a two-letter prefix beginning with K (KA, KB, etc.).  Compositions listed in this catalog are only approximate since successive lots under a given catalog number vary to some degree.  The composition specifically applying to a given CRM section is furnished when the CRM is shipped.  Certified compositions are usually reported utilizing the Aluminum Association rules for reporting compositions.  Concentrations listed in parentheses are considered reference values only and are not certified.</p>

                                            <h4 className="page__section--sub-heading text__align--left">Trace Metal CRMs</h4>
                                            <p className="page__section--para">Arconic Trace Metal CRMs supplement our regular alloy CRMs and are made with pure aluminum.  A unique method for trace metal additions allows alloys to be produced with highly controlled trace metal concentrations.  Trace metal concentrations have been picked to provide both low end calibration points {'(<0.0001%)'} and points that are typical for trace metal content and can be measured with good precision and accuracy by today's instrumentation.  Actual concentrations may vary from those shown in this catalog but will always be certified using a combination of analytical techniques including SPARK-AES, ICP-AES, GD-MS and other appropriate techniques.  For some of the most sought-after aluminum CRMs, our nominal alloy CRMs have been modified to include trace metals of interest.  CRMs designated by the prefix "ST' have been made to be similar to a specific alloy family which is indicated by the number following the "ST' designation, e.g. ST1-1050.  Note: Values given as less than, denoted by {`<`}, indicate that those elements are typically below this value.  No attempt is made to modify the base metal composition for these elements; however, these elements will be certified to the actual composition observed.</p>

                                            <h4 className="page__section--sub-heading text__align--left">Drift Correction RMs
                                            </h4>
                                            <p className="page__section--para">Drift correction RMS are used for ongoing drift correction when instruments are kept in continuous calibration for a variety of alloys.  "SQ" RMs do not correspond to any particular alloy matrix, and their metallurgical structures may not match that of chill cast disks.  Their recommended use is to provide reproducible spectral intensities for drift correction and their relationship to analytical curves must be determined by the user under the particular conditions of use.  The compositions are designed to provide convenient check points on a large number of analytical curves with a minimum number of tests.  They are checked carefully for reproducibility of spectral response but are not certified with respect to true composition.  Only approximate values will be issued with these RMs.</p>

                                            <h4 className="page__section--sub-heading text__align--left">CRMs for Single Elements</h4>
                                            <p className="page__section--para">Single element CRMs are available in a series for each element with various concentrations in a variety of alloy matrices.  They are useful when extending existing calibration curves as well as making adjustments to curves when alloy specific SS CRMs are not adequate.  Single element CRMs are designated with a two-letter identifier that corresponds with the chemical abbreviation for the element added.</p>

                                            <h4 className="page__section--sub-heading text__align--left">CRMs for Chemical Analysis</h4>
                                            <p className="page__section--para">With the proliferation of techniques such as ICP-AES, ICP-MS, GD-AES, GD-MS and XRF, Arconic recognizes the need to supply its CRMs in a form more suitable for use with these and similar techniques.  All CRMs in the catalog are available in lathe turnings (-100 g quantity) for subsequent dissolution.  Other sizes may be available upon special request.</p>

                                            <h4 className="page__section--sub-heading text__align--left">Micro CRMs</h4>
                                            <p className="page__section--para">Similar to CRMs for chemical analysis, Arconic recognizes a need for portable CRMs for use in the field with handheld instrumentation.  A selection of CRMs in this catalog are available in a smaller, 1-inch diameter size.</p>

                                            <h4 className="page__section--sub-heading text__align--left">Alu-H2™ Hydrogen RM
                                            </h4>
                                            <p className="page__section--para">Arconic Alu-H2 ™ Hydrogen RM is provided in rod form and is intended for use in inert gas fusion analysis of hydrogen in aluminum as described in ASTM method E2792.</p>

                                            <h4 className="page__section--sub-heading text__align--left">Specialty CRMs</h4>
                                            <p className="page__section--para">Every effort is made to supply our customers with a wide variety of alloy CRMs.  However, to better serve our customers' needs, CRMs can be made to customer specifications and target compositions. These specialty CRMs are made with the same exact quality as our catalog CRMs.  (Minimum order quantities are required.) Experts in the production and use of Arconic Spectrochemical CRMs are available to discuss special needs at the phone numbers listed in the section on page 2 in this catalog.</p>

                                            <h4 className="page__section--sub-heading text__align--left">Spark-AES Application Notes</h4>
                                            <h4 className="page__section--sub-heading text__align--left">Description of CRM/RM
                                            </h4>
                                            <p className="page__section--para">Aluminum discs are generally 64 mm (2.5 inches) in diameter by 25 mm (1 inch) high.  In a few cases, the height may vary depending on the particular alloy or drift correction standard purchased (see the catalog for details).  Upon request, the certified reference materials (CRMs) and reference materials (RMs) are available as millings or chips for use in chemical methods of analysis requiring dissolution.  Arconic CRMs and RMs are produced by proprietary casting techniques that minimize or substantially eliminate both macro and micro segregations.  Metallurgical structure is controlled to match chill cast disks and therefore produce the same spectral response when used according to the methods cited.  All CRMs and RMs are labeled and stenciled to show the catalog number, production lot and individual section number.</p>

                                            <h4 className="page__section--sub-heading text__align--left">Intended Use</h4>
                                            <p className="page__section--para">Arconic CRMs and RMs in disc form are intended for use with Spark-AES instruments for analysis of chill cast disks as described in American Society for Testing and Materials (ASTM) methods E716 and E1251. Because of the effects of macro and micro segregation and metallurgical structure on spectral response, metal in any form other than chill cast discs (ingot, billet, sheet, plate, extrusion, forging, castings, etc.) should be remelted and cast in disk form, as described in ASTM 8985, prior to comparison to alloy CRMs. CRMs may be used for calibration, type standardizing, control standards and method validation for Spark-AES methods.  RMs, typically designated as "SQ", are intended for drift correction of Spark-AES instruments.  The CRMs may also be used for calibration, control and method validation for other methods, including but not limited to classical wet chemical approaches, ICP-AES, ICP-MS, GD-AES, GD-MS, and XRF.</p>

                                            <h4 className="page__section--sub-heading text__align--left">Instructions for Use - Spark-AES</h4>
                                            <p className="page__section--para">The surface of the disc should be prepared using a lathe or milling machine. Carbide or diamond tipped tool bits are recommended.  Avoid use of tool bits that may contaminate the surface with elements of interest.  Do not grind or sand.  If using a machining lubricant such as alcohol, make sure that it is of sufficient purity to avoid contamination of the surface with elements of interest.  The surface roughness should be less than 0.0016 mm (63 µinches) RMS.  Do not touch the prepared analysis surface or allow the surface to come into contact with any source of contamination.  Sparks should be placed, without overlapping, around the circumference of the CRM/RM close to the outer edge.  Avoid the zone within a 12 mm (0.5 inch) radius of the center of the sample because of the slight chance that radial segregation may occur.  This zone should also be avoided when collecting machine turnings or chips for chemical analysis methods that involve dissolution.  The CRMs and RMs are certified using a Spark-AES spectrometer with a spark circumference of approximately 12 mm (0.5 inch) and ICP-AES results based on sample sizes that are greater than or equal to 0.1 g. Spark diameters or subsample sizes less than those used during the certification may invalidate the use of the certified value.</p>

                                            <h4 className="page__section--sub-heading text__align--left">Storage</h4>
                                            <p className="page__section--para">For best results the CRM/RM should be stored in a cool dry place free from corrosive fumes.  Exposure to temperatures above approximately 200 °C (392 °F) may cause metallurgical changes that will invalidate the certificate of analysis.</p>

                                            <h4 className="page__section--sub-heading text__align--left">Shelf Life</h4>
                                            <p className="page__section--para">The certification is valid indefinitely, within the measurement uncertainty specified, provided the CRM is handled and stored in accordance with the instructions given in the certificate (see "Instructions for Use').  Periodic recertification of the CRM is not required.  The certification is nullified if the CRM is damaged, contaminated, or otherwise modified.</p>

                                            <h4 className="page__section--sub-heading text__align--left">Material Certification</h4>
                                            <p className="page__section--para">Compositions are determined using two or more independent analytical methods which may include, Spark-AES, ICP-AES, XRF, GD-MS, ICP-MS or other approaches deemed appropriate for a specific application.  All CRM/RM ingots are evaluated for uniformity both within and among sections by extensive Spark-AES testing.  Spectral response is determined by comparison with Arconic master CRMs.  Rigorous statistical analysis is used to ensure the accuracy of the final composition certification, the chemical and physical uniformity, and the performance of the CRMs/RMs in use. Values contained within parentheses, e.g. (0.05), are provided for reference only and are not certified for use as calibrants, standardants, or analytical performance checks.</p>

                                            <h4 className="page__section--sub-heading text__align--left">Limits of Uncertainty
                                            </h4>
                                            <p className="page__section--para">The certified values shown are generally weighted mean values from the analysis of representative samples, using at least two independent analytical methods.  The given limits of uncertainty represent a combined uncertainty and seek to estimate, with a 95% confidence level, a range in which the true value may be expected to lie.  While the homogeneity of the ingots and the mean values given as the certified compositions are determined using rigorous statistical techniques, the cited uncertainties represent not only this statistical treatment but also estimates of bias based on extensive historical data and technical judgment.  The uncertainties cited represent an expanded uncertainty given by U=kuc where uc represents the combined standard uncertainty and k is a coverage factor chosen to represent a desired level of confidence.  For this application k=2 and U expresses an estimate of a 95% confidence level.  The use of this expression is consistent with guidelines given in the International Organization for Standardization (ISO) document "Guide to The Expression of Uncertainty in Measurement" and National Institute of Standards and Technology (NIST) Technical Note 1297 "Guidelines for Evaluating and Expressing the Uncertainty of NIST Measurement Results".  In some cases, no uncertainty is given because of limited data or the lack of a second independent measure.  No uncertainties are provided for estimated compositions, i.e. parenthetic numbers.</p>

                                            <h4 className="page__section--sub-heading text__align--left">Traceability</h4>
                                            <p className="page__section--para">CRMs are prepared and certified for the analyses of aluminum alloys using methodology similar to that described in ASTM methods E716 and E1251.  All certifications are produced using at least two independent methods and detailed statistical analysis to assure homogeneity.  Traceability to NIST is maintained through the use of NIST Standard Reference Materials® (SRM) or certified reference materials directly traceable to NIST SRMs.  NIST traceable weights are used for the calibration and verification of balances in both CRM production and analytical certification methods.  Balances used during production and analyses are calibrated with and traceable to NIST standard weight sets.</p>
                                        </div>
                                        <button className='accordion__item--btn' onClick={accordionHandler}>Show more <ArrowDown/>
                                        </button>
                                    </div>
                                </div>
                            </section>

                            <section className="page__section bg--light p_3 mt_50">
                                <h4 className="page__section--sub-heading text__align--left">Catalog Sections</h4>
                                <ul className="catalog__list">
                                    <li className="catalog__list--item">
                                        <a href='/pdf/ArCaCat.pdf'><PDFIcon/>Casting Alloys</a>
                                    </li>
                                    <li className="catalog__list--item">
                                        <a href='/pdf/ArWrCat.pdf'><PDFIcon/>Wrought Alloys</a>
                                    </li>
                                    <li className="catalog__list--item">
                                        <a href='/pdf/ArSpCat.pdf'><PDFIcon/>Special Alloys</a>
                                    </li>
                                    <li className="catalog__list--item">
                                        <a href='/pdf/ArSpCat.pdf'><PDFIcon/>Micro Alloys</a>
                                    </li>
                                </ul>
                            </section>

                            <section className="page__section pb_0">
                                <div className="row">
                                    <div className="col_sm_12 col_md_12 col_lg_8">
                                        <div className="product__info">
                                            <h4 className="page__section--sub-heading text__align--left">Material Forms and Dimensions
                                            </h4>
                                            <ul className="product__info--list">
                                                <li className="product__info--list-item">General - General purpose size (~64 mm diameter x ~25 mm thick) intended for use in consumptive techniques such as Optical Emission and Glow Discharge Spectroscopy.</li>
                                                <li className="product__info--list-item">X-Ray - About 31 mm diameter x ~6 mm thick discs designed specifically for use with X-Ray Fluorescence Instruments</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col_sm_12 col_md_12 col_lg_4">
                                        <div className="product__pricing">
                                            <h4 className="page__section--sub-heading text__align--left">General Pricing</h4>
                                            <p className="page__section--para">Please Call for Pricing : <a href="tel:(720) 943-7676">(720) 943-7676</a></p>
                                            <ul className="product__pricing--list">
                                                <li className="product__pricing--list-title">
                                                    <p>Description</p>
                                                    <p>Suffix #</p>
                                                </li>
                                                <li className="product__pricing--list-item">
                                                    <p>General</p>
                                                    <p>-G</p>
                                                </li>
                                                <li className="product__pricing--list-item">
                                                    <p>X-Ray</p>
                                                    <p>-X</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </section>
                        </div>

                    </div>
                </div>
                
            </div>
            <Footer footerEmails={true} emailList={emailList} />
        </>
    )
}

export default ArconicCRM;
