import React from 'react';

const Menu = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18.833" viewBox="0 0 24 18.833">
                <g id="menu" transform="translate(-3 -7.5)">
                    <path id="Path_36" data-name="Path 36" d="M3,8.86A1.288,1.288,0,0,1,4.2,7.5H25.8A1.288,1.288,0,0,1,27,8.86a1.288,1.288,0,0,1-1.2,1.36H4.2A1.288,1.288,0,0,1,3,8.86Z" fill="#900" />
                    <path id="Path_37" data-name="Path 37" d="M3,17.908a1.288,1.288,0,0,1,1.2-1.36H25.8a1.371,1.371,0,0,1,0,2.72H4.2A1.288,1.288,0,0,1,3,17.908Z" transform="translate(0 -0.991)" fill="#900" />
                    <path id="Path_38" data-name="Path 38" d="M4.2,25.6a1.371,1.371,0,0,0,0,2.72H25.8a1.371,1.371,0,0,0,0-2.72Z" transform="translate(0 -1.983)" fill="#900" />
                </g>
            </svg>
        </>
    );
}

export default Menu;