import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "4px",
  p: 2,
};
const SampleSentUploadModal = ({
  viewSampleSentModal,
  handleViewSampleSentClose,
  setViewSampleSentModal,
  handleSampleOpen,
  calenderData,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={viewSampleSentModal}
      onClose={handleViewSampleSentClose}
      closeAfterTransition
      className="uploadModal"
    >
      <Fade in={viewSampleSentModal}>
        <Box
          sx={style}
          component="form"
          className="uploadModalForm uploadModalOverflow"
        >
          <Typography
            id=""
            variant="h6"
            component="h2"
            style={{
              fontWeight: "700",
            }}
          >
            Upload Sample
            <IconButton
              aria-label="close"
              onClick={handleViewSampleSentClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="sample__modal">
                <Typography id="" variant="h6" component="h2" className="">
                  <div className="flex alignitems--center justifyspace--between">
                    RMPT Subscriber Analysis Instructions - {"(RMPT 188)"}
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      {" "}
                      <div className="button--icon modal--icon">
                        <Tooltip title="Add">
                          <button
                            onClick={(e) => {
                              e.preventDefault();

                              setViewSampleSentModal(false);
                              handleSampleOpen();
                            }}
                          >
                            <AddIcon />
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </Typography>

                <ul className="sample__list">
                  {calenderData && calenderData.length > 0
                    ? calenderData.map((val, index) => {
                        return (
                          <li className="sample__list--item">
                            <div className="flex alignitems--center justifyspace--between">
                              <p>
                                <span>{index + 1}. </span>
                                <span className="analyst">{val.analyst}</span>
                                <span>{val.method}</span>
                                <span>{val.material}</span>
                                <span>{val.sample_type}</span>
                              </p>
                            </div>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SampleSentUploadModal;
