import Swal from "sweetalert2";
const env = "https://api.rmrms.com",
  sitekey = "6LeRxu4nAAAAALjwbRAP6KpLtyU6SCkurmaDqE8C";

const uploadFile = async (file, url, bucket) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      if (bucket) {
        formData.append("bucket", bucket);
      }
      const response = await fetch(env + url, {
          method: "POST",
          headers: {
            Authorization: "bearer " + localStorage.getItem("access_token"),
          },
          body: formData,
        }),
        result = (await response.json()) || {};
      if (result && result.status === 200) {
        return result;
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Session Expired",
          showConfirmButton: false,
          timer: 1500,
        });
        localStorage.clear();
        setTimeout(() => {
          window.location.reload(false);
        }, 1500);
      }
    } catch (error) {}
  },
  postData = async (data, url) => {
    try {
      const response = await fetch(env + url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + localStorage.getItem("access_token"),
          },
          body: JSON.stringify(data),
        }),
        result = (await response.json()) || {};
      if (result && result.status === 200) {
        return result;
      } else if (result?.message === "Unauthorized!") {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Session Expired",
          showConfirmButton: false,
          timer: 1500,
        });
        localStorage.clear();
        setTimeout(() => {
          window.location.reload(false);
        }, 1500);
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: result.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {}
  },
  postDataWithOutAccessToken = async (data, url) => {
    try {
      const response = await fetch(env + url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }),
        result = (await response.json()) || {};
      if (result && result.status === 200) {
        return result;
      } else if (result) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: result.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  },
  getData = async (url) => {
    try {
      const response = await fetch(env + url),
        result = (await response.json()) || {};
      if (result && result.status === 200) {
        return result.data;
      }
    } catch (error) {}
  };

export {
  sitekey,
  uploadFile,
  postData,
  getData,
  postDataWithOutAccessToken,
  env,
};
