import React , { useEffect } from "react";
import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';
import Banner from '../../Common/Banner/Banner';
import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';
import SideNavbar from '../../Templates/SideNavbar/ServiceSideNavbar';

const RMPT = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    },[]);

    var emailList = [
        {email: 'rmpt@rmrms.com'},
        {email: ''},
        {email: ''},
    ]
    
    return(
        <>
            <Header />
            <div className='mainSection'>

                <Banner bgPath={'url(/images/HeaderDark.jpg)'} 
                    breadcrumb={<Breadcrumb title='RMPT' listItem='Home' listItemPath='/' listLink='Services' listLinkPath='/services' />}
                />

                <div className='container'>
                    <div className='row'>
                        <div className='col_sm_12 col_md_5 col_lg_3'>
                            <SideNavbar/>
                        </div>

                        <div className='col_sm_12 col_md_7 col_lg_9'>
                            <section className='page__section'>
                                <h3 className='page__section--heading text__align--left'>“Laboratory Performance Evaluations” Perpetual Monitoring Of Instrument Performance</h3>
                                <h4 className='page__section--sub-heading text__align--left'>What Should One Expect From A Proficiency Testing (PT) Program?</h4>
                                <ul className="pb_30">
                                    <li>A <b>PT</b> program should allow for fast, corrective action in case of problems.</li>
                                    <li>A <b>PT</b> program should be inexpensive, easy to interpret, and comprehensive.</li>
                                    <li>A <b>PT</b> program should provide an assessment of a laboratory's analytical data compared to the true value.</li>
                                    <li>A <b>PT</b> program should provide for a periodic assessment of the performance of a laboratory.</li>
                                    <li>A <b>PT</b> program should satisfy the minimum requirements for laboratory accreditation.</li>
                                </ul>

                                <h4 className='page__section--sub-heading text__align--left'>RMPT "Benchmark" Proficiency Testing</h4>
                                <p className='page__section--para'>The RMPT program is an accredited ISO/IEC 17043 Annex B.2.1 B program.  RMPT has access to chemical results on over 250 metal alloys and over 150 aluminum alloys.  By using this certified data for comparison, we can offer alloys and compare elements not commonly used in other PT programs and we have eliminated the "round robin” and associated problems.  The results still provide a “peer comparison," but the primary benefit is that our clients’ data are compared to the "True Value" because the test samples are actual CRMs.  We refer to this as a "Benchmark" comparison, since the test samples are actual CRMs that have been thoroughly examined for homogeneity and statistically characterized during the certification process.  Outliers have been identified and eliminated for an unparalleled comparison of our subscribers’ analytical results to the "True Value."</p>
                            
                                <h4 className='page__section--sub-heading text__align--left'>Here’s How The RMPT Program Works</h4>
                                <p className='page__section--para'>We supply you with a calendar for selecting the date, type of material by matrix group, and sample configuration (i.e., solids, chips, or pins).  You can enroll in the program any time you choose.  Program pricing is available for monthly, quarterly, or biannual testing.  We then send you a “blind” sample from our stock of CRMs based upon your selection.  When you have completed the analysis of the blind sample, you send us the results via fax or e-mail.  Within 48 hours you will receive your results by fax or e-mail.  Your report will include:</p>
                                <div className="product__info">
                                    <ul className="product__info--list">
                                        <li className="product__info--list-item">A color-coded "Z-Score Comparison” which compares your results to the actual "Benchmark"</li>
                                        <li className="product__info--list-item">A color-coded "Z-Score Chart" graphically depicting your data compared to the certified value of the</li>
                                        <li className="product__info--list-item">A “Certificate of Analysis” confirming which CRM was used as the sample in the</li>
                                    </ul>
                                </div>
                                <p className='page__section--para'>"Color coding" provides you with the instantaneous ability for identifying the problem areas of performance.</p>
                                <div className="product__info pb_30">
                                    <ul className="product__info--list">
                                        <li className="product__info--list-item">Green — indicates data within the acceptable 2 Sigma range above or below the mean.</li>
                                        <li className="product__info--list-item">Yellow — indicates data that have fallen outside the 2 Sigma range but within the 3 Sigma range.</li>
                                        <li className="product__info--list-item">Red — indicates data that have fallen outside the 3 Sigma limit and are possibly out of control.</li>
                                    </ul>
                                    <p className='page__section--para'>Note:  Your color-coded hard copy will be sent to you by regular mail.</p>
                                </div>

                                <h4 className='page__section--sub-heading text__align--left'>Commitment And Confidentiality</h4>
                                <p className='page__section--para'>Keep in mind we are a third-party vehicle and a partner in your ongoing quality assurance/quality control (QA/QC) program.  The results stay confidential.  Our only intention with the RMPT program is to aid our subscribers in determining if "things are the way they should be and if their reported analytical results are good.”</p>
                                <h4 className='page__section--sub-heading text__align--left'>"We Are Part Of Your Quality Team”</h4>
                                <p className='page__section--para'>We DO NOT sit in judgment. We DO NOT certify you to any particular criteria, nor do we certify you to meet any particular industry specifications or regulations. In short, whether you have a single-instrument or a multiple-instrument/multiple- technique environment, the RMPT program affords subscribers a trouble-free QA program that is traceable to NIST and can be easily integrated into the daily laboratory routine.</p>

                                <p className='page__section--para'><b>Please contact us at: <a href='mailto:RMPT@RMRMs.com'>RMPT@RMRMs.com</a> for additional information about the program and how to subscribe.</b></p>
                            </section>

                            
                        </div>
                    </div>
                </div>                
            </div>
            <Footer footerEmails={true} emailList={emailList}/>
        </>
    )
}

export default RMPT;

