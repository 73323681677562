import React , { useEffect } from "react";
import Header from "../../../Common/Header/Header";
import Footer from "../../../Common/Footer/Footer";
import Banner from "../../../Common/Banner/Banner";
import Breadcrumb from "../../../Common/Breadcrumb/Breadcrumb";
import SideNavbar from '../../../Templates/SideNavbar/ProductSideNavbar';
import PDFIcon from "../../../Icon/Pdf";

const AluminiumSUS = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    },[]);

    var emailList = [
        {email: 'sales@rmrms.com'},
        {email: ''},
        {email: ''},
    ]

    return(
        <>
            <Header />
            <div className="mainSection">

                <Banner bgPath={"url(/images/HeaderDark.jpg)"} 
                    breadcrumb={<Breadcrumb title="Aluminium SUS" listItem="Home" listItemPath="/" listLink="Products" listLinkPath="/products" />}
                />

                <div className='container'>
                    <div className='row'>
                        <div className='col_sm_12 col_md_5 col_lg_3'>
                            <SideNavbar/>
                        </div>

                        <div className='col_sm_12 col_md_7 col_lg_9'>
                            <section className="page__section">
                                <h3 className="page__section--heading text__align--left">Drift Correction / Setting-Up-Materials Usage</h3>
                                <p className="page__section--para">Setting-Up Materials (SUSs) are used to drift correct the calibration curves. The process of drift correcting the curves has many names: normalization, standardization, and re-calibration. Regardless of the name given the process, the curves are adjusted back to their state at the time of the original calibration.</p>
                                <p className="page__section--para">It is not important to have accurate chemistries for SUSs; chemistries at +/-10 percent are sufficient. Corrections to the calibration curves are made on intensities, not concentrations. It is important to have {`1)`} homogeneous material, and {`2)`} material that does not vary within a specific lot. It is perfectly fine to have chemistries vary from one lot to another lot of SUSs; it is a very fast and easy change over when one lot of the SUSs are exhausted.</p>
                                <p className="page__section--para">Timing for implementing drift correction is critical. Some laboratories periodically run check or SPC standards to determine if the instrument is within the allowed tolerances. The stability and duty cycle of the instrument determine the period for checking drift. It should at least be checked every hour or before a batch of samples are run to assure quality of results.</p>
                                <p className="page__section--para">If drift detection tolerances are not set up, it is imperative that drift correction, at a minimum, is accomplished every shift for laboratory instruments and hourly for mobile instruments. If the analyst is unsure about the state of the instrument, drift correction should immediately precede the analysis of any samples, for optimum accuracy.</p>
                            </section>


                            <section className="page__section bg--light p_3">
                                <h4 className="page__section--sub-heading text__align--left">Aluminum Setting-Up Materials</h4>
                                <ul className="catalog__list">
                                    <li className="catalog__list--item">
                                        <a href='/pdf/SUS-Al.pdf'><PDFIcon/>SUS Setting-Up Materials</a>
                                    </li>
                                    <li className="catalog__list--item">
                                        <a href='/pdf/SUS-Arconic.pdf'><PDFIcon/>Arconic Setting-Up Materials</a>
                                    </li>
                                    <li className="catalog__list--item">
                                        <a href='/pdf/SUS-STP.pdf'><PDFIcon/>Suisse TP Setting-Up Materials</a>
                                    </li>
                                </ul>
                            </section>

                            <section className="page__section">
                                <div className="row">
                                    <div className="col_sm_12 col_md_12 col_lg_8">
                                        <div className="product__info">
                                            <h4 className="page__section--sub-heading text__align--left">Material Forms and Dimensions
                                            </h4>
                                            <ul className="product__info--list">
                                                <li className="product__info--list-item">General - General purpose size (~55 mm or ~64 mm diameter x ~38 mm thick) intended for drift correction in consumptive techniques such as OE, XRF, and GD Spectroscopy.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col_sm_12 col_md_12 col_lg_4">
                                        <div className="product__pricing">
                                            <h4 className="page__section--sub-heading text__align--left">General Pricing</h4>
                                            <p className="page__section--para">Please Call for Pricing : <a href="tel:(720) 943-7676">(720) 943-7676</a></p>
                                            <ul className="product__pricing--list">
                                                <li className="product__pricing--list-title">
                                                    <p>Description</p>
                                                    <p>Suffix #</p>
                                                </li>
                                                <li className="product__pricing--list-item">
                                                    <p>General</p>
                                                    <p>-G</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>
            </div>

            <Footer footerEmails={true} emailList={emailList}/>
        </>
    )
}

export default AluminiumSUS;
