import React from 'react';

const LinkedInDark = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="linkedin-rect" d="M4.71.009a4.717,4.717,0,0,0-4.7,4.7V19.307a4.717,4.717,0,0,0,4.7,4.7H19.3a4.716,4.716,0,0,0,4.7-4.7V4.712a4.717,4.717,0,0,0-4.7-4.7Zm1.183,3.96a1.89,1.89,0,1,1-.024,3.768H5.846a1.889,1.889,0,1,1,.047-3.768Zm10.687,5c2.385,0,4.173,1.559,4.173,4.909v6.253H17.128V14.3c0-1.466-.524-2.466-1.836-2.466a1.985,1.985,0,0,0-1.86,1.325,2.475,2.475,0,0,0-.119.884v6.091H9.687s.047-9.883,0-10.906h3.625v1.545a3.6,3.6,0,0,1,3.267-1.8ZM4.057,9.228H7.681V20.133H4.057V9.228Z" transform="translate(-0.007 -0.009)" fill="#6d7c90" />
            </svg>
        </>
    );
}

export default LinkedInDark;