import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../Images/logo1.png";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import * as Yup from "yup";
import { authenticate } from "../../auth";
import { useNavigate } from "react-router-dom";

import { Formik } from "formik";
import axios from "../../Services/axios";
import Swal from "sweetalert2";
const CustomerLogin = () => {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(true);
  const [passwordValues, setPasswordValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [obj, setObj] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    if (
      localStorage.getItem("user") !== undefined &&
      localStorage.getItem("user") !== null
    ) {
      setObj({
        email: JSON.parse(localStorage.getItem("user")).email,
        password: JSON.parse(localStorage.getItem("user")).password,
      });
    }
  }, []);
  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const loginSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),

    password: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Please Enter Valid Password use at least one uppercase letter, lowercase letter, number, special character with minimum 8 characters"
      ),
  });
  return (
    <>
      <div className="mainSection customerPortal">
        <section className="page__section">
          <div className="container">
            <Formik
              enableReinitialize={true}
              initialValues={obj}
              validationSchema={loginSchema}
              onSubmit={(values, { setSubmitting }) => {
                let loginObj = {
                  email: values.email,
                  password: values.password,
                };
                axios
                  .post("/rmpt/user/login", loginObj)

                  .then((response) => {
                    let res = JSON.parse(response.data);
                    if (res.status === 200) {
                      setSubmitting(false);
                      localStorage.removeItem("user");
                      localStorage.removeItem("usertype");
                      if (rememberMe) {
                        localStorage.setItem("user", JSON.stringify(loginObj));
                      }
                      localStorage.setItem("firstname", res.data.first_name);
                      localStorage.setItem("companyId", res.data.company_id);

                      if (
                        res.data.user_type === "User" &&
                        res.data.other_user_type === null
                      ) {
                        localStorage.setItem("usertype", "user");
                        localStorage.setItem("otherusertype", "null");
                      }
                      if (
                        res.data.user_type === "User" &&
                        res.data.other_user_type === "Analyst"
                      ) {
                        localStorage.setItem("usertype", "user");
                        localStorage.setItem("otherusertype", "analyst");
                      }
                      if (
                        res.data.user_type === "Analyst" &&
                        res.data.other_user_type === "Analyst"
                      ) {
                        localStorage.setItem("usertype", "analyst");
                      }
                      authenticate(res.data.access_token, () => {
                        if (res.data.user_type === "Analyst") {
                          navigate("/analyst-form-status");
                          return;
                        }
                        if (res.data.is_profile_created) {
                          navigate("/form-status");
                        } else navigate("/profile-data");
                      });

                      // window.location.href = "/profile-data";
                      // window.open("/profile-data", "_self");
                    } else {
                      Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: res.message,
                        showConfirmButton: false,
                        timer: 1500,
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch((error) => {
                    setSubmitting(false);
                  })
                  .then(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit} className="form">
                  {/* Logo */}
                  <div className="form__brand">
                    <NavLink to="/">
                      <img
                        className="form__logo"
                        src={Logo}
                        alt="Logo | Rocky Mountain Reference Materials, LLC"
                        width="120px"
                      />
                    </NavLink>
                  </div>

                  <div className="form__title">Log In</div>

                  <div className="form__description">
                    Welcome to Rocky Mountain Reference Materials
                  </div>

                  <div className="form__group">
                    {/* <label className='form__label'>User Name</label> */}
                    <div className="form__input--group">
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="outlined-username">
                          Enter Email
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-username"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton edge="end">
                                <PersonOutlineRoundedIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          label="Enter Email"
                        />
                      </FormControl>
                      <div className="form__input--error">
                        {errors.email && touched.email && errors.email}
                      </div>
                      {/* <TextField label="Enter Username" id="user-name" size="small" fullWidth /> */}
                      {/* <input required type="text" className="form__input" placeholder='Enter Username' name='username' /> */}
                      {/* <div className="form__input--icon"><img src="/images/user.svg" alt="User" width="18px" /></div> */}
                    </div>
                  </div>

                  <div className="form__group">
                    {/* <label className='form__label'>Password</label> */}
                    <div className="form__input--group">
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-password">
                          Enter Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={
                            passwordValues.showPassword ? "text" : "password"
                          }
                          value={values.password}
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {passwordValues.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Enter Password"
                        />
                      </FormControl>
                      {/* <TextField label="Enter Password" type="password" id="user-name" size="small" fullWidth /> */}
                      {/* <input required type="password" className="form__input" placeholder='Enter Password' name='password' /> */}
                      {/* <div className="form__input--icon">
                        <button><img src="/images/gray-eye.svg" alt="Eye" width="18px" /></button>
                      </div> */}
                    </div>
                    <div className="form__input--error text__align--justify position--unset">
                      {touched.password || errors.password}
                      {touched.password && errors.password}
                    </div>
                  </div>

                  <div className="form__group">
                    <label className="checkbox-container">
                      Remember me!
                      <input
                        checked={rememberMe}
                        onChange={() => {
                          setRememberMe(!rememberMe);
                        }}
                        type="checkbox"
                        className="form-checkbox"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>

                  <div className="form__group">
                    <button type="submit" className="btn btn--red">
                      Log In
                    </button>
                  </div>

                  <div className="form__group text__align--center">
                    <NavLink to="/forgot-password" className="form__link">
                      Forgot your password?
                    </NavLink>
                  </div>

                  <div className="form__group mb_0">
                    <div className="form__action--group">
                      Don't have an account?&nbsp;
                      <NavLink to="/info-request" className="form__link">
                        Sign up
                      </NavLink>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </section>
      </div>
    </>
  );
};

export default CustomerLogin;
