import React from 'react';

const EditIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14.5" viewBox="0 0 16 14.5">
                <g id="Group_2505" data-name="Group 2505" transform="translate(-1357 -252)">
                    <path id="Path_49" data-name="Path 49" d="M2.25,29.25h16v1.139h-16Z" transform="translate(1354.75 236.111)" fill="#6d7c90" />
                    <path id="Path_50" data-name="Path 50" d="M16.74,6.236a1.1,1.1,0,0,0,0-1.594l-2.059-2.05a1.109,1.109,0,0,0-1.6,0L4.5,11.133v3.644H8.161Zm-2.86-2.847,2.059,2.05L14.224,7.147,12.165,5.1ZM5.644,13.638v-2.05l5.72-5.694,2.059,2.05L7.7,13.638Z" transform="translate(1353.639 249.75)" fill="#6d7c90" />
                </g>
            </svg>
        </>
    );
}

export default EditIcon;