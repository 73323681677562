import React from 'react';

const Search = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="15.38" height="15.379" viewBox="0 0 15.38 15.379">
                <g id="search" opacity="0.5">
                    <path id="Path_10" data-name="Path 10" d="M23.119,23.117a.961.961,0,0,1,1.36,0l3.7,3.7a.961.961,0,0,1-1.359,1.36l-3.7-3.7a.961.961,0,0,1,0-1.36Z" transform="translate(-13.081 -13.08)" fill="#6d7c90" fillRule="evenodd" />
                    <path id="Path_11" data-name="Path 11" d="M6.248,11.535A5.287,5.287,0,1,0,.961,6.248,5.287,5.287,0,0,0,6.248,11.535ZM12.5,6.248A6.248,6.248,0,1,1,6.248,0,6.248,6.248,0,0,1,12.5,6.248Z" fill="#6d7c90" fillRule="evenodd" />
                </g>
            </svg>
        </>
    );
}

export default Search;