import React from 'react';
import './banner.scss';

const Banner = ({bgPath, breadcrumb, bannerContent, bannerHeight, bgPosition}) => {
    return(
        <section className={`banner__section ${bannerHeight} ${bgPosition}`} style={{ background: bgPath }}>
            <div className='container'>
                {breadcrumb}
                {bannerContent}
            </div>
        </section>
    );
}

export default Banner;