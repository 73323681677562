import React from 'react';

const FacebookDark = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="24" viewBox="0 0 12 24">
                <path id="social-facebook" d="M19.25,12.5V10.119c0-1.075.237-1.619,1.907-1.619H23.25v-4H19.757c-4.281,0-5.693,1.963-5.693,5.332V12.5H11.251v4h2.812v12h5.188v-12h3.525l.475-4h-4Z" transform="translate(-11.251 -4.5)" fill="#6d7c90" />
            </svg>
        </>
    );
}

export default FacebookDark;