import React from 'react';
import SearchIcon from '../../Icon/SearchIcon';
import './search.scss'

const Search = ({onChangeInput}) => {
    const onClick = (e)=>{
        e.preventDefault();
    }
    return(
        <form className='form'>
            <div className='form__group form__group--wrapper'>
                <input type='search' className='form__search--input' onChange={onChangeInput} placeholder='Search'/>
                <button style={{cursor:"default"}} className='form__search--btn' onClick={onClick}><SearchIcon/></button>
            </div>
        </form>
    );
}

export default Search;
