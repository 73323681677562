import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { postData } from "../../../../Utils/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "4px",
  p: 2,
};
const UploadSample = ({
  modalSample,
  setModalSample,
  status,
  calenderData,
  fetchData,

  setLoader,
  customerId,
  handleSampleClose,
}) => {
  const [ship, setShip] = useState({
    trackingNumber: "",
    shipper: "UPS",
    comment: "",
    shipDate: new Date(),
  });
  const [sampleError, setSampleError] = useState(false);
  const handleSampleShipUploadByIndex = async (event) => {
    event.preventDefault();
    console.log("dsjsdj", calenderData);
    // return;
    if (
      !ship.trackingNumber ||
      !ship.shipper ||
      !ship.comment ||
      !ship.shipDate ||
      !ship.other
    ) {
      setSampleError(true);
      setLoader(false);
      return;
    }
    let obj = calenderData.map((x) => {
      return {
        tracking_number: ship.trackingNumber,
        shipper: ship.shipper,
        ship_comment: ship.comment,
        ship_date: ship.shipDate,
        ship_other: ship.other,
        analystId: x.id,
        update_status: {
          ...status,
          sample_shipped: new Date(),
        },
        reference_id: customerId,
      };
    });
    console.log("dsjsjd", obj);
    setLoader(true);
    await postData(obj, "/update/rmpt/188/Multiple");
    // for (let i = 0; i < calenderData.length; i++) {
    //   setLoader(true);

    //   const obj = {
    //     tracking_number: ship.trackingNumber,
    //     shipper: ship.shipper,
    //     ship_comment: ship.comment,
    //     ship_date: ship.shipDate,
    //     ship_other: ship.other,
    //     analystId: calenderData[i].id,

    //     update_status: {
    //       ...status,
    //       sample_shipped: new Date(),
    //     },
    //     reference_id: customerId,
    //   };

    //   await postData(obj, "/update/rmpt/188");
    // }
    setTimeout(() => {
      handleCloseSample();
    }, 1000);
    setLoader(false);
  };
  const handleCloseSample = () => {
    setShip({
      trackingNumber: "",
      shipper: "UPS",
      comment: "",
      shipDate: new Date(),
    });
    setModalSample(false);
    fetchData();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalSample}
      onClose={handleSampleClose}
      closeAfterTransition
      className="uploadModal"
    >
      <Fade in={modalSample}>
        <Box sx={style} component="form">
          <Typography
            id=""
            variant="h6"
            component="h2"
            style={{
              marginBottom: "10px",
            }}
          >
            Upload Sample
            <IconButton
              aria-label="close"
              onClick={handleSampleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                onChange={(e) => {
                  setShip({
                    ...ship,
                    trackingNumber: e.target.value,
                  });
                }}
                error={sampleError && !ship.trackingNumber}
                helperText={
                  sampleError &&
                  !ship.trackingNumber &&
                  "Tracking Number is required"
                }
                id=""
                fullWidth
                required
                size="small"
                label="Tracking Number"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel id="shipper-select-label">Shipper</InputLabel>
                <Select
                  error={sampleError && !ship.shipper}
                  helperText={
                    sampleError && !ship.shipper && "Shipper is required"
                  }
                  labelId="shipper-select-label"
                  id="shipper-select"
                  value={ship.shipper}
                  label="Shipper"
                  onChange={(e) => {
                    setShip({
                      ...ship,
                      shipper: e.target.value,
                    });
                  }}
                >
                  <MenuItem value={"UPS"}>UPS</MenuItem>
                  <MenuItem value={"FedEx"}>FedEx</MenuItem>
                  <MenuItem value={"DHL"}>DHL</MenuItem>
                  <MenuItem value={"USPS"}>USPS</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                onChange={(e) => {
                  setShip({
                    ...ship,
                    comment: e.target.value,
                  });
                }}
                error={sampleError && !ship.comment}
                helperText={
                  sampleError && !ship.comment && "Comment is required"
                }
                id=""
                fullWidth
                required
                size="small"
                label="Comment"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Ship Date"
                  value={ship.shipDate}
                  onChange={(e) => {
                    setShip({
                      ...ship,
                      shipDate: e,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <TextField
                onChange={(e) => {
                  setShip({
                    ...ship,
                    other: e.target.value,
                  });
                }}
                error={sampleError && !ship.other}
                helperText={sampleError && !ship.other && "Other is required"}
                id=""
                fullWidth
                required
                size="small"
                label="Other"
                variant="outlined"
              />
            </Grid>

            <Button
              autoFocus
              onClick={handleSampleShipUploadByIndex}
              className={"btnClass"}
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                ml: 2,
              }}
            >
              Save
            </Button>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UploadSample;
