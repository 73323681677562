import React, { useState, useEffect } from 'react';
import Catalog from './Catalog/Catalog';
// import ReactPaginate from "react-paginate";
import "./catalogs.scss";
import { getData } from '../../../Utils/utils';


const Catalogs = ({ inputValue }) => {

    const [postData, setPostData] = useState([]),

        fetchData = async () => {
            const { catalogs } = await getData('/catalog/get');
            setPostData(catalogs);
        };

    useEffect(() => {
        fetchData();
    }, [])

    const filterData = postData && (postData.filter(item => {
        let newItem = item.content.concat(" " + item.name + " " + item.title);
        return newItem.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
    }) || []);
    return (
        <>
            <div className="catalog__container">
                {filterData.map((post, index) => (
                    <Catalog key={index} index={index} post={post} />
                ))}
            </div>
        </>
    );
};

export default Catalogs;
