import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import { useState } from "react";
import { postData } from "../../../../Utils/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "4px",
  p: 2,
};
const CustomerNumberUpdateModal = ({
  companyNumberModal,
  handleCustomerNumberClose,
  customerId,
  setLoader,
  companyId,
  status,
  fetchData,
}) => {
  const [customerNumber, setCustomerNumber] = useState(null);
  const [customerNumberError, setcustomerNumberError] = useState("");
  const handleCustomerNumberUpload = async (event) => {
    event.preventDefault();
    setLoader(true);
    if (!customerNumber) {
      setcustomerNumberError(true);
      return;
    }

    const obj = {
      customer_number: customerNumber,
      state: 5,
      reference_id: customerId,
      company_id: companyId,

      update_status: {
        ...status,
      },
    };

    await postData(obj, "/update/calender/data");
    setTimeout(() => {
      handleCustomerNumberClose();
      fetchData();
    }, 1000);
    setLoader(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={companyNumberModal}
      onClose={handleCustomerNumberClose}
      closeAfterTransition
      className="uploadModal"
    >
      <Fade in={companyNumberModal}>
        <Box sx={style} component="form">
          <Typography
            id=""
            variant="h6"
            component="h2"
            style={{
              marginBottom: "10px",
            }}
          >
            Upload Customer Number
            <IconButton
              aria-label="close"
              onClick={handleCustomerNumberClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => {
                    setCustomerNumber(e.target.value);
                  }}
                  error={customerNumberError && !customerNumber}
                  helperText={
                    customerNumberError &&
                    !customerNumber &&
                    "Customer Number is required"
                  }
                  id=""
                  fullWidth
                  required
                  size="small"
                  label="Customer Number"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Button
              autoFocus
              onClick={handleCustomerNumberUpload}
              className={"btnClass"}
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                ml: 2,
              }}
            >
              Save
            </Button>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomerNumberUpdateModal;
