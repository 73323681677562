import React, { useEffect, useState } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Banner from "../Common/Banner/Banner";
import Breadcrumb from "../Common/Breadcrumb/Breadcrumb";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SideNavbar from "../Templates/SideNavbar/AboutUsSideNavbar";
import { getData } from "../../Utils/utils";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";

const PhotoGallery = () => {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [openImageIndex, setOpenImageIndex] = useState(null);

  const fetchProductsData = async () => {
    setLoading(true);
    const result = await getData("/photogallery/get");
    // if (pageStore && pageStore.images && pageStore.images.length > 0 && pageStore.images[0].position)
    // {
    //   let sortedList = pageStore.images.sort((a, b) => a.position - b.position)
    //   setList(sortedList)
    // }
    // else
    // setList(pageStore?.images);
    setGallery(result);
    // setServiceMenu(result?.service_categories)
    setLoading(false);
  };

  useEffect(() => {
    fetchProductsData();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var emailList = [{ email: "Info@RMRMs.com" }, { email: "" }, { email: "" }];

  const handleOpen = (image) => {
    setOpenImageIndex(image);
  };

  const handleCloseModal = () => {
    setOpenImageIndex(null);
  };

  return (
    <>
      <Header />

      <div className="mainSection">
        <Banner
          bgPath={"url(/images/HeaderDark.jpg)"}
          breadcrumb={
            <Breadcrumb
              title="Photo Gallery"
              listItem="Home"
              listItemPath="/"
              listLink="Photo Gallery"
              listLinkPath="/photo-gallery"
            />
          }
          bgPosition={"banner__section--backgroundPosition"}
        />

        <div className="container">
          <div className="row">
            <div className="col_sm_12 col_md_5 col_lg_3">
              <SideNavbar />
            </div>

            <div className="col_sm_12 col_md_7 col_lg_9">
              <section className="page__section">
                <section className="slider-section photoGallery">
                  <div className="container">
                    {gallery && gallery.length > 0
                      ? gallery.map((value, index) => (
                          <div key={index} className="slider-container">
                            <div className="slider-title">{value.title}</div>
                            <Slider {...settings}>
                              {value.images &&
                              value.images.length > 0 &&
                              value.images[0].position
                                ? value.images
                                    .sort((a, b) => a.position - b.position)
                                    .map((image, imageIndex) => (
                                      <div
                                        key={imageIndex}
                                        className="openModal"
                                      >
                                        <img
                                          style={{
                                            height: "200px",
                                            width: "200px",
                                          }}
                                          src={image.file}
                                          alt=""
                                          onClick={() => handleOpen(image)}
                                        />
                                      </div>
                                    ))
                                : value.images &&
                                  value.images.map((image, imageIndex) => (
                                    <div
                                      key={imageIndex}
                                      className="openModal"
                                      onClick={() => handleOpen(image)}
                                    >
                                      <img
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                        }}
                                        src={image.file}
                                        alt=""
                                      />
                                    </div>
                                  ))}
                            </Slider>
                          </div>
                        ))
                      : null}
                  </div>
                </section>
              </section>
            </div>
          </div>
        </div>
      </div>

      <Footer footerEmails={true} emailList={emailList} />
      <Modal
        open={openImageIndex !== null}
        onClose={handleCloseModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        className="galleryModal"
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
          className="galleryModal__box"
        >
          <button className="galleryModal__close" onClick={handleCloseModal}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 9.5L9 15.5M9 9.5L15 15.5M22 12.5C22 18.0228 17.5228 22.5 12 22.5C6.47715 22.5 2 18.0228 2 12.5C2 6.97715 6.47715 2.5 12 2.5C17.5228 2.5 22 6.97715 22 12.5Z"
                stroke="#384250"
                stroke-width="1.67"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <img src={openImageIndex?.file} alt="Modal" />
        </Box>
      </Modal>
    </>
  );
};

export default PhotoGallery;
