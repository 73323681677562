import React, { useEffect } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Banner from "../Common/Banner/Banner";
import Breadcrumb from "../Common/Breadcrumb/Breadcrumb";
import Catalogs from "../Templates/IndustryCatalogs/Catalogs";
import Search from "../Templates/Search/Search";

const Industry = () => {
  const [inputValue, setInputValue] = React.useState("");
  const onChangeInput = (e) => {
    setInputValue(e.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var emailList = [{ email: "Sales@RMRMs.com" }, { email: "" }, { email: "" }];

  return (
    <>
      <Header />
      <div className="mainSection">
        <Banner
          bgPath={"url(/images/HeaderDark.jpg)"}
          breadcrumb={
            <Breadcrumb
              title="Industry Catalogs"
              listItem="Home"
              listItemPath="/"
              listLink="Industry Catalogs"
              listLinkPath="/industry-catalogs"
              // darkTitle="dark-title"
            />
          }
          bgPosition={"banner__section--backgroundPosition"}
        />

        <section className="page__section pb_0">
          <div className="container">
            <Search onChangeInput={onChangeInput} />
          </div>
        </section>

        <section className="page__section">
          <div className="container">
            <Catalogs inputValue={inputValue} />
          </div>
        </section>
      </div>
      <Footer footerEmails={true} emailList={emailList} />
    </>
  );
};

export default Industry;
