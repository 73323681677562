import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from './components/Header';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useState } from "react";
import DrawerCom from "./components/Drawer";
import LoadingOverlay from 'react-loading-overlay';
import TextEditor from './components/Editor';
import { getData, postData } from '../Utils/utils';


const mdTheme = createTheme();

function DashboardContent() {
    const [open, setOpen] = React.useState(true),
        [isLoading, setLoading] = React.useState(false),
        [isError, setError] = useState(false),
        [pageStore, setPageStore] = useState({ id: 0, home_title: "", default_content: "", hidden_content: "", best_solution_heading: "", home_sub_title: "", mission_heading: "", mission_content: "", quality_heading: "", quality_content: "" }),

        onPageChange = (e, name) => {
            if (name === "home_title" || name === "home_sub_title") {
                setPageStore({ ...pageStore, [name]: e.target.value })
            } else if (name === "default_content" || name === "hidden_content") {
                setPageStore({ ...pageStore, [name]: e })
            }
        },

        handleSubmit = async (event) => {
            event.preventDefault();
            const { id = 0, home_title = "", default_content = "", hidden_content = "", home_sub_title = "" } = pageStore;
            if (!home_title) {
                setError(true);
                return;
            };

            const obj = { default_content, hidden_content, home_title, home_sub_title };
            if (!!id) {
                obj.id = id
            };

            setLoading(true);
            await postData(obj, '/home/add');
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
            setLoading(false);
        },

        toggleDrawer = () => {
            setOpen(!open);
        },

        fetchProduct = async () => {
            const { id = 0, home_title = "", default_content = "", hidden_content = "", home_sub_title = "" } = await getData('/home/get');
            setPageStore({ id, home_title, default_content, hidden_content, home_sub_title });
        };

    React.useEffect(() => {
        fetchProduct();
    }, [])

    LoadingOverlay.propTypes = undefined;
    return (
        <LoadingOverlay active={isLoading} spinner text='Loading ...'>
            <ThemeProvider theme={mdTheme}>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <Header open={open} toggleDrawer={toggleDrawer} />
                    <DrawerCom open={open} toggleDrawer={toggleDrawer} />
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={8} lg={12}>
                                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                        <Typography gutterBottom variant="h5" component="div" sx={{ pb: 2 }}>
                                            Home Page
                                        </Typography>
                                        <Box component="form" onSubmit={handleSubmit}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <TextField id="outlined-basic" fullWidth required size="small" value={pageStore.home_title} onChange={(e) => onPageChange(e, "home_title")} label="Home Title" variant="outlined" error={isError && !pageStore.page_title} helperText={isError && !pageStore.home_title && "title is required"}/>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField id="outlined-basic" fullWidth required size="small" value={pageStore.home_sub_title} onChange={(e) => onPageChange(e, "home_sub_title")} label="Home Sub SubTitle" variant="outlined" />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <label style={{ marginTop: "25px", display: "inline-block" }}>Content</label>
                                                    <TextEditor editorValue={pageStore.default_content} onChange={(newContent) => onPageChange(newContent, "default_content")} />
                                                </Grid>
                                                {/* <Grid item xs={12}>
                                                    <label>Hide Content</label>
                                                    <TextEditor editorValue={pageStore.hidden_content} onChange={(newContent) => onPageChange(newContent, "hidden_content")} />
                                                </Grid> */}
                                            </Grid>

                                            <Button type="submit" className={"btnClass"} variant="contained" sx={{ mt: 3, mb: 2, ml: 2 }}>Save</Button>
                                            <Button component={Link} to="/admin/listing" variant="outlined" sx={{ mt: 3, mb: 2, ml: 2 }}>Cancel</Button>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Box>
            </ThemeProvider>
        </LoadingOverlay>
    );
}

export default function HomeAdmin() {
    return <DashboardContent />;
}

