import React from 'react';
// import { useLocation } from "react-router-dom";
import ServiceCard from './Card/ServiceCard';
import "./card.scss";

const Cards = ({productMenu}) => {
    
    // const location = useLocation(),
    // { productMenu = [] } = location.state || {};

    return (
        <div className="cards__container">
            {productMenu.map((lists, index) => (
                <ServiceCard key={index} index={index} lists={lists} productMenu={productMenu}/>
            ))}
        </div>
    );
};

export default Cards;