import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Header from "./components/Header";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Editor } from "react-draft-wysiwyg";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useState } from "react";
import { EditorState } from "draft-js";
import DrawerCom from "./components/Drawer";

const ToolbarOption = {
  options: [
    "inline",
    "fontSize",
    "list",
    "textAlign",
    "colorPicker",
    "link",
    "embedded",
    "emoji",
    "image",
    "history",
  ],
};

const mdTheme = createTheme();

function DashboardContent() {
  const [open, setOpen] = React.useState(true);
  const [pageStore, setPageStore] = useState({
    bannerTitle: "",
    pageHeading: "",
    defaultState: EditorState.createEmpty(),
  });

  const onPageChange = (e, name) => {
    if (name === "bannerTitle") {
      setPageStore({ ...pageStore, bannerTitle: e.target.value });
    } else if (name === "pageHeading") {
      setPageStore({ ...pageStore, pageHeading: e.target.value });
    }
  };
  const onDefaultStateChange = (defaultState) => {
    setPageStore({ ...pageStore, defaultState: defaultState });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header open={open} toggleDrawer={toggleDrawer} />
        <DrawerCom open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8} lg={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ pb: 2 }}
                  >
                    Search Engine
                  </Typography>
                  <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          required
                          size="small"
                          value={pageStore.bannerTitle}
                          onChange={(e) => onPageChange(e, "bannerTitle")}
                          label="Banner Title"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={6}
                        style={{ marginTop: "30px", marginBottom: "-20px" }}
                      >
                        <TextField
                          value={pageStore.pageHeading}
                          onChange={(e) => onPageChange(e, "pageHeading")}
                          label="Page Heading"
                          variant="outlined"
                          fullWidth
                          required
                          size="small"
                          style={{ backgroundColor: "white" }}
                          id="outlined-basic"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <label
                          style={{ marginTop: "25px", display: "inline-block" }}
                        >
                          Content
                        </label>
                        <Editor
                          editorState={pageStore.defaultState}
                          toolbarClassName="editorToolbarWrap"
                          wrapperClassName="editorWrapper"
                          editorClassName="editorInner"
                          onEditorStateChange={(editorState) =>
                            onDefaultStateChange(editorState)
                          }
                          toolbar={ToolbarOption}
                        />
                      </Grid>
                    </Grid>

                    <Button
                      type="submit"
                      className={"btnClass"}
                      variant="contained"
                      sx={{ mt: 3, mb: 2, ml: 2 }}
                    >
                      Save
                    </Button>
                    <Button
                      component={Link}
                      to="/admin/listing"
                      variant="outlined"
                      sx={{ mt: 3, mb: 2, ml: 2 }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function SearchEngineAdmin() {
  return <DashboardContent />;
}
