import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

import Header from "../../components/Header";
import TableListing from "./Table";
import DrawerCom from "../../components/Drawer";
import LoadingOverlay from "react-loading-overlay";
import { getData } from "../../../Utils/utils";

const mdTheme = createTheme();

function ListingContent(props) {
  const [search, setSearch] = React.useState("");
  const [totalCount, setTotalCount] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [sortingOrder, setSortingOrder] = React.useState({});

  const [pageNumber, setPageNumber] = React.useState(1);
  const [open, setOpen] = React.useState(true),
    [rows, setRows] = React.useState([]),
    toggleDrawer = () => {
      setOpen(!open);
    },
    fetchData = async () => {
      let skip = (pageNumber - 1) * 15;
      let limit = 15;
      let name = search;
      let url = "";
      if (name.length > 0) {
        url = `/analytes/get?name=${name}&skip=0&limit=15&is_inventory=1`;
      } else {
        url = `/analytes/get?skip=${skip}&limit=${limit}&is_inventory=1`;
      }
      const res = await getData(url);
      const arr = [...res.analyte];
      setRows(() => arr);
      setSortingOrder(res.sorting_order);
      setTotalCount(res.totalCount);
      setColumns(() => res.columns);
      // window.location.reload();
    };

  React.useEffect(() => {
    //
    const getData = setTimeout(() => {
      fetchData();
    }, 400);

    return () => clearTimeout(getData);
    // eslint-disable-next-line
  }, [pageNumber, search]);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header open={open} toggleDrawer={toggleDrawer} />
        <DrawerCom open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
          className="admin__certificates"
        >
          <Toolbar />
          <Container
            maxWidth="false"
            sx={{ mt: 4, mb: 4 }}
            className="admin__certificates--container inventory--container"
          >
            <TableListing
              sortingOrder={sortingOrder}
              search={search}
              setSearch={setSearch}
              pageNumber={pageNumber}
              totalCount={totalCount}
              setPageNumber={setPageNumber}
              rows={rows}
              name={"Inventory"}
              link={"InventoryItem"}
              columns={columns}
              fetchData={fetchData}
              {...props}
            />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function CertificateListing() {
  const [isLoading, setLoading] = React.useState(false);

  LoadingOverlay.propTypes = undefined;
  return (
    <LoadingOverlay active={isLoading} spinner text="Loading ...">
      <ListingContent isLoading={isLoading} setLoading={setLoading} />
    </LoadingOverlay>
  );
}
