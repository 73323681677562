import React from "react";
import PDFIcon from "../../../Icon/Pdf";

const NewsCard = ({
  lists: {
    title,
    default_content,
    hidden_content,
    date,
    certificate_url,
    file,
  },
}) => {
  const accordionHandler = (event) => {
    event.target.classList.toggle("active");
    var accordionBody = event.target.previousElementSibling;
    if (accordionBody.style.maxHeight) {
      accordionBody.style.maxHeight = null;
      event.target.innerHTML = "Read More";
    } else {
      accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
      event.target.innerHTML = "Read Less";
    }
  };
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const formattedDate = months[month] + " " + day + ", " + year;
    return formattedDate;
  }

  return (
    <div className="news">
      <div style={{ display: "flex", alignItems: "center" }}>
        <h2 className="news__title" style={{ flex: "1" }}>
          {title}
        </h2>
        {file && file.length > 0 ? (
          <button
            className="catalog__list--item reset-props"
            style={{ marginRight: "10px" }}
          >
            <a target="blank" href={file}>
              <PDFIcon /> {file?.split("/")?.pop()?.split(".")[0]}
            </a>
          </button>
        ) : null}
        {/* {file && certificate_url.length > 0 ? (
          <button className="catalog__list--item reset-props">
            <a target="blank" href={file}>
              <PDFIcon />
            </a>
          </button>
        ) : null} */}
      </div>

      <p className="news__meta--date">{formatDate(date)}</p>
      <div
        className="news__description reset-props"
        dangerouslySetInnerHTML={{ __html: default_content }}
      ></div>
      <div className="accordion__item--body">
        <div
          dangerouslySetInnerHTML={{ __html: hidden_content }}
          className="reset-props"
        ></div>
      </div>
      {hidden_content && hidden_content !== null && hidden_content !== "" ? (
        <button
          className="news__btn btn--download color--gray"
          onClick={accordionHandler}
        >
          Read More
        </button>
      ) : null}
    </div>
  );
};

export default NewsCard;
