import React, { useEffect } from "react";
import Header from "../../../Common/Header/Header";
import Footer from "../../../Common/Footer/Footer";
import Banner from "../../../Common/Banner/Banner";
import Breadcrumb from "../../../Common/Breadcrumb/Breadcrumb";
import SideNavbar from "../../../Templates/SideNavbar/ProductSideNavbar";
import PDFIcon from "../../../Icon/Pdf";
import ArrowDown from "../../../Icon/ArrowDown";

const CMICastIrons = () => {
  var arrDown = `<svg xmlns="http://www.w3.org/2000/svg" width="11.987" height="5.986" viewBox="0 0 11.987 5.986">
            <path id="arrow-ios-downward-outline" d="M13.482,19.482a.855.855,0,0,1-.547-.2L7.807,15.012A.856.856,0,0,1,8.9,13.7l4.581,3.829,4.581-3.692a.876.876,0,1,1,1.086,1.376l-5.128,4.128A.855.855,0,0,1,13.482,19.482Z" transform="translate(-7.498 -13.498)" fill="#900" />
        </svg>`;
  const accordionHandler = (event) => {
    event.target.classList.toggle("active");
    var accordionBody = event.target.previousElementSibling;
    if (accordionBody.style.maxHeight) {
      accordionBody.style.maxHeight = null;
      event.target.innerHTML = "Show more" + arrDown;
    } else {
      accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
      event.target.innerHTML = "Show less" + arrDown;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var emailList = [{ email: "sales@rmrms.com" }, { email: "" }, { email: "" }];

  return (
    <>
      <Header />
      <div className="mainSection">
        <Banner
          bgPath={"url(/images/HeaderDark.jpg)"}
          breadcrumb={
            <Breadcrumb
              title="CMI Cast Irons"
              listItem="Home"
              listItemPath="/"
              listLink="Products"
              listLinkPath="/products"
            />
          }
        />

        <div className="container">
          <div className="row">
            <div className="col_sm_12 col_md_5 col_lg_3">
              <SideNavbar />
            </div>

            <div className="col_sm_12 col_md_7 col_lg_9">
              <section className="page__section" id="castIron">
                <h3 className="page__section--heading text__align--left">
                  Cast Iron Reference Materials
                </h3>
                <h4 className="page__section--sub-heading text__align--left">
                  Material Information
                </h4>
                <p className="page__section--para ">
                  Intended for calibration, validation and matrix-match
                  verification of cast iron spectrometric analysis: Spark,
                  Glow-Discharge or Laser Excitation Atomic Emission
                  Spectrometry, and X-ray Fluorescence Spectrometry.
                </p>

                <h4 className="page__section--sub-heading text__align--left">
                  Technical Parameters (CKD241 / CKD249)
                </h4>
                <p className="page__section--para ">
                  The samples were white chill-cast on a massive copper block
                  with controlled speed at a controlled temperature of the
                  molten metal. The samples are truncated pyramids with a base
                  analytical surface (38x38 mm), a minimum total height of 20 mm
                  and a side ledge 11-13 mm high. The samples can be used until
                  1 mm of the ledge height remains. The certified portion of a
                  sample extends 10-12 mm from the original analytical surface.
                  Shrinkage cavities and porosity, which may appear in the
                  uncertified portions of the samples, do not affect the
                  analytical performance of the certified portions. The
                  uncertified portions should not be used for calibration and/or
                  validation. Homogeneity was tested by Atomic Emission
                  Spectrometry with an analytical area approximately 4 mm in
                  diameter. The CRMs were tested for random homogeneity and
                  trend homogeneity along the height of the certified portion.
                  Trend homogeneity of the casting sequence was also tested. The
                  latter test was supported by Combustion-IR Molecular
                  Absorption Spectrometry and Thermo-evolution.
                </p>
              </section>

              <section className="page__section p_0">
                <div className="accordion accordion--show-more m_0">
                  <div className="accordion__item">
                    <div className="accordion__item--body">
                      <h4 className="page__section--sub-heading text__align--left">
                        Technical Parameters (CZ02033-1 / CZ02033-8)
                      </h4>
                      <p className="page__section--para">
                        Homogeneity of the certified constituents and of the
                        influence by structure were tested by the spark
                        excitation AES, the technique prevailing in the cast
                        iron analysis and coincidentally the most strongly
                        structure-influenced technique. The within-sample trend
                        homogeneity was tested as the difference of results on
                        the opposite limits of the certified layer, the
                        between-samples trend as the difference of results from
                        the beginning and the end of casting. Both were found
                        statistically insignificant except for a few cases,
                        contributions of which were combined to the ultimate
                        uncertainty of the certified values. Repeatability of
                        the subsequent analyses distributed evenly on the same
                        working surface was taken for a conservative estimate of
                        the within-sample random homogeneity, as the
                        repeatability of instrument itself cannot be exactly
                        separated. This overall repeatability was satisfactory
                        in respect to the uncertainty of all certified values.
                        The CRM are stable by the nature of their matrix.
                        Manufactured as gangs of chill-cast discs, cooled on
                        either side in vertical position. This resulted in a
                        white (i.e. effectively graphite free) structure
                        required by spectrometry. 0.5 mm was machined off either
                        cooling surface. Supplied in a set or as individual
                        discs 40 mm in diameter and approximately 18 mm of total
                        height, with two certified layers extending 6 mm upwards
                        from either working surface. The discs are marked on
                        side by the CRM code and the certified layers' limits.
                        When used to both limits, the remainder, which may
                        contain minor structure defects, should be discarded.
                      </p>
                    </div>
                    <button
                      className="accordion__item--btn"
                      onClick={accordionHandler}
                    >
                      Show more <ArrowDown />
                    </button>
                  </div>
                </div>
              </section>

              <section className="page__section bg--light p_3 mt_50">
                <h4 className="page__section--sub-heading text__align--left">
                  CMI Cast Iron Materials
                </h4>
                <ul className="catalog__list">
                  <li className="catalog__list--item">
                    <a href="!#">
                      <PDFIcon />
                      Low-Alloy Cast Iron Chemistries
                    </a>
                  </li>
                  <li className="catalog__list--item">
                    <a href="!#">
                      <PDFIcon />
                      CMI Cast Iron Brochure
                    </a>
                  </li>
                </ul>
              </section>

              <section className="page__section pb_0">
                <div className="row">
                  <div className="col_sm_12 col_md_12 col_lg_8">
                    <div className="product__info">
                      <h4 className="page__section--sub-heading text__align--left">
                        Lorem ipsum is a placeholder
                      </h4>
                      <ul className="product__info--list">
                        <li className="product__info--list-item">
                          Lorem ipsum is a placeholder text commonly used to
                          demonstrate the visual form of a document or a
                          typeface without relying on meaningful content.
                        </li>
                        <li className="product__info--list-item">
                          Lorem ipsum is a placeholder text commonly used to
                          demonstrate the visual form of a document or a
                          typeface without relying on meaningful content.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col_sm_12 col_md_12 col_lg_4">
                    <div className="product__pricing">
                      <h4 className="page__section--sub-heading text__align--left">
                        General Pricing
                      </h4>
                      <p className="page__section--para">
                        Please Call for Pricing :{" "}
                        <a href="tel:(720) 943-7676">(720) 943-7676</a>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer footerEmails={true} emailList={emailList} />
    </>
  );
};

export default CMICastIrons;
